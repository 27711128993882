import * as React from 'react'
import EntityService from './EntityServiceContext'
import EnvironmentService from './EnvironmentServiceContext'
import { useContext } from 'react'
import { KeDoContext } from '../kedo-context.js'
import DossierService from './DossierServiceContext'
import SocketService from './SocketServiceContext'

const GlobalServices = ({ children }) => {
    const kedo = useContext(KeDoContext)

    return (
        <>
            <EnvironmentService kedo={kedo}>
                <SocketService kedo={kedo}>
                    <EntityService kedo={kedo}>
                        <DossierService kedo={kedo}>{children}</DossierService>
                    </EntityService>
                </SocketService>
            </EnvironmentService>
        </>
    )
}

export default GlobalServices
