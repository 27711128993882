import React from 'react'
import { Button, Form, Col, Row, FormControl, Modal } from 'react-bootstrap'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye'
import InputGroup from 'react-bootstrap/InputGroup'
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash'
import image1 from '../../../../images/image1.jpg'
import image2 from '../../../../images/image2.jpg'
import image3 from '../../../../images/image3.jpg'
import image4 from '../../../../images/image4.jpg'
import image5 from '../../../../images/image5.jpg'

class UpdateUserPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            password: null,
            old_password: null,
            repeat_password: null,
            showPassword: false,
            showOldPassword: false,
            showRepeatPassword: false,
            bgStyle: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.updateEnterKey = this.updateEnterKey.bind(this)
        this.update = this.update.bind(this)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    updateEnterKey(event) {
        if (event.key === 'Enter') {
            this.update(event)
        }
    }

    renderModal() {
        return (
            <Modal
                centered
                show={true}
                onHide={() => this.setState({ changePassword: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Change password')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderForm()}</Modal.Body>
                <Modal.Footer>
                    <Button
                        title={this.props.kedo.t('Update')}
                        variant={'primary'}
                        type="submit"
                        onClick={this.update}
                    >
                        {this.state.submitting ? (
                            <LoadingDefault as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Update')}
                    </Button>{' '}
                    &nbsp;
                    <Button variant={'secondary'} onClick={this.props.onClose}>
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderForm() {
        let kedo = this.props.kedo
        const currentPath = window.location.pathname
        const isProfilePage = currentPath.endsWith('/profile')

        return (
            <Form onSubmit={this.update}>
                {isProfilePage ? (
                    <InputGroup>
                        <FormControl
                            autoFocus
                            isInvalid={
                                this.state.errors &&
                                this.state.errors['old_password']
                            }
                            name={'old_password'}
                            onChange={this.handleChange}
                            onKeyPress={this.updateEnterKey}
                            placeholder={kedo.t('Old password')}
                            readOnly={this.state.loading}
                            required
                            type={
                                this.state.showOldPassword ? 'text' : 'password'
                            }
                        />
                        <span className="input-group-append">
                            <Button
                                className="border-left-0"
                                onClick={() =>
                                    this.setState({
                                        showOldPassword:
                                            !this.state.showOldPassword,
                                    })
                                }
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.state.showOldPassword
                                            ? faEyeSlash
                                            : faEye
                                    }
                                ></FontAwesomeIcon>
                            </Button>
                        </span>
                        {this.state.errors &&
                        this.state.errors['old_password'] ? (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'text-left'}
                            >
                                {this.state.errors.old_password}
                            </Form.Control.Feedback>
                        ) : null}
                    </InputGroup>
                ) : null}
                <br />
                <InputGroup>
                    <FormControl
                        autoFocus
                        isInvalid={
                            this.state.errors && this.state.errors['password']
                        }
                        name={'password'}
                        onChange={this.handleChange}
                        onKeyPress={this.updateEnterKey}
                        placeholder={kedo.t('New password')}
                        readOnly={this.state.loading}
                        required
                        type={this.state.showPassword ? 'text' : 'password'}
                    />
                    <span className="input-group-append">
                        <Button
                            className="border-left-0"
                            onClick={() =>
                                this.setState({
                                    showPassword: !this.state.showPassword,
                                })
                            }
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.showPassword ? faEyeSlash : faEye
                                }
                            ></FontAwesomeIcon>
                        </Button>
                    </span>
                    {this.state.errors && this.state.errors['password'] ? (
                        <Form.Control.Feedback
                            type="invalid"
                            className={'text-left'}
                        >
                            {this.state.errors.password}
                        </Form.Control.Feedback>
                    ) : null}
                </InputGroup>
                <br />
                <InputGroup>
                    <FormControl
                        isInvalid={
                            this.state.errors &&
                            this.state.errors['repeat_password']
                        }
                        name={'repeat_password'}
                        onChange={this.handleChange}
                        onKeyPress={this.updateEnterKey}
                        placeholder={kedo.t('Repeat new password')}
                        readOnly={this.state.loading}
                        required
                        type={
                            this.state.showRepeatPassword ? 'text' : 'password'
                        }
                    />
                    <span className="input-group-append">
                        <Button
                            className="border-left-0"
                            onClick={() =>
                                this.setState({
                                    showRepeatPassword:
                                        !this.state.showRepeatPassword,
                                })
                            }
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.showRepeatPassword
                                        ? faEyeSlash
                                        : faEye
                                }
                            ></FontAwesomeIcon>
                        </Button>
                    </span>
                    {this.state.errors &&
                    this.state.errors['repeat_password'] ? (
                        <Form.Control.Feedback
                            type="invalid"
                            className={'text-left'}
                        >
                            {this.state.errors.repeat_password}
                        </Form.Control.Feedback>
                    ) : null}
                </InputGroup>
                <br />
            </Form>
        )
    }

    update(event) {
        event.preventDefault()
        const kedo = this.props.kedo

        this.setState({
            errors: false,
            submitting: true,
        })

        if (this.state.password !== this.state.repeat_password) {
            this.setState({
                submitting: false,
                errors: {
                    repeat_password: this.props.kedo.t(
                        'Passwords do not match'
                    ),
                },
            })
            return
        }

        let payload = {
            password: this.state.password,
            old_password: this.state.old_password,
        }

        kedo.api()
            .patch(kedo.api().getUserEndpoint() + '/change_password', payload)
            .then(() => {
                this.setState({
                    errors: false,
                    submitting: false,
                })

                this.props.onSuccess()
            })
            .catch((error) => {
                this.setState({
                    submitting: false,
                    errors: this.props.kedo
                        .api()
                        .getErrorFromApiCall(error.response),
                })
            })
    }

    componentDidMount() {
        const pictureArray = [image1, image2, image3, image4, image5]
        const randomIndex = Math.floor(Math.random() * pictureArray.length)
        const selectedPicture = pictureArray[randomIndex]

        this.setState({
            bgStyle: { backgroundImage: `url(${selectedPicture})` },
        })
    }

    render() {
        if (this.props.modal) {
            return this.renderModal()
        }

        return (
            <Col
                xs={12}
                style={this.state.bgStyle}
                className={'login outerContainer fullHeight'}
            >
                <Row className="d-flex justify-content-center">
                    <Col xs={10} md={8} lg={4} className="mainContainer">
                        <h1>{this.props.kedo.t('change_password_title')}</h1>
                        <p>{this.props.kedo.t('change_password_text')}</p>
                        {this.renderForm()}
                        <Button
                            title={this.props.kedo.t('Update')}
                            variant={'primary'}
                            type="submit"
                            onClick={this.update}
                        >
                            {this.state.submitting ? (
                                <LoadingDefault as={'span'} />
                            ) : null}{' '}
                            {this.props.kedo.t('Update')}
                        </Button>
                    </Col>
                </Row>
            </Col>
        )
    }
}

export default UpdateUserPassword
