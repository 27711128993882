class User {
    constructor() {
        this.username = null
        this.user_id = null
        this.roles = []
    }

    clearInfo() {
        this.username = null
        this.user_id = null
        this.roles = []
    }

    getUserId() {
        this.checkLocalStorage()

        return parseInt(this.user_id)
    }

    /**
     * @returns {null|String}
     */
    getUsername() {
        this.checkLocalStorage()

        return this.username
    }

    /**
     * @returns {boolean}
     */
    isLoggedIn() {
        if (this.getUsername() == null) {
            return false
        }

        let tokenData = localStorage.getItem('tokenData')
        if (!tokenData || tokenData.length <= 0) {
            return false
        }

        tokenData = JSON.parse(tokenData)

        if (!tokenData || !tokenData.exp) {
            return false
        }

        let now = new Date()

        return now.getTime() < tokenData.exp * 1000
    }

    checkLocalStorage() {
        var newUsername = localStorage.getItem('username')
        if (this.username != null && newUsername === this.username) {
            return
        }

        if (
            localStorage.getItem('username') === null ||
            localStorage.getItem('username').length <= 0
        ) {
            this.username = null
            this.user_id = null
            this.roles = []
            return
        }

        this.username = localStorage.getItem('username')
        this.user_id = localStorage.getItem('user_id')
        this.roles = JSON.parse(localStorage.getItem('roles'))
    }

    /**
     * Update token
     * @param {Object} tokenData Object from the jwt token response
     */
    updateInfoFromJWTToken(tokenData) {
        localStorage.setItem('username', tokenData.username)
        localStorage.setItem('roles', JSON.stringify(tokenData.roles))
        localStorage.setItem('user_id', tokenData.user_id)
        if (tokenData.re_pwd === true) {
            localStorage.setItem('forceUpdate', true)
        }

        this.username = tokenData.username
        this.roles = tokenData.roles
    }

    /**
     * @returns {boolean}
     */
    isContractAdminUser() {
        this.checkLocalStorage()

        return this.roles.indexOf('ROLE_CONTRACT_ADMIN') > -1
    }

    /**
     * @returns {boolean}
     */
    isContracAdmintUser() {
        this.checkLocalStorage()

        return this.roles.indexOf('ROLE_CONTRACT_ADMIN') > -1
    }

    /**
     * @returns {boolean}
     */
    isSuperAdmin() {
        this.checkLocalStorage()

        return this.roles.indexOf('ROLE_ADMIN') > -1
    }

    /**
    * @returns {boolean}
    */
    isSingleSignOnUser() {
        this.checkLocalStorage()

        return this.roles.indexOf('ROLE_AZURE_USER') > -1
    }
}

export default User
