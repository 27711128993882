import React from 'react'
import { Modal } from 'react-bootstrap'
import Login from './auth/Login'
import { KeDoContext } from '../kedo-context'

class LoginModal extends React.Component {
    constructor(props) {
        super(props)

        this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this)
    }

    static contextType = KeDoContext

    handleSuccessfulAuth(data) {
        this.props.handleLogin(data)
    }

    render() {
        const kedo = this.context

        return (
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={this.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{kedo.t('inactive_notification')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'login'}>
                        <Login
                            kedo={this.props.kedo}
                            handleSuccessfulAuth={this.handleSuccessfulAuth}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default LoginModal
