import React from 'react'
import { Alert } from 'react-bootstrap'
import BlockCountList from '../Pages/MyPage/BlockCountList'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import Block from '../Pages/MyPage/Block'
import BlockSumFields from '../Pages/MyPage/BlockSumFields'

class DisplayItemBlock extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: false,
            block: null,
        }
    }

    fetchBlock() {
        const kedo = this.props.kedo
        const api = kedo.api()
        this.setState({ loading: true })
        api.get(api.getMyPageBlockEndpoint(this.props.id))
            .then((response) => {
                this.setState({
                    error: false,
                    block: response.data,
                    loading: false,
                })
            })
            .catch(() =>
                this.setState({
                    error: true,
                    loading: false,
                })
            )
    }

    componentDidMount() {
        this.fetchBlock()
    }

    render() {
        const kedo = this.props.kedo
        if (this.state.loading) {
            return <LoadingDefault />
        }

        if (this.state.error || !this.state.block) {
            return (
                <Alert variant={'warning'}>
                    {kedo.t('Something went wrong')}
                </Alert>
            )
        }

        let link = this.props.link
            ? this.props.link
            : this.props.settings.block_link

        switch (this.state.block.type) {
            case 'dashboard':
                return (
                    <Block
                        kedo={kedo}
                        misc={this.props.misc}
                        link={link}
                        dossier={this.props.dossier}
                        block={this.state.block}
                        settingsLink={this.props.settings}
                    />
                )
            case 'count_list_options':
                return (
                    <BlockCountList
                        kedo={kedo}
                        link={link}
                        dossier={this.props.dossier}
                        block={this.state.block}
                    />
                )
            case 'sum_fields':
                return (
                    <BlockSumFields
                        dossier={this.props.dossier}
                        id={this.state.block.id}
                        link={link}
                        kedo={kedo}
                        block={this.state.block}
                    />
                )
        }

        return ''
    }
}

export default DisplayItemBlock
