import React from 'react'
import BlockForm from '../../Admin/Pages/Blocks/BlockForm'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog'

export default class BlockEditButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            size: this.props.size ? this.props.size : 'sm',
        }
    }

    render() {
        return (
            <div>
                {this.state.edit ? (
                    <BlockForm
                        variant={'edit'}
                        item={this.props.item}
                        kedo={this.props.kedo}
                        handleClose={this.props.handleClose}
                        onClose={() => this.setState({ edit: false })}
                        onSuccess={() => {
                            this.setState({ edit: false })
                            this.props.onSuccess()
                        }}
                    />
                ) : null}
                <Button
                    size={this.state.size}
                    variant={'primary'}
                    className={'block-edit-btn float-right'}
                    onClick={() => this.setState({ edit: true })}
                >
                    <FontAwesomeIcon icon={faCog} />
                </Button>
            </div>
        )
    }
}
