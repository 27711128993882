import * as React from 'react'
import Contextualizer from './Contextualizer'
import ProvidedServices from './ProvidedServices'

export interface IEnvironmentListParams {
    limit?: number
    name?: string
    page?: number
}

export interface IEnvironmentList extends IndexResultsInterface {
    results: Environment[]
}

export interface IEnvironmentService {
    getEnvironments(params?: IEnvironmentListParams): Promise<IEnvironmentList>
    getEnvironment(id: number): Promise<Environment>
}

const EnvironmentServiceContext = Contextualizer.createContext(
    ProvidedServices.EnvironmentService
)

const EnvironmentService = ({ kedo, children }) => {
    const environmentService = {
        async getEnvironments(
            params?: IEnvironmentListParams
        ): Promise<IEnvironmentList> {
            const results = await kedo
                .api()
                .get(kedo.api().getEnvironmentEndpoint(), { params: params })

            //TODO: Create generic class for mapping these results.
            return {
                pager: results.data.pager,
                results: results.data.results,
            }
        },
        async getEnvironment(id: number): Promise<Environment> {
            const result = await kedo
                .api()
                .get(kedo.api().getEnvironmentEndpoint(id))

            return result.data
        },
    }

    return (
        <>
            <EnvironmentServiceContext.Provider value={environmentService}>
                {children}
            </EnvironmentServiceContext.Provider>
        </>
    )
}

export default EnvironmentService

export const useEnvironmentService = () =>
    Contextualizer.use<IEnvironmentService>(ProvidedServices.EnvironmentService)
