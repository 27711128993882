import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DefDossierDefFieldAutoIncrementSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prefix: this.props.item && this.props.item.settings && this.props.item.settings.prefix ? this.props.item.settings.prefix : null,
        }
    }

    render() {
        return  <div>
                    <h5>{this.props.kedo.t('Additional settings')}</h5>
                    <Form.Group controlId="prefixx">
                        <Form.Label>{this.props.kedo.t('Prefix')}</Form.Label>
                        <Form.Control
                            value={this.state.prefix}
                            name={'prefix'}
                            onChange={(e) => { this.setState({prefix: e.target.value}); setTimeout(() => this.props.onChange('prefix', e.target.value), 50) }}
                        />
                    </Form.Group>
                </div>
    }
}

export default DefDossierDefFieldAutoIncrementSettings