import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Col, Dropdown, Row } from 'react-bootstrap'
import LoadingContent from '../../../Components/Elements/Loading/LoadingContent'
import UpperMenu from '../../../Components/Template/UpperMenu/UpperMenu'
import NewEnvironment from '../../../Components/Pages/Environment/NewEnvironment'
import EnvironmentCard from '../../../Components/Pages/Environment/EnvironmentCard'
import DefaultSearchbar from '../../../Components/Elements/Search/DefaultSearchbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTools } from '@fortawesome/pro-duotone-svg-icons/faTools'
import { faCopy } from '@fortawesome/pro-duotone-svg-icons/faCopy'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'

import Pagination from '../../../Components/Elements/Pagination'
import UpdateUserPassword from '../../../Components/Admin/Pages/User/UpdateUserPassword'
import LoadingPage from '../../../Components/Elements/Loading/LoadingPage'
import { KeDoContext } from '../../../kedo-context'
import { useHistory, useLocation } from 'react-router-dom'
import { useEnvironmentService } from '../../../context/EnvironmentServiceContext.tsx'
import { useSocketService } from '../../../context/SocketServiceContext.tsx'

const EnvironmentIndex = () => {
    const [page, setPage] = useState(0)
    const [pager, setPager] = useState(null)
    const [searchName, setSearchName] = useState('')

    const [fetching, setFetching] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [items, setItems] = useState([])

    const history = useHistory()
    const location = useLocation()
    const kedo = useContext(KeDoContext)
    const environmentService = useEnvironmentService()
    const socketService = useSocketService()

    const clearPager = () => {
        setFetching(true)
        setPage(0)
        setSearchName('')
    }

    useEffect(() => {
        fetchEnvironments()
    }, [page])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setPage(0)
            fetchEnvironments()
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }, [searchName])

    const setEnvironment = async (item) => {
        setFetching(true)

        const environment = await environmentService.getEnvironment(item.id)
        kedo.env().setEnvironment(environment)
        history.push('/')

        socketService.environment.join(item.id)

        setFetching(false)

        window.scrollTo(0, 0)
    }

    const onChangePage = (pageNumber) => {
        setPage(pageNumber)
    }

    const fetchEnvironments = async () => {
        setFetching(true)

        try {
            const envListResult = await environmentService.getEnvironments({
                limit: 12,
                page: page,
                name: searchName,
            })
            setItems(envListResult.results)
            setPager(envListResult.pager)
            setFetching(false)

            return envListResult
        } catch (err) {
            //TODO: Handle errors here
            setFetching(false)
        }
    }

    /**
     * Checks if we need to redirect to another environment.
     *
     * @param items
     * @returns {boolean}
     */
    const redirectImmediately = (envItems) => {
        if (kedo.isAdminOrContractAdmin() || envItems.length > 1) {
            return false
        } else if (
            envItems.length === 1 &&
            localStorage.getItem('forceUpdate') === null
        ) {
            return true
        }
        return false
    }

    const resetButton = () => {
        return (
            <button
                onClick={() => {
                    clearPager()
                }}
                className="btn bg-transparent resetButton"
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        )
    }

    useEffect(() => {
        const doInit = async () => {
            const envListResult = await fetchEnvironments()
            if (redirectImmediately(envListResult.results)) {
                setEnvironment(envListResult.results[0])
                setLoading(true)
            }
        }
        doInit()
    }, [])

    if (localStorage.getItem('forceUpdate')) {
        return (
            <UpdateUserPassword
                kedo={kedo}
                onSuccess={() => {
                    localStorage.removeItem('forceUpdate')
                    fetchEnvironments()
                }}
            />
        )
    }

    return (
        <Col xs={12} style={{ padding: 0 }}>
            {isNew ? (
                <NewEnvironment
                    kedo={kedo}
                    onClose={() => {
                        setIsNew(false)
                        fetchEnvironments()
                    }}
                />
            ) : null}
            <UpperMenu kedo={kedo} template={'environment'} />
            <div className={'outerContainer fullHeight'}>
                <div className="mainContainer">
                    <Row>
                        <Col xs={12} md={8} lg={8}>
                            <h1>{kedo.t('Select environment')}</h1>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            {kedo.isAdminOrContractAdmin() ? (
                                <div className={'environmentToolbar'}>
                                    <Button
                                        onClick={() => setIsNew(true)}
                                        variant={'primary'}
                                        style={{ marginLeft: '15px' }}
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        &nbsp; {kedo.t('New')}
                                    </Button>
                                    <Dropdown className={'environmentTools'}>
                                        <Dropdown.Toggle
                                            variant="secondary"
                                            id="dropdown-basic"
                                        >
                                            <FontAwesomeIcon icon={faTools} />
                                            &nbsp; {kedo.t('Tools')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/templates">
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                />
                                                &nbsp; {kedo.t('Templates')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <br />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <DefaultSearchbar
                        kedo={kedo}
                        handleChange={(e) => setSearchName(e.target.value)}
                        handleSearch={() => {
                            //
                        }}
                        searchName={searchName}
                        resetButton={resetButton}
                    />
                    {fetching ? (
                        <LoadingContent kedo={kedo} />
                    ) : (
                        <Row className={'d-flex align-items-stretch'}>
                            {items.length <= 0 ? (
                                <Col xs={12}>
                                    <Alert variant={'info'}>
                                        <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                        />
                                        &nbsp; {kedo.t('No environments found')}
                                    </Alert>
                                </Col>
                            ) : null}
                            {items.map((item) => (
                                <Col
                                    key={item.id}
                                    className={'mb-2'}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={3}
                                    xl={2}
                                >
                                    <EnvironmentCard
                                        kedo={kedo}
                                        item={item}
                                        environment={() => {
                                            setEnvironment(item)
                                        }}
                                        onClose={fetchEnvironments}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}
                    {pager && pager.pages && pager.pages > 0 ? (
                        <Pagination
                            onChangePage={onChangePage}
                            pager={pager}
                            limit={12}
                            searchName={searchName}
                            fetch={fetchEnvironments}
                        />
                    ) : null}
                </div>
            </div>
        </Col>
    )
}

export default EnvironmentIndex
