import React, { Component } from 'react'
import { Form, Button, ButtonGroup } from 'react-bootstrap'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class BlockTabQueries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabQueries: this.props.tabQueries ? this.props.tabQueries : [],
        }
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div>
                <h3>{kedo.t('Tab queries')}</h3>
                <ButtonGroup>
                    <Button onClick={this.props.addTabQuery}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </ButtonGroup>
                {this.props.tabQueries
                    ? this.props.tabQueries.map((item, index) => (
                          <div key={item.query}>
                              <Button
                                  size={'sm'}
                                  variant={'danger'}
                                  className={'float-right'}
                                  onClick={() =>
                                      this.props.deleteTabQuery(index)
                                  }
                              >
                                  <FontAwesomeIcon icon={faTimes} />
                              </Button>
                              <Form.Group>
                                  <Form.Label>
                                      {this.props.kedo.t('Query')}
                                  </Form.Label>
                                  <Form.Control
                                      value={item.query}
                                      as="select"
                                      onChange={(e) => {
                                          this.props.onChangeTabQueries(
                                              item,
                                              index,
                                              'query',
                                              e.target.value
                                          )
                                      }}
                                  >
                                      <option value={''}>
                                          {kedo.t('Choose an option')}
                                      </option>
                                      {this.props.dossierQueries.map((item) => (
                                          <option key={item.id} value={item.id}>
                                              {this.props.kedo
                                                  .env()
                                                  .translateItem(
                                                      item,
                                                      'dossierquery'
                                                  )}
                                          </option>
                                      ))}
                                  </Form.Control>
                              </Form.Group>
                              <Form.Group>
                                  <Form.Label>
                                      {this.props.kedo.t('Title')}
                                  </Form.Label>
                                  <Form.Control
                                      onChange={(e) => {
                                          this.props.onChangeTabQueries(
                                              item,
                                              index,
                                              'title',
                                              e.target.value
                                          )
                                      }}
                                      value={item.title}
                                  />
                              </Form.Group>
                          </div>
                      ))
                    : null}
            </div>
        )
    }
}

export default BlockTabQueries
