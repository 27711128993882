import * as React from 'react'
import { useState, useEffect } from 'react'
import {
    Button,
    Modal,
    Form,
    Row,
    Col,
    ProgressBar,
    Alert,
    ButtonGroup,
    ToggleButton,
} from 'react-bootstrap'
import { Kedo } from '../../../../Kedo'
import * as Datetime from 'react-datetime'
import { RRule, Options } from 'rrule'

import DisplayItemFilter from '../../../DisplayItems/Filter/DisplayItemFilter'
import * as moment from 'moment'

type MultiAddProps = {
    kedo: Kedo
    handleGetValues: () => Array<any>
    handleClose: () => void
    handleSuccess: () => void
    displayItems: Array<any>
    defDossier: any
}

const ShowMultiAdd = ({
    kedo,
    handleGetValues,
    displayItems,
    handleClose,
    handleSuccess,
    defDossier,
}: MultiAddProps) => {
    const [item, setItem] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [curPeriod, setCurPeriod] = useState('daily')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [days, setDays] = useState([])
    const [dates, setDates] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [done, setDone] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])
    const [mode, setMode] = useState('BYMONTHDAY')
    const [byMonth, setByMonth] = useState('1')
    const [byMonthDay, setByMonthDay] = useState('1')
    const [bySetPos, setBySetPos] = useState(null)
    const [endMode, setEndMode] = useState('date')
    const [afterExecutions, setAfterExecutions] = useState(1)
    const [intervaling, setIntervaling] = useState(1)

    useEffect(() => {
        const values = handleGetValues()
        if (values[item?.id]) {
            const start = new Date(values[item?.id])
            const end = new Date()
            setStartDate(start)
            setEndDate(end.setDate(start.getDate() + 1))
        } else {
            setStartDate(new Date())
            setEndDate(new Date())
        }
    }, [item])

    const handleRecurChange = (e) => {
        if (!['daily', 'weekly'].includes(e.target.value)) {
            setDays([])
        }

        switch (e.target.value) {
            case 'yearly':
                setMode('BYMONTH')
                setByMonthDay('1')
                setByMonth('1')
                break
            case 'monthly':
                setMode('BYMONTHDAY')
                setByMonthDay('1')
                break
        }

        if (e.target.value === '') {
            setCurPeriod(null)
            return
        }

        setCurPeriod(e.target.value)
    }

    const handleEndChange = (e) => {
        if (e.target.value === '') {
            setEndMode(null)
            return
        }

        setEndMode(e.target.value)
    }

    const changeDay = (e): void => {
        if (curPeriod === 'monthly') {
            setDays(e.target.value.split(','))
        } else {
            let newDays = [...days]
            newDays = newDays.filter((item) => item !== e.target.value)

            if (e.target.checked) {
                newDays.push(e.target.value)
            }

            setDays(newDays)
        }
    }

    useEffect(() => {
        setErrors([])

        if (!startDate || !endDate) {
            return
        }
        setDateRules()
    }, [
        days,
        startDate,
        endDate,
        intervaling,
        afterExecutions,
        byMonthDay,
        byMonth,
        bySetPos,
    ])

    const getFreq = (period: string) => {
        switch (period) {
            case 'daily':
                return RRule.DAILY
            case 'yearly':
                return RRule.YEARLY
            case 'monthly':
                return RRule.MONTHLY
            case 'weekly':
                return RRule.WEEKLY
            default:
                return RRule.MONTHLY
        }
    }

    const setDateRules = () => {
        const weekDays = []
        const errorsArray = []

        if (days.includes('Sunday')) weekDays.push(RRule.SU)
        if (days.includes('Monday')) weekDays.push(RRule.MO)
        if (days.includes('Tuesday')) weekDays.push(RRule.TU)
        if (days.includes('Wednesday')) weekDays.push(RRule.WE)
        if (days.includes('Thursday')) weekDays.push(RRule.TH)
        if (days.includes('Friday')) weekDays.push(RRule.FR)
        if (days.includes('Saturday')) weekDays.push(RRule.SA)

        const data = {
            interval: intervaling,
            freq: getFreq(curPeriod),
            dtstart: startDate,
        } as Options

        if (weekDays.length > 0) {
            data.byweekday = weekDays
        }

        if (mode === 'BYSETPOS') {
            data.bysetpos = +bySetPos

            if (curPeriod === 'yearly') {
                data.bymonth = parseInt(byMonth)
            }
        }

        if (mode === 'BYMONTHDAY' && weekDays.length < 0) {
            data.bymonthday = parseInt(byMonthDay)
        }

        if (mode === 'BYMONTH' && weekDays.length < 0) {
            data.bymonthday = parseInt(byMonthDay)
            data.bymonth = parseInt(byMonth)
        }

        if (endMode === 'after') {
            data.count = afterExecutions
        }

        if (endMode === 'date') {
            data.until = endDate
        }

        const rule = new RRule(data)
        const all = rule.all()

        setErrors(errorsArray)
        setDates(all)
    }

    const changeMonthMode = (e): void => {
        if (e.target.name !== mode) {
            setDays([])
            setBySetPos(null)
            setByMonthDay(null)
            setByMonth(null)
            setMode(e.target.name)
            initMonthMode(e.target.name)
        }
    }

    const initMonthMode = (mode): void => {
        if (mode === 'BYMONTH') {
            setByMonthDay('1')
            setByMonth('1')
        }

        if (mode === 'BYMONTHDAY') {
            setByMonthDay('1')
        }

        if (mode === 'BYSETPOS') {
            setBySetPos('1')
            setDays(['Monday'])

            if (curPeriod === 'yearly') {
                setByMonth('1')
            }
        }
    }

    const translateDays = (period) => {
        switch (period) {
            case 'daily':
                return 'Day'
            case 'yearly':
                return 'year'
            case 'monthly':
                return 'Month'
            case 'weekly':
                return 'Week'
            default:
                return ''
        }
    }

    const isValidStartDate = (current) => {
        if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'before_and_eq_current_date'
        ) {
            return current.isSameOrBefore(moment())
        } else if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'after_and_eq_current_date'
        ) {
            return current.isSameOrAfter(moment())
        } else {
            return current
        }
    }

    const isValidEndDate = (current) => {
        if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'before_and_eq_current_date'
        ) {
            return current.isSameOrBefore(moment())
        } else if (
            item?.def_dossier_def_field?.def_field?.settings?.restriction ===
            'after_and_eq_current_date'
        ) {
            return current.isSameOrAfter(moment())
        } else {
            return current >= moment(startDate).add(-1, 'days')
        }
    }

    const submitDossier = async (date) => {
        const values = handleGetValues()
        values['defDossier'] = defDossier.id

        const itemId = item.id
        values[itemId] = date.toISOString()
        setCurrentItem(date.toLocaleString())
        const response = await kedo
            .api()
            .post(kedo.api().getContentDossierEndpoint(), values)
    }

    const submitDossiers = async () => {
        const errorsArray = []

        if (!isValidStartDate(moment(startDate))) {
            errorsArray.push(kedo.t('end_or_start_date_future'))
        }

        if (!isValidEndDate(moment(endDate))) {
            errorsArray.push(kedo.t('end_date_out_of_range'))
        }

        if (errorsArray.length >= 0) {
            setErrors(errorsArray)
        }

        if (dates.length <= 0) {
            return
        }

        setTotalItems(dates.length)
        setSubmitting(true)

        const forLoop = async () => {
            for (let index = 0; index < dates.length; index++) {
                await submitDossier(dates[index])
                setDone(index + 1)
            }
        }
        await forLoop()
        setSuccess(true)
        setTimeout(() => {
            setSubmitting(false)
            handleSuccess()
        }, 2000)
    }

    const getCurrentLocale = () => {
        const locale = kedo.env().getSelectedLocale()
        if (locale && locale.code) {
            return locale.code.replace('_', '-')
        }

        return 'en'
    }

    const renderItem = () => {
        if (!item) {
            return
        }

        const recurrent = [
            { name: kedo.t('Daily'), value: 'daily' },
            { name: kedo.t('Weekly'), value: 'weekly' },
            { name: kedo.t('monthly'), value: 'monthly' },
            { name: kedo.t('yearly'), value: 'yearly' },
        ]

        const shortDays = [
            { name: kedo.t('days_short.Sun'), value: 'Sunday' },
            { name: kedo.t('days_short.Mon'), value: 'Monday' },
            { name: kedo.t('days_short.Tue'), value: 'Tuesday' },
            { name: kedo.t('days_short.Wed'), value: 'Wednesday' },
            { name: kedo.t('days_short.Thu'), value: 'Thursday' },
            { name: kedo.t('days_short.Fri'), value: 'Friday' },
            { name: kedo.t('days_short.Sat'), value: 'Saturday' },
        ]

        const shortMonths = [
            { name: kedo.t('months_short.Jan'), value: '1' },
            { name: kedo.t('months_short.Feb'), value: '2' },
            { name: kedo.t('months_short.Mar'), value: '3' },
            { name: kedo.t('months_short.Apr'), value: '4' },
            { name: kedo.t('months_short.May'), value: '5' },
            { name: kedo.t('months_short.Jun'), value: '6' },
            { name: kedo.t('months_short.Jul'), value: '7' },
            { name: kedo.t('months_short.Aug'), value: '8' },
            { name: kedo.t('months_short.Sep'), value: '9' },
            { name: kedo.t('months_short.Oct'), value: '10' },
            { name: kedo.t('months_short.Nov'), value: '11' },
            { name: kedo.t('months_short.Dec'), value: '12' },
        ]

        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.def_field &&
            item.def_dossier_def_field.def_field.type === 'date'
        ) {
            return (
                <>
                    <div className="container">
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                {kedo.t('Start date')}
                            </Form.Label>
                            <Col sm="10">
                                <Datetime
                                    locale={getCurrentLocale()}
                                    inputProps={{
                                        disabled: submitting,
                                        readOnly: true,
                                    }}
                                    closeOnSelect={true}
                                    onChange={(newDate) => {
                                        if (typeof newDate === 'string') {
                                            setStartDate(new Date(newDate))
                                            return
                                        }
                                        setStartDate(new Date(newDate.format()))
                                    }}
                                    value={startDate}
                                    isValidDate={isValidStartDate}
                                    utc={false}
                                />
                            </Col>
                        </Form.Group>
                        <hr />
                        <Form.Group as={Row} style={{ flexWrap: 'nowrap' }}>
                            <Form.Label column sm="2">
                                {kedo.t('Repeat')}
                            </Form.Label>
                            <Col
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <ButtonGroup className="mb-1" toggle>
                                    {recurrent.map((recur, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            type="radio"
                                            variant={
                                                curPeriod ===
                                                (recur.value || null)
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                            name="radio"
                                            value={recur.value}
                                            checked={
                                                curPeriod ===
                                                (recur.value || null)
                                            }
                                            onChange={(e) =>
                                                handleRecurChange(e)
                                            }
                                        >
                                            {recur.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>
                                {['daily', 'weekly'].includes(curPeriod) ? (
                                    <ButtonGroup className="mb-1" toggle>
                                        {shortDays.map((day, idx) => (
                                            <ToggleButton
                                                key={idx}
                                                type="checkbox"
                                                variant={
                                                    days.includes(day.value)
                                                        ? 'secondary'
                                                        : 'primary'
                                                }
                                                name="check"
                                                value={day.value}
                                                checked={days.includes(
                                                    day.value
                                                )}
                                                onChange={changeDay}
                                                disabled={curPeriod === null}
                                            >
                                                {day.name}
                                            </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                ) : null}
                                {['monthly'].includes(curPeriod) ? (
                                    <>
                                        <Form.Row
                                            className={'align-items-center p-2'}
                                        >
                                            <Form.Check
                                                checked={
                                                    mode === 'BYMONTHDAY'
                                                        ? true
                                                        : false
                                                }
                                                onChange={changeMonthMode}
                                                name={'BYMONTHDAY'}
                                                type="checkbox"
                                            />
                                            <Form.Label
                                                className={'no-margin'}
                                                sm={3}
                                            >
                                                {kedo.t('On day')}
                                            </Form.Label>
                                            <Col sm={4} xs={2}>
                                                <Form.Control
                                                    defaultValue={byMonthDay}
                                                    disabled={
                                                        mode !== 'BYMONTHDAY'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setByMonthDay(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    {Array.from(
                                                        Array(32).keys()
                                                    )
                                                        .slice(1)
                                                        .map((item, index) => (
                                                            <option
                                                                key={index}
                                                                value={`${item}`}
                                                            >
                                                                {item}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row
                                            className={'align-items-center p-2'}
                                        >
                                            <Form.Check
                                                checked={
                                                    mode === 'BYSETPOS'
                                                        ? true
                                                        : false
                                                }
                                                onChange={changeMonthMode}
                                                name={'BYSETPOS'}
                                                type="checkbox"
                                            />
                                            <Form.Label
                                                className={'no-margin'}
                                                sm={3}
                                            >
                                                {kedo.t('On the')}
                                            </Form.Label>
                                            <Col sm={4} xs={3}>
                                                <Form.Control
                                                    defaultValue={bySetPos}
                                                    disabled={
                                                        mode !== 'BYSETPOS'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setBySetPos(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    <option value={'1'}>
                                                        {kedo.t('First')}
                                                    </option>
                                                    <option value={'2'}>
                                                        {kedo.t('Second')}
                                                    </option>
                                                    <option value={'3'}>
                                                        {kedo.t('Third')}
                                                    </option>
                                                    <option value={'4'}>
                                                        {kedo.t('Fourth')}
                                                    </option>
                                                    <option value={'-1'}>
                                                        {kedo.t('Last')}
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={4} xs={3}>
                                                <Form.Control
                                                    defaultValue={days.toString()}
                                                    disabled={
                                                        mode !== 'BYSETPOS'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={changeDay}
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    <option value={'Monday'}>
                                                        {kedo.t(
                                                            'days_full.Monday'
                                                        )}
                                                    </option>
                                                    <option value={'Tuesday'}>
                                                        {kedo.t(
                                                            'days_full.Tuesday'
                                                        )}
                                                    </option>
                                                    <option value={'Wednesday'}>
                                                        {kedo.t(
                                                            'days_full.Wednesday'
                                                        )}
                                                    </option>
                                                    <option value={'Thursday'}>
                                                        {kedo.t(
                                                            'days_full.Thursday'
                                                        )}
                                                    </option>
                                                    <option value={'Friday'}>
                                                        {kedo.t(
                                                            'days_full.Friday'
                                                        )}
                                                    </option>
                                                    <option value={'Saturday'}>
                                                        {kedo.t(
                                                            'days_full.Saturday'
                                                        )}
                                                    </option>
                                                    <option value={'Sunday'}>
                                                        {kedo.t(
                                                            'days_full.Sunday'
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            'Monday,Tuesday,Wednesday,Thursday,Friday'
                                                        }
                                                    >
                                                        {kedo.t('Weekday')}
                                                    </option>
                                                    <option
                                                        value={
                                                            'Saturday,Sunday'
                                                        }
                                                    >
                                                        {kedo.t('Weekend day')}
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>
                                    </>
                                ) : null}
                                {['yearly'].includes(curPeriod) ? (
                                    <>
                                        <Form.Row
                                            className={'align-items-center p-2'}
                                        >
                                            <Form.Check
                                                checked={
                                                    mode === 'BYMONTH'
                                                        ? true
                                                        : false
                                                }
                                                onChange={changeMonthMode}
                                                name={'BYMONTH'}
                                                type="checkbox"
                                            />
                                            <Form.Label
                                                className={'no-margin'}
                                                sm={3}
                                            >
                                                {kedo.t('On day')}
                                            </Form.Label>
                                            <Col sm={4} xs={2}>
                                                <Form.Control
                                                    defaultValue={byMonthDay}
                                                    disabled={
                                                        mode !== 'BYMONTH'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setByMonthDay(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    {Array.from(
                                                        Array(31).keys()
                                                    )
                                                        .slice(1)
                                                        .map((item, index) => (
                                                            <option
                                                                key={index}
                                                                value={`${item}`}
                                                            >
                                                                {item}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </Col>
                                            <Col sm={4} xs={2}>
                                                <Form.Control
                                                    defaultValue={byMonthDay}
                                                    disabled={
                                                        mode !== 'BYMONTH'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setByMonth(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    {shortMonths.map(
                                                        (month, index) => (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    month.value
                                                                }
                                                            >
                                                                {month.name}
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row
                                            className={'align-items-center p-2'}
                                        >
                                            <Form.Check
                                                checked={
                                                    mode === 'BYSETPOS'
                                                        ? true
                                                        : false
                                                }
                                                onChange={changeMonthMode}
                                                name={'BYSETPOS'}
                                                type="checkbox"
                                            />
                                            <Form.Label
                                                className={'no-margin'}
                                                sm={3}
                                            >
                                                {kedo.t('On the')}
                                            </Form.Label>
                                            <Col xs={3}>
                                                <Form.Control
                                                    defaultValue={bySetPos}
                                                    disabled={
                                                        mode !== 'BYSETPOS'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setBySetPos(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    <option value={'1'}>
                                                        {kedo.t('First')}
                                                    </option>
                                                    <option value={'2'}>
                                                        {kedo.t('Second')}
                                                    </option>
                                                    <option value={'3'}>
                                                        {kedo.t('Third')}
                                                    </option>
                                                    <option value={'4'}>
                                                        {kedo.t('Fourth')}
                                                    </option>
                                                    <option value={'-1'}>
                                                        {kedo.t('Last')}
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Control
                                                    defaultValue={days.toString()}
                                                    disabled={
                                                        mode !== 'BYSETPOS'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={changeDay}
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    <option value={'Monday'}>
                                                        {kedo.t(
                                                            'days_full.Monday'
                                                        )}
                                                    </option>
                                                    <option value={'Tuesday'}>
                                                        {kedo.t(
                                                            'days_full.Tuesday'
                                                        )}
                                                    </option>
                                                    <option value={'Wednesday'}>
                                                        {kedo.t(
                                                            'days_full.Wednesday'
                                                        )}
                                                    </option>
                                                    <option value={'Thursday'}>
                                                        {kedo.t(
                                                            'days_full.Thursday'
                                                        )}
                                                    </option>
                                                    <option value={'Friday'}>
                                                        {kedo.t(
                                                            'days_full.Friday'
                                                        )}
                                                    </option>
                                                    <option value={'Saturday'}>
                                                        {kedo.t(
                                                            'days_full.Saturday'
                                                        )}
                                                    </option>
                                                    <option value={'Sunday'}>
                                                        {kedo.t(
                                                            'days_full.Sunday'
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            'Monday,Tuesday,Wednesday,Thursday,Friday'
                                                        }
                                                    >
                                                        {kedo.t('Weekday')}
                                                    </option>
                                                    <option
                                                        value={
                                                            'Saturday,Sunday'
                                                        }
                                                    >
                                                        {kedo.t('Weekend day')}
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs={3}>
                                                <Form.Control
                                                    defaultValue={byMonthDay}
                                                    disabled={
                                                        mode !== 'BYSETPOS'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        setByMonth(
                                                            e.target.value
                                                        )
                                                    }
                                                    as={'select'}
                                                    size="sm"
                                                >
                                                    {shortMonths.map(
                                                        (month, index) => (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    month.value
                                                                }
                                                            >
                                                                {month.name}
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            </Col>
                                        </Form.Row>
                                    </>
                                ) : null}
                                <Form.Row
                                    style={{
                                        marginRight: 'inherit',
                                        marginLeft: 'inherit',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Form.Label sm={3}>
                                        {kedo.t('Repeat every')}
                                    </Form.Label>
                                    <Col sm={2} xs={2}>
                                        <Form.Control
                                            size="sm"
                                            value={intervaling}
                                            onChange={(e: any) => {
                                                const value = e.target.value
                                                if (
                                                    (/^[0-9]+$/.test(value) &&
                                                        value.length < 4) ||
                                                    e.nativeEvent.inputType ==
                                                        'deleteContentBackward' ||
                                                    e.nativeEvent.inputType ==
                                                        'deleteContentForward'
                                                ) {
                                                    if (value === '') {
                                                        setIntervaling(0)
                                                    } else {
                                                        setIntervaling(
                                                            parseInt(value)
                                                        )
                                                    }
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Form.Label sm={3}>
                                        {kedo
                                            .t(translateDays(curPeriod))
                                            .toLowerCase()}
                                    </Form.Label>
                                </Form.Row>
                            </Col>
                        </Form.Group>
                        <hr />
                        <Form.Group as={Row}>
                            <Form.Label column sm="2">
                                {kedo.t('End')}
                            </Form.Label>
                            <Col sm="10">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Control
                                            onChange={handleEndChange}
                                            as="select"
                                            value={endMode}
                                        >
                                            <option value={'date'}>
                                                {kedo.t('On date')}
                                            </option>
                                            <option value={'after'}>
                                                {kedo.t('time_after')}
                                            </option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        {endMode === 'after' ? (
                                            <Form.Row
                                                style={{ alignItems: 'center' }}
                                            >
                                                <Col sm={4}>
                                                    <Form.Control
                                                        value={afterExecutions}
                                                        onChange={(e: any) => {
                                                            const value =
                                                                e.target.value
                                                            if (
                                                                (/^[0-9]+$/.test(
                                                                    value
                                                                ) &&
                                                                    value.length <
                                                                        4) ||
                                                                e.nativeEvent
                                                                    .inputType ==
                                                                    'deleteContentBackward' ||
                                                                e.nativeEvent
                                                                    .inputType ==
                                                                    'deleteContentForward'
                                                            ) {
                                                                if (
                                                                    value === ''
                                                                ) {
                                                                    setAfterExecutions(
                                                                        0
                                                                    )
                                                                } else {
                                                                    setAfterExecutions(
                                                                        parseInt(
                                                                            value
                                                                        )
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label sm={3}>
                                                    {kedo
                                                        .t('Repetitions')
                                                        .toLowerCase()}
                                                </Form.Label>
                                            </Form.Row>
                                        ) : (
                                            <Datetime
                                                locale={getCurrentLocale()}
                                                inputProps={{
                                                    disabled: submitting,
                                                    readOnly: true,
                                                }}
                                                closeOnSelect={true}
                                                onChange={(newDate) => {
                                                    if (
                                                        typeof newDate ===
                                                        'string'
                                                    ) {
                                                        setEndDate(
                                                            new Date(newDate)
                                                        )
                                                        return
                                                    }
                                                    setEndDate(
                                                        new Date(
                                                            newDate.format()
                                                        )
                                                    )
                                                }}
                                                value={endDate}
                                                isValidDate={isValidEndDate}
                                                utc={false}
                                            />
                                        )}
                                    </Form.Group>
                                </Form.Row>
                            </Col>
                        </Form.Group>
                    </div>
                </>
            )
        }

        return
    }

    const renderAlert = () => {
        if (item?.def_dossier_def_field?.def_field?.settings?.restriction) {
            const restriction =
                item.def_dossier_def_field.def_field.settings.restriction

            if (restriction === 'before_and_eq_current_date') {
                return (
                    <Alert variant="warning">
                        {`${kedo.t(
                            'Before and equal to the current date'
                        )}: ${kedo.t('Enabled')}`}
                    </Alert>
                )
            } else if (restriction === 'after_and_eq_current_date') {
                return (
                    <Alert variant="warning">
                        {`${kedo.t(
                            'After and equal to the current date'
                        )}: ${kedo.t('Enabled')}`}
                    </Alert>
                )
            }
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {kedo.t('Multiple')}{' '}
                    {String(
                        kedo.env().translateItem(defDossier, 'defdossier', true)
                    ).toLowerCase()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {item ? renderAlert() : null}
                {!item ? (
                    <Form.Group>
                        <Form.Row>
                            <Form.Label column="sm" md={12}>
                                {kedo.t(
                                    'Choose a field to create items by a set'
                                )}
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                        setItem(
                                            displayItems.find(
                                                (ddiItem) =>
                                                    ddiItem.id ===
                                                    parseInt(e.target.value)
                                            )
                                        )
                                    }}
                                >
                                    <option>
                                        {kedo.t('Choose an option')}
                                    </option>
                                    {DisplayItemFilter.byTypes(displayItems, [
                                        'date',
                                        'datetime',
                                    ]).map((displayItem) => (
                                        <option
                                            value={displayItem.id}
                                            key={displayItem.id}
                                        >
                                            {kedo.translateItem(
                                                displayItem,
                                                'displayitem'
                                            )}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                ) : null}
                {item ? renderItem() : null}
                {submitting && !success ? (
                    <div className={'text-center'}>
                        <span>{currentItem}</span>
                    </div>
                ) : null}
                {submitting && !success ? (
                    <ProgressBar min={0} max={totalItems} animated now={done} />
                ) : null}
                {success ? (
                    <Alert variant={'success'}>
                        {kedo.t('Item has been saved')}
                    </Alert>
                ) : null}
                {errors && errors.length > 0 ? (
                    <Alert variant={'danger'}>
                        {errors.map((error, index) => (
                            <>
                                <span key={index}>{error}</span>
                                <br />
                            </>
                        ))}
                    </Alert>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                {item ? (
                    <Button
                        disabled={submitting}
                        variant={'primary'}
                        onClick={submitDossiers}
                    >
                        {kedo.t('Save')}
                    </Button>
                ) : null}
                <Button
                    disabled={submitting}
                    onClick={handleClose}
                    variant={'secondary'}
                >
                    {kedo.t('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShowMultiAdd
