export async function TabSelect(
    props: { kedo: { api: () => any } },
    defDossierId: any,
    dossiers: any[]
) {
    const api = props.kedo.api()
    const displayPosition = await Promise.all(
        dossiers.map((dossier) => {
            const baseUrl = api.getDisplayPositionEndpoint()
            const url = `${baseUrl}?defDossier=${defDossierId}&limit=100&contentDossierId=${dossier}`
            return api.get(url)
        })
    )

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index
    }

    const displayItems = displayPosition.flatMap((response) => {
        return response.data.results
            .filter((result: { type: string }) => result.type === 'tab')
            .map((result: { display_items: any }) => result.display_items)
    })

    const displayPositionIds = displayItems.flatMap((innerArray) =>
        innerArray.map(
            (item: { display_position_id: any }) => item.display_position_id
        )
    )

    if (displayPositionIds.length > 0) {
        const uniquePositionIds = displayPositionIds.filter(onlyUnique)
        const tabContent = await Promise.all(
            dossiers.map(async (dossier) => {
                const tabContentItems = await Promise.all(
                    uniquePositionIds.map(async (uniquePosId) => {
                        const baseUrl = api.getContentDossierEndpoint(dossier)
                        const url = `${baseUrl}?displayPosition=${uniquePosId}`

                        const result = await api.get(url)

                        return result
                    })
                )

                return tabContentItems
            })
        )

        const combinedItems: Record<
            number,
            { name: string; id: number; content: any }[]
        > = {}
        const combinedLinks: Record<
            number,
            {
                name: string
                link_id?: number
                ddl_id?: number
                content: any
                multiple: boolean
            }[]
        > = {}
        let contentValue = []

        await tabContent.forEach(async (tabResponses, tabIndex) => {
            const dossierItems = []
            const dossierLinks = []

            tabResponses.map((response, index) => {
                if (response?.data?.content) {
                    contentValue = [
                        ...Object.keys(response.data.content),
                        ...contentValue,
                    ]
                    contentValue = contentValue.filter(onlyUnique)
                }

                if (response?.data?.items) {
                    response.data.items.forEach((item: any) => {
                        const contentId = item.id
                        const content = response.data.content[contentId]
                        if (
                            item.def_dossier_def_field?.name &&
                            dossierItems.filter(
                                (dosItem) =>
                                    dosItem.dddf_id ===
                                    item.def_dossier_def_field.id
                            ).length <= 0
                        ) {
                            dossierItems.push({
                                name: item.def_dossier_def_field?.name || '',
                                dddf_id: item.def_dossier_def_field.id,
                                id: item?.id || '',
                                content: content || '',
                            })
                        } else if (
                            item.def_dossier_link?.name &&
                            dossierLinks.filter(
                                (dosLinkItem) =>
                                    dosLinkItem.ddl_id ===
                                    item.def_dossier_link.id
                            ).length <= 0 &&
                            (!combinedLinks[dossierId] ||
                                combinedLinks[dossierId].filter(
                                    (dosLinkItem) =>
                                        dosLinkItem.ddl_id ===
                                        item.def_dossier_link.id
                                ).length <= 0)
                        ) {
                            dossierLinks.push({
                                name: item.def_dossier_link.name || '',
                                ddl_id: item.def_dossier_link.id,
                                link_id: item?.id || '',
                                content: content || '',
                                multiple:
                                    item.def_dossier_link?.settings?.multiple ||
                                    false,
                            })
                        }
                    })
                }

                const dossierId = response?.data?.dossier?.id

                if (!combinedItems[dossierId]) {
                    combinedItems[dossierId] = []
                }
                combinedItems[dossierId].push(...dossierItems)

                if (!combinedLinks[dossierId]) {
                    combinedLinks[dossierId] = []
                }

                dossierLinks.map((dosLink) => {
                    if (
                        combinedLinks[dossierId].filter(
                            (comLink) => dosLink.ddl_id === comLink.ddl_id
                        ).length <= 0
                    ) {
                        combinedLinks[dossierId].push(dosLink)
                    }
                })
            })

            const dosId = tabContent[tabIndex][0].data.dossier.id
        })

        return { combinedItems, combinedLinks, contentValue }
    }
    return { combinedItems: {}, combinedLinks: {} }
}
