import React, {Component} from 'react'
import {Col, Row} from "react-bootstrap";

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const kedo = this.props.kedo;

        return  <div className={"mainContainer"}>
                    {this.state.deleteItem ? this.renderDeleteForm(this.state.deleteItem) : null}
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <h1>{kedo.t('Your PowerBI reports can be embedded ')}</h1>
                            <iframe referrerPolicy="no-referrer-when-downgrade" width="100%" height="800" frameBorder="0"
                                    src="https://app.powerbi.com/view?r=eyJrIjoiM2U5YTE2ZDMtNmNlMS00MTlhLTk3MmYtZTY0Zjc2YTEwZmY4IiwidCI6IjdlY2M4YWEwLTgzMGUtNGQ4Ni1hZjc4LTgzYTNkY2MyNjIzOCIsImMiOjZ9"></iframe>
                        </Col>
                    </Row>
                </div>
    }
}

export default Reports