import * as React from 'react'
import { useRef } from 'react'

import SignatureCanvas from 'react-signature-canvas'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload'
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons/faTrashCan'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'

import { Button } from 'react-bootstrap'

const Signature = ({ onSave }) => {
    const signatureRef = useRef<SignatureCanvas | null>(null)

    const handleDownloadImage = () => {
        if (signatureRef.current) {
            const dataURL = signatureRef.current.getTrimmedCanvas().toDataURL()
            const downloadLink = document.createElement('a')
            downloadLink.href = dataURL
            downloadLink.download = 'signature.png'
            downloadLink.click()
        }
    }

    const handleUndoStroke = () => {
        if (signatureRef.current) {
            signatureRef.current.clear()
        }
    }

    const handleSave = () => {
        if (signatureRef.current) {
            const trimmedCanvas = signatureRef.current.getTrimmedCanvas()
            const trimmedDataURL = trimmedCanvas.toDataURL('image/png')
            onSave(trimmedDataURL)
        }
    }

    return (
        <>
            <div className="border w-100 h-100">
                <SignatureCanvas
                    ref={signatureRef}
                    velocityFilterWeight={0.7}
                    minWidth={0.5}
                    maxWidth={2.5}
                    minDistance={5}
                    dotSize={() => (0.5 + 2.5) / 2}
                    penColor="black"
                    throttle={28}
                    canvasProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white',
                            margin: 0,
                            padding: 0,
                        },
                    }}
                />
            </div>
            <div className="mt-2">
                <Button
                    onClick={handleDownloadImage}
                    variant={'outline-secondary'}
                    size={'sm'}
                >
                    <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                    onClick={handleUndoStroke}
                    variant={'outline-secondary'}
                    size={'sm'}
                    className="mx-1"
                >
                    <FontAwesomeIcon icon={faTrashCan} />
                </Button>
                <Button
                    onClick={handleSave}
                    variant={'outline-secondary'}
                    size={'sm'}
                >
                    <FontAwesomeIcon icon={faSave} />
                </Button>
            </div>
        </>
    )
}

export default Signature
