import React, {Component} from 'react'
import {Form} from "react-bootstrap"

class DefFieldTextSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            size: this.props.item && this.props.item.settings && this.props.item.settings.size ? this.props.item.settings.size : 100,
            regex: this.props.item && this.props.item.settings && this.props.item.settings.regex ? this.props.item.settings.regex : null,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        let item = this.state.item;
        if (!item.settings) {
            item.settings = {[event.target.name]: event.target.value};
        }

        item.settings[event.target.name] = event.target.value;
        this.setState({item: item});

        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        const kedo = this.props.kedo;

        return  <div>
                    <h3>{this.props.kedo.t('Settings')}</h3>
                    <Form.Group>
                        <Form.Label>{kedo.t('Regular expression')}</Form.Label>
                        <Form.Control as="select" name={'regex'} onChange={this.onChange}>
                            <option selected={!this.props.item.settings.regex} value={''}>{kedo.t('Select an option')}</option>
                            <option selected={this.props.item.settings.regex === '#^[0-9]*$#i'} value={'#^[0-9]*$#i'}>{kedo.t('Numbers only')}</option>
                            <option selected={this.props.item.settings.regex === '#^[0-9]{4}[A-Z]{2}$#'} value={'#^[0-9]{4}[A-Z]{2}$#'}>{kedo.t('Zipcode netherlands')}</option>
                            <option selected={this.props.item.settings.regex === '/^[0-9]{4}$/'} value={'/^[0-9]{4}$/'}>{kedo.t('Municipal code')}</option>
                            <option selected={this.props.item.settings.regex === '/^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$/'} value={'/^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$/'}>{kedo.t('Denomination')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Custom regular expression')}</Form.Label>
                        <Form.Control value={this.props.item.settings.regex} name={'regex'} onChange={this.onChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Size')}</Form.Label>
                        <Form.Control type={"number"} onChange={this.onChange} value={this.state.item.settings.size} name={'size'} className={'form-control'} />
                    </Form.Group>
                </div>
    }
}

export default DefFieldTextSettings