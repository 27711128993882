import React, {useContext} from 'react'
import {Row, Col, Spinner} from 'react-bootstrap'
import {KeDoContext} from "../../../kedo-context";

export const LoadingPage = (props) => {
    const kedo = useContext(KeDoContext);

    return  <Row>
                <Col xs={12} className={"d-flex justify-content-center"}>
                    <div className={"loading loadingPage"}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                        />
                        <br/><br/>
                        {props.title ? kedo.t(props.title) : kedo.t('Busy fetching the page')+'...'}
                    </div>
                </Col>
            </Row>
};

export default LoadingPage