import React from 'react'
import { Row, Button } from 'react-bootstrap'
import { faMapSigns } from '@fortawesome/pro-duotone-svg-icons/faMapSigns'
import { faHome } from '@fortawesome/pro-duotone-svg-icons/faHome'
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from 'react-i18next'

export default class NotFound extends React.Component {
    render() {
        let styles = { justifyContent: 'flex-start' }
        if (!this.props.error) {
            styles = {
                justifyContent: 'flex-start',
                margin: '90px auto',
                width: '70%',
            }
        }

        return (
            <div className="mainContainer page-not-found" style={styles}>
                <Row className={'d-flex align-items-center'}>
                    <div style={{ marginRight: '50px' }}>
                        <FontAwesomeIcon
                            className={'background-icon'}
                            icon={faMapSigns}
                        />
                    </div>
                    <div>
                        <h1>
                            {this.props.error ? (
                                this.props.error && this.props.error.toString()
                            ) : (
                                <span>
                                    <Trans i18nKey="Something went wrong">
                                        Something went wrong
                                    </Trans>
                                    ...
                                </span>
                            )}
                        </h1>
                        <p>
                            <Trans i18nKey="404_general_message">
                                The page may not exist or you may not have the
                                necessary rights. If you think this is
                                incorrect, please contact your system
                                administrator.
                            </Trans>
                        </p>

                        <div>
                            <span>
                                <Button
                                    onClick={() => {
                                        window.history.back()
                                        window.location.reload(true)
                                    }}
                                    style={{ marginTop: '-0.5px' }}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    &nbsp;{' '}
                                    <Trans i18nKey="Back to">
                                        Back to
                                    </Trans>{' '}
                                    <Trans i18nKey="previous page">
                                        previous page
                                    </Trans>
                                </Button>
                                &nbsp; &nbsp;
                            </span>
                            <Button
                                onClick={() => {
                                    window.location.href = '/'
                                }}
                                variant={'secondary'}
                            >
                                <FontAwesomeIcon icon={faHome} />
                                &nbsp; <Trans i18nKey="Back to">
                                    Back to
                                </Trans>{' '}
                                <Trans i18nKey="Homepage">Homepage</Trans>
                            </Button>{' '}
                            &nbsp;
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}
