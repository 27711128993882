import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { KeDoContext } from '../../kedo-context'

class ItemCounter extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }
    static contextType = KeDoContext

    countItems() {
        const kedo = this.context
        let currentPage = this.props.pager.current + 1
        let currentItems = currentPage * this.props.pager.limit

        return (
            <Row>
                <Col xs={12}>
                    <div className={'d-flex justify-content-center text-muted'}>
                        <br />
                        {kedo.t('Shown items')}: &nbsp;
                        {this.props.pager.items <= currentItems
                            ? this.props.pager.items
                            : currentItems}
                        &nbsp;/&nbsp;{this.props.pager.items}
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        return this.props.pager.pages && this.props.pager.pages > 0
            ? this.countItems()
            : null
    }
}

export default ItemCounter
