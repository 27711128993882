import React, { Component } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faBellSlash } from '@fortawesome/pro-duotone-svg-icons/faBellSlash'

class MultiContentDossierSubscribe extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subscribeDefDossierForm: false,
            done: 0,
            total: this.props.items.length,
        }

        this.updateSubscription = this.updateSubscription.bind(this)
    }

    updateSubscription(subscribe) {
        let total = this.props.items.length
        let items = this.props.items
        let itemsDone = 0
        const kedo = this.props.kedo
        this.setState({
            loading: true,
            subscribe: subscribe,
        })
        items.map((dossierId) => {
            let url = kedo
                .api()
                .getContentDossierSubscriptionEndpoint(dossierId, subscribe)

            kedo.api()
                .get(url)
                .then((response) => {
                    itemsDone = itemsDone + 1
                    if (itemsDone === total) {
                        this.setState({ loading: false })
                        this.handleExit()
                    } else {
                        this.setState({
                            done: itemsDone,
                        })
                    }
                })
        })
    }

    handleExit() {
        this.props.onClose()
    }

    renderLoading() {
        let total = this.state.total
        var done = this.state.done
        let perc = 0
        perc = ((done / total) * 100).toFixed(3)

        return (
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.props.kedo.t(
                            this.state.subscribe
                                ? this.props.kedo.t('Subscribe')
                                : this.props.kedo.t('Stop subscription')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="progress">
                        <div
                            className="progress-bar"
                            style={{ width: perc.toString() + '%' }}
                            role="progressbar"
                            aria-valuenow={perc}
                            aria-valuemin="0"
                            aria-valuemax={100}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    displayButton(onClick, button, tooltip = null) {
        if (!tooltip)
            return (
                <Button variant={'outline-secondary'} onClick={onClick}>
                    {button}
                </Button>
            )

        return (
            <>
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <Button variant={'outline-secondary'} onClick={onClick}>
                        {button}
                    </Button>
                </OverlayTrigger>
            </>
        )
    }

    render() {
        const mode = this.props.mode

        if (this.props.showModal) {
            this.updateSubscription(this.props.showActionModal === 'subscribe')
            this.props.onClose()
            return
        }

        return (
            <span>
                {this.state.loading ? this.renderLoading() : null}
                {this.displayButton(
                    () => this.updateSubscription(true),
                    <>
                        <FontAwesomeIcon icon={faBell} />{' '}
                        {mode &&
                        (mode === 'embeddedList' || mode === 'block') ? null : (
                            <>&nbsp; {this.props.kedo.t('Subscription')}</>
                        )}
                    </>,
                    mode && (mode === 'embeddedList' || mode === 'block')
                        ? this.props.kedo.t('Subscription')
                        : null
                )}
                {this.displayButton(
                    () => this.updateSubscription(false),
                    <>
                        <FontAwesomeIcon icon={faBellSlash} />{' '}
                        {mode &&
                        (mode === 'embeddedList' || mode === 'block') ? null : (
                            <>&nbsp; {this.props.kedo.t('Stop subscription')}</>
                        )}
                    </>,
                    mode && (mode === 'embeddedList' || mode === 'block')
                        ? this.props.kedo.t('Stop subscription')
                        : null
                )}
            </span>
        )
    }
}

export default MultiContentDossierSubscribe
