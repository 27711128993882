class DisplayItemHelper {
    public static readonly EDIT_VIEW = 'edit'
    public static readonly SHOW_EDIT_VIEW = 'show/edit'
    public static readonly SHOW_VIEW = 'show'
    public static readonly LIST_VIEW = 'list'

    public static readonly FIELD_CUSTOMACTIONBTN_TYPE = 'customActionButton'
    public static readonly FIELD_LIST_TYPE = 'list'
    public static readonly FIELD_TEXTAREA_TYPE = 'textarea'
    public static readonly FIELD_DATE_TYPE = 'date'
    public static readonly FIELD_TIME_TYPE = 'time'
    public static readonly FIELD_AMOUNT_TYPE = 'amount'

    static mapSortSettings(sortSettings: any) {
        const sortOptions = []

        for (let i = 0; i < sortSettings.length; i++) {
            sortOptions.push({
                order: sortSettings[i].sort,
                item: {
                    def_dossier_def_field: {
                        id: parseInt(sortSettings[i].dddfId),
                    },
                },
            })
        }

        return sortOptions
    }

    static filterViews = (
        items: Array<IDisplayField>,
        views: Array<string>
    ) => {
        return items.filter((item) => views.includes(item.view))
    }

    static isTypeOfField = (
        field: IDisplayField,
        fieldType: string
    ): boolean => {
        if (
            fieldType === 'link' &&
            field.def_dossier_link &&
            field.def_dossier_link.id > 0
        ) {
            return true
        } else if (
            field.def_dossier_def_field &&
            field.def_dossier_def_field.def_field &&
            field.def_dossier_def_field.def_field.type === fieldType
        ) {
            return true
        }

        return false
    }

    static isObligatory = (item: IDisplayField): boolean => {
        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.obligatory &&
            item.def_dossier_def_field.obligatory === true
        ) {
            return true
        }

        if (
            item.def_dossier_link &&
            item.def_dossier_link.obligatory === true
        ) {
            return true
        }

        return false
    }
}

export default DisplayItemHelper
