import React from 'react'
import {Row, Col, Spinner} from 'react-bootstrap'
import Kedo from "../../../Kedo";

class Loading extends React.Component {
    render() {
        const kedo = this.props.kedo ? this.props.kedo : new Kedo();

        return  <Row>
                    <Col xs={12}>
                        <div className={"loading"}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> &nbsp;
                            {this.props.message ? this.props.message : kedo.t('Busy fetching the data')}...
                        </div>
                    </Col>
                </Row>
    }
}

export default Loading