import React, { Component } from 'react'
import { Alert, Button, Form, Modal, Table, ListGroup } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faAsterisk } from '@fortawesome/pro-duotone-svg-icons/faAsterisk'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faCircle } from '@fortawesome/pro-duotone-svg-icons/faCircle'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt'

import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import DefFieldListForm from '../DefFieldListForm'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragAndDropContainer from '../../Elements/DragAndDrop/ContainerDragAndDrop'
import Flag from '../../Elements/Flag.tsx'

class DefFieldListSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            multiple:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.multiple === true
                    ? true
                    : false,
            options:
                this.props.item && this.props.item.def_field_lists
                    ? this.props.item.def_field_lists
                    : [],
            error: false,
            newOptionText: null,
            newOptionColor: null,
        }
        this.fetchOptions = this.fetchOptions.bind(this)
        this.sortListOption = this.sortListOption.bind(this)
        this.renderListComponent = this.renderListComponent.bind(this)
    }

    fetchOptions(defFieldId) {
        const kedo = this.props.kedo
        let params = {
            params: {
                defField: defFieldId,
                limit: 500,
            },
        }
        this.setState({
            loading: true,
        })
        kedo.api()
            .getCached(kedo.api().getDefFieldListEndpoint(), params)
            .then((response) => {
                this.setState({
                    options: response.data.results,
                    loading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    loading: false,
                })
            })
    }

    componentDidMount() {
        if (this.props.item.id) {
            this.fetchOptions(this.props.item.id)
        }
    }

    renderEditOption() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.setState({ editOption: false })}
                centered
                show={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t(`Edit list option`)}{' '}
                        {this.state.editOption ?? ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DefFieldListForm
                        onClose={() => this.setState({ editOption: false })}
                        onSuccess={() => {
                            this.setState({ editOption: false })
                            this.fetchOptions(this.props.item.id)
                        }}
                        kedo={this.props.kedo}
                        defFieldId={this.props.item.id}
                        id={this.state.editOption}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    renderAddOption() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.setState({ addOption: false })}
                centered
                show={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.kedo.t('Add item')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DefFieldListForm
                        onClose={() => this.setState({ addOption: false })}
                        onSuccess={() => {
                            this.setState({ addOption: false })
                            this.fetchOptions(this.props.item.id)
                        }}
                        kedo={this.props.kedo}
                        defFieldId={this.props.item.id}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    sortListOption(listOptionA, listOptionB) {
        let comparison = 0
        if (listOptionA.rank > listOptionB.rank) {
            comparison = 1
        } else if (listOptionA.rank < listOptionB.rank) {
            comparison = -1
        }
        return comparison
    }
    renderListComponent(item, ref, handlerId, opacity, style) {
        return (
            <>
                <tr
                    ref={ref}
                    style={{ ...style, opacity }}
                    data-handler-id={handlerId}
                >
                    <td>{item.id}</td>
                    <td>
                        {item.translations && item.translations.length > 0 ? (
                            <ListGroup variant="flush">
                                {item.translations.map((item, tIndex) => (
                                    <ListGroup.Item key={tIndex}>
                                        <Flag
                                            code={item.culture.substr(
                                                item.culture.length - 2
                                            )}
                                            height="12"
                                            className={'mr-2'}
                                        />
                                        {item.text}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) : null}
                    </td>
                    <td>
                        {item.color ? (
                            <span>
                                <FontAwesomeIcon
                                    style={{
                                        color: item.color,
                                    }}
                                    icon={faCircle}
                                />
                                &nbsp;
                            </span>
                        ) : null}
                    </td>
                    <td>{item.rank}</td>
                    <td>
                        {item.default ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : null}
                    </td>
                    <td>
                        {item.settings &&
                        item.settings.roles &&
                        item.settings.roles.length > 0 ? (
                            <FontAwesomeIcon icon={faAsterisk} />
                        ) : null}
                    </td>
                    <td className={'text-right'}>
                        <Button
                            variant={'outline-secondary'}
                            size={'sm'}
                            onClick={() =>
                                this.setState({
                                    editOption: item.id,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        &nbsp;
                        <Button
                            variant={'outline-danger'}
                            size={'sm'}
                            onClick={() =>
                                this.setState({
                                    confirmDeleteOption: item.id,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </td>
                </tr>
            </>
        )
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div>
                {this.state.addOption ? this.renderAddOption() : null}
                {this.state.editOption ? this.renderEditOption() : null}
                {this.state.confirmDeleteOption ? (
                    <ConfirmDeleteDialog
                        kedo={kedo}
                        onClose={() => {
                            this.setState({ confirmDeleteOption: false })
                            this.fetchOptions(this.props.item.id)
                        }}
                        label={'position'}
                        url={
                            kedo.api().getDefFieldListEndpoint() +
                            '/' +
                            this.state.confirmDeleteOption
                        }
                    />
                ) : null}
                <h3>{kedo.t('Settings')}</h3>
                <Form.Group>
                    <Form.Label>{this.props.kedo.t('Multiple')}</Form.Label>
                    <Form.Check
                        checked={this.state.multiple}
                        id={'radio-multiple-yes'}
                        label={kedo.t('Yes')}
                        onClick={() => {
                            this.setState({ multiple: true })
                            this.props.onChange('multiple', true)
                        }}
                        type={'radio'}
                    />
                    <Form.Check
                        checked={!this.state.multiple}
                        id={'radio-multiple-no'}
                        label={kedo.t('No')}
                        onClick={() => {
                            this.setState({ multiple: false })
                            this.props.onChange('multiple', false)
                        }}
                        type={'radio'}
                    />
                </Form.Group>
                <br />
                <Table striped>
                    <thead>
                        <tr>
                            <th colSpan={'7'}>
                                <Form.Label>{kedo.t('Options')}</Form.Label>
                                <Button
                                    variant={'primary'}
                                    size={'sm'}
                                    className={'float-right'}
                                    onClick={() =>
                                        this.setState({ addOption: true })
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </th>
                        </tr>
                        <tr style={{ width: '100%' }}>
                            <th>#</th>
                            <th>{kedo.t('Text')}</th>
                            <th>{kedo.t('Color')}</th>
                            <th>{kedo.t('Rank')}</th>
                            <th>{kedo.t('Default')}</th>
                            <th>{kedo.t('Visibility')}</th>
                            <th>{kedo.t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.loading ? (
                            <tr>
                                <td colSpan={7} className={'text-center'}>
                                    <LoadingDefault />
                                </td>
                            </tr>
                        ) : null}
                        {!this.props.item.id ? (
                            <tr>
                                <td colSpan={7} className={'text-center'}>
                                    <Alert variant={'info'}>
                                        {kedo.t('Save this item first')}
                                    </Alert>
                                </td>
                            </tr>
                        ) : null}
                        {this.state.error ? (
                            <tr>
                                <td colSpan={7}>
                                    <Alert variant={'warning'}>
                                        {kedo.t('Could not load data')}
                                    </Alert>
                                </td>
                            </tr>
                        ) : null}
                        <DndProvider backend={HTML5Backend}>
                            <DragAndDropContainer
                                items={this.state.options.sort(
                                    this.sortListOption
                                )}
                                onSortItems={(items) => {
                                    items.map((item, idx) => {
                                        item.rank = idx
                                        return item
                                    })
                                    this.setState({ options: items })
                                    this.props.onChange('rank', items)
                                }}
                                Component={this.renderListComponent}
                            />
                        </DndProvider>
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default DefFieldListSettings
