import React, { Component, useEffect, useState } from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import Api from './Api'
import Home from './Components/Home'
import EnvironmentIndex from './feature/Environment/EnvironmentList/EnvironmentIndex'
import DefaultPage from './Components/Template/DefaultPage'
import User from './User'
import ForgotPassword from './Components/ForgotPassword'
import AzureConnect from './Components/AzureConnect'
import TwoFactorAuthentication from './Components/auth/TwoFactorAuthentication'
import NotFound from './Components/Pages/NotFound'
import { ToastContainer } from 'react-toastify'
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeoutPrompt from './Components/IdleTimer/IdleTimeoutPrompt'
import { useSocketService } from './context/SocketServiceContext.tsx'
import LoginModal from './Components/LoginModal'

const handleLogin = (data, callBack = null, idleTimer = null) => {
    const base64Url = data.token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const tokenData = JSON.parse(window.atob(base64))
    var user = new User()
    var api = new Api()
    user.updateInfoFromJWTToken(tokenData)

    if (idleTimer) {
        idleTimer()
    }

    if (callBack) callBack(true, user)

    api.updateToken(tokenData, data.token, data.refresh_token)
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            api: new Api(),
            idleTimer: this.props.idleTimer,
        }

        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLogout() {
        this.setState({
            loggedIn: false,
            user: {},
        })
        localStorage.clear()
    }

    render() {
        return (
            <Home
                kedo={this.props.kedo}
                {...this.props}
                api={this.state.api}
                handleLogin={(data) =>
                    handleLogin(data, (loggedIn, user) => {
                        this.setState({
                            loggedIn: loggedIn,
                            user: user,
                        })
                    })
                }
                handleLogout={this.handleLogout}
            />
        )
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            rest.kedo.user().isLoggedIn() === true ? (
                <Component kedo={rest.kedo} {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
)

const App = ({ user, kedo, i18n }) => {
    const location = useLocation()
    const socketService = useSocketService()
    const timeout = 1000 * 60 * 15
    const debounce = 250
    const crossTab = true
    const promptTimeout = 1000 * 60 * 5
    // Modal open state
    const [open, setOpen] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(0)

    const [loggedIn, setLoggedIn] = useState(false)
    const loggedInHandle = setInterval(() => {
        if (kedo.user().isLoggedIn()) {
            setLoggedIn(kedo.user().isLoggedIn())
            clearInterval(loggedInHandle)
        }
    }, 500)

    const isIgnoredOnPage = () => {
        return (
            window.location.pathname === '/login' ||
            window.location.pathname === '/forgot_password'
        )
    }

    const closePrompt = () => {
        setOpen(false)
        setRemaining(0)
        setShowLoginModal(false)
    }

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        // In this case 15 minutes. Here you can open your prompt.
        // All events are disabled while the prompt is active.
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        if (isIgnoredOnPage()) {
            return
        }
        setOpen(true)
        setRemaining(promptTimeout)

        if (kedo) {
            kedo.api().checkTokenForExpiryAndRenew()
        }
    }

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // In this case 30 seconds. Here you can close your prompt and
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.

        if (isIgnoredOnPage()) {
            return
        }

        setOpen(false)
        setRemaining(0)

        setShowLoginModal(true)
    }

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted()`
        // is true. Here you will also want to close your modal and perform
        // any active actions.
        if (open && isIgnoredOnPage()) {
            closePrompt()
        }
        setOpen(false)
        setRemaining(0)
    }
    const onAction = () => {
        if (open === true && isIgnoredOnPage()) {
            reset()
            setOpen(false)
            setRemaining(0)
            setShowLoginModal(false)
        }

        //Check if the user is logged in and doesn't have an expired token.
        if (open === true && !isIgnoredOnPage()) {
            reset()
            setOpen(false)
            setRemaining(0)
            setShowLoginModal(false)

            if (kedo) {
                kedo.api().checkTokenForExpiryAndRenew()
            }
        }
    }

    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptTimeout,
        debounce,
        crossTab,
        onPrompt,
        onIdle,
        onActive,
        onAction,
    })

    useEffect(() => {
        if (location.pathname !== '/') kedo.title().reset()
    }, [location.pathname])

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])

    useEffect(() => {
        const interval = setInterval(() => {
            if (isIgnoredOnPage()) {
                reset()
                setOpen(false)
                setRemaining(0)
                setShowLoginModal(false)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [open])

    useEffect(() => {
        socketService.init({
            username: kedo.user().getUsername(),
            userId: kedo.user().getUserId(),
            environmentId: kedo.env().hasEnviroment()
                ? kedo.env().getEnvironmentId()
                : null,
        })
    }, [loggedIn])

    return (
        <div
            className={`app ${
                open || showLoginModal ? 'inactive-modal-open' : ''
            }`}
        >
            {open && !isIgnoredOnPage() && !showLoginModal ? (
                <IdleTimeoutPrompt
                    kedo={kedo}
                    promptTimeout={promptTimeout}
                    remaining={remaining}
                />
            ) : null}
            {showLoginModal && !isIgnoredOnPage() ? (
                <LoginModal
                    handleLogin={(data) => {
                        try {
                            handleLogin(data, null, reset)
                            setShowLoginModal(false)
                        } catch (err) {
                            setShowLoginModal(true)
                        }
                    }}
                    kedo={kedo}
                />
            ) : null}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />

            <Switch>
                <Route kedo={kedo} exact path={'/login'} component={Login} />
                <Route
                    kedo={kedo}
                    exact
                    path={'/azure'}
                    component={AzureConnect}
                />
                <Route
                    kedo={kedo}
                    exact
                    path={'/authentication'}
                    component={TwoFactorAuthentication}
                />

                <Route
                    kedo={kedo}
                    exact
                    path={'/forgot_password'}
                    component={ForgotPassword}
                />

                <PrivateRoute
                    kedo={kedo}
                    path={[
                        '/colleagues',
                        '/contact',
                        '/contentdossier/create/:id',
                        '/contentdossier/:id',
                        '/datastructure/:id',
                        '/datastructure',
                        '/subscriptions',
                        '/events',
                        '/defdossier/conditions/:id',
                        '/defdossier/edit/:id',
                        '/defdossier/:id',
                        '/fieldeditor/:id',
                        '/erd',
                        '/import/:id',
                        '/files',
                        '/languages',
                        '/layout',
                        '/environment/edit',
                        '/messages/:id',
                        '/messages',
                        '/modules',
                        '/order/:id',
                        '/permissions',
                        '/profile',
                        '/security',
                        '/blocks/:id',
                        '/blocks',
                        '/querybuilder/:id',
                        '/querybuilder',
                        '/reports',
                        '/reports/overview',
                        '/role/:id',
                        '/roles',
                        '/upgrade',
                        '/user/:id',
                        '/user',
                        '/templates/new',
                        '/templates/:id',
                        '/templates',
                        '/logs',
                        '/useractivity',
                        '/webhook/:id',
                        '/webhooks',
                        '/module/jwwmo/toewijzingen',
                        '/wordkedocx/:id',
                        '/wordkedocx',
                    ]}
                    component={DefaultPage}
                />

                <PrivateRoute
                    kedo={kedo}
                    exact
                    path={'/environment'}
                    component={EnvironmentIndex}
                />
                <PrivateRoute
                    kedo={kedo}
                    exact
                    path={'/'}
                    component={DefaultPage}
                />
                <Route kedo={kedo} path={'*'} component={NotFound} />
            </Switch>
        </div>
    )
}

export default App
