import React from 'react'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faRedo } from '@fortawesome/pro-duotone-svg-icons/faRedo'
import { faExpand } from '@fortawesome/pro-duotone-svg-icons/faExpand'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import BlockEditButton from './BlockEditButton'

export default class BlockToolbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dateFormat: null,
        }
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div className={'kedo-block-toolbar'}>
                <Button
                    title={kedo.t('Hide')}
                    className={'float-right'}
                    variant={'light'}
                    onClick={() => this.props.onClose(this.props.block.uuid)}
                    size={'sm'}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
                {this.props.onMax ? (
                    <Button
                        title={kedo.t('Maximize')}
                        className={'float-right'}
                        variant={'light'}
                        onClick={() => this.props.onMax(this.props.block.uuid)}
                        size={'sm'}
                    >
                        <FontAwesomeIcon icon={faExpand} />
                    </Button>
                ) : null}
                {this.props.block &&
                this.props.block.settings?.use_creation_date ? (
                    <DropdownButton
                        menuAlign="right"
                        variant={'light'}
                        title={kedo.t(this.state.dateFormat)}
                        id={`block-d-${this.props.block.id}`}
                        className={'float-right kedo-block-filter'}
                        size={'sm'}
                    >
                        <Dropdown.Item
                            onSelect={() =>
                                this.setState(
                                    { dateFormat: 'year_month' },
                                    () =>
                                        this.props.setFilterAndFetchData(
                                            'dateFormat',
                                            'year_month'
                                        )
                                )
                            }
                        >
                            {kedo.t('year_month')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onSelect={() =>
                                this.setState({ dateFormat: 'quarter' }, () =>
                                    this.props.setFilterAndFetchData(
                                        'dateFormat',
                                        'quarter'
                                    )
                                )
                            }
                        >
                            {kedo.t('quarter')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onSelect={() =>
                                this.setState({ dateFormat: 'year' }, () =>
                                    this.props.setFilterAndFetchData(
                                        'dateFormat',
                                        'year'
                                    )
                                )
                            }
                        >
                            {kedo.t('year')}
                        </Dropdown.Item>
                    </DropdownButton>
                ) : null}
                {this.props.fetchData ? (
                    <Button
                        title={kedo.t('Refresh')}
                        variant={'light'}
                        className={'float-right kedo-block-filter'}
                        onClick={() => this.props.fetchData()}
                        size={'sm'}
                    >
                        <FontAwesomeIcon icon={faRedo} />
                    </Button>
                ) : null}
                {kedo.isAdminOrEnvironmentAdmin() ? (
                    <BlockEditButton
                        item={this.props.block}
                        kedo={kedo}
                        onSuccess={() =>
                            this.props.onSuccess(this.props.block.id)
                        }
                    />
                ) : null}
            </div>
        )
    }
}
