import React from 'react'
import { Col } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { ResponsivePie } from '@nivo/pie'

export default class UserStatistics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            blockSettings: this.props.block ? this.props.block.settings : null,
            loading: false,
        }
    }

    fetchData() {
        let environment = this.props.kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        let envId = environment.id
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .post('/environmentadmin/statistics/user_current', {
                environment: envId,
            })
            .then((response) =>
                this.setState({
                    loading: false,
                    data: response.data,
                })
            )
            .catch(() =>
                this.setState({
                    loading: false,
                })
            )
    }

    componentDidMount() {
        this.fetchData()
    }

    transFormData(data) {
        return [
            {
                id: 'offline',
                label: this.props.kedo.t('Offline'),
                value: data.offline,
                color: 'hsl(20,100%,43%)',
            },
            {
                id: 'online',
                label: this.props.kedo.t('online'),
                value: data.online,
                color: 'hsl(102,100%,43%)',
            },
        ]
    }

    render() {
        let scheme =
            this.state.blockSettings && this.state.blockSettings.scheme
                ? this.state.blockSettings.scheme
                : 'blues'

        return (
            <div className={!this.props.dashboard ? 'kedo-block' : null}>
                <h3>{this.props.kedo.t('User statistics')}</h3>
                <Col xs={12} md={12} lg={12} className={'statistics'}>
                    {this.state.loading ? (
                        <LoadingDefault />
                    ) : (
                        <ResponsivePie
                            data={this.transFormData(this.state.data)}
                            margin={{
                                top: 40,
                                right: 80,
                                bottom: 80,
                                left: 80,
                            }}
                            innerRadius={0.75}
                            padAngle={0.7}
                            cornerRadius={3}
                            enableArcLinkLabels={false}
                            colors={{ scheme: scheme }}
                            borderWidth={1}
                            borderColor={{
                                from: 'color',
                                modifiers: [['darker', 0.2]],
                            }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{ from: 'color' }}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor="#333333"
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: 'rgba(255, 255, 255, 0.3)',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                },
                            ]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 120,
                                    itemHeight: 18,
                                    itemsSpacing: 100,
                                    itemTextColor: '#999',
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000',
                                            },
                                        },
                                    ],
                                },
                            ]}
                        />
                    )}
                </Col>
            </div>
        )
    }
}
