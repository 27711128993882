import { io } from 'socket.io-client'

class Socket {
    constructor() {
        this.socket = null
        this.url =
            process.env && process.env.REACT_APP_WSS_URL
                ? process.env.REACT_APP_WSS_URL
                : null
    }

    initSocket(callBack) {
        if (this.url) {
            this.socket = io(this.url, {
                withCredentials: true,
            })

            if (callBack) {
                callBack()
            }
        }
    }

    getSocket() {
        return this.socket
    }
}

export default Socket
