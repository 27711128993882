import React, { Component } from 'react'
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'

import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import LoadingContent from '../../Elements/Loading/LoadingContent'
import Applying from '../../Admin/Pages/Templates/Applying'
import DefaultSearchbar from '../../Elements/Search/DefaultSearchbar'
import Pagination from '../../Elements/Pagination'
import logo from '../../../images/xlab-logo.jpg'
import ItemCounter from '../../Elements/ItemCounter'
import ConfirmDeleteChangesDialog from '../../Form/ConfirmDeleteChangesDialog'

class NewEnvironment extends Component {
    _isMounted = false

    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            addLocales: [],
            description: null,
            file: '',
            imagePreviewUrl: '',
            name: null,
            template: null,

            errorFields: {},
            errors: false,
            limit: 9,
            locales: [],
            loadingLocales: false,
            runTemplate: false,
            templates: [],
            NewEnvironmentForm: false,
            saving: false,
            searchTemplate: null,
            page: 0,
            unsavedChanges: false,
        }

        this._handleImageChange = this._handleImageChange.bind(this)
        this.handleChangeTemplates = this.handleChangeTemplates.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleEntriesChange = this.handleEntriesChange.bind(this)
        this.handleSearchTemplates = this.handleSearchTemplates.bind(this)
        this.onKeyUpTemplate = this.onKeyUpTemplate.bind(this)
        this.handleExit = this.handleExit.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleTemplateChange = this.handleTemplateChange.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.resetButton = this.resetButton.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.save = this.save.bind(this)
        this.renderApply = this.renderApply.bind(this)
    }

    fetchTemplates() {
        if (this._isMounted !== true) {
            return
        }

        let params = {
            params: {
                limit: this.state.limit,
                page: this.state.page,
            },
        }
        let url = this.props.kedo.api().getAdminTemplateEndpoint()

        if (this.state.searchTemplate && this.state.searchTemplate.length > 0) {
            params.params.name = this.state.searchTemplate
        }

        this.setState({ fetchingTemplates: true })
        this.props.kedo
            .api()
            .get(url, params)
            .then((response) =>
                this.setState({
                    templates: response.data.results,
                    templatePages: response.data.pager,
                    fetchingTemplates: false,
                })
            )
    }

    fetchLocales() {
        if (this._isMounted !== true) {
            return
        }
        this.setState({ loadingLocales: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getLocaleEndpoint())
            .then((response) =>
                this.setState({
                    loadingLocales: false,
                    locales: response.data.results,
                })
            )
            .catch((error) => this.setState({ loadingLocales: false }))
    }

    handleChangeTemplates(event) {
        this.setState({
            searchTemplate: event.target.value,
        })
    }

    handleSearchTemplates(event) {
        if (event.key !== 'Enter') {
            return
        }

        this.setState(
            { searchTemplate: event.target.value },
            this.fetchTemplates
        )
    }

    handleCheck(event, addItem) {
        let items = this.state.addLocales.filter(
            (item) => item.id !== addItem.id
        )
        if (event.target.checked) {
            items.push(addItem)
        }

        this.setState({ addLocales: items })
    }

    checkErrors() {
        if (!this.state.errors) {
            return ''
        }

        if (this.innerRef.current && this.innerRef.current.focus) {
            setTimeout(() => this.innerRef.current.focus(), 1)
        }

        if (this.state.errors.name) {
            return 'name'
        } else if (this.state.errors.description) {
            return 'description'
        } else if (this.state.errors.language) {
            return 'language'
        }
    }

    handleClose() {
        this.props.onClose()
    }

    handleReset() {
        this.setState(
            { page: 0, searchTemplate: '', templates: [] },
            this.fetchTemplates
        )
    }

    resetButton() {
        return (
            <button
                onClick={this.handleReset}
                className="btn bg-transparent resetButton"
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        )
    }

    onChangePage(number) {
        this.setState({ page: number }, this.fetchTemplates)
    }

    handleErrors(errorData) {
        //TODO: Create handling
    }

    handleExit() {
        this.setState({
            NewEnvironmentForm: false,
            saving: false,
            file: '',
        })
        this.props.onClose()
    }

    handleFieldChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    save() {
        if (
            !this.state.name ||
            !this.state.description ||
            this.state.addLocales.length <= 0
        ) {
            let errors = {}
            if (!this.state.name || this.state.name.length <= 0) {
                errors.name = this.props.kedo.t('This is a required field')
            }
            if (!this.state.description || this.state.description.length <= 0) {
                errors.description = this.props.kedo.t(
                    'This is a required field'
                )
            }
            if (!this.state.addLocales || this.state.addLocales.length <= 0) {
                errors.language = this.props.kedo.t('This is a required field')
            }

            this.setState({ errors: errors }, this.checkErrors)

            return
        }

        this.setState({
            saving: true,
            errors: false,
        })

        let environment = {
            name: this.state.name,
            description: this.state.description,
            image: this.state.imagePreviewUrl,
            template: this.state.template,
        }

        this.props.kedo
            .api()
            .post(this.props.kedo.api().getEnvironmentEndpoint(), environment)
            .then((response) => {
                this.handleOnPostEnvironmentCreate(response.data.id)
            })
            .catch((error) => {
                this.handleErrors(error.response)
                this.setState({
                    general:
                        error.response && error.response.status === 403
                            ? 'You cannot create any more environments in your subscription.'
                            : null,
                    errors: error.response.data.errors,
                    image: null,
                    name: null,
                    saving: false,
                })
            })
    }

    handleOnPostEnvironmentCreate(envId) {
        let total = this.state.addLocales.length
        this.state.addLocales.map((localeItem, index) => {
            let item = {
                environment: envId,
                code: localeItem.code,
            }

            this.props.kedo
                .api()
                .post(
                    this.props.kedo.api().getEnvironmentLocaleEndpoint(),
                    item
                )
                .then((successResponse) => {
                    // eslint-disable-next-line
                    if ((index) => total) {
                        this.handleExit()
                    }
                })
        })
    }

    _handleImageChange(e) {
        e.preventDefault()

        let reader = new FileReader()
        let file = e.target.files[0]

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }

        reader.readAsDataURL(file)
    }

    handleTemplateChange(itemId) {
        if (itemId === this.state.template) {
            this.setState({ template: null })
        } else {
            this.setState({ template: itemId })
        }
    }

    getLogo(item) {
        let template = []

        if (
            this.props.kedo.env().locale &&
            (!item.translations || item.translations.length)
        ) {
            let currentLocale = this.props.kedo.env().getSelectedLocale()
            template = item.translations.find(
                (item) => item.culture === currentLocale.code
            )
        }

        if (
            !this.props.kedo.env().locale ||
            !template ||
            template.length <= 0
        ) {
            return logo
        } else {
            let apiBaseUrl = this.props.kedo.api().getBaseUrl()

            return apiBaseUrl + '/' + template.thumbnail_path
        }
    }

    onKeyUpTemplate(event) {
        if (this.state.timeoutTemplate) clearTimeout(this.state.timeoutTemplate)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeoutTemplate = setTimeout(() => {
            this.fetchTemplates()
        }, 300)
    }

    handleEntriesChange(event) {
        this.setState({ limit: event.target.value }, this.fetchTemplates)
    }

    showEntries() {
        let limit = parseInt(this.state.limit)
        limit = limit < 0 || limit > 200 ? 50 : limit

        return (
            <div className="show-entries float-right">
                <select
                    name={'limit'}
                    value={limit}
                    onChange={this.handleEntriesChange}
                    className="form-control"
                >
                    <option value={9}>9</option>
                    <option value={18}>18</option>
                    <option value={27}>27</option>
                    <option value={51}>51</option>
                    <option value={102}>102</option>
                    <option value={153}>153</option>
                    <option value={204}>204</option>
                </select>
            </div>
        )
    }

    renderTemplates() {
        return this.state.fetchingTemplates ? (
            <LoadingContent />
        ) : (
            <div>
                <DefaultSearchbar
                    kedo={this.props.kedo}
                    handleChange={this.handleChangeTemplates}
                    handleSearch={this.handleSearchTemplates}
                    searchName={this.state.searchTemplate}
                    resetButton={this.resetButton}
                />
                {this.state.templates.length <= 0 ? (
                    <Alert variant={'info'}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        &nbsp; {this.props.kedo.t('No items found')}
                    </Alert>
                ) : (
                    <div>
                        <Row style={{ marginTop: '-10px' }}>
                            <Col md={{ span: 2, offset: 10 }}>
                                {this.showEntries()}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            {this.state.templates.map((item, index) => (
                                <Col
                                    key={item.id}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    style={{ paddingBottom: '25px' }}
                                >
                                    <Card
                                        key={item.id}
                                        onClick={(event) =>
                                            this.handleTemplateChange(item.id)
                                        }
                                        id={`default-${item.id}`}
                                        className={
                                            `d-flex flex-column h-100 ` +
                                            (this.state.template &&
                                            this.state.template === item.id
                                                ? 'activeCard'
                                                : '')
                                        }
                                    >
                                        <div className={'template-image'}>
                                            <Card.Img
                                                src={this.getLogo(item)}
                                            />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{item.name}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">
                                                <small>
                                                    {this.props.kedo
                                                        .utils()
                                                        .dateFormat(
                                                            item.created_at
                                                        )}
                                                </small>
                                            </Card.Subtitle>
                                            <Card.Text>
                                                {item.description}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        {this.state.templatePages ? (
                            <div>
                                <Pagination
                                    pager={this.state.templatePages}
                                    onChangePage={this.onChangePage}
                                />
                                <ItemCounter pager={this.state.templatePages} />
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        )
    }

    handleCloseModal(changes) {
        if (changes) {
            this.setState({ showConfirmChanges: true })
            return this.renderRemoveChangesForm()
        }

        return this.props.onClose()
    }

    renderRemoveChangesForm() {
        if (this.state.showConfirmChanges) {
            return (
                <ConfirmDeleteChangesDialog
                    kedo={this.props.kedo}
                    variant={'modal'}
                    accept={this.handleClose}
                    cancel={() => this.setState({ showConfirmChanges: false })}
                />
            )
        }
    }

    newEnvironmentForm() {
        const kedo = this.props.kedo
        let $imagePreview = null

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => this.handleCloseModal(this.state.unsavedChanges)}
                show={true}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{kedo.t('New environment')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    {this.state.general ? (
                        <Alert variant="danger">{this.state.general}</Alert>
                    ) : null}
                    <Tab.Container defaultActiveKey="generic">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Link eventKey="generic">
                                        {kedo.t('Generic')}
                                        {this.state.errors != null &&
                                        this.state.errors &&
                                        (this.state.errors.name ||
                                            this.state.errors.description) ? (
                                            <FontAwesomeIcon
                                                className={`validationIcon validationFailed`}
                                                icon={faExclamationCircle}
                                            />
                                        ) : null}
                                    </Nav.Link>
                                </Nav>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Link eventKey="languages">
                                        {kedo.t('Languages')}
                                        {this.state.errors != null &&
                                        this.state.errors &&
                                        this.state.errors.language ? (
                                            <FontAwesomeIcon
                                                className={`validationIcon validationFailed`}
                                                icon={faExclamationCircle}
                                            />
                                        ) : null}
                                    </Nav.Link>
                                </Nav>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Link eventKey="templates">
                                        {kedo.t('Templates')}
                                    </Nav.Link>
                                </Nav>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Link eventKey="misc">
                                        {kedo.t('Misc')}
                                    </Nav.Link>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="generic">
                                        <h3>{kedo.t('Generic')}</h3>
                                        <Form.Group controlId="environment.name">
                                            <Form.Label>
                                                {kedo.t('Name')}
                                            </Form.Label>
                                            <Form.Control
                                                ref={
                                                    this.checkErrors() ===
                                                    'name'
                                                        ? this.innerRef
                                                        : false
                                                }
                                                isInvalid={
                                                    this.state.errors != null &&
                                                    this.state.errors.name
                                                }
                                                onChange={
                                                    this.handleFieldChange
                                                }
                                                name={'name'}
                                                readOnly={this.state.saving}
                                                type="text"
                                            />
                                            {this.state.errors != null &&
                                            this.state.errors.name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.name}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group controlId="environment.description">
                                            <Form.Label>
                                                {kedo.t('Description')}
                                            </Form.Label>
                                            <Form.Control
                                                ref={
                                                    this.checkErrors() ===
                                                    'description'
                                                        ? this.innerRef
                                                        : false
                                                }
                                                isInvalid={
                                                    this.state.errors != null &&
                                                    this.state.errors
                                                        .description
                                                }
                                                name={'description'}
                                                onChange={
                                                    this.handleFieldChange
                                                }
                                                readOnly={this.state.saving}
                                                as="textarea"
                                                rows="3"
                                            />
                                            {this.state.errors != null &&
                                            this.state.errors.description ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        this.state.errors
                                                            .description
                                                    }
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group controlId="environment.image">
                                            <Form.Label>
                                                {kedo.t('Image')}
                                            </Form.Label>
                                            <section className="imageContainer fakeInputField">
                                                <input
                                                    accept="image/png, image/jpeg"
                                                    name={'image'}
                                                    onChange={
                                                        this._handleImageChange
                                                    }
                                                    type="file"
                                                />
                                                <aside
                                                    className={'imagePreview'}
                                                >
                                                    {$imagePreview}
                                                </aside>
                                            </section>
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="languages">
                                        <h3>{kedo.t('Languages')}</h3>
                                        <ul className={'list-group'}>
                                            {this.state.loadingLocales ? (
                                                <li
                                                    className={
                                                        'list-group-item'
                                                    }
                                                >
                                                    <LoadingDefault />
                                                    &nbsp; {kedo.t('Loading')}
                                                    ...
                                                </li>
                                            ) : null}
                                            <Form.Group controlId="environment.language">
                                                {this.state.locales.map(
                                                    (item) => (
                                                        <li
                                                            key={item.id}
                                                            className={
                                                                'list-group-item'
                                                            }
                                                        >
                                                            <Form.Check
                                                                isInvalid={
                                                                    this.state
                                                                        .errors !=
                                                                        null &&
                                                                    this.state
                                                                        .errors
                                                                        .language
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    this.handleCheck(
                                                                        event,
                                                                        item
                                                                    )
                                                                }
                                                                key={item.id}
                                                                type="checkbox"
                                                                value={item.id}
                                                                label={
                                                                    item.name
                                                                }
                                                            />
                                                        </li>
                                                    )
                                                )}
                                                <Form.Text className="text-muted">
                                                    {kedo.t(
                                                        'Select at least 1 language'
                                                    )}
                                                </Form.Text>
                                                {this.state.errors != null &&
                                                this.state.errors.language ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.errors
                                                                .language
                                                        }
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="templates">
                                        <h3>{kedo.t('Templates')}</h3>
                                        {this.renderTemplates()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="misc">
                                        <h3>{kedo.t('Misc')}</h3>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.save} variant="primary">
                        {this.state.saving ? (
                            <LoadingDefault size={'sm'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.handleClose()}
                        variant="secondary"
                        title={'Cancel'}
                    >
                        {kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderApply(templId, templates, environment) {
        let templ = templates.find((item) => item.id === templId)
        if (!templ || templ.template) {
            return <p>{this.props.kedo.t('Template could not be applied')}</p>
        }

        return (
            <Applying
                confirmed={true}
                popup={true}
                kedo={this.props.kedo}
                environment={environment}
                templateName={templ.name}
                template={JSON.parse(templ.template)}
            />
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.addLocales !== this.state.addLocales ||
            prevState.description !== this.state.description ||
            prevState.file !== this.state.file ||
            prevState.imagePreviewUrl !== this.state.imagePreviewUrl ||
            prevState.name !== this.state.name ||
            prevState.template !== this.state.template
        ) {
            this.setState({ unsavedChanges: true })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.fetchTemplates()
        this.fetchLocales()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        if (true !== this.props.kedo.isAdminOrContractAdmin()) {
            return ''
        }

        return (
            <div
                className={'newEnvironmentButton'}
                style={{ marginRight: '10px' }}
            >
                {this.state.showConfirmChanges
                    ? this.renderRemoveChangesForm()
                    : null}
                {this.state.runTemplate ? (
                    <Applying
                        kedo={this.props.kedo}
                        manualConfirm={true}
                        onClose={this.handleExit}
                        popup={true}
                        environment={this.state.newEnvironment}
                        template={JSON.parse(
                            this.state.templates.find(
                                (item) => item.id === this.state.template
                            ).template
                        )}
                    />
                ) : null}
                {!this.state.runTemplate ? this.newEnvironmentForm() : null}
            </div>
        )
    }
}

export default NewEnvironment
