class Utils {
    constructor() {
        this.i18n = null
        this.savedSearch = []
        this.savedLimit = []
    }

    setI18N(i18n) {
        this.i18n = i18n
    }

    getI18N() {
        return this.i18n
    }

    translate(translation, options) {
        if (!this.i18n) {
            return translation
        }

        return this.i18n.t(translation, options)
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8
                return v.toString(16)
            }
        )
    }

    dateFormat(val, dateType) {
        if (!val || val.length <= 0) {
            return ''
        }

        let date = new Date(val)

        if (date instanceof Date && !isNaN(date)) {
            if (dateType === 'short') {
                return date.toLocaleDateString()
            }

            if (dateType === 'YYYY-MM-DD') {
                let month = '' + (date.getMonth() + 1)
                let day = '' + date.getDate()
                let year = date.getFullYear()

                if (month.length < 2) {
                    month = '0' + month
                }

                if (day.length < 2) {
                    day = '0' + day
                }

                return [year, month, day].join('-')
            }

            return date.toLocaleString()
        }

        return ''
    }

    getSavedLimit(defDossierId) {
        this.checkSessionStorage()

        let find =
            defDossierId && this.savedLimit
                ? this.savedLimit.find(
                      (item) => item.defDossierId === parseInt(defDossierId)
                  )
                : null

        return find
    }

    createSavedLimit(data) {
        let filter =
            data.pager.limit && this.savedLimit
                ? this.savedLimit.filter((item) => {
                      item.pager.limit !== parseInt(data.pager.limit)
                  })
                : null

        if (data && filter) {
            filter.push(data)
            sessionStorage.setItem('limit', JSON.stringify(filter))
        }
    }

    deleteSavedLimit(defDossierId = null) {
        let filter = defDossierId
            ? this.savedLimit.filter(
                  (item) => item.defDossierId !== parseInt(defDossierId)
              )
            : null

        if (defDossierId && filter.length > 0) {
            this.savedLimit = filter
            sessionStorage.setItem('limit', JSON.stringify(filter))
        } else {
            this.savedLimit = []
            sessionStorage.removeItem('limit')
        }
    }

    getSavedSearch(defDossierId) {
        this.checkSessionStorage()

        let find =
            defDossierId && this.savedSearch
                ? this.savedSearch.find(
                      (item) => item.defDossierId === parseInt(defDossierId)
                  )
                : null

        return find
    }

    createSavedSearch(data) {
        let filter =
            data.defDossierId && this.savedSearch
                ? this.savedSearch.filter(
                      (item) =>
                          item.defDossierId !== parseInt(data.defDossierId)
                  )
                : null

        if (data && filter) {
            filter.push(data)
            sessionStorage.setItem('search', JSON.stringify(filter))
        }
    }

    deleteSavedSearch(defDossierId = null) {
        let filter = defDossierId
            ? this.savedSearch.filter(
                  (item) => item.defDossierId !== parseInt(defDossierId)
              )
            : null

        if (defDossierId && filter.length > 0) {
            this.savedSearch = filter
            sessionStorage.setItem('search', JSON.stringify(filter))
        } else {
            this.savedSearch = []
            sessionStorage.removeItem('search')
        }
    }

    checkSessionStorage() {
        const storage = sessionStorage.getItem('search')
        const storageLimit = sessionStorage.getItem('limit')

        if (storage) {
            this.savedSearch = JSON.parse(storage)
        }
        if (storageLimit) {
            this.savedLimit = JSON.parse(storageLimit)
        }
    }

    getCustomlength(value) {
        if (
            value !== null &&
            typeof value === 'object' &&
            Array.isArray(value) === false
        ) {
            return Object.keys(value).length
        } else {
            return value.length
        }
    }
}

export default Utils
