import React from 'react'
import {Row, Col, FormControl, Form} from 'react-bootstrap'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

class GeolocationField extends React.Component {
    constructor(props) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            latitude: this.props.value && this.props.value.latitude ? this.props.value.latitude : null,
            longitude: this.props.value && this.props.value.longitude ? this.props.value.longitude : null,
            zoom: this.props.value && this.props.value.zoom ? this.props.value.zoom : 10,
        };

        this.doFocus = this.doFocus.bind(this);
        this.onChangeLongitudeLatitude = this.onChangeLongitudeLatitude.bind(this);
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1);
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    setVal(name, val) {
        this.setState({[name]: val})
    }

    getValue() {
        return {
            longitude: this.state.longitude,
            latitude: this.state.latitude,
        };
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true;
        }

        return false;
    }

    renderShow() {
        const containerStyle = {
            width: '800px',
            height: '600px',
            position: 'absolute'
        };

        const defLoc = {
            lng: 4.895168,
            lat:  52.370216,
        };

        const curLocation = {
            lng: this.state.longitude ? parseFloat(this.state.longitude) : null,
            lat: this.state.latitude ? parseFloat(this.state.latitude) : null,
        };

        const changeValue = this.props.changeValue;

        if (!this.state.longitude && this.props.mode != 'edit') {
            return <div />
        }

        return <div>
            <LoadScript googleMapsApiKey={"AIzaSyAVb-S8KFIQgQ1KhSs9JvYJ1VFYzkPjar4"}><GoogleMap
                    options={{
                        googleMapsApiKey:  "AIzaSyAVb-S8KFIQgQ1KhSs9JvYJ1VFYzkPjar4",
                    }}
                center={this.state.longitude ? curLocation : defLoc}
                mapContainerStyle={containerStyle}
                zoom={this.state.zoom}
            >
                { /* Child components, such as markers, info windows, etc. */ }
                <Marker draggable={this.props.mode === 'edit'} onDragEnd={this.onChangeLongitudeLatitude} position={this.state.longitude ? curLocation : defLoc} />
                <></>
            </GoogleMap> </LoadScript>
        </div>;
    }

    onChangeLongitudeLatitude(event) {
        if (!event || !event.latLng) {
            return;
        }

        const lng = event.latLng.lng();
        const lat = event.latLng.lat();

        this.setState({
            latitude: lat,
            longitude: lng,
        });
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, {
                latitude: lat,
                longitude: lng,
            });
        }
    }

    renderEdit() {
        return  <Row>
            <Col>
                <h3>Geolocation</h3>
                <Form.Group>
                    <FormControl
                        className={"displayItemField"}
                        isInvalid={this.isInvalid()}
                        onChange={(event) => this.onChangeLatitude(event)}
                        ref={this.state.latitude ? this.innerRef : false}
                        type="text"
                        value={this.state.latitude}
                    />
                </Form.Group>
                <Form.Group>
                    <FormControl
                        className={"displayItemField"}
                        isInvalid={this.isInvalid()}
                        onChange={(event) => this.onChangeLongitude(event)}
                        ref={this.state.longitude ? this.innerRef : false}
                        type="text"
                        value={this.state.longitude}
                    />
                </Form.Group>
            </Col>
        </Row>
    }

    renderSearch() {
        return null;
    }

    render() {
        if (this.props.loadError) {
            return <div>{this.props.kedo.t('General error')}</div>
        }

        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            return this.renderSearch()
        } else if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderShow()
        }
        return this.renderShow()
    }
}

export default GeolocationField