import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import SelectList from '../SelectList'
import DossierQueryModal from '../Modals/DossierQueryModal'

export default class DossierQuerySelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value,
            ddId: this.props.ddId,
        }

        this.onChange = this.onChange.bind(this)
    }

    onChange(id, item) {
        this.setState({ value: id })
        if (this.props.onChange) {
            this.props.onChange(id, item)
        }
    }

    render() {
        const kedo = this.props.kedo

        let urlParams = {
            environment: kedo.env().getEnvironment().id,
            view: this.state.view,
            defDossier: this.state.ddId,
        }

        let newItem = {
            defDossier: this.state.ddId,
        }

        return (
            <Form.Group>
                <Form.Label className={this.props.required ? 'required' : ''}>
                    {this.props.label}
                </Form.Label>
                <Form.Label>{kedo.t('Query')}</Form.Label>
                <SelectList
                    detailUrl={`/querybuilder/${this.state.value}`}
                    currentValue={this.state.value}
                    onClear={() => this.onChange(null)}
                    disableInit={true}
                    searchName={'search'}
                    kedo={kedo}
                    addComponent={(onSuccess, onClose) => (
                        <DossierQueryModal
                            type={this.props.type}
                            onSuccess={(item, onClose) => {
                                this.onChange(item.id, item)
                                onClose()
                            }}
                            onClose={onClose}
                            item={newItem}
                            kedo={kedo}
                            defDossier={this.state.ddId}
                        />
                    )}
                    editComponent={(onClose) => (
                        <DossierQueryModal
                            onClose={onClose}
                            defDossier={this.state.ddId}
                            kedo={kedo}
                            id={this.state.value}
                        />
                    )}
                    urlParams={urlParams}
                    onDisplayName={(item) => {
                        return (
                            <span>
                                {item.id}:{' '}
                                {kedo.env().translateItem(item, 'dossierquery')}{' '}
                                {item.def_dossier_def_field &&
                                item.def_dossier_def_field.def_field ? (
                                    <small
                                        title={`Data field: ${item.def_dossier_def_field.id}`}
                                        className={
                                            'badge badge-secondary float-right'
                                        }
                                    >
                                        {
                                            item.def_dossier_def_field.def_field
                                                .type
                                        }
                                    </small>
                                ) : null}
                            </span>
                        )
                    }}
                    url={kedo.api().getDossierQueryEndpoint()}
                    onSelect={(item) => this.onChange(item.id, item)}
                />
            </Form.Group>
        )
    }
}
