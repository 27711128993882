import React, { PureComponent } from 'react'
import {Button, Modal} from "react-bootstrap";

class ExpiredModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const kedo = this.props.kedo;

        return <Modal centered show={true}>
            <Modal.Header>
                <Modal.Title>{kedo.t('Expired')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {kedo.t('subscription_expired')}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {location.href = '/subscriptions'}} variant="success">{kedo.t('Renew')}</Button>
            </Modal.Footer>
        </Modal>
    }
}

export default ExpiredModal