import React from 'react'
import { Card, CardDeck, Row, Button } from 'react-bootstrap'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'

export default class BlockCardDefault extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderContentCard(dossierData, dossier, listItems) {
        return (
            <Card
                key={dossier.id}
                className={`d-flex flex-column h-100 card-contentRow ${
                    dossier.archived ? 'archivedDossier' : ''
                }`}
                onClick={(event) => this.props.handleContent(event, dossier)}
                style={{ cursor: 'pointer' }}
            >
                <Card.Body>
                    <Card.Title>{dossier.summary}</Card.Title>
                    <Card.Text>
                        <table className={'table table-striped'}>
                            <tbody>
                                {listItems.map((listItem, lIndex) => (
                                    <tr key={lIndex}>
                                        <th>
                                            {this.props.kedo
                                                .env()
                                                .translateItem(
                                                    listItem,
                                                    'displayitem'
                                                )}
                                        </th>
                                        <td>
                                            {this.props.getValue(
                                                listItem,
                                                dossierData,
                                                dossier
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card.Text>
                </Card.Body>
                {this.props.block.settings.inline_edit ? (
                    <Card.Footer>
                        <DossierModalEditButton
                            dossierId={dossier.id}
                            kedo={this.props.kedo}
                        />
                    </Card.Footer>
                ) : null}
            </Card>
        )
    }

    render() {
        return (
            <Row className={'d-flex align-items-stretch'}>
                <CardDeck>
                    {this.props.data.dossiers.map((dossier) =>
                        this.renderContentCard(
                            this.props.data.contents.filter(
                                (dataItem) => dataItem.id === dossier.id
                            ),
                            dossier,
                            this.props.listItems
                        )
                    )}
                </CardDeck>
            </Row>
        )
    }
}
