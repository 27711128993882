import packageJson from '../package.json'
global.appVersion = packageJson.version
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import App from './App'
import Kedo from './Kedo'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { KeDoContext } from './kedo-context'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import('./css/kedo-frontend.scss')
import('./../node_modules/react-datetime/css/react-datetime.css')
import 'react-toggle/style.css'
import 'react-toastify/dist/ReactToastify.css'
import GlobalServices from './context/GlobalServices.tsx'
import { BrowserRouter } from 'react-router-dom'

const kedo = new Kedo()
kedo.utils().setI18N(i18n)
let locale = false
try {
    if (
        localStorage.getItem('locale') &&
        localStorage.getItem('locale').length > 0
    ) {
        locale = JSON.parse(localStorage.getItem('locale'))
    }
} catch (e) {
    locale = false
}

if (!locale || !locale.code) {
    locale = false
} else {
    locale = locale.code.substr(0, 2).toLowerCase()
}

const fallbackLng = ['en']
const availableLanguages = ['en', 'nl', 'es', 'fr', 'de', 'ru']

const options = {
    // order and from where user language should be detected
    order: ['navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // only detect languages that are in the whitelist
    checkWhitelist: true,
}

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
        {
            fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
            debug: false,
            whitelist: availableLanguages,
            detection: options,

            ns: ['common', 'other', 'secureshare'],
            defaultNS: 'common',
            fallbackNS: ['other'],

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        },
        function (err, t) {
            const root = ReactDOMClient.createRoot(
                document.getElementById('root')
            )
            root.render(
                <KeDoContext.Provider value={kedo}>
                    <BrowserRouter>
                        <GlobalServices>
                            <App user={kedo.user()} kedo={kedo} i18n={i18n} />
                        </GlobalServices>
                    </BrowserRouter>
                </KeDoContext.Provider>
            )
        }
    )
