import React, { Component, Suspense } from 'react'
import { Dropdown, Modal, ListGroup } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flag from '../../../Components/Elements/Flag.tsx'

class UpperMenuLocales extends Component {
    constructor(props) {
        super(props)

        this.state = {
            browserLang: this.props.kedo.env().getBrowserLanguage(),
            newLang: false,
        }

        this.getAvailableLocales = this.getAvailableLocales.bind(this)
        this.getPopUp = this.getPopUp.bind(this)
    }

    getCurrentLocale() {
        let currentLocaleCode = this.props.kedo.env().locale.code
        currentLocaleCode = currentLocaleCode.substr(
            currentLocaleCode.length - 2
        )

        return <Flag code={currentLocaleCode} height="12" className={'mr-2'} />
    }

    getAvailableLocales() {
        let availableLocales = this.props.kedo.env().getEnvironmentLocales()
        return availableLocales.map((locale) => {
            let flagCode = locale.code.substr(locale.code.length - 2)
            let localeCode = this.getLanguageCodeFromCode(locale.code)

            return (
                <Dropdown.Item
                    key={flagCode}
                    onClick={() => {
                        this.props.kedo.env().setLocale(locale)
                        this.props.kedo
                            .utils()
                            .getI18N()
                            .changeLanguage(localeCode)
                    }}
                >
                    <Flag code={flagCode} />
                    &nbsp;&nbsp; {locale.name}
                </Dropdown.Item>
            )
        })
    }

    getLanguageCodeFromCode(code) {
        return code.substr(0, 2).toLowerCase()
    }

    getPopUp() {
        let availableLocales = this.props.kedo.env().getEnvironmentLocales()
        let selectedLocale = this.props.kedo.env().getSelectedLocale()

        let checkLocale = null
        let checkLocalStorage = null

        if (availableLocales) {
            checkLocale = availableLocales.find((item) =>
                item.code.includes(this.state.browserLang)
            )
            checkLocalStorage = availableLocales.find(
                (item) => item.code === selectedLocale.code
            )
        }

        if (availableLocales && !checkLocale && !checkLocalStorage) {
            return (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                            &nbsp; {this.props.kedo.t('Language not available')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.kedo.t('Locales_warning')} <br />
                        <br />
                        <strong>
                            {this.props.kedo.t('Available languages')}:
                        </strong>
                        <br />
                        <ListGroup>
                            {availableLocales.map((locale) => {
                                let localeCode = this.getLanguageCodeFromCode(
                                    locale.code
                                )
                                return (
                                    <ListGroup.Item
                                        className={'select-language'}
                                        key={locale.id}
                                        onClick={() => {
                                            this.props.kedo
                                                .env()
                                                .setLocale(locale)
                                            this.props.kedo
                                                .utils()
                                                .getI18N()
                                                .changeLanguage(localeCode)
                                            this.setState({ newLang: true })
                                        }}
                                    >
                                        {locale.name}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Modal.Body>
                </Modal>
            )
        }
    }

    componentDidMount() {
        let selectedLocale = this.props.kedo.env().getSelectedLocale()

        if (selectedLocale) {
            let localeCode = this.getLanguageCodeFromCode(selectedLocale.code)
            return this.props.kedo.utils().getI18N().changeLanguage(localeCode)
        }
    }

    render() {
        return (
            <li>
                {this.state.newLang ? null : this.getPopUp()}
                {this.props.kedo.env().getEnvironmentLocales() &&
                this.props.kedo.env().getEnvironmentLocales().length > 1 ? (
                    <Suspense fallback={<LoadingDefault />}>
                        <Dropdown>
                            <Dropdown.Toggle
                                className={'uppermenu-buttons'}
                                variant="light"
                            >
                                {this.props.kedo.env().locale
                                    ? this.getCurrentLocale()
                                    : '?'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight>
                                {this.getAvailableLocales()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Suspense>
                ) : null}
            </li>
        )
    }
}

export default UpperMenuLocales
