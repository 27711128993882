import { Badge, Dropdown } from 'react-bootstrap'
import * as React from 'react'
import { Link } from 'react-router-dom'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import ItemCounter from '../../../Components/Elements/ItemCounter.jsx'
import Pagination from '../../../Components/Elements/Pagination.jsx'
import DisplayItemHelper from '../../../util/DisplayItemHelper'

interface ContentDossierIndexFooterProps {
    listItems: Array<IDisplayField>
    dossiers: Array<any>
    kedo: any
    onChangePage: any
    loadingContent: boolean
    pager: any
    aggregations: any
    resultAggregations: any
    setAggregations: any
    extraColumns: any
}

const ContentDossierIndexFooter: React.FC<ContentDossierIndexFooterProps> = ({
    listItems,
    dossiers,
    kedo,
    onChangePage,
    loadingContent,
    pager,
    aggregations,
    setAggregations,
    resultAggregations,
    extraColumns,
}) => {
    const renderAggregateField = (field) => {
        let aggrOptions = []

        if (field.def_dossier_link) {
            aggrOptions = ['unique']
        } else if (field.def_dossier_def_field) {
            if (field.def_dossier_def_field.def_field.type === 'amount') {
                aggrOptions = ['avg', 'sum', 'min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'time') {
                aggrOptions = ['avg', 'sum', 'min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'date') {
                aggrOptions = ['min', 'max']
            } else if (field.def_dossier_def_field.def_field.type === 'list') {
                aggrOptions = ['unique']
            }
        }
        if (aggrOptions.length <= 0) {
            return null
        }

        const curVal = aggregations.find((item) => item.id === field.id)
        return (
            <Dropdown className={'float-right aggregate'}>
                <Dropdown.Toggle size="sm" key={`dd-aggr-${field.id}`} />

                <Dropdown.Menu align={'right'}>
                    <Dropdown.Item
                        onClick={() => {
                            const aggrs = aggregations.filter(
                                (aggrItem) => aggrItem.id !== field.id
                            )
                            setAggregations(aggrs)
                        }}
                    >
                        &nbsp;
                    </Dropdown.Item>
                    {aggrOptions.map((aggrOption, aggrIndex) => (
                        <Dropdown.Item
                            key={aggrIndex}
                            active={curVal && curVal.aggregate === aggrOption}
                            onClick={() => {
                                const aggrs = aggregations.filter(
                                    (aggrItem) => aggrItem.id !== field.id
                                )
                                aggrs.push({
                                    id: field.id,
                                    aggregate: aggrOption,
                                })

                                setAggregations(aggrs)
                            }}
                        >
                            {kedo.t(aggrOption.toUpperCase())}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const renderAggregateFieldResult = (listItem) => {
        if (!resultAggregations || !resultAggregations[listItem.id]) {
            return null
        }

        if (listItem && listItem.def_dossier_link) {
            return (
                <div className={'aggr-result'}>
                    {resultAggregations[listItem.id].map(
                        (aggrItem, aggrIndex) => (
                            <Badge key={aggrIndex} className={'mr-1'}>
                                <Link to={`/contentdossier/${aggrItem.id}`}>
                                    {aggrItem.summary}
                                </Link>{' '}
                                <span className={'badge badge-light'}>
                                    {aggrItem.total}
                                </span>
                            </Badge>
                        )
                    )}
                </div>
            )
        } else if (listItem && listItem.def_dossier_def_field) {
            return (
                <div className={'aggr-result'}>
                    {DisplayItemHelper.isTypeOfField(
                        listItem,
                        DisplayItemHelper.FIELD_DATE_TYPE
                    )
                        ? kedo
                              .utils()
                              .dateFormat(resultAggregations[listItem.id])
                        : null}
                    {DisplayItemHelper.isTypeOfField(
                        listItem,
                        DisplayItemHelper.FIELD_TIME_TYPE
                    ) ||
                    DisplayItemHelper.isTypeOfField(
                        listItem,
                        DisplayItemHelper.FIELD_AMOUNT_TYPE
                    )
                        ? resultAggregations[listItem.id]
                        : null}
                    {DisplayItemHelper.isTypeOfField(
                        listItem,
                        DisplayItemHelper.FIELD_LIST_TYPE
                    )
                        ? listItem.def_dossier_def_field.def_field.def_field_lists
                              .filter((defFieldListItem) =>
                                  resultAggregations[listItem.id].find(
                                      (selItem) =>
                                          selItem.id === defFieldListItem.id
                                  )
                              )
                              .map((defFieldListItem, dIndex) => (
                                  <Badge
                                      key={dIndex}
                                      className={'mr-1'}
                                      style={{
                                          backgroundColor:
                                              defFieldListItem.color
                                                  ? defFieldListItem.color
                                                  : null,
                                      }}
                                  >
                                      {kedo.translateItem(
                                          defFieldListItem,
                                          'def_field_list'
                                      )}{' '}
                                      <span className={'badge badge-light'}>
                                          {
                                              resultAggregations[
                                                  listItem.id
                                              ].find(
                                                  (selItem) =>
                                                      selItem.id ===
                                                      defFieldListItem.id
                                              ).total
                                          }
                                      </span>
                                  </Badge>
                              ))
                        : null}
                </div>
            )
        }
    }

    if (loadingContent) {
        return (
            <tfoot>
                <tr>
                    <td
                        className={'text-center'}
                        colSpan={listItems.length + extraColumns}
                    >
                        <LoadingDefault />
                    </td>
                </tr>
            </tfoot>
        )
    }

    if (!dossiers || dossiers.length <= 0) {
        return <tfoot />
    }

    return (
        <tfoot>
            <tr className={'tr-aggr'}>
                <th style={{ width: '3%' }} />
                <th style={{ textAlign: 'center' }} />
                {listItems.map((listItem, lIndex) => (
                    <td key={lIndex}>
                        {renderAggregateField(listItem)}
                        {renderAggregateFieldResult(listItem)}
                    </td>
                ))}
                {extraColumns - 2 > 0 ? (
                        <td />
                ) : null}              
                {extraColumns - 2 > 0 ? (
                        <td />
                ) : null}                
            </tr>
            <tr>
                <td colSpan={listItems.length + extraColumns}>
                    <Pagination pager={pager} onChangePage={onChangePage} />
                    <ItemCounter pager={pager} />
                </td>
            </tr>
        </tfoot>
    )
}

export default ContentDossierIndexFooter
