import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DisplayItemTimeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable_limit: this.props.item && this.props.item.settings && this.props.item.settings.disable_limit === true ? true : false,
        };
    }

    render() {
        const kedo = this.props.kedo;

        return <Form.Group>
            <Form.Check
                type={"checkbox"}
                label={kedo.t('Disable limit')}
                onChange={(e) => {this.setState({disable_limit: e.target.checked}); this.props.onChange('disable_limit', e.target.checked)}}
                name={'disable_limit'}
                checked={this.state.disable_limit === true}
            />
        </Form.Group>
    }
}

export default DisplayItemTimeSettings