import React from 'react'
import {Carousel} from "react-bootstrap";
import DossierModalEditButton from "../../Elements/DossierModalEditButton";


export default class BlockCardCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getImageContent(imageField, dossierData) {
        if (!dossierData) {
            return '';
        }
        let baseUrl = this.props.kedo.api().getBaseUrl();
        let singleDossierData = dossierData.find(data => data.content.id === imageField.id);
        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content;
        }

        if (!singleDossierData || singleDossierData.length <= 0) {
            return <img style={{maxHeight: '400px', objectFit: 'cover'}} className={"d-block w-100"} src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='} />;
        }


        return <img style={{maxHeight: '400px', objectFit: 'cover'}} className={"d-block w-100"} src={`${baseUrl}/file/${singleDossierData[0].id}/download`} />
    }

    renderContentCarousel(dossierData, dossier, listItems) {
        return <Carousel.Item key={`carousel-item-${dossier.id}`}>
            <center>
                <h3><a href={`/contentdossier/${dossier.id}`}>{dossier.summary}</a></h3>
                {listItems.map(listItem =>
                    <div className={'kedo-carousel-content'} key={`carousel-d-${dossier.id}-i-${listItem.id}`}><strong>{this.props.kedo.env().translateItem(listItem, 'displayitem')}</strong>{this.props.getValue(listItem, dossierData, dossier)}</div>
                )}
                {this.props.block.settings.inline_edit ? <DossierModalEditButton dossierId={dossier.id} kedo={this.props.kedo} /> : null }
            </center>
        </Carousel.Item>
    }

    render() {
        const col = this.props.block.col;

        if (!this.props.block || !this.props.block.settings || !this.props.block.settings.templateOptions) {
            return '';
        }

        let pictureId = this.props.block.settings.templateOptions.picture;
        let pictureField = this.props.listItems.find(listItem => listItem.id === pictureId);

        let titleId = this.props.block.settings.templateOptions.title;
        let titleField = this.props.listItems.find(listItem => listItem.id === titleId);

        let descId = this.props.block.settings.templateOptions.description;
        let descField = this.props.listItems.find(listItem => listItem.id === descId);

        const mdCol = col && col >= 6 && col <= 12 ? col : 12;
        const lgCol = col && col > 0 && col <= 12 ? col : 12;

        return <Carousel>
            {this.props.data.dossiers.map(dossier => {

                let dossierData = this.props.data.contents.filter(dataItem => dataItem.id === dossier.id);

                return <Carousel.Item className={`col-md-${mdCol} col-lg-${lgCol}`} key={`carousel-item-${dossier.id}`}>
                        {this.getImageContent(pictureField, dossierData)}
                        <Carousel.Caption>
                            <h3><a href={`/contentdossier/${dossier.id}`}>{titleField ? this.props.getValue(titleField, dossierData, dossier) : dossier.summary}</a></h3>
                            {descField ? this.props.getValue(descField, dossierData, dossier) : null}
                        </Carousel.Caption>
                    </Carousel.Item>
            })}
        </Carousel>
    }
}