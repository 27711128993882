import React from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap'

class AmountField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            value: this.props.value,
        }

        this.doFocus = this.doFocus.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.value
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    getDefFieldSettings() {
        if (!this.props.item.def_dossier_def_field.def_field.settings) {
            return {}
        }

        return this.props.item.def_dossier_def_field.def_field.settings
    }

    getAmountType() {
        let amountType = this.getDefFieldSettings().valueType

        if (!amountType) {
            return 'number'
        }

        return amountType
    }

    renderEditNumber() {
        return (
            <div>
                <FormControl
                    className={'displayItemField'}
                    isInvalid={this.isInvalid()}
                    onChange={(event) => this.onChange(event)}
                    onKeyPress={(evt) => {
                        switch (evt.keyCode) {
                            case 8: // Backspace
                            case 46: // Delete
                            case 37: // Left arrow
                            case 39: // Right arrow
                                break
                            default:
                                // eslint-disable-next-line
                                const regex = new RegExp('^[0-9., ]+$')
                                // eslint-disable-next-line no-case-declarations
                                const key = evt.key
                                if (!regex.test(key)) {
                                    evt.preventDefault()
                                    return false
                                }
                        }
                    }}
                    ref={this.innerRef}
                    type="text"
                    value={this.state.value}
                />
                {this.isInvalid() ? (
                    <div className="invalid-feedback">{this.props.errors}</div>
                ) : null}
            </div>
        )
    }

    renderShowNumber() {
        if (!this.state.value || this.state.value.length <= 0) {
            return (
                <span className={'text-muted small'}>
                    {this.props.kedo.t('Unknown')}
                </span>
            )
        }

        return this.state.value
    }

    onChange(event) {
        this.setState({ value: event.target.value }, () => {
            if (this.props.changeValue) {
                this.props.changeValue(
                    this.props.item.id,
                    event.target.value,
                    this.props.item
                )
            }
        })
    }

    getCurrencySymbols() {
        let curSymbols = {
            USD: '$', // US Dollar
            COP: '$', // Colombian Peso
            EUR: '€', // Euro
            CRC: '₡', // Costa Rican Colón
            GBP: '£', // British Pound Sterling
            ILS: '₪', // Israeli New Sheqel
            INR: '₹', // Indian Rupee
            JPY: '¥', // Japanese Yen
            KRW: '₩', // South Korean Won
            NGN: '₦', // Nigerian Naira
            PHP: '₱', // Philippine Peso
            PLN: 'zł', // Polish Zloty
            PYG: '₲', // Paraguayan Guarani
            THB: '฿', // Thai Baht
            UAH: '₴', // Ukrainian Hryvnia
            VND: '₫', // Vietnamese Dong
        }

        return curSymbols
    }

    getCurrencySign() {
        let currencySymbols = this.getCurrencySymbols()
        if (
            !this.props.item ||
            !this.props.item.def_dossier_def_field ||
            !this.props.item.def_dossier_def_field.def_field ||
            (!this.props.item.def_dossier_def_field.def_field.settings &&
                this.props.item.def_dossier_def_field.def_field.settings)
        ) {
            return ''
        }

        if (
            !this.props.item.def_dossier_def_field.def_field.settings.currency
        ) {
            return ''
        }

        return currencySymbols[
            this.props.item.def_dossier_def_field.def_field.settings.currency
        ]
    }

    renderEditCurrency() {
        let curSign = this.getCurrencySign()

        return (
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{curSign}</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    isInvalid={this.isInvalid()}
                    onChange={(event) => this.onChange(event)}
                    onKeyPress={(evt) => {
                        switch (evt.keyCode) {
                            case 8: // Backspace
                            case 46: // Delete
                            case 37: // Left arrow
                            case 39: // Right arrow
                                break
                            default:
                                // eslint-disable-next-line
                                const regex = new RegExp('^[0-9., ]+$')
                                // eslint-disable-next-line no-case-declarations
                                const key = evt.key
                                if (!regex.test(key)) {
                                    evt.preventDefault()
                                    return false
                                }
                        }
                    }}
                    placeholder={'0,00'}
                    ref={this.innerRef}
                    type="text"
                    value={this.state.value}
                />
                {this.isInvalid() ? (
                    <div className="invalid-feedback">{this.props.errors}</div>
                ) : null}
            </InputGroup>
        )
    }

    renderShowCurrency() {
        if (!this.state.value || this.state.value.length <= 0) {
            return (
                <span className={'text-muted small'}>
                    {this.props.kedo.t('Unknown')}
                </span>
            )
        }

        let curSign = this.getCurrencySign()
        let value = this.props.value

        // TODO CHECK THIS WHEN API IS FIXED
        // let checkDecimals = this.state.value.includes(",");
        // if (checkDecimals && this.props.item && this.props.item.def_dossier_def_field && this.props.item.def_dossier_def_field.def_field && this.props.item.def_dossier_def_field.def_field.settings && this.props.item.def_dossier_def_field.def_field.settings.decimals) {
        //     return '\u20AC' + value.toFixed(this.props.item.def_dossier_def_field.def_field.settings.decimals)
        // }

        return curSign + value
    }

    renderEditPercentage() {
        return (
            <InputGroup>
                <FormControl
                    isInvalid={this.isInvalid()}
                    onChange={(event) => this.onChange(event)}
                    onKeyPress={(evt) =>
                        evt.key === 'e' && evt.preventDefault()
                    }
                    ref={this.innerRef}
                    type="number"
                    value={this.state.value}
                />
                <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
                {this.isInvalid() ? (
                    <div className="invalid-feedback">{this.props.errors}</div>
                ) : null}
            </InputGroup>
        )
    }

    renderShowPercentage() {
        if (!this.state.value || this.state.value.length <= 0) {
            return (
                <span className={'text-muted small'}>
                    {this.props.kedo.t('Unknown')}
                </span>
            )
        }

        return this.state.value + ' %'
    }

    renderShow() {
        let type = this.getAmountType()
        switch (type) {
            case 'number':
                return this.renderShowNumber()
            case 'currency':
                return this.renderShowCurrency()
            case 'percentage':
                return this.renderShowPercentage()
            default:
                return this.renderShowNumber()
        }
    }

    renderEdit() {
        let type = this.getAmountType()
        switch (type) {
            case 'number':
                return this.renderEditNumber()
            case 'currency':
                return this.renderEditCurrency()
            case 'percentage':
                return this.renderEditPercentage()
            default:
                return this.renderEditNumber()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== prevProps.value && this.props.mode === 'show') {
            this.setState({ value: this.props.value })
        }
    }

    render() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            if (this.state.value !== this.props.value) {
                this.setState({ value: this.props.value })
            }
            return (
                <Form.Control
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    value={this.props.value}
                    onChange={(event) =>
                        this.props.changeValue(
                            this.props.item.id,
                            event.target.value
                        )
                    }
                    type="text"
                />
            )
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            return (
                <div>
                    {this.renderEdit()}
                    {this.isInvalid() ? (
                        <div className="invalid-feedback">
                            {this.props.errors}
                        </div>
                    ) : null}
                </div>
            )
        }
        return this.renderShow()
    }
}

export default AmountField
