import React, { Component } from 'react'
import { Button, Form, Modal, Alert } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

class ConfirmDeleteDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submitting: false,
        }

        this.deleteItem = this.deleteItem.bind(this)
    }

    deleteItem() {
        if (!this.props.url) {
            return
        }

        this.setState({ submitting: true })
        this.props.kedo
            .api()
            .delete(this.props.url)
            .then((response) => {
                if (this.props.onSuccess) {
                    this.props.onSuccess()
                } else {
                    this.props.onClose()
                }
            })
            .catch(
                (error) =>
                    console.log('Error deleting item', error) ||
                    this.setState({
                        error: true,
                        submitting: false,
                    })
            )
    }

    render() {
        return (
            <Modal
                show={true}
                variant={'danger'}
                onHide={() => this.props.onClose()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Confirmation')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {!this.state.error
                            ? this.props.kedo.t('confirm_delete_text_singular')
                            : null}
                        {this.props.warning ? <br /> : null}
                        {this.props.warning ? (
                            <Alert variant={'warning'}>
                                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                                {this.props.warning}
                            </Alert>
                        ) : null}
                        {this.state.error && this.props.errorLabel ? (
                            <Alert variant={'danger'}>
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                &nbsp; {this.props.errorLabel}
                            </Alert>
                        ) : null}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.error && this.props.errorLabel ? (
                        <Button
                            onClick={() => this.props.onClose()}
                            variant="secondary"
                        >
                            {this.props.kedo.t('Close')}
                        </Button>
                    ) : null}
                    {!this.state.error ? (
                        <div>
                            <Button onClick={this.deleteItem} variant="danger">
                                {this.state.submitting ? (
                                    <LoadingDefault size={'sm'} as={'span'} />
                                ) : (
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                )}
                                &nbsp; {this.props.kedo.t('Delete')}
                            </Button>{' '}
                            &nbsp;
                            <Button
                                onClick={() => this.props.onClose()}
                                variant="secondary"
                            >
                                {this.props.kedo.t('Cancel')}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ConfirmDeleteDialog
