import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class DisplayItemUserSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            default_current_user:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.default_current_user
                    ? this.props.item.settings.default_current_user
                    : null,
            display_name_style:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.display_name_style
                    ? this.props.item.settings.display_name_style
                    : null,
        }
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Form.Group>
                <Form.Check
                    checked={this.state.default_current_user === true}
                    id={'default_current_user'}
                    label={kedo.t('Current user as default')}
                    onChange={(e) => {
                        this.setState({
                            default_current_user: e.target.checked,
                        })
                        this.props.onChange(
                            'default_current_user',
                            e.target.checked
                        )
                    }}
                    type="checkbox"
                />
                <Form.Control
                    as="select"
                    onSelect={this.state.default_current_user === true}
                    id={'display_name_style'}
                    value={this.state.display_name_style}
                    onChange={(e) => {
                        this.setState({ display_name_style: e.target.value })
                        this.props.onChange(
                            'display_name_style',
                            e.target.value
                        )
                    }}
                >
                    <option value="default">Default</option>
                    <option value="avatar">Avatar</option>
                    <option value="both">Both</option>
                </Form.Control>
            </Form.Group>
        )
    }
}

export default DisplayItemUserSettings
