import React from 'react'
import { OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import DisplayItem from '../DisplayItems/DisplayItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons/faQuestionCircle'

import DisplayItemForm from '../Form/DisplayItemForm'
import DefDossierDefFieldForm from '../Form/DefDossierDefFieldForm'
import DefFieldForm from '../Form/DefFieldForm'
import DefDossierLinkForm from '../Form/DefDossierLinkForm'
import AdminQuickEditMenu from '../Elements/AdminQuickEditMenu'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'

class DefaultGrid extends React.Component {
    constructor(props) {
        super(props)

        for (let i = 0; i < props.items.length; i++) {
            this['ddiRef' + props.items[i].id] = React.createRef()
        }

        this.state = {
            docheck: false,
            firstcheck: false,
            withoutEdit: this.props.withoutEdit
                ? this.props.withoutEdit
                : false,
        }

        this.doFocus = this.doFocus.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.recheckConditions = this.recheckConditions.bind(this)
    }

    doFocus(errorId) {
        if (
            !this['ddiRef' + errorId] ||
            !this['ddiRef' + errorId].current ||
            !this['ddiRef' + errorId].current.ddiRef
        ) {
            return
        }

        this['ddiRef' + errorId].current.ddiRef.current.doFocus(errorId)
    }

    onCancel() {
        let filtered = this.props.items.filter(
            (item) => item.view === 'edit' || item.view === 'show/edit'
        )

        filtered.map((filterItem) => {
            if (
                (filterItem.def_dossier_link &&
                    filterItem.def_dossier_link.type_of_link === 'select') ||
                (filterItem.def_dossier_def_field &&
                    filterItem.def_dossier_def_field.def_field &&
                    (filterItem.def_dossier_def_field.def_field.type ===
                        'user' ||
                        filterItem.def_dossier_def_field.def_field.type ===
                            'list'))
            ) {
                this['ddiRef' + filterItem.id].current.ddiRef.current.onCancel()
            }
        })
    }

    getValues() {
        let values = {}
        let filtered = this.props.items.filter(
            (item) => item.view === 'edit' || item.view === 'show/edit'
        )

        filtered.map((filterItem) => {
            if (
                this['ddiRef' + filterItem.id].current &&
                this['ddiRef' + filterItem.id].current.ddiRef &&
                this['ddiRef' + filterItem.id].current.ddiRef.current &&
                this['ddiRef' + filterItem.id].current.ddiRef.current.getValue
            ) {
                values[filterItem.id] =
                    this[
                        'ddiRef' + filterItem.id
                    ].current.ddiRef.current.getValue()
            }
        })

        return values
    }

    isObligatory(item) {
        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.obligatory &&
            item.def_dossier_def_field.obligatory === true
        ) {
            return true
        }

        if (
            item.def_dossier_link &&
            item.def_dossier_link.obligatory === true
        ) {
            return true
        }

        if (this.props.conditions) {
            let values = this.getValues()
            let condResult = false
            this.props.conditions.map((condition) => {
                if (this.conditionMatches(condition, values)) {
                    if (
                        condition.actions
                            .filter(
                                (action) => action.action === 'require_fields'
                            )
                            .filter((action) =>
                                action.values.find(
                                    (actVal) => actVal === item.id
                                )
                            ).length > 0
                    ) {
                        condResult = true
                    }
                }
            })

            return condResult
        }

        return false
    }

    isEditMode() {
        if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return true
        }

        if (this.props.mode === 'show/edit') {
            return true
        }

        return false
    }

    getTranslationFromItem(item) {
        if (!item.translations || item.translations.length <= 0) {
            return
        }

        let locale = this.props.kedo.env().getSelectedLocale()
        let trans = item.translations.find(
            (trans) => trans.culture === locale.code
        )

        if (!trans && item.translations.length > 0) {
            trans = item.translations[0]
        }

        return trans
    }

    replaceSpecialTags(preText) {
        if (preText.indexOf('{') === false) {
            return preText
        }
        // eslint-disable-next-line
        let parts = preText.split(/\{dossier\:([0-9]+)\}/g)
        for (var i = 1; i < parts.length; i += 2) {
            parts[i] = (
                <Link target={'_new'} to={`/contentdossier/${parts[i]}`}>
                    {parts[i]}
                </Link>
            )
        }

        return <div>{parts}</div>
    }

    renderPreText(item) {
        let trans = this.getTranslationFromItem(item)

        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return
        }

        if (!trans || !trans.settings || !trans.settings.pretext) {
            return
        }

        return (
            <span className={'PreText'}>
                {this.replaceSpecialTags(trans.settings.pretext)}
            </span>
        )
    }

    renderInformationText(translation, show_info_direct) {
        if (
            !translation ||
            !translation.settings ||
            !translation.settings.informationtext
        ) {
            return null
        }

        let settings = {
            ALLOWED_TAGS: [
                'b',
                'img',
                'br',
                'span',
                'table',
                'ol',
                'tbody',
                'li',
                'i',
                'strong',
                'tr',
                'td',
                'thead',
                'div',
                'p',
            ],
            ALLOWED_ATTR: ['style', 'border', 'src', 'width', 'heigth'],
        }

        return (
            <>
                {show_info_direct ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                translation.settings.informationtext,
                                settings
                            ),
                        }}
                    />
                ) : (
                    <OverlayTrigger
                        key={translation.id + '-help'}
                        placement={'auto'}
                        overlay={
                            translation.settings.informationtext.includes(
                                '\n'
                            ) ? (
                                <Popover
                                    id={`pop-${translation.id}`}
                                    className="popOver"
                                >
                                    <Popover.Content className="popOverMoreContent">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    translation.settings
                                                        .informationtext,
                                                    settings
                                                ),
                                            }}
                                        />
                                    </Popover.Content>
                                </Popover>
                            ) : (
                                <Popover
                                    id={`pop-${translation.id}`}
                                    className="popOver"
                                >
                                    <Popover.Content className="popOverContent">
                                        <div
                                            className="popOverDiv"
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    translation.settings
                                                        .informationtext,
                                                    settings
                                                ),
                                            }}
                                        />
                                    </Popover.Content>
                                </Popover>
                            )
                        }
                    >
                        <span>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                    </OverlayTrigger>
                )}
            </>
        )
    }

    renderLabel(item) {
        if (this.props.custom_layout === 'row_input') {
            return null
        }

        if (!item.translations || item.translations.length <= 0) {
            return item.id
        }

        if (
            this.props.embedded &&
            item.def_dossier_link &&
            item.def_dossier_link.id === this.props.linkId
        ) {
            return null
        }

        let trans = this.getTranslationFromItem(item)

        //No label for embeddedLink display item if we are creating a new dossier.
        if (
            !this.props.dossier &&
            item.def_dossier_link &&
            item.def_dossier_link.type_of_link === 'embeddedList' &&
            this.props.mode !== 'search'
        ) {
            if (trans.length <= 0) {
                return ''
            }
        }

        return (
            <>
                <h6 data-ddiid={item.id}>
                    {trans.name ? trans.name : ''}&nbsp;
                    {this.isEditMode() && this.isObligatory(item) ? (
                        <span style={{ color: '#F38B8F' }}>*</span>
                    ) : (
                        ''
                    )}
                    &nbsp;
                    {!item.settings?.show_info_direct &&
                        this.renderInformationText(
                            trans,
                            item.settings?.show_info_direct
                        )}
                    <AdminQuickEditMenu kedo={this.props.kedo} item={item} />
                </h6>
                {item.settings?.show_info_direct &&
                    this.renderInformationText(
                        trans,
                        item.settings.show_info_direct
                    )}
                <AdminQuickEditMenu kedo={this.props.kedo} item={item} />
            </>
        )
    }

    getMinimalColspan(item) {
        let minColSpan = 12
        if (window.innerWidth < 576) {
            minColSpan = 12
        }
        if (
            window.innerWidth > 576 &&
            window.innerWidth < 768 &&
            item.colspan < 6
        ) {
            minColSpan = 6
        }
        if (
            window.innerWidth > 768 &&
            window.innerWidth < 992 &&
            item.colspan < 4
        ) {
            minColSpan = 4
        }
        if (
            window.innerWidth > 992 &&
            window.innerWidth < 1200 &&
            item.colspan < 2
        ) {
            minColSpan = 2
        }
        if (window.innerWidth > 1200) {
            minColSpan = 1
        }

        if (item.colspan < minColSpan) {
            return minColSpan
        }

        return item.colspan
    }

    recheckConditions() {
        let check = !this.state.docheck
        this.setState({ docheck: check })
    }

    conditionRuleFieldMatches(fieldRule, values) {
        let value = values[fieldRule.field]
        let result = false
        if (fieldRule.field_method === 'equals') {
            fieldRule.values.map((fieldRuleValId) => {
                if (
                    value &&
                    value.find &&
                    value.find(
                        (listOptionId) => listOptionId === fieldRuleValId
                    )
                ) {
                    result = true
                }
            })
        } else if (fieldRule.field_method === 'notequals') {
            fieldRule.values.map((fieldRuleValId) => {
                if (
                    (value &&
                        value.find &&
                        value.find(
                            (listOptionId) => listOptionId !== fieldRuleValId
                        )) ||
                    (value && value.length <= 0)
                ) {
                    result = true
                }
            })
        }

        return result
    }

    conditionMatches(condition, values) {
        let result = false
        let conditionRuleMatches = condition.rules.filter((rule) => {
            let wantLength = rule.type === 'or' ? 1 : rule.fields.length
            return (
                rule.fields.filter((ruleField) =>
                    this.conditionRuleFieldMatches(ruleField, values)
                ).length >= wantLength
            )
        })

        if (conditionRuleMatches.length > 0) {
            return true
        }

        return result
    }

    checkCondition(conditions, hiddenfields, item, condAct = 'show_fields') {
        if (!conditions || !hiddenfields || conditions.length <= 0) {
            return true
        }
        let result = !hiddenfields.find((hiddenId) => hiddenId === item.id)

        if (result) {
            return true
        }

        let values = this.getValues()

        conditions.map((condition) => {
            if (this.conditionMatches(condition, values)) {
                if (
                    condition.actions
                        .filter((action) => action.action === condAct)
                        .filter((action) =>
                            action.values.find((actVal) => actVal === item.id)
                        ).length > 0
                ) {
                    result = true
                }
            }
        })

        return result
    }

    shouldListenForConditions(conditions, item) {
        if (!conditions || conditions.length <= 0) {
            return false
        }
        let result = false
        conditions.map((condition) => {
            condition.rules.map((rule) => {
                if (rule.fields.find((field) => field.field === item.id)) {
                    result = true
                }
            })
        })

        return true
    }

    isView(currentView, itemView) {
        if (
            currentView === 'show' &&
            (itemView === 'show' || itemView === 'show/edit')
        ) {
            return true
        }

        if (
            currentView === 'edit' &&
            (itemView === 'edit' || itemView === 'show/edit')
        ) {
            return true
        }

        return currentView === itemView
    }

    returnContent(displayItem) {
        if (!this.props.content) return null

        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            let contentObject = {}
            this.props.content.forEach(
                (item) => (contentObject[item.id] = item.value)
            )

            return contentObject[displayItem.id]
        } else {
            return this.props.content[displayItem.id]
        }
    }

    renderDisplayItems() {
        let currentRank = null
        let breakClassLine = ''

        return this.props.items
            .filter((item) => this.isView(this.props.mode, item.view))
            .sort((a, b) => {
                return a.rank - b.rank
            })
            .map((item, index) => {
                if (currentRank === null) {
                    currentRank = item.rank
                }

                if (item.rank !== currentRank) {
                    breakClassLine = 'w-100  d-none d-md-block'
                    currentRank = item.rank
                } else {
                    breakClassLine = ''
                }

                if (
                    this.state.firstcheck &&
                    !this.checkCondition(
                        this.props.conditions,
                        this.props.hiddenfields,
                        item
                    )
                ) {
                    return
                }

                if (
                    this.isEditMode() &&
                    item.def_dossier_def_field &&
                    item.def_dossier_def_field.def_field &&
                    item.def_dossier_def_field.def_field.type ===
                        'autoIncrement'
                ) {
                    return
                }

                let checkConditionsOnChange = this.shouldListenForConditions(
                    this.props.conditions,
                    item
                )

                !this.state.firstcheck
                    ? setTimeout(() => this.setState({ firstcheck: true }), 150)
                    : null
                //This displays timer data inside render

                let cssClassName = `displayGrid col-xs-12 col-md-${this.getMinimalColspan(
                    item
                )} ${
                    this.props.mode === 'search' ||
                    this.props.mode === 'advanced-search'
                        ? 'search-item'
                        : ''
                }`

                if (this.props.custom_layout === 'row_input') {
                    cssClassName = ' displayGrid '
                }

                return (
                    <>
                        {breakClassLine.length > 0 ? (
                            <div className={breakClassLine} />
                        ) : null}
                        <div
                            style={
                                this.props.custom_layout === 'row_input' &&
                                (item?.def_dossier_def_field?.def_field.type ===
                                    'list' ||
                                    (item?.def_dossier_link?.id > 0 &&
                                        item.settings?.enable_small_view ===
                                            true))
                                    ? { minWidth: '200px' }
                                    : null
                            }
                            key={item.id}
                            className={cssClassName}
                        >
                            {this.renderPreText(item)}
                            {this.renderLabel(item)}
                            <DisplayItem
                                changeValue={this.props.changeValue}
                                conditions={this.props.conditions}
                                content={this.returnContent(item)}
                                dossier={this.props.dossier}
                                errors={
                                    item &&
                                    item.id &&
                                    this.props.errors &&
                                    this.props.errors[item.id]
                                        ? this.props.errors[item.id]
                                        : null
                                }
                                item={item}
                                kedo={this.props.kedo}
                                embedded={this.props.embedded}
                                linkId={this.props.linkId}
                                key={item.id}
                                mode={this.props.mode}
                                custom_layout={this.props.custom_layout}
                                onKeyPress={
                                    this.props.onKeyPress
                                        ? this.props.onKeyPress
                                        : null
                                }
                                recheckConditions={this.recheckConditions}
                                ref={this['ddiRef' + item.id]}
                                shoudListenForConditions={
                                    checkConditionsOnChange
                                }
                            />
                        </div>
                    </>
                )
            })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            this.props.items.length !== nextProps.items.length ||
            this.props.mode !== nextProps.mode
        ) {
            return true
        }
        if (
            this.state.docheck !== nextState.docheck ||
            this.state.firstcheck !== nextState.firstcheck
        ) {
            return true
        }
        if (
            nextProps.errors &&
            this.props.errors.length !== nextProps.errors.length
        ) {
            return true
        }

        return true
    }

    render() {
        return (
            <Row
                className={
                    this.props.custom_layout === 'row_input'
                        ? 'd-flex justify-content-between align-items-baseline'
                        : ''
                }
                style={{ margin: '0 -10px' }}
            >
                {this.state.editDdl ? (
                    <DefDossierLinkForm
                        defDossier={this.state.editDdl.def_dossier_id}
                        onClose={() => this.setState({ editDdl: false })}
                        item={this.state.editDdl.def_dossier_link}
                        kedo={this.props.kedo}
                        onSuccess={() => {
                            this.setState({ editDdl: false })
                            this.props.fetch()
                        }}
                    />
                ) : null}
                {this.state.editDdi ? (
                    <DisplayItemForm
                        defDossier={this.state.editDdi.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDdi: false })
                            this.props.fetch()
                        }}
                        id={this.state.editDdi.id}
                        kedo={this.props.kedo}
                    />
                ) : null}
                {this.state.editDddf ? (
                    <DefDossierDefFieldForm
                        defDossier={this.state.editDddf.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDddf: false })
                            this.props.fetch()
                        }}
                        item={this.state.editDddf.def_dossier_def_field}
                        kedo={this.props.kedo}
                    />
                ) : null}
                {this.state.editDf ? (
                    <DefFieldForm
                        defDossier={this.state.editDf.def_dossier_id}
                        onClose={() => {
                            this.setState({ editDf: false })
                            this.props.fetch()
                        }}
                        item={this.state.editDf.def_dossier_def_field.def_field}
                        kedo={this.props.kedo}
                    />
                ) : null}
                {this.renderDisplayItems(this.state.firstcheck)}
                {this.props.customButtons ? this.props.customButtons() : null}
            </Row>
        )
    }
}

export default DefaultGrid
