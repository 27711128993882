import * as React from 'react'
type Props = {
    code: string
    height?: string
    width?: string
    className?: string
}
const Flag = (props: Props) => {
    return (
        <>
            <img
                src={`/static/flags/${props.code.toLowerCase()}.svg`}
                alt="flag"
                style={{
                    width: `${props?.width ?? '12'}px`,
                    height: `${props?.height ?? '12'}px`,
                }}
                className={props?.className}
            />
        </>
    )
}

export default Flag
