import React from 'react'
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
require('../../../css/kedo-timeline.scss')

export default class BlockCardTimeline extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    getFormattedDate(dateField, dossierData) {
        if (!dossierData) {
            return ''
        }

        let singleDossierData = false
        singleDossierData = dossierData.find(
            (data) => data.content.id === dateField.id
        )
        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content
        }

        if (!singleDossierData || singleDossierData.length <= 0) {
            return ''
        }

        let date = new Date(singleDossierData)
        let dateTimeFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })
        let dateFormat = new Intl.DateTimeFormat('nl-NL', {
            dateStyle: 'medium',
        })

        if (this.state.withtime) {
            return dateTimeFormat.format(date)
        }

        return dateFormat.format(date)
    }

    intToRGB(i) {
        var c = (i & 0x00ffffff).toString(16).toUpperCase()

        return '00000'.substring(0, 6 - c.length) + c
    }

    hashCode(str) {
        var hash = 0
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        return hash
    }

    render() {
        if (
            !this.props.block ||
            !this.props.block.settings ||
            !this.props.block.settings.templateOptions
        ) {
            return ''
        }

        let pictureId = this.props.block.settings.templateOptions.picture
        let pictureField = this.props.listItems.find(
            (listItem) => listItem.id === pictureId
        )

        let titleId = this.props.block.settings.templateOptions.title
        let titleField = this.props.listItems.find(
            (listItem) => listItem.id === titleId
        )

        let dateId = this.props.block.settings.templateOptions.date
        let dateField = this.props.listItems.find(
            (listItem) => listItem.id === dateId
        )

        let subtitleId = this.props.block.settings.templateOptions.subtitle
        let subtitleField = this.props.listItems.find(
            (listItem) => listItem.id === subtitleId
        )

        let descId = this.props.block.settings.templateOptions.description
        let descField = this.props.listItems.find(
            (listItem) => listItem.id === descId
        )

        return (
            <VerticalTimeline>
                {this.props.data.dossiers.map((dossier) => {
                    let dossierData = this.props.data.contents.filter(
                        (dataItem) => dataItem.id === dossier.id
                    )
                    let titleVal = titleField
                        ? this.props.getValue(titleField, dossierData, dossier)
                        : dossier.summary

                    return (
                        <VerticalTimelineElement
                            key={dossier.id}
                            className="vertical-timeline-element--work"
                            onTimelineElementClick={() =>
                                (window.location.href = `/contentdossier/${dossier.id}`)
                            }
                            contentStyle={{
                                background: '#DCECF7',
                                color: '#000',
                            }}
                            contentArrowStyle={{
                                borderRight: '7px solid  #DCECF7',
                            }}
                            date={
                                dateField
                                    ? this.getFormattedDate(
                                          dateField,
                                          dossierData
                                      )
                                    : ''
                            }
                            iconStyle={{ background: '#DCECF7', color: '#fff' }}
                        >
                            <h5 className="vertical-timeline-element-title">
                                {titleVal}
                            </h5>
                            {subtitleField ? (
                                <div className="vertical-timeline-element-subtitle">
                                    {this.props.getValue(
                                        subtitleField,
                                        dossierData,
                                        dossier
                                    )}
                                </div>
                            ) : null}
                            {descField ? (
                                <div>
                                    {this.props.getValue(
                                        descField,
                                        dossierData,
                                        dossier
                                    )}
                                </div>
                            ) : null}
                        </VerticalTimelineElement>
                    )
                })}
            </VerticalTimeline>
        )
    }
}
