import { RefObject } from 'react'

class BagHelper {
    static autocomplete = (
        kedo: any,
        entity: Entity,
        displayPositionRef: RefObject<any>
    ): void => {
        const addressSettings = entity.settings.address_lookup_settings

        const values = displayPositionRef.current.getValues()
        //refs['dpRef' + displayItem.display_position_id].current.getValues()

        const streetDdiId = parseInt(addressSettings.street_ddi)
        const zipcodeDdiId = parseInt(addressSettings.zipcode_ddi)
        const housenrDdiId = parseInt(addressSettings.housenr_ddi)
        const housenrSufDdiId = parseInt(addressSettings.housenrsuf_ddi)
        const municipalityDdiId = parseInt(addressSettings.municipality_ddi)
        const longDdiId = parseInt(addressSettings.long_ddi)
        const latDdiId = parseInt(addressSettings.lat_ddi)

        //Don't do a lookup if the address is already known.
        if (values[streetDdiId] && values[streetDdiId].length > 0) {
            //return;
        }

        //Need more values to be able to
        if (
            (!values[zipcodeDdiId] || values[zipcodeDdiId].length <= 0) &&
            (!values[housenrDdiId] || values[housenrDdiId].length <= 0)
        ) {
            return
        }

        const params = {
            apikey:
                process.env && process.env.REACT_APP_BAG_API_KEY
                    ? process.env.REACT_APP_BAG_API_KEY
                    : '',
            postcode: values[zipcodeDdiId],
            housenumber: values[housenrDdiId],
            suffix: values[housenrSufDdiId],
            districts: false,
            neighbourhoods: false,
        }

        kedo.api()
            .getFull(
                'https://bag.kedo.nu/byPostCode',
                {
                    disableSecurity: true,
                    params: params,
                },
                false
            )
            .then((lookupResult) => {
                if (
                    lookupResult.data.street &&
                    displayPositionRef.current[
                        'ddiRef' + addressSettings.street_ddi
                    ]
                ) {
                    displayPositionRef.current[
                        'ddiRef' + addressSettings.street_ddi
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.street
                    )
                }
                if (
                    lookupResult.data.city &&
                    displayPositionRef.current[
                        'ddiRef' + addressSettings.city_ddi
                    ]
                ) {
                    displayPositionRef.current[
                        'ddiRef' + addressSettings.city_ddi
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.city
                    )
                }
                if (
                    latDdiId &&
                    lookupResult.data.latitude &&
                    displayPositionRef.current['ddiRef' + latDdiId]
                ) {
                    displayPositionRef.current[
                        'ddiRef' + latDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.latitude
                    )
                    displayPositionRef.current[
                        'ddiRef' + latDdiId
                    ].current.ddiRef.current.setVal(
                        'latitude',
                        lookupResult.data.latitude
                    )
                }
                if (
                    longDdiId &&
                    lookupResult.data.longitude &&
                    displayPositionRef.current['ddiRef' + longDdiId]
                ) {
                    displayPositionRef.current[
                        'ddiRef' + longDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.longitude
                    )
                    displayPositionRef.current[
                        'ddiRef' + longDdiId
                    ].current.ddiRef.current.setVal(
                        'longitude',
                        lookupResult.data.longitude
                    )
                }
                if (
                    municipalityDdiId &&
                    lookupResult.data.council_name &&
                    displayPositionRef.current['ddiRef' + municipalityDdiId]
                ) {
                    displayPositionRef.current[
                        'ddiRef' + municipalityDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.council_name
                    )
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {})
    }
}

export default BagHelper
