import React from 'react'
import {Form, FormControl} from 'react-bootstrap'

class UrlField extends React.Component {
    constructor(props) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            value: this.props.value,
        };

        this.doFocus = this.doFocus.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1);
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.value;
    }

    getTarget() {
        return '_blank';
    }

    onChange(event) {
        this.setState({value: event.target.value});
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, event.target.value);
        }
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true;
        }

        return false;
    }

    renderShow() {
        if (!this.state.value || this.state.value.length <= 0 || this.state.value === "") {
            return <span className={"text-muted small"}>{this.props.kedo.t('Unknown')}</span>
        }

        return <a href={this.props.value} target={this.getTarget()}>{this.props.value}</a>
    }

    renderEdit() {
        return  <div>
                    <FormControl
                        className={"displayItemField"}
                        isInvalid={this.isInvalid()}
                        onChange={(event) => this.onChange(event)}
                        ref={this.innerRef}
                        type={"url"}
                        value={this.state.value}
                    />
                    {this.isInvalid() ? <div className="invalid-feedback">{this.props.errors}</div> : null}
                </div>
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.value !== prevProps.value) && this.props.mode === 'show') {
            this.setState({value: this.props.value});
        }
    }

    render() {
        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            if (this.state.value !== this.props.value) {
                this.setState({value: this.props.value});
            }
            return  <Form.Control
                        onChange={(event) => this.props.changeValue(this.props.item.id, event.target.value)}
                        onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event) : null}
                        type="text"
                        value={this.props.value}
                    />
        } else if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderEdit()
        }
        return this.renderShow()
    }
}

export default UrlField