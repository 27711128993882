import * as React from 'react'

import AsyncSelect from 'react-select/async'
import { useContext, useEffect, useState } from 'react'
import { KeDoContext } from '../../../kedo-context.js'

const UserSelect = ({ kedo, onChangeUsers, currentUsers }) => {
    //const kedo = useContext(KeDoContext)

    const [values, setValues] = useState(currentUsers ?? [])
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(100)

    const handleReceiversChange = (selectedItems) => {
        if (!selectedItems || !selectedItems.map) {
            setValues([])
            return
        }

        setValues(selectedItems)
        if (onChangeUsers) {
            onChangeUsers(selectedItems)
        }
    }

    useEffect(() => {
        if (onChangeUsers) {
            onChangeUsers(values)
        }
    }, [values])

    const getOptions = (search) => {
        setLoading(true)
        let params = {
            params: {
                limit: limit,
            },
        }

        if (search.length > 0) {
            params.params.name = search
        }
        return kedo
            .api()
            .get(kedo.api().getUserEndpoint(), params)
            .then(function (response) {
                if (!response.data.results) {
                    return []
                }

                return response.data.results.map((user) => ({
                    id: user.id,
                    value: user.id,
                    label: user.profile
                        ? user.profile.last_name +
                          ', ' +
                          user.profile.first_name
                        : user.username,
                }))
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <AsyncSelect
                className={'basic-multi-select'}
                isMulti
                cacheOptions
                defaultOptions
                multi={true}
                loadOptions={getOptions}
                value={values}
                placeholder={kedo.t('Search') + '...'}
                onChange={(event) => handleReceiversChange(event)}
                noOptionsMessage={() => kedo.t('No items found')}
            />
        </>
    )
}

export default UserSelect
