import React, { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    ButtonGroup,
    Button,
    Tabs,
    Tab,
} from 'react-bootstrap'
import Loading from '../../Elements/Loading/LoadingData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'

import DisplayItem from '../../DisplayItems/DisplayItem'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'
import BlockCardPerson from './BlockCardPerson'
import BlockCardCarousel from './BlockCardCarousel'
import BlockCardDefault from './BlockCardDefault'
import BlockCardTimeline from './BlockCardTimeline'
import DossierModalNewButton from '../../Elements/DossierModalNewButton'
import Pagination from '../../Elements/Pagination'
import EventCalendar from '../MyPage/EventCalendar'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import BlockToolbar from './BlockToolbar'
import {
    default as ContentDossierIndex,
    ContentDossierIndexHandle,
} from '../../../feature/ContentDossier/Index/ContentDossierIndex.tsx'
import { useSocketService } from '../../../context/SocketServiceContext.tsx'
import { toast } from 'react-toastify'

const Block = (props) => {
    const socketService = useSocketService()
    const kedo = props.kedo
    let curRef = useRef < ContentDossierIndexHandle > null

    const [contents, setContents] = useState(null)
    const [query, setQuery] = useState(
        props.block &&
            props.block.settings?.tab_queries?.length > 0 &&
            props.block.settings.tab_queries[0]
            ? props.block.settings.tab_queries[0].query
            : null
    )
    const [add, setAdd] = useState(false)
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [pager, setPager] = useState(null)
    const [pageError, setPageError] = useState(false)
    const toastOptions = {
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    const getCurrentRef = () => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const fetchData = () => {
        let environment = kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        let params = {
            params: {
                defDossier: props.block.def_dossier_id,
                environment: environment.id,
                page: page,
                limit:
                    props.block.settings?.limit?.length > 0
                        ? props.block.settings.limit
                        : 10,
            },
        }
        if (props.block.settings && props.block.settings.view_name) {
            params.params.view = props.block.settings.view_name
        }

        if (
            props.block.settings?.sort?.length > 0 &&
            Array.isArray(props.block.settings.sort)
        ) {
            params.params.sort = []
            params.params.sortOrder = []
            props.block.settings.sort.map((filterItem) => {
                params.params.sort.push(filterItem.dddfId)
                params.params.sortOrder.push(filterItem.sort)
            })
        }

        if (props.link) {
            params.params.linkId = props.link
            params.params.embedded =
                props.dossier && props.dossier.def_dossier_id
                    ? props.dossier.id
                    : props.dossier
        }

        if (query) {
            params.params.dossierQuery = query
        }

        if (props.block.dossier_query_id) {
            params.params.dossierQuery = props.block.dossier_query_id
        }

        setLoading(true)

        kedo.api()
            .get(kedo.api().getContentDossierEndpoint(), params)
            .then((response) => {
                setContents(response.data.results)
                setPager(response.data.pager)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                throw error
            })
    }

    useEffect(() => {
        if (!curRef) {
            fetchData()
        }
    }, [page])

    const onSuccess = (dossierId) => {
        setTimeout(() => {
            socketService.displayItem.update({
                dossierId: dossierId,
                sender: socketService.socket().id,
                displayItem: props.block.id,
            })

            if (curRef && curRef.current) {
                curRef.current.fetchNewDossier(dossierId)
            }
        }, 100)
        setAdd(false)
    }

    const loadSocketCallbacks = () => {
        socketService.displayItem.refreshListener((data) => {
            if (
                socketService.socket().id !== data.sender.id &&
                data.displayItem === props.block.id
            ) {
                if (curRef && curRef.current) {
                    curRef.current.fetchNewDossier(data.dossierId)
                }

                if (kedo.user().getUserId() !== data.sender.userId) {
                    toast.success(
                        props.kedo.t('dossier_errors.success_create_ws', {
                            username: data.sender.username,
                        }),
                        {
                            ...toastOptions,
                            toastId: `dossier_errors.success_create_ws_${data.dossierId}`,
                        }
                    )
                }
            }
        })
    }

    loadSocketCallbacks()

    useEffect(() => {
        return () => {
            socketService.displayItem.removeDdiListeners()
        }
    }, [])

    const renderData = (data) => {
        if (loading) {
            return <Loading />
        }

        let view = props.block.settings.view_name
            ? props.block.settings.view_name
            : 'list'
        let listItems = contents
            ? contents.items.filter((item) => item.view === view)
            : []
        let layout = props.block.settings.layout
            ? props.block.settings.layout
            : 'list'
        let template = props.block.settings.template
            ? props.block.settings.template
            : 'list'

        let blockProps = {
            pager: pager,
            getValue: getValue,
            kedo: kedo,
            listItems: listItems,
            data: data,
            block: props.block,
            // nextPage: nextPage,
            // prevPage: prevPage,
            handleContent: handleContent,
        }

        if (layout === 'cards') {
            switch (template) {
                case 'cardCarousel':
                    return <BlockCardCarousel {...blockProps} />
                case 'cardPerson':
                    return <BlockCardPerson {...blockProps} />
                case 'cardTimeline':
                    return <BlockCardTimeline {...blockProps} />
                default:
                    return <BlockCardDefault {...blockProps} />
            }
        }

        if (layout === 'calendar') {
            return (
                <EventCalendar
                    {...blockProps}
                    templateOptions={
                        props.block.settings.templateOptions
                            ? props.block.settings.templateOptions
                            : null
                    }
                />
            )
        }

        return (
            <div>
                {loading ? (
                    <center>
                        <LoadingDefault />
                    </center>
                ) : props.misc ? (
                    <>
                        {props.settingsLink?.hide_add !== true ||
                        props.block?.settings?.show_add === true ? (
                            <div className="d-flex justify-content-end p-1">
                                <DossierModalNewButton
                                    location={'Block'}
                                    defDossierId={props.block.def_dossier_id}
                                    onSuccess={onSuccess}
                                    onClose={() => setAdd(false)}
                                    add={add}
                                    onMax={props.onMax}
                                    size={'md'}
                                    kedo={kedo}
                                />
                            </div>
                        ) : null}
                        <Table striped responsive>
                            {renderTableHead(contents, listItems)}
                            {renderTableContent(contents, listItems)}
                        </Table>
                    </>
                ) : (
                    <ContentDossierIndex
                        props={{
                            ...props,
                            defDossierId: props.block.def_dossier_id,
                            dossierQuery: query
                                ? query
                                : props.block.dossier_query_id,
                            mode: 'block',
                            showEmail: false,
                            view: props.block.settings.view_name,
                            onClick: (dossier) => {
                                window.location.href =
                                    '/contentdossier/' + dossier.id
                            },
                            limit:
                                props.block.settings?.limit?.length > 0
                                    ? props.block.settings.limit
                                    : null,
                            addDossier:
                                props.block?.settings?.show_add === true ? (
                                    <DossierModalNewButton
                                        location={'Block'}
                                        defDossierId={
                                            props.block.def_dossier_id
                                        }
                                        onSuccess={onSuccess}
                                        onClose={() => setAdd(false)}
                                        add={add}
                                        onMax={props.onMax}
                                        size={'md'}
                                        kedo={kedo}
                                    />
                                ) : null,
                            callBackPager: (value) => setPager(value),
                        }}
                        kedo={kedo}
                        forwardedRef={getCurrentRef()}
                    />
                )}
            </div>
        )
    }

    const renderTableHead = (results, listItems) => {
        if (!results || !results.items) {
            return ''
        }

        return (
            <thead>
                {!results ||
                !results.dossiers ||
                results.dossiers.length <= 0 ? (
                    <tr>
                        <th>
                            <span className={'text-muted'}>
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                &nbsp; {kedo.t('No items found')}
                            </span>
                        </th>
                    </tr>
                ) : (
                    <tr>
                        {listItems.map((listItem) => {
                            return (
                                <th data-ddiid={listItem.id} key={listItem.id}>
                                    {kedo
                                        .env()
                                        .translateItem(listItem, 'displayitem')}
                                </th>
                            )
                        })}
                        {props.block.settings &&
                        props.block.settings.inline_edit === true ? (
                            <th key={`inline-header-edit-${props.block.id}`}>
                                &nbsp;
                            </th>
                        ) : null}
                    </tr>
                )}
            </thead>
        )
    }

    const getValue = (listItem, dossierData, dossier) => {
        if (!dossierData || !listItem) {
            return ''
        }

        let singleDossierData = false
        singleDossierData = dossierData.find(
            (data) => data.content.id === listItem.id
        )
        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content
        }

        if (!singleDossierData || singleDossierData.length <= 0) {
            if (listItem.def_dossier_def_field?.def_field?.type !== 'list') {
                return ''
            } else if (listItem.settings && !listItem.settings.fast_switch) {
                return ''
            }
        }

        return (
            <DisplayItem
                kedo={kedo}
                changeValue={() => {
                    //TODO
                }}
                content={singleDossierData}
                dossier={dossier}
                errors={null}
                item={listItem}
                key={listItem.id}
                mode={'list'}
            />
        )
    }

    const handleContent = (event, dossier) => {
        if (event.target.nodeName !== 'TD' && event.target.nodeName !== 'DIV') {
            if (event.target.nodeName !== 'BUTTON') {
                event.preventDefault()
            }

            return false
        }

        window.location.replace(`/contentdossier/${dossier.id}`)
    }

    const renderContentRow = (dossierData, dossier, listItems) => {
        return (
            <tr
                key={dossier.id}
                className={`contentRow ${
                    dossier.archived ? 'archivedDossier' : ''
                }`}
            >
                {listItems.map((listItem) => {
                    if (
                        listItem.def_dossier_def_field?.def_field?.type ===
                            'list' &&
                        listItem.settings?.fast_switch
                    ) {
                        return (
                            <td className={'contentData'} key={listItem.id}>
                                {getValue(listItem, dossierData, dossier)}
                            </td>
                        )
                    }

                    return (
                        <td
                            onClick={(event) => handleContent(event, dossier)}
                            style={{ cursor: 'pointer' }}
                            className={'contentData'}
                            key={listItem.id}
                        >
                            {getValue(listItem, dossierData, dossier)}
                        </td>
                    )
                })}
                {props.block.settings.inline_edit ? (
                    <td>
                        <DossierModalEditButton
                            dossierId={dossier.id}
                            kedo={kedo}
                        />
                    </td>
                ) : null}
            </tr>
        )
    }

    const renderPagination = (data) => {
        if (!pager) {
            return ''
        }

        return (
            <Pagination
                onChangePage={(page) => {
                    if (
                        props.block.settings.layout !==
                            ('cards' || 'calendar') &&
                        curRef &&
                        curRef.current
                    ) {
                        curRef.current.onChangePage(page)
                    } else {
                        setPage(page)
                    }
                }}
                kedo={kedo}
                pager={pager}
            />
        )
    }

    const renderTableContent = (results, listItems) => {
        if (!results || !results.dossiers || results.dossiers.length <= 0) {
            return ''
        }

        return (
            <tbody>
                {results.dossiers.map((dossier) =>
                    renderContentRow(
                        results.contents.filter(
                            (dataItem) => dataItem.id === dossier.id
                        ),
                        dossier,
                        listItems
                    )
                )}
            </tbody>
        )
    }

    const getFilterButton = () => {
        //TODO
    }

    const renderTabsOrTable = (data) => {
        if (props.block?.settings?.tab_queries?.[0]?.length > 0) {
            return (
                <Tabs
                    key={`block-${props.block.id}-tabs`}
                    onSelect={(eKey) => {
                        let eKeyNr = parseInt(eKey)
                        setQuery(props.block.settings.tab_queries[eKeyNr].query)
                        setTimeout(() => fetchData(), 50)
                    }}
                    defaultActiveKey="0"
                    id={`block-${props.block.id}-tabs`}
                >
                    {props.block.settings.tab_queries.map((item, index) => (
                        <Tab
                            key={index}
                            eventKey={index.toString()}
                            title={item.title}
                        >
                            {renderData(data)}
                            {renderPagination(data)}
                        </Tab>
                    ))}
                </Tabs>
            )
        }

        return (
            <div>
                <div className="block-content">{renderData(data)}</div>
                {pager && pager.pages > 1 ? (
                    <div className="block-footer">{renderPagination(data)}</div>
                ) : null}
            </div>
        )
    }

    return (
        <div className={!props.dashboard ? 'kedo-block' : null}>
            <div
                style={props.misc ? { height: '50px' } : null}
                className="block-header"
            >
                <BlockToolbar
                    onClose={props.onClose}
                    block={props.block}
                    onMax={props.onMax}
                    kedo={kedo}
                    fetchData={
                        props.block.settings.layout === ('cards' || 'calendar')
                            ? fetchData
                            : null
                    }
                />
                {props.getBlockHeader
                    ? props.getBlockHeader(props.block, props.dossier)
                    : null}
            </div>
            {loading ? (
                <center>
                    <LoadingDefault />
                </center>
            ) : contents ? (
                props.block.settings.layout !== ('cards' || 'calendar') &&
                renderTabsOrTable(contents)
            ) : null}
        </div>
    )
}

export default Block
