import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import BlockEditButton from './BlockEditButton'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let percentage = 0
    dataWithArc.forEach((datum) => {
        percentage = datum.data.percentage
    })

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '24px',
                fontWeight: '600',
            }}
        >
            {percentage}%
        </text>
    )
}

export default class BlockListPercentageOptionMatching extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            block: this.props.block,
            data: null,
            loading: false,
            pager: null,
            blockSettings: this.props.block ? this.props.block.settings : null,
            pageError: false,
        }
    }

    fetchData() {
        let environment = this.props.kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        if (!this.props.block.id || !this.props.block.settings.pie_charts) {
            return
        }

        let api = this.props.kedo.api()

        let params = { params: {} }

        if (this.props.dossier && this.props.dossier.id) {
            params.dossier = this.props.dossier.id
            params.params.dossier = this.props.dossier.id
            params.params.linkId = this.props.link
            params.linkId = this.props.link
        }

        this.setState({ loading: true })

        api.get(
            api.getMyPageBlockEndpoint(this.props.block.id) + '/data',
            params
        ).then((response) =>
            this.setState({
                data: response.data,
                loading: false,
            })
        )
    }

    transFormData(data) {
        let pieData = []

        pieData.push({
            id: 'total_dossiers',
            label: 'total_dossiers',
            percentage: data.percentage,
            value: data.total_dossiers,
            color: data.color,
        })

        pieData.push({
            id: 'totals',
            label: 'totals',
            percentage: data.percentage,
            value: data.totals - data.total_dossiers,
            color: 'hsl(0,0%,100%)',
        })

        return pieData
    }

    componentDidMount() {
        this.fetchData()
    }

    renderData(data) {
        if (!data) {
            return ''
        }

        return (
            <ResponsivePie
                colors={(d) => {
                    return d.data.color
                }}
                data={this.transFormData(data)}
                isInteractive={false}
                margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
                innerRadius={0.8}
                activeOuterRadiusOffset={10}
                padAngle={0.7}
                arcLinkLabelsThickness={1}
                cornerRadius={1}
                onClick={(node, event) => {
                    if (node && node.data && node.data.kedoId) {
                        window.location.href =
                            '/contentdossier/' + node.data.kedoId
                    }
                }}
                borderWidth={1}
                animate={false}
                motionStiffness={90}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                activeInnerRadiusOffset={10}
                layers={[
                    'arcs',
                    'arcLabels',
                    'arcLinkLabels',
                    'legends',
                    CenteredMetric,
                ]}
            />
        )
    }

    getBlockLink() {
        if (!this.props.block || !this.props.block.def_dossier_id) {
            return '#'
        }

        return `/defdossier/${this.props.block.def_dossier_id}`
    }

    render() {
        const kedo = this.props.kedo

        if (!this.state.data) {
            return <LoadingDefault />
        }

        const blockLink = this.getBlockLink()

        return (
            <div
                className={!this.props.dashboard ? 'kedo-block row' : 'row'}
                style={
                    !this.props.dashboard
                        ? {
                              height: '300px',
                              marginBottom: '30px',
                              marginTop: '30px',
                              minHeight: '300px',
                              minWidth: '300px',
                          }
                        : null
                }
            >
                <div className={'col-md-12'}>
                    {this.props.kedo.isAdminOrEnvironmentAdmin() ? (
                        <BlockEditButton
                            item={this.state.block}
                            kedo={this.props.kedo}
                            onSuccess={() => {
                                this.fetchData()
                            }}
                        />
                    ) : null}
                    {this.props.misc &&
                    this.props.misc.hideTitle == true ? null : (
                        <h3>
                            <a href={blockLink}>
                                {kedo
                                    .env()
                                    .translateItem(
                                        this.props.block,
                                        'mypageblock'
                                    )}
                            </a>
                        </h3>
                    )}
                </div>
                {this.state.data.map((pieChartData, index) => (
                    <div
                        key={index}
                        style={{
                            height: '100px',
                            marginBottom: '0px',
                            marginTop: '0px',
                            minHeight: '100px',
                            minWidth: '100px',
                        }}
                        className={'col kedo-block-list-pct-item'}
                    >
                        {this.renderData(pieChartData)}
                        <div className={'text-center'}>
                            {pieChartData.title}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}
