import React, { useCallback, useEffect, useState, useRef } from 'react'
import '../../css/dateRangeSlider.css'

const SoloRangeSlider = ({ min, max, onChange }) => {
    const [minVal, setMinVal] = useState(min)
    const [maxVal, setMaxVal] = useState(max)
    const minValRef = useRef(null)
    const maxValRef = useRef(null)
    const range = useRef(null)

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    )

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal)
            const maxPercent = getPercent(+maxValRef.current.value) // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`
                range.current.style.width = `${maxPercent - minPercent}%`
            }
        }
    }, [minVal, getPercent])

    // Get min and max values when their state changes
    useEffect(() => {
        onChange(minVal, maxVal)
    }, [minVal, maxVal])

    return (
        <div>
            <div className="container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onChange={(event) => {
                        const value = Math.min(+event.target.value)
                        setMinVal(value)
                        event.target.value = value.toString()
                    }}
                    className={'thumb thumb--zindex-3 thumb--zindex-5'}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    className={'thumb thumb-index'}
                />
                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />
                    <div className="slider__left-value">{minVal}</div>
                    <div className="slider__right-value">{maxVal}</div>
                </div>
            </div>
        </div>
    )
}

export default SoloRangeSlider
