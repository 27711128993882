import Contextualizer from './Contextualizer'
import ProvidedServices from './ProvidedServices'
import * as React from 'react'

interface IDossierListParams {
    defDossier: number
    limit: number
    page: number
    archived?: boolean
    dossierQuery?: string
    sort?: []
    sortOrder?: []
    embedded?: number
    linkId?: number
    view?: string | number

    [x: string | number | symbol]: unknown
}

export interface IDossierList {
    dossiers: Array<any>
    aggregations: any | undefined
    contents: Array<any>
    items: Array<any>
    pager: PagerInterface
}

export interface IDossierService {
    getDossier(id: number, displayPosition?: string): Promise<Dossier>
    getDossiers(params: IDossierListParams): Promise<IDossierList>
    updateDossier(
        id: number,
        displayPositionId: number,
        values: Array<any>
    ): Promise<any>
}

const DossierServiceContext = Contextualizer.createContext(
    ProvidedServices.DossierService
)

const DossierService = ({ kedo, children }) => {
    const dossierService = {
        async getDossiers(params: IDossierListParams): Promise<IDossierList> {
            const results = await kedo
                .api()
                .get(kedo.api().getContentDossierEndpoint(), { params: params })

            return {
                dossiers: results.data.results.dossiers,
                aggregations: results.data.results.aggregations
                    ? results.data.results.aggregations
                    : {},
                contents: results.data.results.contents,
                items: results.data.results.items,
                pager: results.data.pager,
            }
        },
        async getDossier(
            id: number,
            displayPosition?: string
        ): Promise<Dossier> {
            const params = {
                displayPosition: undefined,
            }

            if (displayPosition) {
                params.displayPosition = displayPosition
            }

            const result = await kedo
                .api()
                .get(kedo.api().getContentDossierEndpoint(id), {
                    params: params,
                })

            return result.data as Dossier
        },
        async updateDossier(
            id: number,
            displayPositionId: number,
            values: Array<any>
        ) {
            const result = await kedo
                .api()
                .put(
                    kedo.api().getContentDossierEndpoint(id) +
                        '?displayPosition=' +
                        displayPositionId.toString(),
                    values
                )
        },
    }

    return (
        <>
            <DossierServiceContext.Provider value={dossierService}>
                {children}
            </DossierServiceContext.Provider>
        </>
    )
}

export default DossierService

export const useDossierService = () =>
    Contextualizer.use<IDossierService>(ProvidedServices.DossierService)
