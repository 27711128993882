import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'

import { Button, Modal } from 'react-bootstrap'
import CreateContentDossier from '../../feature/ContentDossier/Create/CreateContentDossier.tsx'

class DossierModalNewButton extends Component {
    constructor(props) {
        super(props)

        this.state = {
            add: this.props.add === true,
            size: this.props.size ? this.props.size : 'sm',
        }
    }

    renderAdd() {
        return (
            <Modal
                key={`new-inline-modal-${this.props.defDossierId}`}
                centered
                backdrop="static"
                show={true}
                onHide={() => {
                    this.props.onClose ? this.props.onClose() : null
                    this.setState({ add: false })
                }}
                size={'xl'}
            >
                <Modal.Header
                    closeButton
                    closeLabel={this.props.kedo.t('Close')}
                />
                <Modal.Body>
                    <CreateContentDossier
                        kedo={this.props.kedo}
                        props={{
                            ...this.props,
                            location: this.props.location,
                            content: this.props.content,
                            onSuccess: (dossierId) => {
                                this.setState({ add: false })
                                this.props.onSuccess(dossierId)
                            },
                            onClose: () => {
                                this.setState({ add: false })
                                this.props.onClose()
                            },
                            defDossierId: this.props.defDossierId,
                            disableBreadcrumb: true,
                            size: this.state.size,
                        }}
                    />
                </Modal.Body>
            </Modal>
        )
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.add !== this.state.add) {
            return true
        }

        return false
    }

    render() {
        if (!this.props.defDossierId) {
            return ''
        }

        if (
            this.props.kedo
                .env()
                .isAllowedDefDossier(
                    'create',
                    this.props.defDossierId,
                    this.props.kedo.user()
                ) !== true
        ) {
            return ''
        }

        return (
            <>
                {this.state.add === true ? this.renderAdd() : null}
                <Button
                    className={'new-dossier-inline'}
                    onClick={() => this.setState({ add: true })}
                    size={this.state.size}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </>
        )
    }
}

export default DossierModalNewButton
