import React from 'react'
import { Link } from 'react-router-dom'
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardDeck,
    Col,
    ListGroup,
    Modal,
    Row,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faAngleRight } from '@fortawesome/pro-duotone-svg-icons/faAngleRight'
import { faFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faPortrait } from '@fortawesome/pro-duotone-svg-icons/faPortrait'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'

import LoadingDefault from '../Elements/Loading/LoadingDefault'
import SearchDefDossier from '../Pages/DefDossier/SearchDefDossier'
import { Trans } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import DisplayItemBlock from './DisplayItemBlock'
import BrpModal from '../Modules/Brp/BrpModal'
import DefDossierList from '../Pages/DefDossier/DefDossierList'
import NewContentDossier from '../../feature/ContentDossier/Create/CreateContentDossier.tsx'
import ContentDossier from '../../feature/ContentDossier/Detail/ContentDossier.tsx'
import Loading from '../Elements/Loading/LoadingData'

const multiValueLabel = (props) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${props.data.value}`}>
                    {props.children}
                </Tooltip>
            }
        >
            <div {...props.innerProps}>{props.children}</div>
        </OverlayTrigger>
    )
}

class LinkField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        let selected = []
        if (this.props.value && this.props.value.length > 0) {
            this.props.value.map((item) => selected.push(item))
        }

        //If the dossier is embedded in another dossier and its a new dossier we will select this link automatic.
        if (
            !this.props.dossier &&
            this.props.embedded &&
            this.props.item.def_dossier_link.id === this.props.linkId
        ) {
            selected.push({ id: this.props.embedded })
        }

        this.state = {
            initialLoad: false,
            initial: [],
            linkSearch: null,
            searchDossierQuery: null,
            dpChildMain: null,
            options: [],
            optionsLoading: false,
            page: 0,
            pages: 0,
            search: '',
            searchItems: [],
            searchItemsInitialized: false,
            selected: selected,
            total: 0,
            value: this.props.value,
        }

        this.doFocus = this.doFocus.bind(this)
        this.doLinkSearch = this.doLinkSearch.bind(this)
        this.fetchSearchFilterItems = this.fetchSearchFilterItems.bind(this)
        this.fetchSearchData = this.fetchSearchData.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onCreated = this.onCreated.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.fetchOtherDisplayPositions =
            this.fetchOtherDisplayPositions.bind(this)
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    onCancel() {
        this.setState({ selected: this.props.value })
    }

    getValue() {
        return this.state.selected.map((item) => item.id)
    }

    noHideAdd() {
        let settings = this.props.item.settings
        if (!settings || !settings.hide_add || settings.hide_add !== true) {
            return true
        }

        return settings.hide_add === true ? false : true
    }

    isSingleSelect() {
        let settings = this.props.item.def_dossier_link.settings
        if (settings && settings.multiple && settings.multiple === true) {
            return false
        }

        return true
    }

    removeSelectedItem(item) {
        let removed = this.state.selected.filter((opt) => opt.id !== item.id)

        this.setState({
            selected: removed,
        })

        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, removed)
        }
    }

    addSelectedItem(item) {
        let select = this.state.selected

        if (this.isSingleSelect() === true && select.length > 0) {
            return
        }

        select = select.filter((opt) => opt.id !== item.id)
        select.push(item)
        let ids = select.map((item) => item.id)

        if (this.props.changeValue) {
            let ids = select.map((item) => item.id)
            this.props.changeValue(this.props.item.id, select)
        }

        this.setState({
            selected: select,
        })
    }

    getChildDefDossierId() {
        return this.props.item.def_dossier_link.child_def_dossier_id
    }

    getOptions(nextPage, setValuesDefaultQuery) {
        if (this.props.item.settings?.only_allow_add === true) {
            return true
        }

        const kedo = this.props.kedo
        let params = {
            params: {
                defDossier: this.getChildDefDossierId(),
                environment: kedo.env().getEnvironment().id,
                limit: 25,
            },
        }

        if (
            this.props.embedded &&
            this.props.item.settings?.limit_embedded_link
        ) {
            params.params.linkId = this.props.item.settings.limit_embedded_link
            params.params.embedded = this.props.embedded
        }

        if (nextPage === true && this.state.page < this.state.pages) {
            params.params.page = this.state.page + 1
        }

        if (this.state.search) {
            params.params.summary = this.state.search
        }
        if (this.state.linkSearch) {
            this.state.linkSearch.map((searchItem) => {
                if (typeof searchItem.value === 'string') {
                    params.params[searchItem.id] = searchItem.value
                } else if (searchItem.value instanceof Array) {
                    let values = []
                    searchItem.value.map((valItem) => values.push(valItem.id))
                    params.params[searchItem.id] = values
                }
            })
        }

        //Limit the options available to a dossierQuery filter
        if (
            this.props.item.def_dossier_link.settings?.limit_output_to_mis_item
        ) {
            params.params.dossierQuery =
                this.props.item.def_dossier_link.settings.limit_output_to_mis_item
        } else if (this.state.searchDossierQuery) {
            params.params.dossierQuery = this.state.searchDossierQuery
        }

        if (setValuesDefaultQuery) {
            params.params.dossierQuery = setValuesDefaultQuery
        }

        if (this.props.item?.settings?.sort?.length > 0) {
            params.params.sort = []
            params.params.sortOrder = []
            this.props.item.settings.sort.map((filterItem) => {
                params.params.sort.push(filterItem.dddfId)
                params.params.sortOrder.push(filterItem.sort)
            })
        }

        this.setState({ optionsLoading: true })

        kedo.api()
            .get(kedo.api().getContentDossierEndpoint(), params)
            .then((response) => {
                if (setValuesDefaultQuery) {
                    if (response.data?.results?.dossiers) {
                        for (
                            let i = 0;
                            i < response.data.results.dossiers.length;
                            i++
                        ) {
                            this.addSelectedItem(
                                response.data.results.dossiers[i]
                            )
                        }
                    }
                    this.setState({ optionsLoading: false })
                } else {
                    this.setState({
                        options: nextPage
                            ? [
                                  ...this.state.options,
                                  ...response.data.results.dossiers,
                              ]
                            : response.data.results.dossiers,
                        optionContents: nextPage
                            ? [
                                  ...this.state.optionContents,
                                  ...response.data.results.contents,
                              ]
                            : response.data.results.contents,
                        page: response.data.pager.current,
                        pages: response.data.pager.pages,
                        total: response.data.pager.items,
                        optionsLoading: false,
                    })
                }
            })
    }

    searchOption(event) {
        this.setState({ search: event.target.value })
        if (this.state.timeout) clearTimeout(this.state.timeout)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.timeout = setTimeout(() => {
            this.getOptions()
        }, 500)
    }

    getBackLinkId() {
        return this.props.item.def_dossier_link.back_link_id
    }

    getSelectableOptions(options, selects) {
        return options.filter((opt) => {
            let found = selects.find((select) => opt.id === select.id)
            return !found || found.length <= 0
        })
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    onCreated(newDossierId) {
        if (newDossierId > 0) {
            let selected = this.state.selected
            selected.push({
                id: newDossierId,
                summary: this.props.kedo.t('Loading') + '...',
                loading: true,
            })
            this.setState({
                addDossier: false,
                selected: selected,
            })
            this.props.kedo
                .api()
                .get(
                    this.props.kedo.api().getContentDossierEndpoint() +
                        '/' +
                        newDossierId
                )
                .then((response) => {
                    if (response.data && response.data.dossier) {
                        let selected = this.state.selected
                        selected = selected.filter(
                            (selected) => selected.id !== newDossierId
                        )
                        selected.push({
                            id: newDossierId,
                            summary: response.data.dossier.summary,
                        })
                        this.setState({ selected: selected })
                    }
                })
        } else {
            this.setState({ addDossier: false })
        }
    }

    renderAddDossier() {
        let defDossier = null
        if (
            this.props.item.def_dossier_link &&
            this.props.item.def_dossier_link.child_def_dossier_id
        ) {
            defDossier = this.props.kedo
                .env()
                .getDefDossierById(
                    this.props.item.def_dossier_link.child_def_dossier_id
                )
        }

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                size="xl"
                backdrop="static"
                onHide={() => this.setState({ addDossier: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('New')}{' '}
                        {defDossier
                            ? this.props.kedo
                                  .env()
                                  .translateItem(defDossier, 'defdossier')
                            : this.props.kedo.t('Dossier')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewContentDossier
                        kedo={this.props.kedo}
                        props={{
                            defDossierId: this.getChildDefDossierId(),
                            linkId: this.getBackLinkId(),
                            embedded: true,
                            location: 'EmbeddedLinkField',
                            onClose: () => this.setState({ addDossier: false }),
                            onSuccess: this.onCreated,
                        }}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    fetchSearchFilterItems() {
        //Do not get the search items twice!
        if (
            this.state.searchItemsInitialized ||
            this.state.searchItems.length > 0
        ) {
            return
        }

        if (
            this.props.item.settings &&
            this.props.item.settings.only_allow_add === true
        ) {
            return true
        }

        let params = {
            params: {
                view: ['search', 'advanced-search'],
                defDossier: this.getChildDefDossierId(),
            },
        }
        this.setState({ searchItemsLoading: true })
        this.props.kedo
            .api()
            .getCached(this.props.kedo.api().getDisplayItemEndpoint(), params)
            .then((response) => {
                this.setState({
                    searchItemsInitialized: true,
                    searchItemsLoading: false,
                    searchItems: response.data.results,
                })
            })
    }

    getDefDossierFromId(defDossierId) {
        return this.props.kedo
            .env()
            .getCurrentEnvironmentDefDossiers()
            .find((item) => item.id === parseInt(defDossierId))
    }

    search(values) {
        this.setState({ search: values })
    }

    doLinkSearch(searchParams, dossierQuery, archived) {
        this.setState(
            {
                linkSearch: searchParams,
                searchDossierQuery: dossierQuery,
                showFilter: false,
            },
            this.getOptions
        )
    }

    renderFilter() {
        let searchItems = this.state.searchItems.filter(
            (searchItem) =>
                searchItem.view === 'search' ||
                searchItem.view === 'advanced-search'
        )
        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                size="xl"
                onHide={() => this.setState({ showFilter: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.kedo.t('Search')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.searchItemsLoading ? <LoadingDefault /> : null}
                    {this.state.searchItemsInitialized ? (
                        <SearchDefDossier
                            doSearch={this.doLinkSearch}
                            resetSearch={() =>
                                this.setState({
                                    search: null,
                                    searchDossierQuery: null,
                                })
                            }
                            kedo={this.props.kedo}
                            defDossier={this.getDefDossierFromId(
                                this.props.item.def_dossier_link
                                    .child_def_dossier_id
                            )}
                            items={searchItems}
                            search={this.state.search}
                            location={'EmbeddedLinkField'}
                        />
                    ) : null}
                </Modal.Body>
            </Modal>
        )
    }

    renderEdit() {
        //If the dossier is embedded in another dossier and its a new dossier we will hide the field, the values is already set.
        if (
            this.props.embedded &&
            !this.props.dossier &&
            this.props.item.def_dossier_link.id === this.props.linkId
        ) {
            return ''
        }

        const kedo = this.props.kedo
        const totals = this.state.total
        let checkSelectedId = null
        let matchCounter = 0

        if (this.props.item?.settings?.enable_small_view === true) {
            let vals = []

            this.state.selected.map((item) => {
                vals.push({
                    id: item.value ? item.value : item.id,
                    label: item.summary ? item.summary : item.label,
                })
            })

            return (
                <div>
                    {this.state.addDossier ? this.renderAddDossier() : null}

                    {this.noHideAdd() &&
                    kedo
                        .env()
                        .isAllowedDefDossier(
                            'create',
                            this.getChildDefDossierId(),
                            kedo.user()
                        ) &&
                    (this.isSingleSelect() !== true ||
                        this.state.selected.length <= 0) ? (
                        <Button
                            onClick={() =>
                                this.setState({
                                    addDossier: true,
                                })
                            }
                            className={'float-right'}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    ) : null}
                    <Row>
                        <Col md={12}>
                            <AsyncSelect
                                ref={this.innerRef}
                                isMulti={this.isSingleSelect() !== true}
                                cacheOptions
                                className={
                                    this.isInvalid() ? 'fake-feedback' : ''
                                }
                                isClearable={true}
                                defaultOptions
                                components={{
                                    MultiValueLabel: multiValueLabel,
                                }}
                                loadOptions={this.fetchSearchData}
                                onChange={(item, actions) => {
                                    let newVal = []
                                    if (actions.action === 'remove-value') {
                                        newVal = this.state.selected.filter(
                                            (sel) =>
                                                sel.id !==
                                                actions.removedValue.id
                                        )
                                    } else if (actions.action === 'clear') {
                                        newVal = []
                                    } else {
                                        if (item && (item.value || item.id)) {
                                            newVal.push({
                                                id: item.id
                                                    ? item.id
                                                    : item.value,
                                                value: item.id
                                                    ? item.id
                                                    : item.value,
                                                summary: item.label,
                                            })
                                        } else if (Array.isArray(item)) {
                                            item.map((itm) =>
                                                newVal.push({
                                                    id: itm.id
                                                        ? itm.id
                                                        : itm.value,
                                                    value: itm.id
                                                        ? itm.id
                                                        : itm.value,
                                                    summary: itm.label,
                                                })
                                            )
                                        }
                                    }

                                    if (this.props.changeValue) {
                                        this.props.changeValue(
                                            this.props.item.id,
                                            newVal.map((item) => item.id)
                                        )
                                    }

                                    this.setState({
                                        selected: newVal,
                                    })
                                }}
                                placeholder={this.props.kedo.t('Search')}
                                value={vals}
                            />
                            {this.isInvalid() ? (
                                <span className="fake-feedback">
                                    {this.props.errors}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            )
        }

        if (this.state.selected?.length > 0) {
            this.state.selected.forEach((selected) => {
                checkSelectedId = this.state.options.find(
                    (item) => item.id === selected.id
                )
                if (checkSelectedId) {
                    matchCounter = matchCounter + 1
                }
            })
        }

        let totalOptions = this.state.options.length
        if (matchCounter > 0) {
            totalOptions = this.state.options.length - matchCounter
        }

        let isSingleSelect = this.isSingleSelect()

        if (
            this.props.item.settings &&
            this.props.item.settings.only_allow_add === true
        ) {
            return (
                <div>
                    {this.state.addDossier ? this.renderAddDossier() : null}
                    {this.noHideAdd() &&
                    kedo
                        .env()
                        .isAllowedDefDossier(
                            'create',
                            this.getChildDefDossierId(),
                            kedo.user()
                        ) &&
                    (this.isSingleSelect() !== true ||
                        this.state.selected.length <= 0) ? (
                        <Button
                            onClick={() =>
                                this.setState({
                                    addDossier: true,
                                })
                            }
                            variant={
                                this.isInvalid()
                                    ? 'outline-danger'
                                    : 'outline-secondary'
                            }
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    ) : null}
                    <br />
                    <br />
                    <ListGroup as={'ul'} variant="flush">
                        {this.state.selected.map((item) => (
                            <ListGroup.Item key={item.id}>
                                {item.summary?.length > 0 ? (
                                    item.summary
                                ) : (
                                    <span>
                                        <span
                                            className={'text-muted text-small'}
                                        >
                                            ({kedo.t('No summary available')})
                                        </span>
                                    </span>
                                )}{' '}
                                {item.loading ? (
                                    <LoadingDefault as={'span'} />
                                ) : null}
                                <span className={'float-right'}>
                                    <Button
                                        title={kedo.t('Delete')}
                                        size={'sm'}
                                        variant={'outline-danger'}
                                        onClick={() =>
                                            this.removeSelectedItem(item)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </span>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    {this.isInvalid() ? (
                        <span className="fake-feedback">
                            {this.props.errors}
                        </span>
                    ) : null}
                </div>
            )
        }

        return (
            <Row className={'field-link'}>
                <Col md={12}>
                    {this.state.showBrp ? (
                        <BrpModal
                            onClose={() => this.setState({ showBrp: false })}
                            enrichData={(personData) => {
                                let otherData = {
                                    environment_id: kedo
                                        .env()
                                        .getEnvironmentId(),
                                }
                                let data = Object.assign(
                                    {},
                                    personData,
                                    otherData
                                )
                                kedo.api()
                                    .post('/module/brp/person', data)
                                    .then((personResponse) => {
                                        this.addSelectedItem(
                                            personResponse.data
                                        )
                                        this.setState({ showBrp: false })
                                    })
                                    .catch((personErr) => {
                                        alert(
                                            'Er is iets mis gegaan met het koppelen van de BRP gegevens.'
                                        )
                                    })
                            }}
                            querystring={this.state.search}
                            kedo={kedo}
                        />
                    ) : null}
                    {this.state.addDossier ? this.renderAddDossier() : null}
                    {this.state.showFilter ? this.renderFilter() : null}
                    {this.isInvalid() ? (
                        <span className="fake-feedback">
                            {this.props.errors}
                        </span>
                    ) : null}
                    <CardDeck ref={this.innerRef}>
                        <Card
                            className={`${
                                this.isInvalid() ? 'fake-feedback' : ''
                            }`}
                        >
                            <Card.Header>
                                <strong>{kedo.t('Options')}</strong>
                                <ButtonGroup
                                    size="sm"
                                    className={'float-right'}
                                >
                                    {this.state.linkSearch ? (
                                        <Button
                                            title={kedo.t('Clear filter')}
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        linkSearch: null,
                                                        searchDossierQuery:
                                                            null,
                                                    },
                                                    this.getOptions
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon icon={faTimes} />{' '}
                                            {kedo.t('Clear filter')}
                                        </Button>
                                    ) : null}
                                    {this.noHideAdd() &&
                                    kedo
                                        .env()
                                        .isAllowedDefDossier(
                                            'create',
                                            this.getChildDefDossierId(),
                                            kedo.user()
                                        ) &&
                                    (this.isSingleSelect() !== true ||
                                        this.state.selected.length <= 0) ? (
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    addDossier: true,
                                                })
                                            }
                                            className={'float-right'}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    ) : null}
                                    <Button
                                        title={kedo.t('Filter')}
                                        active={
                                            this.state.linkSearch ||
                                            this.state.showFilter
                                        }
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    showFilter:
                                                        !this.state.showFilter,
                                                    search: '',
                                                },
                                                this.getOptions
                                            )
                                            this.fetchSearchFilterItems()
                                        }}
                                        className={'float-right'}
                                    >
                                        <FontAwesomeIcon icon={faFilter} />
                                    </Button>
                                </ButtonGroup>
                            </Card.Header>
                            <Card.Body style={{ paddingBottom: '36px' }}>
                                <div
                                    className="input-group"
                                    style={{ marginBottom: '10px' }}
                                >
                                    <input
                                        value={this.state.search}
                                        className={'form-control'}
                                        type={'text'}
                                        onChange={(event) =>
                                            this.setState({
                                                search: event.target.value,
                                            })
                                        }
                                        onKeyUp={(event) =>
                                            this.searchOption(event)
                                        }
                                        placeholder={
                                            kedo.t('Search an option') + '...'
                                        }
                                    />
                                    {this.state.search.length > 0 ? (
                                        <div className="input-group-append">
                                            {this.state.optionsLoading ? (
                                                <Button>
                                                    <LoadingDefault />
                                                </Button>
                                            ) : null}
                                            <button
                                                onClick={() =>
                                                    this.setState(
                                                        { search: '' },
                                                        this.getOptions
                                                    )
                                                }
                                                className="btn btn-outline-secondary"
                                                type="button"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                />
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {this.state.search.length > 0 &&
                                this.props.item.settings?.show_brp &&
                                (kedo.isAdminOrEnvironmentAdmin() ||
                                    kedo.env().hasCredential('brp')) &&
                                this.state.search.match(
                                    /^(\d{7,9})|(\d{4}\s?[a-zA-Z]{2})\s(\d+)$/
                                ) ? (
                                    <Button
                                        className={'btn-block'}
                                        title={kedo.t('Search BRP')}
                                        onClick={() => {
                                            this.setState({ showBrp: true })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPortrait} />{' '}
                                        {this.props.kedo.t('Search BRP')}
                                    </Button>
                                ) : null}
                                <ListGroup
                                    variant={'flush'}
                                    className={`link-options`}
                                >
                                    {this.state.optionsLoading ? (
                                        <ListGroup.Item>
                                            <LoadingDefault />
                                        </ListGroup.Item>
                                    ) : null}
                                    {isSingleSelect &&
                                    this.state.selected.length > 0 ? (
                                        <Alert variant={'info'}>
                                            {this.props.kedo.t(
                                                'Maximum number of items selected'
                                            )}
                                        </Alert>
                                    ) : null}
                                    {this.getSelectableOptions(
                                        this.state.options,
                                        this.state.selected
                                    ).map((item) => (
                                        <ListGroup.Item
                                            action
                                            key={item.id}
                                            onClick={() =>
                                                this.addSelectedItem(item)
                                            }
                                            className={
                                                item.archived
                                                    ? 'text-muted'
                                                    : ''
                                            }
                                        >
                                            {item.summary?.length > 0 &&
                                            this.props.item.settings
                                                ?.show_id ? (
                                                item.id
                                            ) : item.summary?.length > 0 ? (
                                                item.summary
                                            ) : (
                                                <span>
                                                    {item.id}{' '}
                                                    <span
                                                        className={
                                                            'text-muted text-small'
                                                        }
                                                    >
                                                        (
                                                        {kedo.t(
                                                            'No summary available'
                                                        )}
                                                        )
                                                    </span>
                                                </span>
                                            )}
                                        </ListGroup.Item>
                                    ))}
                                    {this.state.page < this.state.pages &&
                                    this.state.options.length !==
                                        this.state.total ? (
                                        <ListGroup.Item
                                            variant="info"
                                            action
                                            key={
                                                'load-more-' +
                                                this.props.item.id
                                            }
                                            onClick={() =>
                                                this.getOptions(true)
                                            }
                                        >
                                            {this.state.optionsLoading ? (
                                                <LoadingDefault />
                                            ) : null}{' '}
                                            {kedo.t('Load more')}...
                                        </ListGroup.Item>
                                    ) : null}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className={'text-muted'}>
                                <Trans
                                    i18nKey="linkFieldSelected"
                                    totalOptions={totalOptions}
                                    totals={totals}
                                >
                                    {{ totalOptions }} of a {{ totals }} of
                                    items found
                                </Trans>
                                {!isSingleSelect ? (
                                    <Button
                                        onClick={() => {
                                            let newItems =
                                                this.getSelectableOptions(
                                                    this.state.options,
                                                    this.state.selected
                                                )
                                            let select = this.state.selected

                                            newItems.forEach((newItem) => {
                                                let existant = select.find(
                                                    (opt) =>
                                                        opt.id === newItem.id
                                                )
                                                if (!existant) {
                                                    select.push(newItem)
                                                }
                                            })

                                            if (this.props.changeValue) {
                                                let ids = select.map(
                                                    (item) => item.id
                                                )
                                                this.props.changeValue(
                                                    this.props.item.id,
                                                    select
                                                )
                                            }

                                            this.setState({
                                                selected: select,
                                            })
                                        }}
                                        size={'sm'}
                                        className={'float-right'}
                                    >
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </Button>
                                ) : null}
                            </Card.Footer>
                        </Card>
                        <Card>
                            <Card.Header>
                                <strong>{kedo.t('Selected')}</strong>
                            </Card.Header>
                            <Card.Body>
                                <ListGroup
                                    variant={'flush'}
                                    className={'link-selected'}
                                >
                                    {this.state.selected.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`list-group-item ${
                                                item.archived
                                                    ? ' text-muted '
                                                    : ''
                                            }`}
                                        >
                                            {item.summary?.length > 0 &&
                                            this.props.item.settings
                                                ?.show_id ? (
                                                item.id
                                            ) : item.summary?.length > 0 ? (
                                                item.summary
                                            ) : (
                                                <span>
                                                    {item.id}{' '}
                                                    <span
                                                        className={
                                                            'text-muted text-small'
                                                        }
                                                    >
                                                        (
                                                        {kedo.t(
                                                            'No summary available'
                                                        )}
                                                        )
                                                    </span>
                                                </span>
                                            )}
                                            {item.loading ? (
                                                <LoadingDefault as={'span'} />
                                            ) : null}
                                            <button
                                                className={
                                                    'btn btn-xs float-right'
                                                }
                                                onClick={() =>
                                                    this.removeSelectedItem(
                                                        item
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer>
                                {this.state.selected.length}{' '}
                                {isSingleSelect ? ' of 1 ' : null}{' '}
                                {this.props.kedo.t('Selected').toLowerCase()}
                            </Card.Footer>
                        </Card>
                    </CardDeck>
                </Col>
            </Row>
        )
    }

    fetchSearchData(inputValue, callBack) {
        if (!inputValue && callBack) {
            callBack([])
        }

        let params = {
            params: {
                defDossier: this.getChildDefDossierId(),
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 50,
            },
        }

        if (inputValue) {
            params.params.summary = inputValue
        }

        if (
            this.props.item.def_dossier_link.settings?.limit_output_to_mis_item
        ) {
            params.params.dossierQuery =
                this.props.item.def_dossier_link.settings.limit_output_to_mis_item
        }

        if (
            this.props.embedded &&
            this.props.item.settings?.limit_embedded_link
        ) {
            params.params.linkId = this.props.item.settings.limit_embedded_link
            params.params.embedded = this.props.embedded
        }

        if (this.props.item?.settings?.sort?.length > 0) {
            params.params.sort = []
            params.params.sortOrder = []
            this.props.item.settings.sort.map((filterItem) => {
                params.params.sort.push(filterItem.dddfId)
                params.params.sortOrder.push(filterItem.sort)
            })
        }

        const idToCheck = this.state.value
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getContentDossierEndpoint(), params)
            .then(async (response) => {
                const tempArray = []

                if (this.props.item.settings?.show_id) {
                    response.data.results.dossiers.forEach((element) => {
                        tempArray.push({
                            label: element.id,
                            value: element.id,
                            summary: element.summary,
                        })
                    })
                } else {
                    response.data.results.dossiers.forEach((element) => {
                        tempArray.push({
                            label: element.summary,
                            value: element.id,
                            summary: element.summary,
                        })
                    })
                }
                let idExists = false

                if (callBack) callBack(tempArray)

                if (
                    Array.isArray(idToCheck) &&
                    idToCheck.length > 0 &&
                    idToCheck[0]?.id
                ) {
                    idExists = tempArray.some(
                        (item) => item.value === idToCheck[0].id
                    )
                }

                if (!idExists) {
                    const idToFetch =
                        idToCheck?.length > 0 ? idToCheck[0].id : null

                    if (idToFetch) {
                        this.props.kedo
                            .api()
                            .get(
                                `${this.props.kedo
                                    .api()
                                    .getContentDossierEndpoint()}/${idToFetch}`
                            )
                            .then(async (response) => {
                                const dossier = response.data.dossier
                                if (dossier) {
                                    const result = {
                                        id: dossier.id,
                                        label: dossier.summary,
                                        summary: dossier.summary,
                                    }
                                    this.setState({ value: result })
                                }
                            })
                            .catch((error) => {
                                console.error('Error dossier:', error)
                            })
                    }
                }
            })
    }

    onSearchChange(e) {
        let ids = []
        if (e) {
            e.map((item) => {
                ids.push({ id: item.value })
            })
        }
        this.setState({ value: e })

        this.props.changeValue(this.props.item.id, ids)
    }

    renderSearch() {
        return (
            <AsyncSelect
                cacheOptions
                defaultOptions
                isMulti
                loadOptions={this.fetchSearchData}
                onChange={(e) => this.onSearchChange(e)}
                placeholder="Search"
                value={this.state.value}
            />
        )
    }

    renderShowFancy() {
        return (
            <>
                <DefDossierList
                    addDossier={false}
                    showEmail={this.state.showEmail === true}
                    ddiSort={
                        this.props.item &&
                        this.props.item.settings &&
                        this.props.item.settings.sort
                            ? this.props.item.settings &&
                              this.props.item.settings.sort
                            : null
                    }
                    defDossierId={
                        this.props.item.def_dossier_link.child_def_dossier_id
                    }
                    dossierQuery={this.state.dossierQuery}
                    embedded={this.props.dossier ? this.props.dossier.id : null}
                    filterDossier={null}
                    kedo={this.props.kedo}
                    linkId={this.props.item.def_dossier_link.back_link_id}
                    mode={'embeddedList'}
                    onClick={(dossier) => {
                        window.location.href = '/contentdossier/' + dossier.id
                    }}
                    ref={this.ddListRef}
                    searchParams={null}
                />
            </>
        )
    }

    renderShow() {
        if (!this.props.value || this.props.value.length <= 0) {
            return ''
        }

        if (this.props.item.settings?.show_embedded) {
            return this.renderShowFancy()
        }
        if (this.props.item.settings && this.props.item.settings.block) {
            return (
                <DisplayItemBlock
                    kedo={this.props.kedo}
                    misc={{ hideTitle: true }}
                    link={this.props.item.def_dossier_link.back_link_id}
                    settings={this.props.item.settings}
                    dossier={this.props.dossier.id}
                    id={this.props.item.settings.block}
                />
            )
        }
        if (this.props.value.length === 1) {
            //One result
            if (this.props.item.settings?.clickable) {
                return (
                    <Link
                        onClick={() => {
                            setTimeout(
                                (window.location.href = `/contentdossier/${this.props.value[0].id}`),
                                200
                            )
                        }}
                        to={`/contentdossier/${this.props.value[0].id}`}
                    >
                        <span key={this.props.value[0].id}>
                            {this.props.value[0].summary.length > 0 &&
                            this.props.item.settings.show_id ? (
                                this.props.value[0].id
                            ) : this.props.value[0].summary?.length > 0 ? (
                                this.props.value[0].summary
                            ) : (
                                <span>
                                    {this.props.value[0].id}{' '}
                                    <span className={'text-muted text-small'}>
                                        (
                                        {this.props.kedo.t(
                                            'No summary available'
                                        )}
                                        )
                                    </span>
                                </span>
                            )}
                        </span>
                    </Link>
                )
            }
            return (
                <span
                    key={this.props.value[0].id}
                    onClick={() => {
                        setTimeout(() => {
                            window.location.href = `/contentdossier/${this.props.dossier.id}`
                        }, 200)
                    }}
                >
                    {this.props.value[0].summary?.length > 0 &&
                    this.props.item.settings?.show_id ? (
                        this.props.value[0].id
                    ) : this.props.value[0].summary?.length > 0 ? (
                        this.props.value[0].summary
                    ) : (
                        <span>
                            {this.props.value[0].id}{' '}
                            <span className={'text-muted text-small'}>
                                ({this.props.kedo.t('No summary available')})
                            </span>
                        </span>
                    )}
                </span>
            )
        }

        //Results are higher then 1
        if (this.props.item.settings && this.props.item.settings.clickable) {
            return (
                <ul>
                    {this.props.value && this.props.value.map
                        ? this.props.value.map((item) => (
                              <li key={item.id}>
                                  <a href={'/contentdossier/' + item.id}>
                                      {item.summary?.length > 0 &&
                                      this.props.item.settings?.show_id ? (
                                          item.id
                                      ) : item.summary?.length > 0 ? (
                                          item.summary
                                      ) : (
                                          <span>
                                              {item.id}{' '}
                                              <span
                                                  className={
                                                      'text-muted text-small'
                                                  }
                                              >
                                                  (
                                                  {this.props.kedo.t(
                                                      'No summary available'
                                                  )}
                                                  )
                                              </span>
                                          </span>
                                      )}
                                  </a>
                              </li>
                          ))
                        : null}
                </ul>
            )
        } else {
            return (
                <ul>
                    {this.props.value && this.props.value.map
                        ? this.props.value.map((item) => (
                              <li key={item.id}>
                                  {item.summary?.length > 0 &&
                                  this.props.item.settings?.show_id ? (
                                      item.id
                                  ) : item.summary?.length > 0 ? (
                                      item.summary
                                  ) : (
                                      <span>
                                          {item.id}{' '}
                                          <span
                                              className={
                                                  'text-muted text-small'
                                              }
                                          >
                                              (
                                              {this.props.kedo.t(
                                                  'No summary available'
                                              )}
                                              )
                                          </span>
                                      </span>
                                  )}
                              </li>
                          ))
                        : null}
                </ul>
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (this.props.mode === 'edit' || this.props.mode === 'show/edit') &&
            this.state.initialLoad !== true
        ) {
            this.setState({ initialLoad: true })
            this.getOptions()
        }
    }

    setDefaultValues() {
        this.getOptions(null, this.props.item.settings.default_val_dos_query)
    }

    componentDidMount() {
        if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            this.getOptions()
            if (
                (!this.props.dossier || !this.props.dossier.id) &&
                this.props.item?.settings?.default_val_dos_query
            ) {
                this.setDefaultValues()
            }
        } else if (
            (this.props.mode === 'search' ||
                this.props.mode === 'advanced-search') &&
            this.state.selected
        ) {
            this.fetchSearchData(null, (values) => {
                const newSelected = []

                this.state.selected.forEach((item) => {
                    const found = values.find((obj) => obj.value === item.id)

                    if (found) {
                        newSelected.push(found)
                    }
                })

                this.setState({ value: newSelected })
            })
        }

        if (this.props.item?.settings?.embedded_single_view === true) {
            this.fetchOtherDisplayPositions()
        }
    }

    render() {
        if (this.state.loading === true) {
            return <Loading kedo={this.props.kedo} />
        }

        if (this.props.item?.settings?.embedded_single_view === true) {
            return this.renderEmbeddedView()
        }

        if (this.props.mode === 'list') {
            return this.renderShow()
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            return this.renderEdit()
        } else if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return this.renderSearch()
        }

        return this.renderShow()
    }

    renderEmbeddedView() {
        if (!this.isSingleSelect()) {
            return (
                <Alert variant={'warning'}>
                    {this.props.kedo.t(
                        'A embedded view can only contain one link'
                    )}
                </Alert>
            )
        }

        if (!this.state.dpChildMain) {
            return
        }

        if (this.state.selected.length > 1) {
            return (
                <Alert variant={'warning'}>
                    {this.props.kedo.t(
                        'You cannot convert a multiselect to an embedded view'
                    )}
                </Alert>
            )
        } else if (this.state.selected.length === 1) {
            return (
                <ContentDossier
                    props={{
                        selectedTab: this.state.dpChildMain?.id,
                        existingUser: true,
                        modalized: true,
                        onClose: () => {
                            //TODO:
                        },
                        dossierId: this.state.selected[0].id,
                        disableBreadcrumb: true,
                    }}
                    kedo={this.props.kedo}
                />
            )
        }

        if (!this.props.item?.def_dossier_link?.child_def_dossier_id) {
            return <span>Loading</span>
        }

        return (
            <NewContentDossier
                props={{
                    kedo: this.props.kedo,
                    multiAdd: this.state.multiAdd,
                    defDossierId:
                        this.props.item.def_dossier_link.child_def_dossier_id,
                    embeddedDefDossierId: this.props.item.def_dossier_id,
                    embedded: this.props.dossier.id,
                    embeddedFieldId: this.props.item.id,
                    linkId: this.props.item.def_dossier_link.back_link_id,
                    location: 'EmbeddedLinkField',
                    onSuccess: (dosId) => {
                        this.setState({ loading: true }, () => {
                            setTimeout(() => {
                                this.setState({
                                    selected: [{ id: dosId }],
                                    loading: false,
                                })
                            }, 500)
                        })
                    },
                }}
                multiAdd={this.state.multiAdd}
                kedo={this.props.kedo}
                defDossierId={
                    this.props.item.def_dossier_link.child_def_dossier_id
                }
                embeddedDefDossierId={this.props.item.def_dossier_id}
                leftSideBar={
                    this.props.item.settings &&
                    this.props.item.settings.passing_sidebar
                        ? this.props.item.settings.passing_sidebar
                        : null
                }
                embedded={this.props.dossier.id}
                embeddedFieldId={this.props.item.id}
                linkId={this.props.item.def_dossier_link.back_link_id}
                location={'EmbeddedLinkField'}
                onClose={() => this.setState({ addDossier: false })}
                onSuccess={this.onSuccess}
                onContinueSuccess={this.onContinueSuccess}
            />
        )
    }

    fetchOtherDisplayPositions() {
        let dpFetchUrl =
            this.props.kedo.api().getDisplayPositionEndpoint() +
            '?defDossier=' +
            this.props.item.def_dossier_link.child_def_dossier_id +
            '&limit=100'

        this.props.kedo
            .api()
            .getCached(dpFetchUrl)
            .then((response) => {
                let dpFetchMain = response.data.results.find(
                    (item) => item.type === 'main_content'
                )
                this.setState({ dpChildMain: dpFetchMain })
            })
    }
}

export default LinkField
