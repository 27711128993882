import React from 'react'
import {Col, Row, Spinner} from 'react-bootstrap'
import Kedo from "../../../Kedo";

class Loading extends React.Component {
    render() {
        const kedo = this.props.kedo ? this.props.kedo : new Kedo();

        return  <Row>
                    <Col xs={12} className={"d-flex justify-content-center"} style={{marginTop: "50px"}}>
                        <div className={"loading"}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                            />
                            <br/><br/>
                            {kedo.t('Busy fetching the content')}...
                        </div>
                    </Col>
                </Row>
    }
}

export default Loading