import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class ContentDossierNotifications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subscribed: this.props.subscribed === true ? true : false,
        }
        this.subscribeOrUnsubscribeDossier =
            this.subscribeOrUnsubscribeDossier.bind(this)
    }

    subscribe() {
        let url = this.props.kedo
            .api()
            .getContentDossierSubscriptionEndpoint(this.props.dossier.id, true)
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    subscribed: response.data.result === true ? true : false,
                    loading: false,
                })
            )
    }

    unsubscribe() {
        let url = this.props.kedo
            .api()
            .getContentDossierSubscriptionEndpoint(this.props.dossier.id, false)
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    subscribed: response.data.result === false ? true : false,
                    loading: false,
                })
            )
    }

    subscribeOrUnsubscribeDossier() {
        if (this.state.subscribed === true) {
            return this.unsubscribe()
        } else {
            return this.subscribe()
        }
    }

    render() {
        return (
            <Button
                size={this.props.size}
                active={this.state.subscribed}
                onClick={this.subscribeOrUnsubscribeDossier}
                variant="outline-primary"
                title="Ontvang notificaties"
            >
                {this.state.loading ? (
                    <LoadingDefault as={'span'} />
                ) : (
                    <FontAwesomeIcon icon={faBell} />
                )}
            </Button>
        )
    }
}

export default ContentDossierNotifications
