import React, {Component} from 'react'
import {Alert} from "react-bootstrap";

class DefDossierDefFieldAmountSettings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return  <div>
                    <h5>{this.props.kedo.t('Additional settings')}</h5>
                    <Alert variant={"info"}>{this.props.kedo.t('No settings available')}</Alert>
                </div>
    }
}

export default DefDossierDefFieldAmountSettings