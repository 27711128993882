import React from 'react'

export default class BlockSumFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            block: this.props.block,
            data: null,
            loading: false,
            pager: null,
            blockSettings: this.props.block ? this.props.block.settings : null,
            pageError: false,
        }
    }

    fetchData () {
        let environment = this.props.kedo.env().getEnvironment();
        if (!environment) {
            return;
        }

        if (!this.props.block.id) {
            return;
        }

        let api = this.props.kedo.api();
        let params = {params: {}};

        if (this.props.dossier && this.props.dossier.id) {
            params.params.dossier = this.props.dossier.id;
            params.params.dossier = this.props.dossier.id;
            params.params.linkId = this.props.link;
        }
        this.setState({loading: true});

        api
            .get(api.getMyPageBlockEndpoint(this.props.block.id)+'/data', params)
            .then(response => {
                this.setState({
                    loading: false,
                    data: response.data.result
                });
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    renderData(data) {
        if (!data) {
            return '';
        }

        return data;
    }

    render() {
        return <div style={{backgroundColor: '#dee2e6 !important'}} className={'kedo-block card-sum-fields card col-md-12'}>
            {this.props.getBlockHeader ? this.props.getBlockHeader() : null}
            <h1>{this.renderData(this.state.data)}</h1>
        </div>
    }
}