import React from 'react'
import {Row, Col, FormControl} from 'react-bootstrap'

class TimeField extends React.Component {
    constructor(props) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            hours: this.getHours(this.props.value),
            minutes: this.getMinutes(this.props.value),
        };

        this.doFocus = this.doFocus.bind(this);
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1);
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        if (!this.state.hours && !this.state.minutes) {
            return null;
        }

        return this.state.hours+':'+this.state.minutes;
    }

    hasLimit() {
        if (this.props.item && this.props.item.settings && this.props.item.settings && this.props.item.settings.disable_limit === true) {
            return true;
        }

        return false;
    }

    onChangeHours(event) {
        if (!this.isEmptyOrNumber(event.target.value)) {
            return;
        }

        if ((this.hasLimit() !== true && parseInt(event.target.value) > 23) || parseInt(event.target.value) < 0) {
            return;
        }

        this.setState({
            hours: event.target.value,
            minutes: this.state.minutes ? this.state.minutes : '00',
        });


        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, this.state.hours+':'+this.state.minutes);
        }
    }

    onChangeMinutes(event) {
        if (!this.isEmptyOrNumber(event.target.value)) {
            return;
        }

        if (parseInt(event.target.value) > 59 || parseInt(event.target.value) < 0) {
            return;
        }

        this.setState({
            hours: this.state.hours ? this.state.hours : '00',
            minutes: event.target.value
        });
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, this.state.hours+':'+event.target.value);
        }
    }

    isEmptyOrNumber(value) {
        const regExp = /^[0-9\b]+$/;
        if (value === '' || regExp.test(value)) {
            return true;
        }
        return false;
    }

    getSplitted(value) {
        if (value.length <= 0) {
            return;
        }

        let times = String(value).split(':');

        if (times.length !== 2) {
            return;
        }

        return times;
    }

    getHours(value) {
        if (!value) {
            return;
        }

        let times = this.getSplitted(value);
        if (!times) {
            return;
        }

        return times[0];
    }

    getMinutes(value) {
        if (!value) {
            return;
        }
        let times = this.getSplitted(value);
        if (!times) {
            return;
        }

        return times[1];
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true;
        }

        return false;
    }

    renderShow() {
        if (!this.props.value) {
            return '';
        }

        return this.props.value;
    }

    renderEdit() {
        return  <Row style={{marginTop: "-5px"}}>
                    <Col>
                        <FormControl
                            className={"displayItemField"}
                            isInvalid={this.isInvalid()}
                            onChange={(event) => this.onChangeHours(event)}
                            ref={!this.state.hours && !this.state.minutes ? this.innerRef : false}
                            type="text"
                            value={this.state.hours}
                        />
                    </Col>
                    <div className={"timeFieldColon"}>:</div>
                    <Col>
                        <FormControl
                            className={"displayItemField"}
                            isInvalid={this.isInvalid()}
                            onChange={(event) => this.onChangeMinutes(event)}
                            ref={this.state.hours && !this.state.minutes ? this.innerRef : false}
                            type="text"
                            value={this.state.minutes}
                        />
                    </Col>
                    <Col xs={12} style={{marginTop: "-5px"}}>{this.isInvalid() ? <span className="fake-feedback">{this.props.errors}</span> : null}</Col>
                </Row>
    }

    renderSearch() {
        if (this.state.value !== this.props.value) {
            this.setState({value: this.props.value});
        }

        return  <Row>
                    <Col>
                        <FormControl
                            className={"displayItemField"}
                            onChange={(event) => this.onChangeHours(event)}
                            onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event) : null}
                            type="text"
                            value={this.state.hours}
                        />
                    </Col>
                    <div className={"timeFieldColon"}>:</div>
                    <Col>
                        <FormControl
                            className={"displayItemField"}
                            onChange={(event) => this.onChangeMinutes(event)}
                            onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event) : null}
                            type="text"
                            value={this.state.minutes}
                        />
                    </Col>
                    <Col xs={12} style={{marginTop: "-5px"}}>{this.isInvalid() ? <span className="fake-feedback">{this.props.errors}</span> : null}</Col>
                </Row>
    }

    render() {
        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            return this.renderSearch()
        } else if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderEdit()
        }
        return this.renderShow()
    }
}

export default TimeField