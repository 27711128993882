import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { Table } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import BlockToolbar from './BlockToolbar'

export default class BlockCountList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            block: this.props.block,
            data: null,
            transformedData: null,
            transformedKeys: null,
            dateFormat:
                this.props.block &&
                this.props.block.settings &&
                this.props.block.settings.date_format
                    ? this.props.block.settings.date_format
                    : 'year_month',
            loading: false,
            hidePrint: false,
            listFieldTotal: 0,
            pager: null,
            blockSettings: this.props.block ? this.props.block.settings : null,
            pageError: false,
        }
        this.parRef = React.createRef()
        this.setFilterAndFetchData = this.setFilterAndFetchData.bind(this)
    }

    getParentRef() {
        return this.parRef
    }

    setFilterAndFetchData(key, val) {
        this.setState({ [key]: val }, this.fetchData)
    }

    fetchData() {
        let environment = this.props.kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        if (
            !this.props.block.id ||
            !this.props.block.settings.count_list_field
        ) {
            return
        }

        let api = this.props.kedo.api()

        let params = { params: {} }

        if (this.state.dateFormat) {
            params.params.date_format = this.state.dateFormat
        }

        if (this.props.dossier && this.props.dossier.id) {
            params.dossier = this.props.dossier.id
            params.params.dossier = this.props.dossier.id
            params.params.linkId = this.props.link
            params.linkId = this.props.link
        }

        this.setState({ loading: true })

        api.get(
            api.getMyPageBlockEndpoint(this.props.block.id) + '/data',
            params
        ).then((response) =>
            api
                .getCached(
                    api.getDefDossierDefFieldEndpoint(
                        this.state.blockSettings.count_list_field
                    )
                )
                .then((dddfResponse) => {
                    let transFormedData = this.transformData(
                        this.props.block,
                        response.data,
                        dddfResponse.data
                    )
                    let transFormedKeys = this.getTransformedKeys(
                        this.props.block,
                        dddfResponse.data
                    )
                    this.setState({
                        data: response.data,
                        transformedData: transFormedData,
                        transformedKeys: transFormedKeys,
                        listField: dddfResponse.data,
                        listFieldTotal:
                            dddfResponse.data &&
                            dddfResponse.data.def_field &&
                            dddfResponse.data.def_field.def_field_lists
                                ? dddfResponse.data.def_field.def_field_lists
                                      .length
                                : 0,
                        loading: false,
                    })
                })
        )
    }

    transFormDateBarData(data, listField) {
        const kedo = this.props.kedo
        let defFieldLists = listField.def_field.def_field_lists
        let results = []
        let dates = []
        for (let i = 0; i < data.length; i++) {
            if (
                data[i]['yearAnddate'] !== null &&
                !dates.includes(data[i]['yearAnddate'].toString())
            ) {
                dates.push(data[i]['yearAnddate'].toString())
            }
        }

        for (let i = 0; i < dates.length; i++) {
            let newResult = {}
            newResult.period = dates[i]
            for (let b = 0; b < defFieldLists.length; b++) {
                let keyName = kedo
                    .env()
                    .translateItem(defFieldLists[b], 'def_field_list')
                let keyColor =
                    defFieldLists[b] && defFieldLists[b].color
                        ? keyName + 'Color'
                        : null
                let periodTotal = 0
                for (let d = 0; d < data.length; d++) {
                    if (
                        data[d]['yearAnddate'] !== null &&
                        data[d]['yearAnddate'].toString() ===
                            dates[i].toString() &&
                        parseInt(data[d]['2']) === defFieldLists[b].id
                    ) {
                        periodTotal = parseInt(data[d]['1'])
                        break
                    }
                }
                newResult[keyName] = periodTotal
                newResult[keyColor] =
                    defFieldLists[b] && defFieldLists[b].color
                        ? defFieldLists[b].color
                        : null
            }

            results.push(newResult)
        }

        return results
    }

    getTransformedKeys(block, listField) {
        if (listField) {
            return listField.def_field.def_field_lists.map((item) =>
                this.props.kedo.env().translateItem(item, 'def_field_list')
            )
        }

        return []
    }

    transFormDateData(data, listField) {
        let lineData = []

        let defFieldLists = listField.def_field.def_field_lists
        defFieldLists.map((listItem) => {
            let listTrans = this.props.kedo
                .env()
                .translateItem(listItem, 'def_field_list', true)
            let listData = []
            data.map((dataItem) => {
                if (
                    dataItem['2'] !== null &&
                    parseInt(dataItem['2']) === listItem.id
                ) {
                    listData.push({
                        x:
                            dataItem['yearAnddate'] !== null
                                ? dataItem['yearAnddate']
                                : 'other',
                        y: parseInt(dataItem['1']),
                    })
                }
            })

            lineData.push({
                id:
                    listTrans && listTrans.length > 0
                        ? listTrans
                        : this.props.kedo.t('No value'),
                data: listData,
                color: listItem && listItem.color ? listItem.color : '#F5F5F5',
            })
        })

        let unknownListData = []
        data.map((dataItem) => {
            let itemFound = false
            defFieldLists.map((listItem) => {
                if (parseInt(dataItem['2']) === listItem.id) {
                    itemFound = true
                }
            })

            if (!itemFound) {
                unknownListData.push({
                    x: dataItem['yearAnddate'],
                    y: parseInt(dataItem['1']),
                    color: '#F5F5F5',
                })
            }
        })

        lineData.push({
            id: this.props.kedo.t('Unknown'),
            data: unknownListData,
        })

        return lineData
    }

    hasColors() {
        let defFieldLists = this.state.listField.def_field.def_field_lists
        let hasColor = false

        if (
            defFieldLists.length > 0 &&
            defFieldLists[0] &&
            defFieldLists[0].color &&
            defFieldLists[0].color.length > 0
        ) {
            return true
        }

        return hasColor
    }

    transformData(block, data, listField) {
        //Transform date orientated data.
        let chartFormat = block.settings?.chart_format
        if (block.settings?.use_creation_date || block.settings?.date_field) {
            if (chartFormat === 'bar') {
                return this.transFormDateBarData(data, listField)
            }

            return this.transFormDateData(data, listField)
        }

        let pieData = []

        let defFieldLists = listField?.def_field?.def_field_lists
        if (data) {
            let unknownItems = data.find((dataItem) => dataItem['2'] === null)
            if (unknownItems) {
                pieData.push({
                    id: this.props.kedo.t('No value'),
                    label: this.props.kedo.t('No value'),
                    value: parseInt(unknownItems['1']),
                    //"color": '#F5F5F5',
                })
            }

            defFieldLists.map((item) => {
                let trans = this.props.kedo
                    .env()
                    .translateItem(item, 'def_field_list', true)
                let dataItem = data.find(
                    (dataItem) => parseInt(dataItem['2']) === item.id
                )

                if (dataItem) {
                    pieData.push({
                        id:
                            trans && trans.length > 0
                                ? trans
                                : this.props.kedo.t('No value'),
                        label:
                            trans && trans.length > 0
                                ? trans
                                : this.props.kedo.t('No value'),
                        value: parseInt(dataItem['1']),
                        color: item && item.color ? item.color : '#F5F5F5',
                    })
                }
            })
        }

        return pieData
    }

    componentDidMount() {
        this.fetchData()
    }

    renderData(data) {
        if (!data || data.length <= 0) {
            return ''
        }
        const kedo = this.props.kedo

        let scheme =
            this.state.blockSettings && this.state.blockSettings.scheme
                ? this.state.blockSettings.scheme
                : null

        const hasDflColors = this.hasColors()
        let useColors = scheme
            ? { scheme: scheme }
            : hasDflColors
            ? { datum: 'data.color' }
            : { scheme: 'nivo' }

        let chartFormat = this.state.blockSettings.chart_format

        if (
            this.state.blockSettings.use_creation_date ||
            this.state.blockSettings.date_field
        ) {
            useColors = scheme
                ? { scheme: scheme }
                : hasDflColors
                ? { datum: 'color' }
                : { scheme: 'nivo' }

            if (chartFormat === 'table') {
                let tableData = this.state.transformedData //this.transFormDateData(data);
                let dates = []
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i]['yearAnddate'] !== null &&
                        !dates.includes(data[i]['yearAnddate'].toString())
                    ) {
                        dates.push(data[i]['yearAnddate'].toString())
                    }
                }
                return (
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>{kedo.t('Period')}</th>{' '}
                                {dates.map((dateItem, index) => (
                                    <th key={index}>{dateItem}</th>
                                ))}
                                <th>{kedo.t('Other')}</th>
                                <th>{kedo.t('Total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((dataItem, index) => {
                                const otherData = dataItem.data.find(
                                    (dItem) => dItem.x === 'other'
                                )
                                return (
                                    <tr key={index}>
                                        <td>{dataItem.id}</td>
                                        {dates.map((dateItem, index) => {
                                            let foundItem = dataItem.data.find(
                                                (dItem) => dItem.x == dateItem
                                            )
                                            return (
                                                <td key={index}>
                                                    {foundItem
                                                        ? foundItem.y
                                                        : 0}
                                                </td>
                                            )
                                        })}
                                        <td>{otherData ? otherData.y : 0}</td>
                                        <th>
                                            {dataItem.data
                                                .map((dItem) => dItem.y)
                                                .reduce(
                                                    (prev, curr) => prev + curr,
                                                    0
                                                )}
                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>{kedo.t('Total')}</th>
                                {dates.map((dateItem, index) => {
                                    let foundItems = data.filter(
                                        (dItem) =>
                                            dItem['yearAnddate'] === dateItem
                                    )
                                    let sum = foundItems
                                        .map((fItem) => parseInt(fItem['1']))
                                        .reduce((prev, curr) => prev + curr, 0)
                                    return <th key={index}>{sum}</th>
                                })}
                                <th>
                                    {tableData
                                        .map((dataItem) =>
                                            dataItem.data.find(
                                                (dItem) => dItem.x === 'other'
                                            )
                                        )
                                        .map((otherData) =>
                                            otherData ? otherData.y : 0
                                        )
                                        .reduce((prev, curr) => prev + curr, 0)}
                                </th>
                            </tr>
                        </tfoot>
                    </Table>
                )
            } else if (chartFormat === 'bar') {
                let barData = this.state.transformedData //this.transFormDateBarData(data);
                let keys = this.state.transformedKeys //this.getKeys(this.state.blockSettings, this.state.listField);
                let groupMode =
                    this.state.blockSettings.group_format === 'stacked'
                        ? 'stacked'
                        : 'grouped'
                let chartLayout =
                    this.state.blockSettings.chart_layout === 'horizontal'
                        ? 'horizontal'
                        : 'vertical'
                let reverse_axis =
                    this.state.blockSettings.reverse_axis === 'yes'
                let legend_direction = this.state.blockSettings.legend_direction
                let enable_labels =
                    this.state.blockSettings.enable_labels === 'yes'
                let enable_grid = this.state.blockSettings.enable_grid === 'yes'
                return (
                    <ResponsiveBar
                        indexBy="period"
                        margin={{ top: 0, right: 130, bottom: 70, left: 60 }}
                        padding={0.3}
                        reverse={reverse_axis}
                        keys={keys}
                        layout={chartLayout}
                        groupMode={groupMode}
                        useMesh={false}
                        data={barData}
                        enableGridX={enable_grid}
                        enableGridY={enable_grid}
                        enableLabel={enable_labels}
                        colors={
                            scheme
                                ? useColors
                                : ({ id, data }) => String(data[`${id}Color`])
                        }
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: kedo.t('Date'),
                            legendPosition: 'middle',
                            legendOffset: 32,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            format: (e) => Math.floor(e) === e && e,
                            tickRotation: 0,
                            legend: kedo.t('Totals'),
                            legendPosition: 'middle',
                            legendOffset: -40,
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 50,
                                itemsSpacing: 2,
                                itemWidth: 80,
                                itemHeight: 20,
                                itemDirection: legend_direction,
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        theme={{
                            axis: {
                                legend: {
                                    text: {
                                        fontWeight: 700,
                                    },
                                },
                                ticks: {
                                    text: {
                                        fontWeight: 300,
                                    },
                                },
                            },
                        }}
                    />
                )
            } else if (chartFormat === 'pie') {
                let pieData = this.state.transformedData.map((data) => {
                    let aux = {
                        id: data.id,
                        color: data.color,
                        value: data.data.length > 0 ? data.data[0].y : 0,
                    }
                    return aux
                })

                return (
                    <ResponsivePie
                        data={pieData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={10}
                        borderWidth={1}
                        colors={useColors}
                        borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.4]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [['darker', 1.8]],
                        }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true,
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                            },
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'ruby',
                                },
                                id: 'dots',
                            },
                            {
                                match: {
                                    id: 'c',
                                },
                                id: 'dots',
                            },
                            {
                                match: {
                                    id: 'go',
                                },
                                id: 'dots',
                            },
                            {
                                match: {
                                    id: 'python',
                                },
                                id: 'dots',
                            },
                            {
                                match: {
                                    id: 'scala',
                                },
                                id: 'lines',
                            },
                            {
                                match: {
                                    id: 'lisp',
                                },
                                id: 'lines',
                            },
                            {
                                match: {
                                    id: 'elixir',
                                },
                                id: 'lines',
                            },
                            {
                                match: {
                                    id: 'javascript',
                                },
                                id: 'lines',
                            },
                        ]}
                        enableArcLinkLabels={false}
                        startAngle={0}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                translateY: 0,
                                translateX: -50,
                                justify: false,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#000',
                                itemDirection: 'left-to-right',
                                symbolSize: 15,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000',
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                )
            }

            let transformedData = this.state.transformedData //this.transFormDateData(data);
            return (
                <ResponsiveLine
                    colors={useColors}
                    data={transformedData}
                    margin={{ top: 40, right: 110, bottom: 70, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        fontWeight: 700,
                        tickRotation: 0,
                        legend: kedo.t('Date'),
                        legendOffset: 36,
                        legendPosition: 'middle',
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: kedo.t('Totals'),
                        legendOffset: -40,
                        legendPosition: 'middle',
                    }}
                    pointSize={4}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={false}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    theme={{
                        axis: {
                            legend: {
                                text: {
                                    fontWeight: 700,
                                },
                            },
                            ticks: {
                                text: {
                                    fontWeight: 300,
                                },
                            },
                        },
                    }}
                />
            )
        }

        if (chartFormat === 'table') {
            let tableData = this.transformData(data)
            return (
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{kedo.t('Name')}</th>
                            <th>{kedo.t('Amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((dataItem, index) => (
                            <tr key={index}>
                                <td>{dataItem.id}</td>
                                <td>{dataItem.value}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{kedo.t('Total')}</th>
                            <th>
                                {tableData
                                    .map((dItem) => dItem.value)
                                    .reduce((prev, curr) => prev + curr, 0)}
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            )
        }

        return (
            <ResponsivePie
                data={this.state.transformedData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                enableArcLinkLabels={false}
                activeOuterRadiusOffset={10}
                padAngle={0.7}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.8]],
                }}
                cornerRadius={3}
                colors={useColors}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'column',
                        translateY: 0,
                        translateX: -50,
                        justify: false,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        symbolSize: 15,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
        )
    }

    isPie() {
        let chartFormat = this.state.blockSettings?.chart_format

        if (chartFormat === 'pie') {
            return true
        }

        //Below condition will result in rendering a pie chart
        if (
            !this.state.blockSettings?.use_creation_date &&
            !this.state.blockSettings?.date_field &&
            chartFormat !== 'table'
        ) {
            return true
        }

        return false
    }

    render() {
        const kedo = this.props.kedo

        let cssClassName = !this.props.dashboard ? 'kedo-block' : ''
        if (this.state.hidePrint) {
            cssClassName = cssClassName + ' d-print-none'
        } else {
            cssClassName = cssClassName + ' d-print-block'
        }

        return (
            <div
                ref={this.parRef}
                className={cssClassName}
                style={{
                    height:
                        this.props.container === true || this.isPie()
                            ? '300px'
                            : '100%',
                    marginBottom: '30px',
                    minHeight: '300px',
                    minWidth: '300px',
                }}
            >
                <div className="block-header">
                    <BlockToolbar
                        setFilterAndFetchData={this.setFilterAndFetchData}
                        onClose={this.props.onClose}
                        block={this.props.block}
                        onMax={this.props.onMax}
                        kedo={kedo}
                        fetchData={() => this.fetchData()}
                    />
                    {this.props.getBlockHeader
                        ? this.props.getBlockHeader(
                              this.props.block,
                              this.props.dossier
                          )
                        : null}
                </div>
                {this.state.loading ? (
                    <center>
                        <LoadingDefault />
                    </center>
                ) : (
                    this.renderData(this.state.data)
                )}
            </div>
        )
    }
}
