import React from 'react'
import { toast } from 'react-toastify'
import { Form, FormControl } from 'react-bootstrap'

class TextField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            value: this.props.value,
            fillDossierId: this.isFillDossierIdField() === true,
            toastOptions: {
                position: 'bottom-center',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },
        }

        this.doFocus = this.doFocus.bind(this)
        this.setVal = this.setVal.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.value
    }

    setVal(name, val) {
        this.setState({ [name]: val })
    }

    renderShow() {
        if (!this.props.value) {
            return ''
        }

        if (
            this.props.item.settings &&
            this.props.item.settings.phone_number === true
        ) {
            return <a href={`tel:${this.props.value}`}>{this.props.value}</a>
        }

        return this.props.value
    }

    isFillDossierIdField() {
        return this.getDefFieldSettings().fill_dossier_id
    }

    getDefFieldSettings() {
        if (!this.props.item.def_dossier_def_field.def_field.settings) {
            return {}
        }

        return this.props.item.def_dossier_def_field.def_field.settings
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    onChange(event) {
        this.setState({ value: event.target.value }, () => {
            if (this.props.changeValue) {
                {
                    this.state.value.length >= 255
                        ? toast.error(
                              this.props.kedo.t(
                                  'dossier_errors.maxLength_edit'
                              ),
                              this.state.toastOptions
                          )
                        : null
                }
                this.props.changeValue(
                    this.props.item.id,
                    event.target.value,
                    this.props.item
                )
            }
        })
    }

    renderEdit() {
        return (
            <div>
                <FormControl
                    className={'displayItemField'}
                    onChange={this.onChange}
                    value={this.state.value}
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(this.props.item, event)
                            : null
                    }
                    isInvalid={this.isInvalid()}
                    ref={this.innerRef}
                    maxLength={255}
                />
                {this.isInvalid() ? (
                    <div className="invalid-feedback">{this.props.errors}</div>
                ) : null}
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== prevProps.value && this.props.mode === 'show') {
            this.setState({ value: this.props.value })
        }
    }

    render() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            if (this.state.value !== this.props.value) {
                this.setState({ value: this.props.value })
            }
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue(
                            this.props.item.id,
                            event.target.value
                        )
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            if (this.state.fillDossierId) {
                return ''
            }
            return this.renderEdit()
        }

        return this.renderShow()
    }
}

export default TextField
