export class DisplayItemFilter {
    getFieldType = (displayItem): string => {
        if (displayItem.def_dossier_link) {
            return 'link'
        }
        if (
            displayItem.def_dossier_def_field &&
            displayItem.def_dossier_def_field.def_field
        ) {
            return displayItem.def_dossier_def_field.def_field.type
        }

        return ''
    }

    hasFieldTypes = (displayItem, fieldTypes): boolean => {
        let hasType = false
        for (let i = 0; i <= fieldTypes.length; i++) {
            if (this.getFieldType(displayItem) === fieldTypes[i]) {
                hasType = true
            }
        }

        return hasType
    }

    byTypes(displayItems: Array<any>, fieldTypes?: Array<any>): Array<any> {
        return displayItems.filter((displayItem) => {
            if (fieldTypes && !this.hasFieldTypes(displayItem, fieldTypes)) {
                return false
            }

            return true
        })
    }
}

export default new DisplayItemFilter()
