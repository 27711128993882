import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt } from '@fortawesome/pro-duotone-svg-icons/faAt'

class UpperMenuContact extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayHelpdesk: this.props.displayHelpdesk,
            HelpdeskWidth: '16.67%',
        }

        this.handleHelpdesk = this.handleHelpdesk.bind(this)
    }

    handleHelpdesk() {
        this.setState((state) => ({
            displayHelpdesk: !state.displayHelpdesk,
        }))
    }

    setHelpdeskWidth() {
        if (window.innerWidth > 576) {
            this.setState({
                HelpdeskWidth: '16,67%',
            })
        } else {
            this.setState({
                HelpdeskWidth: '100%',
            })
        }
    }

    componentDidMount() {
        {
            this.setHelpdeskWidth()
        }
    }

    render() {
        return (
            <Row>
                <Col xs={12} md={6}>
                    <div className="mainContainer">
                        <h1>{this.props.kedo.t('Do you need help?')}</h1>
                        <br />
                        <Row className={'uppermenu-contact'}>
                            {/* TODO CHECK IF ENTERPRISE ACCOUNT*/}
                            {/*<Col xs={12} md={4}>*/}
                            {/*    <FontAwesomeIcon icon={faPhone} size={"3x"}/> <br/><br/>*/}
                            {/*    +31 (0) 35 603 00 20*/}
                            {/*</Col>*/}
                            <Col xs={12} md={12}>
                                <FontAwesomeIcon icon={faAt} size={'3x'} />
                                <br />
                                <br />
                                <a href={'mailto:help@xlab.nl'}>help@xlab.nl</a>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <small>
                            {this.props.kedo.t('KeDo Next Gen is a product of')}{' '}
                            &nbsp;
                            <strong>
                                <a
                                    href={'https://www.xlab.nl'}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Xlab B.V.
                                </a>
                            </strong>
                        </small>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default UpperMenuContact
