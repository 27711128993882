import React from 'react'
import update from 'immutability-helper'
import Item from './ItemDragAndDrop'
const style = {
    width: 400,
}
class ContainerDragandDrop extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.moveItem = this.moveItem.bind(this)
        this.renderItem = this.renderItem.bind(this)
    }
    moveItem(dragIndex, hoverIndex) {
        const newItems = update(this.props.items, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, this.props.items[dragIndex]],
            ],
        })
        this.props.onSortItems(newItems)
    }
    renderItem(item, index) {
        return (
            <Item
                key={item.id}
                index={index}
                id={item.id}
                moveItem={this.moveItem}
                Component={(ref, handlerId, opacity, style) =>
                    this.props.Component(item, ref, handlerId, opacity, style)
                }
            ></Item>
        )
    }
    render() {
        return (
            <>{this.props.items.map((item, i) => this.renderItem(item, i))}</>
        )
    }
}

export default ContainerDragandDrop
