import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class NivoSchemeSelector extends Component {
    constructor(props) {
        super(props)
    }

    getOptions() {
        return [
            'nivo',
            // categorical
            'category10',
            'accent',
            'dark2',
            'paired',
            'pastel1',
            'pastel2',
            'set1',
            'set2',
            'set3',
            // diverging
            'brown_blueGreen',
            'purpleRed_green',
            'pink_yellowGreen',
            'purple_orange',
            'red_blue',
            'red_grey',
            'red_yellow_blue',
            'red_yellow_green',
            'spectral',
            // sequential single hue
            'blues',
            'greens',
            'greys',
            'oranges',
            'purples',
            'reds',
            // sequential multi hue
            'blue_green',
            'blue_purple',
            'green_blue',
            'orange_red',
            'purple_blue_green',
            'purple_blue',
            'purple_red',
            'red_purple',
            'yellow_green_blue',
            'yellow_green',
            'yellow_orange_brown',
            'yellow_orange_red',
        ]
    }

    render() {
        const kedo = this.props.kedo
        const options = this.getOptions()

        return (
            <div>
                <Form.Group>
                    <Form.Label>{kedo.t('Color scheme')}</Form.Label>
                    <Form.Control
                        onChange={this.props.onChange}
                        as={'select'}
                        value={this.props.value}
                    >
                        <option value={''}>{kedo.t('Choose an option')}</option>
                        {options.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
        )
    }
}

export default NivoSchemeSelector
