import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SelectList from '../../../Elements/SelectList'
import { CirclePicker } from 'react-color'

class BlockFormPercentageChartSetting extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pie_charts:
                this.props.pie_charts && Array.isArray(this.props.pie_charts)
                    ? this.props.pie_charts
                    : [],
        }
        this.removeTotal = this.removeTotal.bind(this)
        this.removeQuery = this.removeQuery.bind(this)
        this.onDeleteChart = this.onDeleteChart.bind(this)
    }

    removeTotal(index) {
        let pieCharts = this.state.pie_charts
        pieCharts[index].total_query = null
        this.setState({ pie_charts: pieCharts })
        this.props.onChange(pieCharts)
    }

    removeQuery(index) {
        let pieCharts = this.state.pie_charts
        pieCharts[index].dossier_query = null
        this.setState({ pie_charts: pieCharts })
        this.props.onChange(pieCharts)
    }

    onDeleteChart(index) {
        let pieCharts = this.state.pie_charts
        pieCharts.splice(index, 1)
        this.setState({ pie_charts: pieCharts })
        this.props.onChange(pieCharts)
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div>
                <h3>{kedo.t('Percentage settings')}</h3>
                <table className={'table table-striped'}>
                    <tr>
                        <td colSpan={3}>
                            <Button
                                onClick={() => {
                                    let pieCharts = this.state.pie_charts
                                    pieCharts.push({})
                                    this.setState({ pie_charts: pieCharts })
                                    this.props.onChange(pieCharts)
                                }}
                            >
                                {kedo.t('Add')}
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {kedo.t('Total')} {kedo.t('Query')}
                        </th>
                        <th>
                            {kedo.t('Percentage')} {kedo.t('Query')}
                        </th>
                        <th>{kedo.t('Color')}</th>
                        <th>{kedo.t('Title')}</th>
                        <th />
                    </tr>
                    {this.state.pie_charts.map((pieItem, index) => (
                        <tr key={index}>
                            <td>
                                <SelectList
                                    currentValue={
                                        this.state.pie_charts &&
                                        this.state.pie_charts[index]
                                            ? this.state.pie_charts[index]
                                                  .total_query
                                            : null
                                    }
                                    onClear={() => this.removeTotal(index)}
                                    searchName={'search'}
                                    kedo={this.props.kedo}
                                    urlParams={{
                                        page: 0,
                                        limit: 200,
                                        environment: kedo.env().getEnvironment()
                                            .id,
                                        defDossier: this.props.defDossier,
                                    }}
                                    onDisplayName={(item) =>
                                        kedo
                                            .env()
                                            .translateItem(item, 'dossierquery')
                                    }
                                    url={kedo.api().getDossierQueryEndpoint()}
                                    onSelect={(item) => {
                                        let pieCharts = this.state.pie_charts
                                        pieCharts[index].total_query = item.id
                                        this.setState({ pie_charts: pieCharts })
                                        this.props.onChange(pieCharts)
                                    }}
                                />
                            </td>
                            <td>
                                <SelectList
                                    currentValue={
                                        this.state.pie_charts &&
                                        this.state.pie_charts[index]
                                            ? this.state.pie_charts[index]
                                                  .dossier_query
                                            : null
                                    }
                                    onClear={() => this.removeQuery(index)}
                                    searchName={'search'}
                                    kedo={this.props.kedo}
                                    urlParams={{
                                        page: 0,
                                        limit: 200,
                                        environment: kedo.env().getEnvironment()
                                            .id,
                                        defDossier: this.props.defDossier,
                                    }}
                                    onDisplayName={(item) =>
                                        kedo
                                            .env()
                                            .translateItem(item, 'dossierquery')
                                    }
                                    url={kedo.api().getDossierQueryEndpoint()}
                                    onSelect={(item) => {
                                        let pieCharts = this.state.pie_charts
                                        pieCharts[index].dossier_query = item.id
                                        this.setState({ pie_charts: pieCharts })
                                        this.props.onChange(pieCharts)
                                    }}
                                />
                            </td>
                            <td>
                                <CirclePicker
                                    value={
                                        this.state.pie_charts &&
                                        this.state.pie_charts[index]
                                            ? this.state.pie_charts[index].color
                                            : null
                                    }
                                    onChangeComplete={(color) => {
                                        let pieCharts = this.state.pie_charts
                                        pieCharts[index].color = color.hex
                                        this.setState({ pie_charts: pieCharts })
                                        this.props.onChange(pieCharts)
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    onChange={(e) => {
                                        let pieCharts = this.state.pie_charts
                                        pieCharts[index].title = e.target.value
                                        this.setState({ pie_charts: pieCharts })
                                        this.props.onChange(pieCharts)
                                    }}
                                    type={'text'}
                                    value={
                                        this.state.pie_charts &&
                                        this.state.pie_charts[index]
                                            ? this.state.pie_charts[index].title
                                            : null
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        )
    }
}

export default BlockFormPercentageChartSetting
