import React, { Component } from 'react'
import Kedo from '../../Kedo.js'

export default class ShowEntries extends Component {
    /*** How to use:
     <ShowEntries value={this.state.limit} onChange={this.handleEntriesChange}/>

     handleEntriesChange(event) {
        this.setState({limit: event.target.value});
        setTimeout(() => this.fetchItems(), 100);
    }
     ***/

    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.pager.pages <= 1 && this.props.pager.items <= 10) {
            return ''
        }

        let limit = parseInt(this.props.value)
        limit = limit < 0 || limit > 100 ? 50 : limit

        return (
            <div className="show-entries float-right">
                <select
                    name={'limit'}
                    value={limit}
                    onChange={(e) => {
                        this.props?.onChange ? this.props.onChange(e) : null
                    }}
                    className="form-control"
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
        )
    }
}
