import React, {Component} from 'react'
import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class EnvironmentInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    addDefaultSrc(ev){
        ev.target.src = '/static/images/xlab-logo.jpg';
    }

    getLogo() {
        let env = this.props.kedo.env().getEnvironment();

        return  <Link onClick={() => this.clearEnvironment()} to={"/environment"}>
                    <img onError={this.addDefaultSrc}  className={"avatar"} src={env && env.id ? `/images/environment/${env.id}/logo.png` : '/static/images/xlab-logo.jpg'} alt={"Logo van " + env.name}/>
                </Link>
    }

    clearEnvironment() {
        localStorage.removeItem('environment');
        this.props.kedo.env().clearInfo();
    }

    render() {
        const environmentName = this.props.kedo.env().getEnvironmentName()

        return  <Row className={"environmentInfo"}>
                    <Col xs={12}>
                        <div className={"switchSaas"}>
                            {this.getLogo()}
                            <h6 className='text-capitalize m-1'>{environmentName.slice(0, 25)}</h6>
                        </div>
                    </Col>
                </Row>
    }
}
