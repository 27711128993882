import React, { useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.css';
import { Dropdown } from 'react-bootstrap'
import Avatar from './Avatar.tsx'

const AvatarGroup = ({ users, kedo, props }) => {
    const [itemsToShow, setItemsToShow] = useState(1)
    const userFilter =
        users && users.filter
            ? users
                  .filter((user) => user.userId !== kedo.user().getUserId())
                  .sort((a, b) =>
                      a.username > b.username
                          ? 1
                          : b.username > a.username
                          ? -1
                          : 0
                  )
            : []
    const me =
        users && users.find
            ? users.find((user) => user.userId === kedo.user().getUserId())
            : null

    if (me) {
        userFilter.push(me)
    }

    const buttons = () => {
        if (users && users.length > itemsToShow) {
            return (
                <div className="avatar-online">
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                        >
                            <span>+{users.length - itemsToShow}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {userFilter.slice(itemsToShow).map((user, i) => (
                                <Dropdown.Item
                                    key={i}
                                    onClick={() =>
                                        history.push('/user/' + user.userId)
                                    }
                                >
                                    <span>
                                        <Avatar
                                            kedo={kedo}
                                            user={user}
                                            key={i}
                                            props={props}
                                            options={{
                                                size: 33,
                                                showPresence: true,
                                            }}
                                        />
                                        &nbsp;
                                        {user.username}
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }
    }

    return (
        <div className="container">
            {userFilter.slice(0, itemsToShow).map((user, i) => (
                <Avatar
                    onClick={() =>
                        this.props.history.push('/user/' + user.userId)
                    }
                    kedo={kedo}
                    user={user}
                    key={i}
                    props={props}
                    options={{
                        size: 35,
                        showPresence: true,
                    }}
                />
            ))}

            {buttons()}
        </div>
    )
}

export default AvatarGroup
