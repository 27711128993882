import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DisplayItemDossierDataSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dossier_data: this.props.item && this.props.item.settings && this.props.item.settings.dossier_data ? this.props.item.settings.dossier_data : null,
        };
    }


    render() {
        const kedo = this.props.kedo;

        let defDossierId = this.state.block_data ? this.state.block_data.def_dossier_id : null;

        return  <div>
            <Form.Group>
                <Form.Label>{kedo.t('Dossier data')}</Form.Label>
                <Form.Control
                    name={'dossier_data'}
                    onChange={(e) => {
                        this.setState({dossier_data: e.target.value});
                        this.props.onChange('dossier_data', e.target.value)
                    }}
                    value={this.state.dossier_data}
                    as={'select'}>
                    <option value={''}>{kedo.t('Choose an option')}</option>
                    <option value={'created_by'}>{kedo.t('Created by')}</option>
                    <option value={'modified_by'}>{kedo.t('Modified by')}</option>
                    <option value={'created_at'}>{kedo.t('Created at')}</option>
                    <option value={'modified_at'}>{kedo.t('Modified at')}</option>
                    <option value={'dossier_id'}>{kedo.t('Dossier id')}</option>
                </Form.Control>
            </Form.Group>
        </div>
    }
}

export default DisplayItemDossierDataSettings