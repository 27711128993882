import React from 'react'
import {Form} from "react-bootstrap";
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class CalcField extends React.Component {
    constructor(props) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            value: this.props.value ? this.props.value : null,
        };
        this.onSliderChange = this.onSliderChange.bind(this);
     }

    onSliderChange(value) {
        this.setState({
            value: value
        });

        let searchValue = {
            custom: 'slider',
            min: value[0],
            max: value[1],
        };

        if (value[0] === 0 && value[1] === 1) {
            this.props.changeValue(this.props.item.id, null);
        }

        this.props.changeValue(this.props.item.id, searchValue);
    }

    getValue() {
        return this.state.value;
    }

    render() {
        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            if (this.state.value !== this.props.value) {
                //this.setState({value: this.props.value});
            }

            if (this.props.item.settings && this.props.item.settings.show_slider === true) {
                return <div>
                    {Array.isArray(this.state.value) ? <center><span className={'text-center'}>{this.state.value[0]} - {this.state.value[1]}</span></center> : null}
                    <Range
                        allowCross={false}
                        min={0}
                        defaultValue={[0, 0]}
                        max={this.props.item.settings && this.props.item.settings.max_slider ? this.props.item.settings.max_slider : 1000}
                        onChange={this.onSliderChange}
                /></div>
            }

            return  <Form.Control
                onChange={(event) => this.props.changeValue(this.props.item.id, event.target.value)}
                onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event) : null}
                type="text"
                value={this.props.value}
            />
        }

        if (!this.state.value) {
            return null;
        }

        return this.state.value;
    }
}

export default CalcField