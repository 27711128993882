import React from 'react'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { ResponsiveLine } from '@nivo/line'
import { Col } from 'react-bootstrap'

export default class ContentDossierStatistics extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            blockSettings: this.props.block ? this.props.block.settings : null,
            data: [],
            loading: false,
        }
    }

    fetchData() {
        if (!this.props.kedo.env().getEnvironment()) {
            return ''
        }
        let envId = this.props.kedo.env().getEnvironment().id
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .post('/environmentadmin/statistics/contentdossier', {
                environment: envId,
            })
            .then((response) =>
                this.setState({
                    loading: false,
                    data: response.data,
                })
            )
            .catch(() =>
                this.setState({
                    loading: false,
                })
            )
    }

    componentDidMount() {
        this.fetchData()
    }

    dayNmbrToDayName(nmbr) {
        let day = 'unknown'
        switch (nmbr) {
            case 0:
                day = ''
                break
            case 1:
                day = 'days_short.Sun'
                break
            case 2:
                day = 'days_short.Mon'
                break
            case 3:
                day = 'days_short.Tue'
                break
            case 4:
                day = 'days_short.Wed'
                break
            case 5:
                day = 'days_short.Thu'
                break
            case 6:
                day = 'days_short.Fri'
                break
            case 7:
                day = 'days_short.Sat'
                break
        }

        return this.props.kedo.t(day)
    }

    transformLineData(data) {
        if (!data.lastweek) {
            return []
        }

        let returnData = []

        let lastWeek = []
        let thisWeek = []
        let threeWeek = []

        for (let dayNmbr = 1; dayNmbr <= 7; dayNmbr++) {
            let dayName = this.dayNmbrToDayName(dayNmbr)
            let weekTotal = data.lastweek.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!weekTotal) {
                weekTotal = 0
            } else {
                weekTotal = parseInt(weekTotal.cd_count)
            }
            thisWeek.push({
                x: dayName,
                y: weekTotal,
            })

            weekTotal = data.twoweeks.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!weekTotal) {
                weekTotal = 0
            } else {
                weekTotal = parseInt(weekTotal.cd_count)
            }
            lastWeek.push({
                x: dayName,
                y: weekTotal,
            })

            weekTotal = data.threeweeks.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!weekTotal) {
                weekTotal = 0
            } else {
                weekTotal = parseInt(weekTotal.cd_count)
            }
            threeWeek.push({
                x: dayName,
                y: weekTotal,
            })
        }
        returnData.push({
            id: this.props.kedo.t('Past week'),
            color: 'hsl(60, 70%, 50%)',
            data: thisWeek,
        })

        returnData.push({
            id: this.props.kedo.t('Two weeks ago'),
            color: 'hsl(60, 70%, 50%)',
            data: lastWeek,
        })

        returnData.push({
            id: this.props.kedo.t('Three weeks ago'),
            color: 'hsl(60, 70%, 50%)',
            data: threeWeek,
        })

        return returnData
    }

    transFormData(data) {
        if (!data.lastweek) {
            return []
        }

        let returnData = []
        for (let dayNmbr = 1; dayNmbr <= 7; dayNmbr++) {
            let thisweekDayTotal = data.lastweek.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!thisweekDayTotal) {
                thisweekDayTotal = 0
            } else {
                thisweekDayTotal = parseInt(thisweekDayTotal.cd_count)
            }

            let twoweeksDayTotal = data.lastweek.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!twoweeksDayTotal) {
                twoweeksDayTotal = 0
            } else {
                twoweeksDayTotal = parseInt(twoweeksDayTotal.cd_count)
            }

            let threeweeksDayTotal = data.threeweeks.find(
                (item) => item.created_at === dayNmbr.toString()
            )
            if (!threeweeksDayTotal) {
                threeweeksDayTotal = 0
            } else {
                threeweeksDayTotal = parseInt(threeweeksDayTotal.cd_count)
            }

            let thisWeekLabel = this.props.kedo.t('Past week')
            let twoweeksLabel = this.props.kedo.t('Two weeks ago')
            let threeweeksLabel = this.props.kedo.t('Three weeks ago')

            let dayData = {
                days: this.dayNmbrToDayName(dayNmbr),
                [thisWeekLabel]: thisweekDayTotal,
                [thisWeekLabel + 'Color']: 'hsl(314, 70%, 50%)',
                [twoweeksLabel]: twoweeksDayTotal,
                [twoweeksLabel + 'Color']: 'hsl(7, 70%, 50%)',
                [threeweeksLabel]: threeweeksDayTotal,
                [threeweeksLabel + 'Color']: 'hsl(210, 70%, 50%)',
            }
            returnData.push(dayData)
        }

        return returnData
    }

    render() {
        if (this.state.loading) {
            return <LoadingDefault />
        }

        let scheme =
            this.state.blockSettings && this.state.blockSettings.scheme
                ? this.state.blockSettings.scheme
                : 'blues'

        return (
            <div className={'row kedo-block-statistics kedo-block'}>
                <h3>{this.props.kedo.t('Dossier statistics')}</h3>
                <Col xs={12} md={12} lg={12} className={'statistics'}>
                    <ResponsiveLine
                        data={this.transformLineData(this.state.data)}
                        curve="natural"
                        colors={{ scheme: scheme }}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false,
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'count',
                            legendOffset: -40,
                            legendPosition: 'middle',
                        }}
                        enableCrosshair={false}
                        enablePoints={true}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={2}
                        pointBorderWidth={1}
                        pointColor={{ from: 'color', modifiers: [] }}
                        pointBorderColor={{ theme: 'grid.line.stroke' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'row',
                                justify: false,
                                translateX: 100,
                                translateY: 46,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 100,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground:
                                                'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </Col>
            </div>
        )
    }
}
