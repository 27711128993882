import React, {Component} from "react";
import {Modal} from 'react-bootstrap'
import LoadingDefault from "../Elements/Loading/LoadingDefault";
import Applying from "../Admin/Pages/Templates/Applying";
import MainMenuItems from "./MainMenu/MainMenuItems";

class TemplateInstall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateJson: null,
            template: null,
            loaded: false,
            loading: false,
        };

        this.handleExit = this.handleExit.bind(this);
    }

    fetchTemplate() {
        if (this.state.loading) {
            return;
        }
        this.setState({loading: true});
        let envId = this.props.kedo.getCurrentEnvironmentId();
        let api = this.props.kedo.api();
        api.get(api.getAdminTemplateEndpoint()+'/'+this.props.templateId).
            then(response => {
                let template = JSON.parse(response.data.template);
                this.setState({
                    templateJson: template,
                    template: response.data,
                    loaded: true,
                    loading: false,
                });

                let finishedUrl = api.getApplyTemplateEndpoint(this.props.templateId, 2)+'?environment='+envId;
                api.patch(finishedUrl);
        })
        .catch(error => {
            this.setState({
                loading: false,
                error: api.getErrorFromApiCall(error.response),
            });
            let errorUrl = api.getApplyTemplateEndpoint(this.props.templateId, 9)+'?environment='+envId;
            api.patch(errorUrl);
        }
        );
    }

    async handleExit() {
        await this.props.kedo.env().clearPendingTemplate();

        if (this.props.kedo.env().hasEnviroment()) {
            await this.props.kedo.env().reloadEnvironment(this.props.kedo.getCurrentEnvironmentId(), this.props.kedo.api(), () => {
                window.location.reload();
            });
        }
    }

    getTranslation(item) {
        let language = this.props.kedo.getLanguage();

        for(let i=0;i <= item.translations.length;i++) {
            let curTrans = item.translations[i];
            let culture = curTrans && curTrans.culture ? curTrans.culture.substr(0,2) : null;
            if (culture === language) {
                return curTrans;
            }
        }

        return false;
    }

    getTemplateName(template) {
        let trans = this.getTranslation(template);

        if (trans === false) {
            return  <div>
                        <center><strong>{template.name}</strong></center>
                        <center><p>{template.description}</p></center>
                    </div>
        }

        let baseApiUrl = this.props.kedo.api().getBaseUrl();

        return <div>
                    <img className={'img-fluid mx-auto d-block'} src={`${baseApiUrl}/`+trans.thumbnail_path} />
                    <br />
                    <center><strong>{trans.name}</strong></center>
                    <center>{trans.description}</center>
                </div>
    }

    renderMenuItems() {
        if (this.state.template) {
            return <MainMenuItems kedo={this.props.kedo} items={this.props.template}/>
        }
    }

    componentDidMount() {
        this.fetchTemplate();
    }

    render() {
        const kedo = this.props.kedo;

        if (this.state.template) {
            this.renderMenuItems();
        }

        return  <Modal show={true}>
                    <Modal.Body>
                        {this.state.loaded ? this.getTemplateName(this.state.template) : null}
                        {this.state.loading ? <LoadingDefault /> : null}
                        {this.state.loaded ?  <Applying
                            kedo={kedo}
                            manualConfirm={true}
                            noModal={true}
                            onClose={this.handleExit}
                            popup={true}
                            environment={kedo.env().getEnvironment()}
                            showButtons={false}
                            template={this.state.templateJson} /> : null
                        }
                    </Modal.Body>
                </Modal>
    }
}
export default TemplateInstall