import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import BlockToolbar from "./BlockToolbar";

export default class BlockGeomap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 10,
            latitude: null,
            longitude: null,
            data: null,
            loading: false,
        };
        this.parRef = React.createRef();
        this.onChangeLongitudeLatitude = this.onChangeLongitudeLatitude(this);
    }

    getParentRef() {
        return this.parRef;
    }

    fetchData () {
        let environment = this.props.kedo.env().getEnvironment();
        if (!environment) {
            return;
        }

        if (!this.props.block.id) {
            return;
        }

        let api = this.props.kedo.api();
        let params = {params: {}};

        if (this.props.dossier && this.props.dossier.id) {
            params.params.dossier = this.props.dossier.id;
            params.params.dossier = this.props.dossier.id;
            params.params.linkId = this.props.link;
        }
        this.setState({loading: true});

        api
            .get(api.getMyPageBlockEndpoint(this.props.block.id)+'/data', params)
            .then(response => {
                this.setState({
                    loading: false,
                    data: response.data.result
                });
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    onChangeLongitudeLatitude(event) {
        if (!event || !event.latLng) {
            return;
        }

        const lng = event.latLng.lng();
        const lat = event.latLng.lat();

        this.setState({
            latitude: lat,
            longitude: lng,
        });
    }

    renderData(data) {
        if (!data) {
            //return '';
        }
        const containerStyle = {
            minWidth: '300px',
            minHeight: '300px',
            height: '100%',
            //position: 'absolute'
        };

        const defLoc = {
            lng: 4.895168,
            lat:  52.370216,
        };

        const curLocation = {
            lng: this.state.longitude ? parseFloat(this.state.longitude) : null,
            lat: this.state.latitude ? parseFloat(this.state.latitude) : null,
        };

        return <LoadScript googleMapsApiKey={"AIzaSyAVb-S8KFIQgQ1KhSs9JvYJ1VFYzkPjar4"}><GoogleMap
            options={{
                googleMapsApiKey:  "AIzaSyAVb-S8KFIQgQ1KhSs9JvYJ1VFYzkPjar4",
            }}
            center={defLoc}
            mapContainerStyle={containerStyle}
            zoom={this.state.zoom}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <Marker draggable={true} onDragEnd={this.onChangeLongitudeLatitude} position={this.state.longitude ? curLocation : defLoc} />
            <></>
        </GoogleMap> </LoadScript>;
    }

    render() {
        return <div ref={this.parRef}>
            <BlockToolbar
                setFilterAndFetchData={this.setFilterAndFetchData}
                onClose={this.props.onClose}
                onSuccess={this.props.onSuccess}
                block={this.props.block}
                kedo={this.props.kedo}
                fetchData={() => this.fetchData()}
            />
            {this.props.getBlockHeader ? this.props.getBlockHeader(this.props.block, this.props.dossier) : null}
            <h1>{this.renderData(this.state.data)}</h1>
        </div>
    }
}