import * as React from 'react'
import Contextualizer from './Contextualizer'
import ProvidedServices from './ProvidedServices'
import { io, Socket } from 'socket.io-client'

export interface ISocketService {
    socket(): Socket | null
    attachEvent(eventName: string): Promise<boolean>
    init(data: ISocketData): Promise<Socket | null>
    environment: IEnvironmentItemSocket
    dossier: IDossierSocket
    displayItem: IDisplayItemSocket
    user: IUserItemSocket
    removeEventListener(listener?: string | Array<string>): void
}

interface IDossierSocket {
    join(id: number): void
    exit(): void
    usersListener(callBack?: (data: IDossierUserData) => void): void
    removeDosListeners(): void
}

interface IUserItemSocket {
    getPresence(id: number): string
    setPresence(presence: string): Promise<string>
}

interface IEnvironmentItemSocket {
    join(id: number): Promise<Array<number>>
}

interface IDisplayItemSocket {
    update(data: IDisplayItemUpdateData): void
    delete(data: IDisplayItemDeleteData): void
    refreshListener(callBack?: (data: IDisplayItemRefreshData) => void): void
    fetchListener(callBack?: (data: IDisplayItemFetchData) => void): void
    removeDdiListeners(): void
}

interface ISocketData {
    username: string
    userId: number
    environmentId: number | null
}

interface IDisplayItemUpdateData {
    dossierId: number
    displayItem: number
    sender: string
}

interface IDisplayItemDeleteData {
    defDossierId: number
    sender: string
    room: string
}

interface IDisplayItemRefreshData {
    dossierId: number
    displayItem: number
    sender: ISocketSenderData
}

interface IDisplayItemFetchData {
    defDossierId: number
    sender: ISocketSenderData
}

interface ISocketSenderData {
    id: string
    userId: number
    username: string
}

interface IDossierUserData {
    dossierId: string
    users: Array<number>
}

const SocketServiceContext = Contextualizer.createContext(
    ProvidedServices.SocketService
)

const SOCKET_ENV_JOIN = 'environment:join'
const SOCKET_ENV_PRESENCE_UPDATE = 'environment:presence:update'
const SOCKET_USER_PRESENCE_SET = 'user:presence:set'
const SOCKET_DOS_JOIN = 'dossier:join'
const SOCKET_DOS_EXIT = 'dossier:exit'
const SOCKET_DOS_USERS = 'dossier:users'
const SOCKET_DDI_UPDATE = 'displayItem:update'
const SOCKET_DDI_FETCH = 'displayItem:fetch'
const SOCKET_DDI_REFRESH = 'displayItem:refresh'
const SOCKET_DDI_DELETE = 'displayItem:delete'

const SocketService = ({ kedo, children }) => {
    let socket: Socket = null
    let envPresenceArray = []

    const socketService = {
        async init(data: ISocketData): Promise<Socket | null> {
            if (!socket && kedo.user().isLoggedIn()) {
                if (process.env && process.env.REACT_APP_WSS_URL) {
                    socket = io(process.env.REACT_APP_WSS_URL, {
                        reconnection: false,
                        withCredentials: true,
                    })

                    socket.emit('init', data, (result) => {
                        envPresenceArray = result ? result : []
                    })

                    socketService.environment.presenceListener()
                }
            }

            return socket
        },
        socket(): Socket | null {
            //TODO: Create generic class for mapping these results.
            return socket
        },
        user: {
            getPresence(id: number): void {
                const find = envPresenceArray.find((item) => item.id === id)
                return find && find.presence ? find.presence : null
            },
            async setPresence(presence: string): Promise<string> {
                return new Promise<string>((resolve) => {
                    if (socket) {
                        socket.emit(
                            SOCKET_USER_PRESENCE_SET,
                            {
                                presence: presence,
                                environmentId: kedo.env().hasEnviroment()
                                    ? kedo.env().getEnvironmentId()
                                    : null,
                                userId: kedo.user().getUserId(),
                            },
                            (online) => {
                                resolve(online)
                            }
                        )
                    } else {
                        resolve('')
                    }
                })
            },
        },
        environment: {
            async join(id: number): Promise<Array<object>> {
                return new Promise<Array<object>>((resolve) => {
                    if (socket) {
                        socket.emit(
                            SOCKET_ENV_JOIN,
                            {
                                environmentId: id,
                            },
                            (data) => {
                                envPresenceArray = data ? data : []
                                resolve(data)
                            }
                        )
                    } else {
                        resolve([])
                    }
                })
            },
            presenceListener(): void {
                if (socket)
                    socket.on(SOCKET_ENV_PRESENCE_UPDATE, (data) => {
                        envPresenceArray = data ? data : []
                    })
            },
        },
        dossier: {
            join(id: number): void {
                if (socket) socket.emit(SOCKET_DOS_JOIN, { dossierId: id })
            },
            exit(): void {
                if (socket) socket.emit(SOCKET_DOS_EXIT)
            },
            usersListener(callBack?: (data: IDossierUserData) => void): void {
                if (socket)
                    socket.on(SOCKET_DOS_USERS, (data) => {
                        callBack(data)
                    })
            },
            removeDosListeners() {
                socketService.removeEventListener([SOCKET_DOS_USERS])
            },
        },
        displayItem: {
            update(data: IDisplayItemUpdateData) {
                if (socket) socket.emit(SOCKET_DDI_UPDATE, data)
            },
            delete(data: IDisplayItemDeleteData) {
                if (socket) socket.emit(SOCKET_DDI_DELETE, data)
            },
            refreshListener(
                callBack?: (data: IDisplayItemRefreshData) => void
            ): void {
                if (socket)
                    socket.on(SOCKET_DDI_REFRESH, (data) => {
                        callBack(data)
                    })
            },
            fetchListener(
                callBack?: (data: IDisplayItemFetchData) => void
            ): void {
                if (socket)
                    socket.on(SOCKET_DDI_FETCH, (data) => {
                        callBack(data)
                    })
            },
            removeDdiListeners() {
                socketService.removeEventListener([
                    SOCKET_DDI_FETCH,
                    SOCKET_DDI_REFRESH,
                ])
            },
        },
        async attachEvent(eventName: string): Promise<boolean> {
            console.log('Attaching:' + eventName)
            return true
        },
        removeEventListener(listener?: string | Array<string>) {
            if (!socket) return

            if (!listener) {
                socket.removeAllListeners()
            } else if (listener && typeof listener === 'string') {
                socket.removeAllListeners(listener)
            } else if (listener && Array.isArray(listener)) {
                listener.forEach((item) => {
                    socket.removeAllListeners(item)
                })
            }
        },
    }

    return (
        <>
            <SocketServiceContext.Provider value={socketService}>
                {children}
            </SocketServiceContext.Provider>
        </>
    )
}

export default SocketService

export const useSocketService = () =>
    Contextualizer.use<ISocketService>(ProvidedServices.SocketService)
