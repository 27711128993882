import React, { Component } from 'react'
import { Form, Alert } from 'react-bootstrap'
import Select from 'react-select'

class DisplayItemDateSettings extends Component {
    constructor(props) {
        super(props)

        const kedo = props.kedo

        this.state = {
            show_slider:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.show_slider
                    ? true
                    : false,
            show_ago:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.show_ago
                    : null,
        }
    }

    render() {
        return (
            <div>
                <h3>{this.props.kedo.t('Settings')}</h3>
                <Form.Group>
                    <Form.Check
                        checked={this.state.show_slider}
                        id={'show_slider'}
                        name={'show_slider'}
                        label={this.props.kedo.t('Show slider')}
                        onChange={(e) => {
                            this.setState({ show_slider: e.target.checked })
                            this.props.onChange('show_slider', e.target.checked)
                        }}
                    />
                    <Form.Check
                        checked={this.state.show_ago === true}
                        id={'radio-show-ago'}
                        name={'show_ago'}
                        label={this.props.kedo.t('Show ago')}
                        onChange={(e) => {
                            this.setState({ show_ago: e.target.checked })
                            this.props.onChange('show_ago', e.target.checked)
                        }}
                        type="checkbox"
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemDateSettings
