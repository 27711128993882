import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faFileExport } from '@fortawesome/pro-duotone-svg-icons/faFileExport'
import { faPlay } from '@fortawesome/pro-duotone-svg-icons/faPlay'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'

import { Alert, Button, Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import DefaultGrid from '../Grid/DefaultGrid'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { faChartLine } from '@fortawesome/pro-duotone-svg-icons'

class CustomActionButtonField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPopup: false,
            submitting: false,
            loadingPopup: false,
            errors: [],
            popupSubmitting: false,
            popupContent: null,
            popupDd: null,
            popupItems: null,
            popupDossier: null,
            isOnlySingleEmbeddedField: false,
            isAllowedToEdit: false,
        }
        this.popupRef = React.createRef()

        this.toastOptions = {
            position: 'bottom-center',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    }

    isObligatory(item, values) {
        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.obligatory &&
            item.def_dossier_def_field.obligatory === true
        ) {
            return true
        }

        if (
            item.def_dossier_link &&
            item.def_dossier_link.obligatory === true
        ) {
            return true
        }

        if (
            this.state.popupDd &&
            this.state.popupDd.settings &&
            this.state.popupDd.settings.conditions
        ) {
            let condResult = false
            this.state.popupDd.settings.conditions.map((condition) => {
                if (this.popupRef.current.conditionMatches(condition, values)) {
                    if (
                        condition.actions
                            .filter(
                                (action) => action.action === 'require_fields'
                            )
                            .filter((action) =>
                                action.values.find(
                                    (actVal) => actVal === item.id
                                )
                            ).length > 0
                    ) {
                        condResult = true
                    }
                }
            })

            return condResult
        }

        return false
    }

    submitDossier(saveAndClose) {
        const kedo = this.props.kedo
        this.setState({
            submitting: true,
            subSaveAndClose: saveAndClose,
        })

        let mandatory = {}
        let values = this.popupRef.current.getValues()
        this.state.popupItems
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => {
                if (
                    this.isObligatory(item, values) &&
                    (!values[item.id] || values[item.id].length <= 0)
                ) {
                    mandatory[item.id] = kedo.t('This is a required field')
                }
            })

        if (this.state.ignoreWarning === true) {
            values['ignore_existing'] = true
        }

        //Prevent a API call and just check obligatory fields
        if (!!Object.keys(mandatory).length > 0) {
            this.setState(
                {
                    errors: mandatory,
                    submitting: false,
                },
                () => {
                    const errorId = this.checkErrors(mandatory)
                    this.popupRef.current.doFocus(errorId)
                }
            )

            return
        }

        let url =
            kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id +
            '?'

        if (this.props.item.settings.popup_view) {
            url = url + 'displayPosition=' + this.props.item.settings.popup_view
        } else {
            url =
                url +
                'displayPosition=' +
                this.props.item.settings.popup_custom_view
        }

        kedo.api()
            .put(url, values)
            .then(() => {
                this.setState({
                    edit: false,
                    submitting: false,
                    subSaveAndClose: false,
                    showExisting: false,
                    errors: [],
                    existingConstraintType: null,
                    showSaveSuccess: true,
                })

                if (this.props.onSuccess) {
                    this.props.onSuccess(() =>
                        this.setState({ showPopup: false })
                    )
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    this.setState({
                        errors: error.response.data.errors,
                        submitting: false,
                    })
                    if (
                        error.response.data.existing &&
                        error.response.data.existing.length > 0
                    ) {
                        this.setState({
                            //existingDp: displayPositionId,
                            showExisting: error.response.data.existing,
                            existingConstraintType:
                                error.response.data.constraint_type,
                        })
                    }
                } else {
                    this.setState({
                        errors:
                            error.response && error.response.data
                                ? error.response.data.errors
                                : [],
                        submitting: false,
                        showExisting: false,
                        subSaveAndClose: false,
                        existingDp: null,
                        existingConstraintType: null,
                    })

                    const errorId = this.checkErrors()
                    this.popupRef.current.doFocus(errorId)
                }
            })
    }

    renderEditButton() {
        return (
            <div>
                <hr />
                {this.props.showSaveClose === true ? (
                    <Button
                        className={'mr-2'}
                        disabled={this.state.popupSubmitting}
                        onClick={() => this.submitDossier(true)}
                        title={this.props.kedo.t('Save and close')}
                        variant="primary"
                    >
                        {this.state.submitting &&
                        this.state.subSaveAndClose === true ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Save and close')}
                    </Button>
                ) : null}
                <Button
                    className={'mr-2'}
                    disabled={this.state.submitting}
                    onClick={() => this.submitDossier()}
                    title={this.props.kedo.t('Save')}
                    variant="primary"
                >
                    {this.state.submitting && !this.state.subSaveAndClose ? (
                        <LoadingDefault size={'sm'} as={'span'} />
                    ) : (
                        <FontAwesomeIcon icon={faSave} />
                    )}
                    &nbsp; {this.props.kedo.t('Save')}
                </Button>
                <Button
                    disabled={this.state.submitting}
                    variant="secondary"
                    title={this.props.kedo.t('Cancel')}
                    onClick={() =>
                        this.setState({
                            showPopup: false,
                            popupContent: null,
                            popupItems: [],
                        })
                    }
                >
                    {this.props.kedo.t('Cancel')}
                </Button>
            </div>
        )
    }

    fetchPopupContent() {
        let dosUrl =
            this.props.kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id
        let params = { params: {} }
        if (this.props.item.settings && this.props.item.settings.popup_view) {
            params.params.displayPosition = this.props.item.settings.popup_view
        }
        this.setState({ loadingPopup: true })
        this.props.kedo
            .api()
            .get(dosUrl, params)
            .then((response) => {
                let content = {}
                if (response.data.items.length >= 0) {
                    response.data.items.map((displayItem) => {
                        content[displayItem.id] =
                            response.data.content[displayItem.id]
                    })
                }
                this.setState({
                    popupContent: response.data.content,
                    popupItems: response.data.items,
                    popupDossier: response.data.dossier,
                    loadingPopup: false,
                    isOnlySingleEmbeddedField:
                        response.data.items.length === 1 &&
                        response.data.items[0].def_dossier_link &&
                        response.data.items[0].def_dossier_link.type_of_link ===
                            'embeddedList'
                            ? true
                            : false,
                    isAllowedToEdit: this.props.kedo
                        .env()
                        .isAllowedDefDossier(
                            'edit',
                            this.props.item.def_dossier_id,
                            this.props.kedo.user()
                        ),
                })
            })
    }

    onChangeValue(itemId, value, item) {
        //Stub here
    }

    triggerEvent() {
        if (!this.props.item.settings.event_id || !this.props.dossier.id) {
            return
        }
        let eventId = this.props.item.settings.event_id
        let ddiId = this.props.item.id

        let eventUrl = this.props.kedo.api().getEventEndpoint(eventId)
        eventUrl = eventUrl + '/trigger/' + ddiId + '/' + this.props.dossier.id
        this.setState({ triggered: true })
        this.props.kedo
            .api()
            .patch(eventUrl)
            .then(() =>
                this.setState({ triggered: false }, () => {
                    if (this.props.onSuccess) {
                        this.props.onSuccess(() =>
                            this.setState({ triggered: false })
                        )
                        toast.success(
                            this.props.kedo.t('dossier_errors.event_send'),
                            this.toastOptions
                        )
                    }
                })
            )
    }

    fetchDossier() {
        //TODO
    }

    renderPopup() {
        return (
            <Modal
                size={'xl'}
                show={true}
                onHide={() =>
                    this.setState({
                        popupContent: null,
                        popupItems: [],
                        loadingPopup: false,
                        popupSubmitting: false,
                        showPopup: false,
                    })
                }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Edit')}:{' '}
                        {this.props.dossier && this.props.dossier.summary
                            ? this.props.dossier.summary
                            : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {this.state.loadingPopup ? (
                                <LoadingDefault />
                            ) : null}
                            {!this.state.loadingPopup &&
                            this.state.popupContent ? (
                                <div>
                                    {this.state.isAllowedToEdit &&
                                    !this.props.dossier.archived &&
                                    !this.state.isOnlySingleEmbeddedField
                                        ? this.renderEditButton()
                                        : null}
                                    <DefaultGrid
                                        changeValue={this.onChangeValue}
                                        defDossierSettings={
                                            this.state.defdossier &&
                                            this.state.defdossier.settings
                                                ? this.state.defdossier.settings
                                                : null
                                        }
                                        conditions={
                                            this.state.defdossier &&
                                            this.state.defdossier.settings &&
                                            this.state.defdossier.settings
                                                .conditions
                                                ? this.state.defdossier.settings
                                                      .conditions
                                                : []
                                        }
                                        content={this.state.popupContent}
                                        dossier={this.props.dossier}
                                        errors={this.state.errors}
                                        fetch={this.fetchDossier}
                                        embedded={this.props.embedded}
                                        hiddenfields={
                                            this.state.defdossier &&
                                            this.state.defdossier.settings &&
                                            this.state.defdossier.settings
                                                .hiddenfields
                                                ? this.state.defdossier.settings
                                                      .hiddenfields
                                                : []
                                        }
                                        items={this.state.popupItems.filter(
                                            (item) =>
                                                item.view === 'edit' ||
                                                item.view === 'show/edit' ||
                                                item.view === 'show'
                                        )}
                                        kedo={this.props.kedo}
                                        mode={
                                            this.state.popupItems.filter(
                                                (item) =>
                                                    item.def_dossier_link &&
                                                    item.def_dossier_link
                                                        .type_of_link ===
                                                        'embeddedList'
                                            ).length ===
                                            this.state.popupItems.length
                                                ? 'show'
                                                : 'edit'
                                        }
                                        ref={this.popupRef}
                                    />
                                    {this.state.isAllowedToEdit &&
                                    !this.props.dossier.archived &&
                                    !this.state.isOnlySingleEmbeddedField
                                        ? this.renderEditButton()
                                        : null}
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }

    renderEventView() {
        if (!this.props.dossier || this.props.dossier.length <= 0) {
            return ''
        }

        return (
            <Button
                disabled={this.state.triggered}
                size={'sm'}
                onClick={() => this.triggerEvent()}
                variant={'primary'}
            >
                <FontAwesomeIcon icon={faPlay} />{' '}
                {this.state.triggered
                    ? `${this.props.kedo.t('Loading')}...`
                    : this.props.kedo.translateItem(
                          this.props.item,
                          'displayitem'
                      )}
            </Button>
        )
    }

    renderPopupOrTabView() {
        if (!this.props.dossier || this.props.dossier.length <= 0) {
            return ''
        }

        return (
            <div>
                <Button
                    size={'sm'}
                    onClick={() =>
                        this.setState(
                            { showPopup: true, popupLoading: true },
                            this.fetchPopupContent
                        )
                    }
                    variant={'primary'}
                >
                    <FontAwesomeIcon icon={faPlay} />{' '}
                    {this.props.kedo.translateItem(
                        this.props.item,
                        'displayitem'
                    )}
                </Button>
                {this.state.showPopup ? this.renderPopup() : null}
            </div>
        )
    }

    renderKedocxButton() {
        if (!this.props.dossier || this.props.dossier.length <= 0) {
            return (
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {this.props.kedo.t('Save this item first')}
                </Alert>
            )
        }

        const kedo = this.props.kedo
        let baseUrl = kedo.api().getBaseUrl()
        let extra = '?contentDossierIds[]=' + this.props.dossier.id

        if (this.props.item.settings.kedocx_format) {
            extra =
                extra + '&outputType=' + this.props.item.settings.kedocx_format
        }

        return (
            <a
                target={'_blank'}
                className={'btn btn-secondary'}
                href={`${baseUrl}/kedocx/generate/${
                    this.props.item.settings.kedocx
                }/${this.props.kedo.env().getEnvironment().id}${extra}`}
                rel="noreferrer"
            >
                <FontAwesomeIcon icon={faFileExport} /> {kedo.t('Download')}
            </a>
        )
    }

    render() {
        if (!this.props.item.settings || !this.props.item.settings.action) {
            return null
        }

        const action = this.props.item.settings.action
        if (action === 'kedocx') {
            return this.renderKedocxButton()
        } else if (action === 'popup_tab_or_view') {
            return this.renderPopupOrTabView()
        } else if (action === 'fire_event') {
            return this.renderEventView()
        } else if (action === 'report') {
            return this.renderReportView()
        }

        return ''
    }

    renderReportView() {
        if (!this.props.dossier || this.props.dossier.length <= 0) {
            return (
                <Alert variant={'warning'}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {this.props.kedo.t('Save this item first')}
                </Alert>
            )
        }

        const kedo = this.props.kedo
        const api = kedo.api()

        let extra = '?contentDossierId=' + this.props.dossier.id

        if (this.props.item.settings.report_format) {
            extra =
                extra + '&outputType=' + this.props.item.settings.report_format
        }

        let data = {
            environment_id: kedo.env().getEnvironmentId(),
            payload: {
                format: this.props.item.settings?.report_format
                    ? this.props.item.settings.report_format
                    : 'pdf',
                contentDossierId: this.props.dossier.id,
            },
        }

        return (
            <Button
                onClick={() => {
                    api.post(
                        '/module/report/' +
                            this.props.item.settings.report +
                            '/request' +
                            extra,
                        data
                    ).then((response) => {
                        toast.success(
                            this.props.kedo.t('Report requested'),
                            this.toastOptions
                        )
                    })
                }}
                variant={'secondary'}
            >
                <FontAwesomeIcon icon={faChartLine} /> {kedo.t('Report')}
            </Button>
        )
    }
}

export default CustomActionButtonField
