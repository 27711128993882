import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

class DefDossierDefFieldCalcSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formula:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.formula
                    ? this.props.item.settings.formula
                    : null,
        }
    }

    render() {
        return (
            <div>
                <h5>{this.props.kedo.t('Additional settings')}</h5>
                <Form.Group controlId="formula">
                    <Form.Label>{this.props.kedo.t('Formula')}</Form.Label>
                    <Form.Control
                        value={this.state.formula}
                        name={'formula'}
                        onChange={(e) => {
                            this.setState({ formula: e.target.value })
                            setTimeout(
                                () =>
                                    this.props.onChange(
                                        'formula',
                                        e.target.value
                                    ),
                                50
                            )
                        }}
                        as="textarea"
                        rows={3}
                    />
                </Form.Group>
            </div>
        )
    }
}

export default DefDossierDefFieldCalcSettings
