import React from 'react'
import { Modal } from 'react-bootstrap'
import QueryBuilderEditor from '../../Admin/Pages/QueryBuilder/QueryBuilderEditor'

export default class DossierQueryModal extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'xl'} show={true} onHide={this.props.onClose}>
                <Modal.Header
                    closeButton
                    closeLabel={this.props.kedo.t('Close')}
                >
                    <Modal.Title>
                        {this.props.id > 0
                            ? kedo.t('Edit query')
                            : kedo.t('Add query')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QueryBuilderEditor
                        id={this.props.id}
                        onSuccess={this.props.onSuccess}
                        ddId={this.props.ddId}
                        kedo={kedo}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}
