class Title {
    constructor() {
        this._titleDefault = document.title
        this._title = []
    }

    set(title, withDefault = true) {
        if (document.title.includes(title)) {
            return
        }

        this._title[0] = document.title
        this._title[1] = withDefault
            ? `${title} | ${this._titleDefault}`
            : title

        document.title = this._title[1]
    }

    revert() {
        document.title = this._title[0]
    }

    reset() {
        document.title = this._titleDefault
    }
}

export default Title
