import React from 'react'
import { Link } from 'react-router-dom'
import {
    Alert,
    Badge,
    Button,
    Popover,
    Overlay,
    Modal,
    Col,
    Row,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope'
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faInbox } from '@fortawesome/pro-duotone-svg-icons/faInbox'

import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import DOMPurify from 'dompurify'

class MessagesPopOver extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props)
        this.state = {
            count: 0,

            currentPopUp: 0,
            newMessages: [],
            loaded: false,
            loading: false,
            msgInterval: false,
            popInterval: false,
            popupItems: [],
            showPopOver: false,
        }
        this.target = React.createRef()
        this.fetchCount = this.fetchCount.bind(this)
        this.fetchNewMessages = this.fetchNewMessages.bind(this)
        this.fetchPopups = this.fetchPopups.bind(this)
        this.onClickMessage = this.onClickMessage.bind(this)
        this.popupMessagesPagination = this.popupMessagesPagination.bind(this)
    }

    fetchPopups(isFirst) {
        if ((!isFirst && this.state.loading) || this._isMounted !== true) {
            return
        }

        let url =
            this.props.kedo.api().getMessageEndpoint() +
            '?type=popup&new=1&filter=incoming'
        this.setState({ loading: true })

        this.props.kedo
            .api()
            .get(url, {}, false)
            .then((response) =>
                this.setState({
                    loading: false,
                    popupItems: response.data.results,
                })
            )
    }

    fetchNewMessages() {
        if (this.state.loading || this._isMounted !== true) {
            return
        }

        let url =
            this.props.kedo.api().getMessageEndpoint() +
            '?limit=5&new=1&filter=incoming'
        this.setState({ loading: true })

        this.props.kedo
            .api()
            .get(url)
            .then((response) =>
                this.setState({
                    loading: false,
                    loaded: true,
                    newMessages: response.data.results,
                })
            )
    }

    fetchCount() {
        if (
            this._isMounted !== true ||
            true !== this.props.kedo.user().isLoggedIn()
        ) {
            return
        }

        let url = this.props.kedo.api().getMessageEndpoint() + '/unread/count'
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .get(url, {}, false)
            .then((response) => {
                this.setState({
                    count: response.data.total,
                    loading: false,
                })
            })
            .catch((error) =>
                this.setState({
                    count: 0,
                    loading: false,
                })
            )
    }

    shortenTitle(title) {
        let dotsNeeded = title.length >= 30
        let trimmedString = title.substr(0, 30)

        trimmedString = trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        )
        trimmedString = dotsNeeded ? trimmedString + '...' : title

        return trimmedString
    }

    shortenText(text) {
        let dotsNeeded = text.length >= 100
        let trimmedString = text.substr(0, 100)

        trimmedString = trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        )
        trimmedString = dotsNeeded ? trimmedString + '...' : text

        return trimmedString
    }

    onClickMessage() {
        this.setState({ showPopOver: false, count: this.state.count - 1 })
        this.toTop()
    }

    getItems() {
        return (
            <ul className={'notification'}>
                {this.state.newMessages.slice(0, 5).map((item) => (
                    <Link
                        to={`/messages/${item.id}`}
                        key={item.id}
                        onClick={this.onClickMessage}
                    >
                        <li
                            className={`${
                                item.read_date ? 'newNotification' : ''
                            }`}
                        >
                            <Badge variant="light">
                                {this.props.kedo.t('Sender')}:{' '}
                                {item.sender_name}
                            </Badge>
                            <p className={'messageTitle readMore'}>
                                {this.shortenTitle(item.title)}
                            </p>
                            <p
                                className={'messageText'}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        this.shortenText(item.message_text)
                                    ),
                                }}
                            />
                        </li>
                    </Link>
                ))}
                {this.state.newMessages.length <= 0 ? (
                    <li>{this.props.kedo.t('No unread messages')}</li>
                ) : null}
                {this.state.count > 5 ? (
                    <li>
                        <small className={'text-muted'}>
                            + {this.state.count - 5}{' '}
                            {this.props.kedo.t('remaining unread messages')}
                        </small>
                    </li>
                ) : null}
            </ul>
        )
    }

    setViewedPopupItem(item) {
        this.setState({ loadingPopup: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getMessageEndpoint() + '/' + item.id)
            .then((response) => {
                let popupItems = this.state.popupItems.filter(
                    (popItem) => item.id !== popItem.id
                )
                this.setState({
                    popupItems: popupItems,
                    loadingPopup: false,
                })
            })
    }

    renderPopupItems() {
        let firstItem = this.state.popupItems[0]

        return (
            <Modal size="lg" backdrop={'static'} keyboard={false} show={true}>
                <Modal.Header>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faEnvelope} />
                        &nbsp; {this.props.kedo.t(
                            'You have a message from'
                        )}{' '}
                        {firstItem.sender_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: '10px' }}>
                        <Link
                            to={`/user/${firstItem.sender_id}`}
                            onClick={() => this.setState({ popupItems: [] })}
                        >
                            <Badge variant="primary">
                                {this.props.kedo.t('Sender')}:{' '}
                                {firstItem.sender_name}
                            </Badge>
                        </Link>
                        <Badge variant={'secondary'} className={'float-right'}>
                            {this.props.kedo
                                .utils()
                                .dateFormat(firstItem.created_at)}
                        </Badge>{' '}
                        <br />
                    </div>
                    <h3>{firstItem.title}</h3>
                    <div
                        className={'messagePopOver'}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(firstItem.message_text),
                        }}
                    />
                    <br />
                    <div>
                        <Button
                            onClick={() => this.setViewedPopupItem(firstItem)}
                        >
                            {this.state.loadingPopup ? (
                                <LoadingDefault as={'span'} size={'sm'} />
                            ) : (
                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                            )}
                            &nbsp; {this.props.kedo.t('Mark as read')}
                        </Button>{' '}
                        &nbsp;
                        <Link to={'/messages'}>
                            <Button
                                onClick={() =>
                                    this.setState({ popupItems: [] })
                                }
                                variant={'secondary'}
                            >
                                <FontAwesomeIcon icon={faInbox} />
                                &nbsp; {this.props.kedo.t('Go to')}{' '}
                                {this.props.kedo.t('Inbox').toLowerCase()}
                            </Button>
                        </Link>
                    </div>
                </Modal.Body>
                {this.state.popupItems.length > 1 ? (
                    <Modal.Footer>
                        <Row>
                            <Col xs={12}>
                                {this.popupMessagesPagination()}
                                <div
                                    className={
                                        'd-flex justify-content-center text-muted'
                                    }
                                >
                                    {this.props.kedo.t('Message')} 1 /{' '}
                                    {this.state.popupItems.length}
                                </div>
                            </Col>
                        </Row>
                    </Modal.Footer>
                ) : null}
            </Modal>
        )
    }

    toTop() {
        window.scrollTo(0, 0)
    }

    popupMessagesPagination() {
        // TODO PAGINATION

        const pageNumbers = []

        for (let i = 1; i <= this.state.popupItems.length; i++) {
            pageNumbers.push(i)
        }

        if (pageNumbers.length == 1) {
            pageNumbers.pop()
        }

        return (
            <Row>
                <Col
                    xs={12}
                    className={'defaultPagination'}
                    style={{ textAlign: 'center', marginTop: '15px' }}
                >
                    {/*{current >= 1 ? <Button className={"transparantButton"} size="sm" onClick={() => {this.props.onChangePage(previousPage); this.toTop()}}><FontAwesomeIcon icon={faAngleLeft}/></Button> : null}*/}
                    {/*<Button size="sm" onClick={() => {this.props.onChangePage(firstPage); this.toTop()}} className={`${current === 0 ? 'page-item active' : 'page-item'}`}>1</Button>*/}
                    {/*{pageNumbers.length > 6 && current > 3 ? '...' : null}*/}

                    {/*{this.state.popupItems.map( i => {*/}
                    {pageNumbers.map((i) => {
                        // if (i > 1 && i < pageNumbers.length) {
                        //     if (pageNumbers.length > 5) {
                        //         if (i === current-1 || i === current || i === current+1 || i === current+2 || i === current+3) {
                        return (
                            <Button
                                size="sm"
                                value={i}
                                key={i}
                                onClick={() => {
                                    this.popupMessagesPaginationChange(i - 1)
                                    this.toTop()
                                }}
                            >
                                {i}
                            </Button>
                        )
                        // }
                        // } else {
                        //     return <Button size="sm" value={i} key={i} onClick={() => {this.props.onChangePage(i-1); this.toTop()}} className={`${current === (i-1) ? 'page-item active' : 'page-item'}`}>{i}</Button>
                        // }
                        // }
                    })}

                    {/*{pageNumbers.length > 6 && current < pageNumbers.length-2 && current < pageNumbers.length-3 && current < pageNumbers.length-4 ? '...' : null}*/}
                    {/*<Button size="sm" onClick={() => {this.props.onChangePage(lastPage); this.toTop()}} className={`${current === pageNumbers.length-1 ? 'page-item active' : 'page-item'}`}>{pageNumbers.length}</Button>*/}
                    {/*{current <= this.props.pager.elements-2 ? <Button className={"transparantButton"} size="sm" onClick={() => {this.props.onChangePage(nextPage); this.toTop()}}><FontAwesomeIcon icon={faAngleRight}/></Button> : null}*/}
                </Col>
            </Row>
        )
    }

    popupMessagesPaginationChange(pageNumber) {
        this.setState({ currentPopUp: pageNumber }, this.fetchPopups)
    }

    getNewMessagesPopOver() {
        return (
            <div>
                <Button
                    title={this.props.kedo.t('Messages')}
                    variant={'light'}
                    onClick={() => {
                        this.fetchNewMessages()
                        this.setState({ showPopOver: !this.state.showPopOver })
                    }}
                    ref={this.target}
                    className={'uppermenu-buttons'}
                >
                    <FontAwesomeIcon icon={faBell} />
                    {this.state.count <= 0 ? null : (
                        <span className="notification notification-danger">
                            {' '}
                            {this.state.count}{' '}
                        </span>
                    )}
                </Button>

                <Overlay
                    placement="bottom"
                    show={this.state.showPopOver}
                    target={this.target}
                    rootClose
                    onHide={() => this.setState({ showPopOver: false })}
                >
                    <Popover
                        id={'newmessage-popover'}
                        className={this.state.loading ? 'text-center' : ''}
                    >
                        <Popover.Content>
                            {this.state.loading ? (
                                <LoadingDefault />
                            ) : (
                                <div>
                                    {!this.state.newMessages ||
                                    this.state.newMessages.length <= 0 ? (
                                        <Alert
                                            variant={'light'}
                                            className={'text-center'}
                                        >
                                            {this.props.kedo.t(
                                                'No unread messages'
                                            )}
                                        </Alert>
                                    ) : (
                                        this.getItems()
                                    )}
                                    <Link to={'/messages'}>
                                        <Button
                                            block
                                            onClick={() => {
                                                this.setState({
                                                    showPopOver: false,
                                                })
                                                this.toTop()
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faInbox} />
                                            &nbsp; {this.props.kedo.t(
                                                'Go to'
                                            )}{' '}
                                            {this.props.kedo
                                                .t('Inbox')
                                                .toLowerCase()}
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        )
    }

    componentWillUnmount() {
        this._isMounted = false
        clearInterval(this.state.popInterval)
        clearInterval(this.state.msgInterval)
    }

    componentDidMount() {
        this._isMounted = true
        setTimeout(() => {
            this.fetchCount()
            this.fetchNewMessages(true)
            this.fetchPopups(true)
        }, 5000)

        let msgInterv = setInterval(this.fetchCount, 100000)
        let popInterv = setInterval(this.fetchPopups, 300000)
        this.setState({
            popInterval: popInterv,
            msgInterval: msgInterv,
        })
    }

    render() {
        return (
            <>
                {this.state.popupItems && this.state.popupItems.length > 0
                    ? this.renderPopupItems()
                    : null}
                {this.getNewMessagesPopOver()}
            </>
        )
    }
}

export default MessagesPopOver
