import React, { Component } from 'react'

import DefDossierDefFieldAmountSettings from './DefDossierDefFieldAmountSettings'
import DefDossierDefFieldCalcSettings from './DefDossierDefFieldCalcSettings'
import DefDossierDefFieldAutoIncrementSettings from './DefDossierDefFieldAutoIncrementSettings'

class DefDossierDefFieldSettings extends Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange(name, value) {
        this.props.onSettingsChange(name, value)
    }

    render() {
        if (
            !this.props.item ||
            !this.props.item.def_field ||
            !this.props.item.def_field.type
        ) {
            return ''
        }

        let compProps = {
            item: this.props.item,
            onChange: this.onChange,
            kedo: this.props.kedo,
        }

        if (this.props.item.def_field.type === 'amount') {
            return <DefDossierDefFieldAmountSettings {...compProps} />
        } else if (this.props.item.def_field.type === 'calc') {
            compProps = { ...compProps, defDossier: this.props.defDossier }
            return <DefDossierDefFieldCalcSettings {...compProps} />
        } else if (this.props.item.def_field.type === 'autoIncrement') {
            return <DefDossierDefFieldAutoIncrementSettings {...compProps} />
        }

        return ''
    }
}

export default DefDossierDefFieldSettings
