import { RefObject } from 'react'
import DisplayItemHelper from './DisplayItemHelper'

class EntityHelper {
    static checkCanSubmitDisplayPosition = (
        kedo,
        entity: Entity,
        displayPosition,
        displayPositionRef: RefObject<any>,
        values: any,
        ignoreWarning = false
    ) => {
        const mandatory = {}

        //Check if an item is obligatory or if it has conditions.
        DisplayItemHelper.filterViews(displayPosition.display_items, [
            DisplayItemHelper.EDIT_VIEW,
            DisplayItemHelper.SHOW_EDIT_VIEW,
        ]).map((item) => {
            if (
                (DisplayItemHelper.isObligatory(item) === true ||
                    EntityHelper.conditionMatches(
                        entity,
                        item,
                        displayPositionRef,
                        values
                    )) &&
                (!values[item.id] || values[item.id].length <= 0)
            ) {
                mandatory[item.id] = kedo.t('This is a required field')
            }
        })

        //TODO: Don't know what this does?
        if (ignoreWarning === true) {
            values['ignore_existing'] = true
        }

        return mandatory
    }

    static conditionMatches = (
        entity: Entity,
        item: IDisplayField,
        displayPositionref: RefObject<any>,
        values: Array<any>
    ): boolean => {
        let condResult = false
        if (entity && entity.settings && entity.settings.conditions) {
            entity.settings.conditions.map((condition) => {
                if (
                    displayPositionref.current.conditionMatches(
                        condition,
                        values
                    )
                ) {
                    if (
                        condition.actions
                            .filter(
                                (action) => action.action === 'require_fields'
                            )
                            .filter((action) =>
                                action.values.find(
                                    (actVal) => actVal === item.id
                                )
                            ).length > 0
                    ) {
                        condResult = true
                    }
                }
            })
        }

        return condResult
    }
}

export default EntityHelper
