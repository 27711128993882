import * as React from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'

import FieldSelect from './Components/FieldsSelect'

interface ViewDataMergeDossier {
    props: Array<any>
    kedo: any
    defDossierId: any
    closeModal: () => void
    idDossiers: number[]
}

const MergeDossier: React.FC<ViewDataMergeDossier> = ({
    props,
    kedo,
    defDossierId,
    closeModal,
    idDossiers,
}) => {
    const [modal, setModal] = useState<boolean>(true)
    const [selectedOption, setSelectedOption] = useState<string>('')
    const [next, setNext] = useState<boolean>(true)
    const [finish, setFinish] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState(false)

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setSelectedOption((prev) => (prev === value ? '' : value))
    }

    const confirmationModal = () => {
        if (selectedOption) {
            setNext(false)
            setShowAlert(false)
        } else {
            setShowAlert(true)
        }
    }

    const canDefDossierBeArchived = (ddId: any) => {
        const dds = kedo.env().getCurrentEnvironmentDefDossiers()
        if (dds.length <= 0) {
            return false
        }

        return (
            dds.filter(
                (item: { id: any; archiveable: boolean }) =>
                    item.id === ddId && item.archiveable === true
            ).length > 0
        )
    }

    const renderShow = () => {
        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modal}
                    onHide={() => setModal(false)}
                >
                    <Modal.Header>
                        <Modal.Title>{kedo.t('Merge dossiers')} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {next ? (
                            <div>
                                <p>
                                    {kedo.t(
                                        'A new dossier is created with values from the selected files.'
                                    )}
                                </p>
                                <p style={{ fontWeight: 'bold' }}>
                                    {kedo.t(
                                        'What needs to be done with the old dossiers?'
                                    )}
                                </p>
                                <Form.Check
                                    type="radio"
                                    id="keep"
                                    label={kedo.t('Keep')}
                                    value="keep"
                                    checked={selectedOption === 'keep'}
                                    onChange={handleOptionChange}
                                />
                                <Form.Check
                                    type="radio"
                                    id="archive"
                                    label={kedo.t('Archive')}
                                    value="archive"
                                    disabled={
                                        canDefDossierBeArchived(
                                            defDossierId
                                        ) !== true ||
                                        (kedo
                                            .env()
                                            .isAllowedDefDossier(
                                                'archive',
                                                defDossierId
                                            ) &&
                                            !kedo.isAdminOrEnvironmentAdmin())
                                    }
                                    checked={selectedOption === 'archive'}
                                    onChange={handleOptionChange}
                                />
                                <Form.Check
                                    type="radio"
                                    id="delete"
                                    disabled={
                                        defDossierId <= 0 ||
                                        (defDossierId > 0 &&
                                            !kedo
                                                .env()
                                                .isAllowedDefDossier(
                                                    'delete',
                                                    defDossierId
                                                ))
                                    }
                                    label={
                                        <>
                                            {kedo.t('Delete ')}
                                            <span style={{ color: 'red' }}>
                                                {kedo.t('(permanent!)')}
                                            </span>
                                        </>
                                    }
                                    value="delete"
                                    checked={selectedOption === 'delete'}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        ) : (
                            <FieldSelect
                                props={props}
                                dossiers={idDossiers}
                                option={selectedOption}
                                onFinish={finish}
                                closeModal={closeModal}
                            />
                        )}
                        {showAlert && (
                            <Alert variant={'info'} className="mt-2">
                                {kedo.t('You must select an option')}
                            </Alert>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {next ? (
                            <Button
                                variant="primary"
                                onClick={() => confirmationModal()}
                            >
                                {kedo.t('Next')}
                            </Button>
                        ) : null}
                        {!next ? (
                            <Button
                                variant="primary"
                                onClick={() => setFinish(true)}
                            >
                                {kedo.t('Finish')}
                            </Button>
                        ) : null}
                        <Button
                            className={'ml-2'}
                            variant="secondary"
                            onClick={() => {
                                setModal(false), closeModal()
                            }}
                        >
                            {kedo.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return renderShow()
}

export default MergeDossier
