import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { KeDoContext } from '../kedo-context'
class AzureConnect extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    static contextType = KeDoContext

    render() {
        const kedo = this.context

        let params = new URLSearchParams(this.props.location.search)

        if (params.has('username')) {
            kedo.user().updateInfoFromJWTToken({
                username: params.get('username'),
                iat: params.get('iat'),
                exp: params.get('exp'),
                user_id: parseInt(params.get('id')),
                roles: params.get('roles').split(','),
            })
            localStorage.setItem(
                'tokenData',
                JSON.stringify({
                    username: params.get('username'),
                    iat: params.get('iat'),
                    exp: params.get('exp'),
                    user_id: parseInt(params.get('id')),
                    roles: params.get('roles').split(','),
                })
            )

            if (
                localStorage.getItem('referer') &&
                localStorage.getItem('referer') !== '/' &&
                localStorage.getItem('referer') !== ''
            ) {
                let referer = localStorage.getItem('referer')
                localStorage.removeItem('referer')
                this.props.history.push(referer)
            } else {
                this.props.history.push('/environment')
            }
        } else {
            this.props.history.push('/')
        }

        return <Row className="d-flex justify-content-center"></Row>
    }
}
export default AzureConnect
