import React from 'react'
import {Spinner} from 'react-bootstrap'

class LoadingDefault extends React.Component {
    render() {
        return(
                <Spinner
                    as={this.props.as ? this.props.as : 'span'}
                    animation="border"
                    className={`loading`}
                    size={this.props.size ? this.props.size : "sm"}
                    style={{marginTop: "0"}}
                    role="status"
                    aria-hidden="true"
                >{this.props.message ? this.props.message : ''}</Spinner>
        )
    }
}

export default LoadingDefault