import React from 'react'
import {Form, FormControl} from 'react-bootstrap'
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';


class ImageCodeField extends React.Component {
    constructor(props) {
        super(props);

        this.innerRef = React.createRef();

        this.state = {
            value: this.props.value,
            imageType: this.getImageType(),
        };

        this.doFocus = this.doFocus.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1);
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getImageType() {
        if (!this.props.item
            || !this.props.item.def_dossier_def_field
            || !this.props.item.def_dossier_def_field.def_field
            || !this.props.item.def_dossier_def_field.def_field.settings
        ) {
            return null;
        }

        return this.props.item.def_dossier_def_field.def_field.settings.imageType;
    }

    getValue() {
        return this.state.value;
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    onChange(event) {
        this.setState({value: event.target.value});
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, event.target.value);
        }
    }

    renderShow() {
        if (!this.state.value) {
            return null;
        }

        switch(this.state.imageType) {
            case 'code39':
                return <div>
                        <Barcode format={'CODE39'} value={this.state.value} />,
                    </div>
            case 'qrcode':
                return <div>
                        <QRCode value={this.state.value} />
                    </div>
            default:
            case 'code128':
                return <div>
                    <Barcode format={'CODE128'} value={this.state.value} />,
                </div>
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.value !== prevProps.value) && this.props.mode === 'show') {
            this.setState({value: this.props.value});
        }
    }

    render() {
        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            if (this.state.value !== this.props.value) {
                this.setState({value: this.props.value});
            }
            return  <Form.Control
                        onChange={(event) => this.props.changeValue(this.props.item.id, event.target.value)}
                        onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event) : null}
                        type="text"
                        value={this.props.value}
                    />
        } else if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return  <div>
                        <FormControl
                            className={"displayItemField"}
                            isInvalid={this.isInvalid()}
                            name="code"
                            onChange={(event) => this.onChange(event)}
                            ref={this.innerRef}
                            type="text"
                            value={this.state.value}
                        />
                        {this.isInvalid() ? <div className="invalid-feedback">{this.props.errors}</div> : null}
                    </div>
        }

        return this.renderShow()
    }
}

export default ImageCodeField