import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DefFieldUserSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            multiple: this.props.item && this.props.item.settings && this.props.item.settings.multiple === true ? true : false,
            error: false,
        };
    }


    render() {
        const kedo = this.props.kedo;

        return  <div>
                    <h3>{kedo.t('Settings')}</h3>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Multiple')}</Form.Label>
                        <Form.Check
                            checked={this.state.multiple}
                            id={'radio-multiple-yes'}
                            label={kedo.t('Yes')}
                            onClick={() => {this.setState({multiple: true}); this.props.onChange('multiple', true)}}
                            type={"radio"}
                        />
                        <Form.Check
                            checked={!this.state.multiple}
                            id={'radio-multiple-no'}
                            label={kedo.t('No')}
                            onClick={() => {this.setState({multiple: false});this.props.onChange('multiple', false);}}
                            type={"radio"}
                        />
                    </Form.Group>
                </div>
    }
}

export default DefFieldUserSettings