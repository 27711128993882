import React, { Component } from 'react'
import { Alert, Col, Form, Button, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Api from '../Api'
import LoadingDefault from './Elements/Loading/LoadingDefault'
import { KeDoContext } from '../kedo-context'
import image1 from '../images/image1.jpg'
import image2 from '../images/image2.jpg'
import image3 from '../images/image3.jpg'
import image4 from '../images/image4.jpg'
import image5 from '../images/image5.jpg'

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            success: false,
            errors: false,
            bgStyle: null,
        }

        this.onLoginEnterKey = this.onLoginEnterKey.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    static contextType = KeDoContext

    onLoginEnterKey(event) {
        if (event.key === 'Enter') {
            this.handleSubmit(event)
        }
    }

    onSubmit(event) {
        event.preventDefault()
        let api = new Api()

        let formData = {
            username: event.target.username.value,
            email: event.target.email.value,
        }

        this.setState({ loading: true, errors: false })

        api.postRaw(api.getUserEndpoint() + '/forgot_password', formData)
            .then((response) => {
                this.setState({
                    loading: false,
                    success: true,
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    success: false,
                    errors: true,
                })
            })

        return false
    }

    componentDidMount() {
        const pictureArray = [image1, image2, image3, image4, image5]
        const randomIndex = Math.floor(Math.random() * pictureArray.length)
        const selectedPicture = pictureArray[randomIndex]

        this.setState({
            bgStyle: { backgroundImage: `url(${selectedPicture})` },
        })
    }

    render() {
        const kedo = this.context

        if (this.state.success) {
            return (
                <Col
                    xs={12}
                    style={this.state.bgStyle}
                    className={'login outerContainer fullHeight'}
                >
                    <Row className="d-flex justify-content-center">
                        <Col xs={10} md={8} lg={4} className="mainContainer">
                            <h1>{kedo.t('Check your mailbox')}</h1>
                            <p>{kedo.t('forgot_password_check')}</p>
                            <Link to={'/login'}>
                                <Button variant="primary">
                                    {kedo.t('Back to')}{' '}
                                    {kedo.t('Login').toLowerCase()}
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            )
        }

        return (
            <Col
                xs={12}
                style={this.state.bgStyle}
                className={'login outerContainer fullHeight'}
            >
                <Row className="d-flex justify-content-center">
                    <Col xs={10} md={8} lg={4} className="mainContainer">
                        <h1>{kedo.t('Forgot password')}</h1>
                        <p>{kedo.t('forgot_password_text')}</p>
                        <br />
                        <Form onSubmit={this.onSubmit}>
                            {this.state.errors ? (
                                <Alert variant={'danger'}>
                                    {kedo.t(
                                        'Incorrect username or email address'
                                    )}
                                </Alert>
                            ) : null}
                            <input
                                type="text"
                                name="username"
                                autoFocus
                                className="form-control"
                                placeholder={kedo.t('Username')}
                                onKeyPress={this.onLoginEnterKey}
                                readOnly={this.state.loading}
                                required
                            />
                            <br />
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder={kedo.t('E-mail')}
                                onKeyPress={this.onLoginEnterKey}
                                readOnly={this.state.loading}
                                required
                            />
                            <br />
                            <Button block variant="primary" type="submit">
                                {this.state.loading ? (
                                    <LoadingDefault size={'sm'} />
                                ) : null}
                                &nbsp; {kedo.t('Request a new password')}
                            </Button>
                            <Link className={'btn btn-tertiary'} to={'/login'}>
                                <small>
                                    {kedo.t('Back to')}{' '}
                                    {kedo.t('Login').toLowerCase()}
                                </small>
                            </Link>
                        </Form>
                    </Col>
                </Row>
            </Col>
        )
    }
}
export default ForgotPassword
