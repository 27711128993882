import React, { Component, Suspense } from 'react'
import LoadingDefault from '../Loading/LoadingDefault'
import Flag from '../../Elements/Flag.tsx'

export default class LocaleName extends Component {
    getFlag() {
        let locale = this.props.localeCode
        let flagCode = locale.substr(locale.length - 2)

        if (locale === 'nl_NL') {
            return (
                <span>
                    <Flag code={flagCode} height="12" className={'flagIcon'} />
                    &nbsp; {this.props.kedo.t('Dutch')}
                </span>
            )
        } else if (locale === 'en_GB') {
            return (
                <span>
                    <Flag code={flagCode} height="12" className={'flagIcon'} />
                    &nbsp; {this.props.kedo.t('en')}
                </span>
            )
        } else if (locale === 'fr_FR') {
            return (
                <span>
                    <Flag code={flagCode} height="12" className={'flagIcon'} />
                    &nbsp; {this.props.kedo.t('French')}
                </span>
            )
        } else if (locale === 'es_ES') {
            return (
                <span>
                    <Flag code={flagCode} height="12" className={'flagIcon'} />
                    &nbsp; {this.props.kedo.t('Spanish')}
                </span>
            )
        } else if (locale === 'es_CO') {
            return (
                <span>
                    <Flag code={flagCode} height="12" className={'flagIcon'} />
                    &nbsp; {this.props.kedo.t('Spanish')}
                </span>
            )
        }

        return locale
    }

    render() {
        return (
            <Suspense fallback={<LoadingDefault />}>{this.getFlag()}</Suspense>
        )
    }
}
