import * as React from 'react'
import Contextualizer from './Contextualizer'
import ProvidedServices from './ProvidedServices'

export interface IEntityService {
    getEntities(): Promise<Entity[]>
    getEntity(id: number): Promise<Entity>
    getDisplayPositions(entityId: number): Promise<IDisplayPositionList>
    getDisplayFields(entityId: number, params?: any): Promise<IDisplayItemList>
}

export interface IDisplayPositionList {
    pager: PagerInterface
    results: Array<IDisplayPosition>
}

export interface IDataFieldList {
    pager: PagerInterface
    results: DataFieldInterface[]
}

export interface IDisplayItemList {
    pager: PagerInterface
    results: IDisplayField[]
}

export interface IEntityList {
    pager: PagerInterface
    results: Entity[]
}

class EntityList implements IEntityList {
    private _pager: PagerInterface
    private _results: Entity[]

    get results(): Entity[] {
        return this._results
    }

    set results(value: Entity[]) {
        this._results = value
    }

    get pager(): PagerInterface {
        return this._pager
    }

    set pager(value: PagerInterface) {
        this._pager = value
    }
}

const EntityServiceContext = Contextualizer.createContext(
    ProvidedServices.EntityService
)

const EntityService = ({ kedo, children }) => {
    const entityService = {
        async getEntities(): Promise<EntityList> {
            const results = await kedo
                .api()
                .getCached(kedo.api().getDefDossierEndpoint())

            return results
        },
        async getEntity(id: number): Promise<Entity> {
            const result = await kedo
                .api()
                .getCached(kedo.api().getDefDossierEndpoint(id))

            return result.data
        },
        async getDisplayPositions(
            entityId: number
        ): Promise<IDisplayPositionList> {
            const results = await kedo
                .api()
                .getCached(
                    kedo.api().getDisplayPositionEndpoint() +
                        '?defDossier=' +
                        entityId.toString()
                )

            return {
                pager: results.data.pager,
                results: results.data.results,
            }
        },
        async getDisplayFields(
            entityId: number,
            params = null
        ): Promise<IDisplayItemList> {
            params.defDossier = entityId

            const results = await kedo
                .api()
                .getCached(kedo.api().getDisplayItemEndpoint(), {
                    params: params,
                })

            return {
                results: results.data.results,
                pager: results.data.pager,
            }
        },
        async getDataFields(
            entityId: number,
            params = null
        ): Promise<IDataFieldList> {
            params.defDossier = entityId

            const results = await kedo
                .api()
                .getCached(kedo.api().getDataFieldEndpoint(), {
                    params: params,
                })

            return {
                results: results.data.results,
                pager: results.data.pager,
            }
        },
    }

    return (
        <>
            <EntityServiceContext.Provider value={entityService}>
                {children}
            </EntityServiceContext.Provider>
        </>
    )
}

export default EntityService
export const useEntityService = () =>
    Contextualizer.use<IEntityService>(ProvidedServices.EntityService)
