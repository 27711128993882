import React, {Component} from 'react'
import {Form, FormControl, FormLabel} from "react-bootstrap";

class DefFieldDateSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_time_as_default: this.props.item && this.props.item.settings && this.props.item.settings.current_time_as_default ? this.props.item.settings.current_time_as_default : null,
            withtime: this.props.item && this.props.item.settings && this.props.item.settings.withtime ? this.props.item.settings.withtime : null,
            restriction: this.props.item && this.props.item.settings && this.props.item.settings.restriction ? this.props.item.settings.restriction : null,
            restrict_days: this.props.item && this.props.item.settings && this.props.item.settings.restrict_days && Array.isArray(this.props.item.settings.restrict_days) ? this.props.item.settings.restrict_days : [],
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeDay = this.onChangeDay.bind(this);
    }

    onChange(event) {
        if(event.target.getAttribute('type') === 'checkbox') {
            this.props.onChange(event.target.name, event.target.checked);
            this.setState({[event.target.name]: event.target.checked});
            return;
        }
        this.props.onChange(event.target.name, event.target.value);
        this.setState({[event.target.name]: event.target.value});
    }

    onChangeDay (e) {
        let days = this.state.restrict_days;
        days = days.filter(item => item !== e.target.value);
        if (e.target.checked) {
            days.push(e.target.value);
        }

        this.props.onChange(e.target.name, days);
        this.setState({restrict_days: days})
    }

    render() {
        const kedo = this.props.kedo;

        return  <div>
                    <h3>{this.props.kedo.t('Settings')}</h3>
                    <Form.Group>
                        <Form.Check
                            checked={this.state.withtime}
                            id={'checkbox-withtime'}
                            label={kedo.t('With time')}
                            name={'withtime'}
                            onChange={this.onChange}
                            type="checkbox"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            checked={this.state.current_time_as_default}
                            id={'checkbox-current_time_as_default'}
                            label={kedo.t('Use current date/time as default value')}
                            name={'current_time_as_default'}
                            onChange={this.onChange}
                            type="checkbox"
                        />
                    </Form.Group>
                    <hr/>
                    <Form.Group>
                        <FormLabel>{kedo.t('Restrict calendar selection')}</FormLabel>
                        <FormControl value={this.state.restriction} onChange={this.onChange} name={'restriction'} as={'select'}>
                            <option value={'none'}>{kedo.t('None')}</option>
                            <option value={'before_and_eq_current_date'}>{kedo.t('Before and equal to the current date')}</option>
                            <option value={'after_and_eq_current_date'}>{kedo.t('After and equal to the current date')}</option>
                        </FormControl>
                    </Form.Group>
                    <hr/>
                    <Form.Group>
                        <FormLabel>{kedo.t('Only allow selected days')}</FormLabel>
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("0") >= 0}
                            id={'checkbox-restrict_days_monday'}
                            label={kedo.t('days_full.Monday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={0}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("1") >= 0}
                            id={'checkbox-restrict_days_tuesday'}
                            label={kedo.t('days_full.Tuesday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={1}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("2") >= 0}
                            id={'checkbox-restrict_days_wednesday'}
                            label={kedo.t('days_full.Wednesday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={2}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("3") >= 0}
                            id={'checkbox-restrict_days_thursday'}
                            label={kedo.t('days_full.Thursday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={3}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("4") >= 0}
                            id={'checkbox-restrict_days_friday'}
                            label={kedo.t('days_full.Friday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={4}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("5") >= 0}
                            id={'checkbox-restrict_days_saturday'}
                            label={kedo.t('days_full.Saturday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={5}
                        />
                        <Form.Check
                            checked={this.state.restrict_days.indexOf("6") >= 0}
                            id={'checkbox-restrict_days_sunday'}
                            label={kedo.t('days_full.Sunday')}
                            name={'restrict_days'}
                            onChange={this.onChangeDay}
                            type="checkbox"
                            value={6}
                        />
                    </Form.Group>
                </div>
    }
}

export default DefFieldDateSettings