interface Item {
    name: string
    id: number
    content: any
}

interface Result {
    newSelectedCheckboxes: { [key: number]: number | number[] }
    newSelected: { [key: number]: Item }
}

export function processItems(
    items: { [key: number]: Item[] },
    selectedCheckboxes: { [key: number]: number | number[] },
    setDossierData: React.Dispatch<React.SetStateAction<any>>,
    setSelectedCheckboxes: React.Dispatch<React.SetStateAction<any>>
): Result | undefined {
    if (Object.keys(items).length === 0) return

    const itemValues = Object.values(items)
    const arrayLength = itemValues[0].length

    const newSelectedCheckboxes: { [key: number]: number | number[] } = {
        ...selectedCheckboxes,
    }
    const newSelected: { [key: number]: Item } = {}

    for (let rowIndex = 0; rowIndex < arrayLength; rowIndex++) {
        for (
            let dossierIndex = 0;
            dossierIndex < itemValues.length;
            dossierIndex++
        ) {
            const content = itemValues[dossierIndex][rowIndex]?.content

            if (
                (!Array.isArray(content) && content.length === 0) ||
                content === ''
            ) {
                const nextContent =
                    itemValues[dossierIndex + 1]?.[rowIndex]?.content
                const prevContent =
                    itemValues[dossierIndex - 1]?.[rowIndex]?.content

                if (
                    (dossierIndex === 0 &&
                        ((!Array.isArray(nextContent) &&
                            nextContent.length === 0) ||
                            nextContent === '')) ||
                    (dossierIndex === 1 &&
                        ((!Array.isArray(prevContent) &&
                            prevContent.length === 0) ||
                            prevContent === ''))
                ) {
                    continue
                }

                setDossierData((prevSelected: any) => {
                    const newSelected = { ...prevSelected }

                    if (dossierIndex === 0) {
                        newSelectedCheckboxes[rowIndex] = dossierIndex + 1

                        newSelected[rowIndex] =
                            itemValues[dossierIndex + 1][rowIndex]
                    } else if (dossierIndex === 1) {
                        newSelectedCheckboxes[rowIndex] = dossierIndex - 1
                        newSelected[rowIndex] =
                            itemValues[dossierIndex - 1][rowIndex]
                    }

                    if (newSelected[rowIndex].length >= 1) {
                        newSelected[rowIndex].id = [
                            itemValues[dossierIndex][rowIndex].id,
                        ]
                    }

                    return newSelected
                })
            }

            for (
                let compareIndex = 0;
                compareIndex < itemValues.length;
                compareIndex++
            ) {
                if (compareIndex !== dossierIndex) {
                    const compareContent =
                        itemValues[compareIndex][rowIndex]?.content

                    if (content === compareContent) {
                        if (dossierIndex === 0) {
                            newSelectedCheckboxes[rowIndex] = dossierIndex + 1
                        } else if (dossierIndex === 1) {
                            newSelectedCheckboxes[rowIndex] = dossierIndex - 1
                        }

                        setDossierData((prevSelected: any) => {
                            const newSelected = { ...prevSelected }

                            if (!newSelected[rowIndex]) {
                                newSelected[rowIndex] = []
                            }

                            newSelected[rowIndex] =
                                itemValues[dossierIndex][rowIndex]

                            if (newSelected[rowIndex].length >= 1) {
                                newSelected[rowIndex].id = [
                                    itemValues[dossierIndex][rowIndex].id,
                                ]
                            }

                            return newSelected
                        })
                    }

                    if (content?.[0]?.text && compareContent.length === 0) {
                        newSelectedCheckboxes[rowIndex] = [dossierIndex]
                    }

                    if (
                        content?.[0]?.text !== undefined &&
                        compareContent?.[0]?.text !== undefined &&
                        content[0].text === compareContent[0].text
                    ) {
                        newSelectedCheckboxes[rowIndex] = [dossierIndex]

                        setDossierData((prevSelected: any) => {
                            const newSelected = { ...prevSelected }

                            newSelected[rowIndex] = compareContent

                            if (newSelected[rowIndex].length >= 1) {
                                newSelected[rowIndex].id =
                                    itemValues[dossierIndex][rowIndex].id
                            }

                            return newSelected
                        })
                    }

                    if (content?.[0]?.translations) {
                        const translationTexts = content[0].translations.map(
                            (translation: { text: string }) => translation.text
                        )

                        if (
                            translationTexts[0] ===
                            compareContent?.[0]?.translations?.[0]?.text
                        ) {
                            newSelectedCheckboxes[rowIndex] = [dossierIndex]

                            setDossierData((prevSelected) => {
                                const newSelected = { ...prevSelected }

                                newSelected[rowIndex] = compareContent

                                if (newSelected[rowIndex].length >= 1) {
                                    newSelected[rowIndex].id =
                                        itemValues[dossierIndex][rowIndex].id
                                }

                                return newSelected
                            })
                        }
                    }
                }
            }
        }
    }

    setSelectedCheckboxes(newSelectedCheckboxes)

    return { newSelectedCheckboxes, newSelected }
}
