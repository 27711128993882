import * as React from 'react'
import { RefObject, useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle'
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare'

import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'
import DefaultGrid from '../../../Components/Grid/DefaultGrid.jsx'
import LoadingPage from '../../../Components/Elements/Loading/LoadingPage.jsx'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import EntityHelper from '../../../util/EntityHelper'
import {
    IDisplayPositionList,
    useEntityService,
} from '../../../context/EntityServiceContext'
import DisplayItemHelper from '../../../util/DisplayItemHelper'

const MultiUpdateContentDossier = ({ kedo, props }) => {
    const toastOptions = {
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    } as ToastOptions
    const params = useParams()
    const entityService = useEntityService()
    const [defDossier, setDefDossier] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showSaveSuccess, setShowSaveSuccess] = useState(false)
    const [denied_error, setDenied_error] = useState(false)
    const [ignoreWarning, setIgnoreWarning] = useState(false)
    const [embedded, setEmbedded] = useState(false)
    const [saved, setSaved] = useState(false)
    const [changed, setChanged] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [edit, setEdit] = useState(true)
    const [content, setContent] = useState({})
    const [mainPos, setMainPos] = useState({})
    const [errors, setErrors] = useState({})
    const [items, setItems] = useState([])
    const [id, setId] = useState(
        props.defDossierId ? props.defDossierId : params.id
    )
    const refs = useRef([])
    let curRef = useRef()

    const fetchDisplayPositions = async (defDossierId) => {
        const dpUrl =
            kedo.api().getDisplayPositionEndpoint() +
            '?defDossier=' +
            defDossierId
        return await kedo.api().get(dpUrl)
    }

    const fetchDisplayItems = async () => {
        const ddiParams = {
            params: {
                view: ['edit', 'show/edit'],
                defDossier: id,
                displayPosition: ['main_content'],
                sort: ['rank', 'col'],
                sortOrder: ['ASC', 'ASC'],
            },
        }

        setLoading(true)

        const ddPositions = (await entityService.getDisplayPositions(
            id
        )) as IDisplayPositionList

        const entity = (await entityService.getEntity(id)) as Entity

        const mainPos = ddPositions.results.find(
            (dpItem) => dpItem.type === 'main_content'
        )

        setDefDossier(entity)
        setMainPos(mainPos)

        const roleIds = kedo
            .env()
            .getCurrentEnvironmentRoles()
            .map((item) => item.id)

        setItems(
            DisplayItemHelper.filterViews(mainPos.display_items, [
                DisplayItemHelper.EDIT_VIEW,
                DisplayItemHelper.SHOW_EDIT_VIEW,
            ]).filter((item) => {
                if (
                    !item.permissions_type ||
                    kedo.isAdminOrEnvironmentAdmin()
                ) {
                    return true
                }

                if (item.permissions_type === 'INCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length > 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                } else if (item.permissions_type === 'EXCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length <= 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                }

                return true
            })
        )
        setLoading(false)
    }

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const onChangeValue = (itemId, value, item) => {
        //TODO KeDo: Check conditions here.
        //TODO KeDo: Check other modules here and maybe change data based on
    }

    const dossierMultiUpdate = () => {
        setSubmitting(true)

        const values = getCurrentRef().current.getValues()

        if (ignoreWarning === true) {
            values['ignore_existing'] = true
        }

        values.defDossier = id

        const submitErrors = EntityHelper.checkCanSubmitDisplayPosition(
            kedo,
            defDossier,
            mainPos,
            getCurrentRef(),
            values,
            ignoreWarning
        )

        if (Object.keys(submitErrors).length > 0) {
            setErrors(submitErrors)
            setSubmitting(false)
            const errorId = checkErrors(submitErrors)
            getCurrentRef().current.doFocus(errorId)
            return
        }

        const payload = {
            overwrite: props.overwrite,
            def_dossier_id: parseInt(values.defDossier),
            update_values: values,
            dossier_ids: props.selectedDossierIds,
        }

        kedo.api()
            .post(kedo.api().getMassUpdateEndpoint(), payload)
            .then((response) => {
                setRedirect(
                    props.location === 'Block' ? false : response.data.id
                )
                setEdit(false)
                setSubmitting(false)
                setErrors([])

                toast.success(
                    kedo.t('dossier_errors.success_create'),
                    toastOptions
                )
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    setErrors(error.response.data.errors)
                    setSubmitting(false)
                } else if (error.response && error.response.status === 403) {
                    setDenied_error(kedo.t('not_allowed_to_create_dossier'))
                    setSubmitting(false)

                    toast.error(
                        kedo.t('dossier_errors.permission_create'),
                        toastOptions
                    )
                    window.scrollTo(0, 0)
                } else {
                    toast.error(
                        kedo.t('dossier_errors.error_create'),
                        toastOptions
                    )
                    setErrors(error.response.data.errors)
                    setSubmitting(false)

                    const errorId = checkErrors(false)
                    getCurrentRef().current.doFocus(errorId)
                }
            })
    }

    const getAllValues = () => {
        const values = getCurrentRef().current.getValues()
        if (props.embedded && props.embedded !== true) {
            values['embedded'] = props.embedded
            values['linkId'] = props.linkId
        }

        return values
    }

    const preSubmitDossier = () => {
        dossierMultiUpdate()
    }

    const renderButtons = () => {
        return (
            <div className={'float-right'}>
                <Button
                    disabled={submitting ? true : false}
                    type="submit"
                    onClick={() => preSubmitDossier()}
                    title="Update"
                    variant="primary"
                >
                    {submitting ? (
                        <LoadingDefault size={'sm'} as={'span'} />
                    ) : (
                        <FontAwesomeIcon icon={faPenToSquare} />
                    )}
                    &nbsp; {kedo.t('Update')}
                </Button>{' '}
                &nbsp;
                <Button
                    variant="secondary"
                    title={kedo.t('Cancel')}
                    onClick={() => props.onClose()}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                    &nbsp; {kedo.t('Cancel')}
                </Button>
            </div>
        )
    }

    const renderSuccessAlert = () => {
        return (
            <Alert key={'success'} variant={'success'}>
                <FontAwesomeIcon icon={faCheckCircle} />
                &nbsp; {kedo.t('Dossier has been saved successfully')}
            </Alert>
        )
    }

    const checkErrors = (errors) => {
        const values = []
        const newItems = []
        items
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => newItems.push(item))

        newItems.map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: errors ? errors[filterItem.id] : errors[filterItem.id],
            })
        })

        const focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    useEffect(() => {
        fetchDisplayItems()
    }, [])

    if (redirect !== false) {
        if (props.embedded) {
            // this.props.onSuccess(this.state.redirect);
            return ''
        }
        return <Redirect to={`/contentdossier/${redirect}`} />
    }

    if (!defDossier || loading || id !== params.id) {
        return <LoadingPage />
    }

    return (
        <Row>
            <Col xs={12}>
                {denied_error !== false ? (
                    <Alert variant={'warning'}>{denied_error}</Alert>
                ) : null}
                {saved === true ? (
                    <Alert variant={'success'}>
                        {kedo.t('Dossier has been saved successfully')}
                    </Alert>
                ) : null}
                <div
                    className={
                        props.location === 'EmbeddedLinkField'
                            ? ''
                            : 'mainContainer'
                    }
                >
                    {showSaveSuccess === true ? renderSuccessAlert() : null}
                    {props.location === 'EmbeddedLinkField' ? null : (
                        <Row>
                            <Col xs={8}>
                                <h1>
                                    {props.multiAdd ? (
                                        <small>Multi add </small>
                                    ) : null}
                                    {kedo.t('New')}{' '}
                                    {kedo
                                        .env()
                                        .translateItem(
                                            defDossier,
                                            'defdossier'
                                        )}
                                </h1>
                            </Col>
                            {items.length ? (
                                <Col xs={4}>{renderButtons()}</Col>
                            ) : null}
                        </Row>
                    )}
                    <div>
                        {props.multiAdd ? (
                            <Alert variant={'info'}>
                                {kedo.t('multi_add_dossier_header')}
                            </Alert>
                        ) : null}
                        <DefaultGrid
                            changeValue={onChangeValue}
                            conditions={
                                defDossier &&
                                defDossier.settings &&
                                defDossier.settings.conditions
                                    ? defDossier.settings.conditions
                                    : []
                            }
                            content={content}
                            embedded={props.embedded}
                            linkId={props.linkId}
                            errors={errors}
                            hiddenfields={
                                defDossier &&
                                defDossier.settings &&
                                defDossier.settings.hiddenfields
                                    ? defDossier.settings.hiddenfields
                                    : []
                            }
                            items={items}
                            kedo={kedo}
                            mode={'edit'}
                            ref={getCurrentRef()}
                        />
                        <Row>
                            <Col xs={12}>{renderButtons()}</Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default MultiUpdateContentDossier
