import User from './User'

class EnvironmentUtil {
    constructor() {
        this.environment = null
    }

    getDefDossierById(id) {
        if (!id) {
            return null
        }

        return this.getCurrentEnvironmentDefDossiers().find(
            (item) => item.id === parseInt(id)
        )
    }

    /**
     * @returns {Array}
     */
    getCurrentEnvironmentRoles() {
        if (!this.hasEnviroment()) {
            return []
        }

        if (!this.getEnvironment().roles || !this.getEnvironment().roles.map) {
            return []
        }

        return this.getEnvironment().roles
    }

    /**
     * @returns {Array}
     */
    getCurrentEnvironmentModules() {
        if (!this.hasEnviroment()) {
            return []
        }

        return this.getEnvironment().env_modules
    }

    /**
     * @returns {Array}
     */
    getCurrentEnvironmentDefDossiers() {
        if (!this.hasEnviroment()) {
            return []
        }

        return this.getEnvironment().defdossiers
    }

    /**
     * @param {int} id
     * @param {Object} api
     * @param {Object} callBack
     */
    reloadEnvironment(id, api, callBack) {
        api.get(api.getEnvironmentEndpoint() + '/' + id).then((response) => {
            this.setEnvironment(response.data)
            if (callBack) {
                callBack()
            }
        })
    }

    clearInfo() {
        this.environment = null
    }

    clearMenuStatus() {
        this.menu = null
    }

    translateItem(item, itemType, plural = false) {
        let locale = this.getSelectedLocale()

        if (!locale) {
            return item.id
        }

        if (!item.translations || item.translations.length <= 0) {
            return item.id
        }
        let trans = null
        switch (itemType) {
            case 'dossierquery':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans && trans.dashboard_name) {
                    return trans.dashboard_name
                }
                return item.uuid
            case 'deffieldlist':
            case 'def_field_list':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans && trans.text) {
                    return trans.text
                }
                break
            case 'displayitem':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans && trans.name) {
                    return trans.name
                }
                break
            case 'defdossier':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans) {
                    return plural ? trans.name_plural : trans.name_singular
                }
                break
            case 'mypageblock':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans) {
                    return trans.title
                }
                break
            case 'webhook':
            case 'displayposition':
                trans = item.translations.find(
                    (trans) => trans.culture === locale.code
                )
                if (trans) {
                    return trans.name_plural
                }
                break
            default:
                break
        }

        return item.id
    }

    /**
     * @returns {number|null}
     */
    getEnvironmentId() {
        this.checkLocalStorage()

        if (!this.environment) {
            return null
        }

        return parseInt(this.environment.id)
    }

    getEnvironmentName() {
        this.checkLocalStorage()

        if (!this.environment) {
            return ''
        }

        return this.environment.name
    }

    /**
     * @returns {null|String}
     */
    getEnvironment() {
        this.checkLocalStorage()
        return this.environment
    }

    getSelectedLocale() {
        if (this.locale) {
            return this.locale
        }

        if (localStorage.getItem('locale')) {
            this.locale = JSON.parse(localStorage.getItem('locale'))
            return this.locale
        }

        let locales = this.getEnvironmentLocales()
        if (locales === null) {
            return null
        }

        if (locales.length >= 1) {
            for (let i = 0; i < locales.length; i++) {
                this.locale = locales[i]
            }

            return this.locale
        }

        return null
    }

    getEnvironmentLocales() {
        let environment = this.getEnvironment()
        if (
            !environment ||
            !environment.locales ||
            environment.locales.length <= 0
        ) {
            return null
        }

        return environment.locales
    }

    updateEnvironmentLocales(environment) {
        this.environment = environment
        localStorage.setItem('environment', JSON.stringify(environment))
    }

    checkLocalStorage() {
        if (this.environment != null) {
            return
        }

        if (
            localStorage.getItem('environment') === null ||
            localStorage.getItem('environment').length <= 0
        ) {
            return
        }

        this.environment = JSON.parse(localStorage.getItem('environment'))
    }

    checkMenuStatus() {
        if (this.menu != null) {
            return
        }

        if (
            localStorage.getItem('menu') === null ||
            localStorage.getItem('menu').length <= 0
        ) {
            return
        }

        this.menu = JSON.parse(localStorage.getItem('menu'))
    }

    setLocale(locale) {
        this.locale = locale
        localStorage.setItem('locale', JSON.stringify(locale))
    }

    getBrowserLanguage() {
        let userLang = navigator.language || navigator.userLanguage
        userLang = userLang.replace('-', '_')
        return userLang
    }

    /**
     * Update token
     * @param {Object} environment Object from the API response
     */
    setEnvironment(environment) {
        localStorage.setItem('environment', JSON.stringify(environment))

        this.autoSetLocale(environment)
        this.setMenuStatus()

        this.environment = environment
    }

    autoSetLocale(environment) {
        let browserLanguage = this.getBrowserLanguage()
        let environmentLocales = environment.locales

        let localeMatch = environmentLocales.find(
            (locale) => locale.code === browserLanguage
        )
        let dutch = environmentLocales.find((locale) => locale.code === 'nl_NL')
        let english = environmentLocales.find(
            (locale) => locale.code === 'en_GB'
        )

        if (localeMatch) {
            this.setLocale(localeMatch)
        } else if (environment.locales.length === 1) {
            this.setLocale(environment.locales[0])
        } else if (dutch) {
            this.setLocale(dutch)
        } else if (english) {
            this.setLocale(english)
        }
    }

    setMenuStatus() {
        localStorage.setItem('menu', JSON.stringify(true))
    }

    clearPendingTemplate() {
        if (!this.hasEnviroment()) {
            return false
        }
        this.environment.apply_template = null
    }

    getEnvironmentSetting(name, defaultVal) {
        if (!this.hasEnviroment()) {
            return defaultVal
        }

        let env = this.getEnvironment()

        if (!env.settings || typeof env.settings !== 'object') {
            return defaultVal
        }

        return env.settings[name]
    }

    /**
     * @returns {boolean}
     */
    hasTemplate() {
        if (!this.hasEnviroment()) {
            return false
        }

        if (
            this.environment.apply_template &&
            parseInt(this.environment.apply_template) > 0
        ) {
            return true
        }

        return false
    }

    /**
     * @returns {boolean}
     */
    hasEnviroment() {
        this.checkLocalStorage()

        return this.environment != null
    }

    /**
     * @returns {boolean}
     */
    getMenuStatus() {
        if (localStorage.environment) {
            this.checkMenuStatus()
        }
        return this.menu != null
    }

    /**
     * Check if the DefDossier has custom queries configured.
     *
     * @param credential
     * @param defDossierId
     * @param userUtil
     * @returns {boolean}
     */
    customCredsDefDossier(credential, defDossierId, userUtil) {
        if (!userUtil) {
            userUtil = new User()
        }

        let env = this.getEnvironment()

        if (!env || !env.defdossiers) {
            throw new Error('Dossier not found')
        }

        let defDosPerms = env.defdossiers.find(
            (item) => item.id === defDossierId
        )
        if (!defDosPerms || !defDosPerms.custom_credentials) {
            return false
        }

        return defDosPerms.custom_credentials.includes(credential)
    }

    /**
     *
     * @param {String} credential     Edit, show, create or delete
     * @param {Int}    defDossierId   The DefDossierId of the DefDossier
     * @param {Object} userUtil       The User object
     *
     * @returns {boolean}
     */
    isAllowedDefDossier(credential, defDossierId, userUtil) {
        if (this.isEnvironmentAdmin()) {
            return true
        }

        if (!userUtil) {
            userUtil = new User()
        }

        if (userUtil.isSuperAdmin()) {
            return true
        }

        let env = this.getEnvironment()

        if (!env || !env.defdossiers) {
            throw new Error('Dossier not found')
        }

        let defDosPerms = env.defdossiers.find(
            (item) => item.id === defDossierId
        )
        if (!defDosPerms) {
            return false
        }

        return defDosPerms.credentials.includes(credential)
    }

    saveCurrentPage(page) {
        localStorage.setItem('currentPage', JSON.stringify(page))
    }

    checkCurrentPage() {
        if (
            localStorage.getItem('currentPage') === null ||
            localStorage.getItem('currentPage').length <= 0
        ) {
            return
        }

        return JSON.parse(localStorage.getItem('currentPage'))
    }

    removeCurrentPage() {
        localStorage.removeItem('currentPage')
        this.currentPage = null
    }

    hasEntityCredentials(cred) {
        let entities = this.getCurrentEnvironmentDefDossiers()

        return entities.find((entityItem) =>
            entityItem.credentials.includes(cred)
        )
    }

    hasCredential(cred) {
        this.checkLocalStorage()

        if (this.environment === null || !this.environment.credentials) {
            return false
        }

        return this.environment.credentials.includes(cred)
    }

    hasModule(name) {
        let modules = this.getCurrentEnvironmentModules()
        if (!modules || !Array.isArray(modules)) {
            return false
        }

        return modules.find((item) => item.module === name) ? true : false
    }

    /**
     * @returns {boolean|*}
     */
    isEnvironmentAdmin() {
        return this.hasCredential('environment-admin')
    }
}

export default EnvironmentUtil
