import React, { Component } from 'react'
import {
    Alert,
    Button,
    Col,
    Form,
    Modal,
    Nav,
    Row,
    ProgressBar,
    Tab,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import DefFieldSettings from './DefFieldSettings/DefFieldSettings'

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt'
import { faClone } from '@fortawesome/pro-duotone-svg-icons/faClone'
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ObjectCreatedInfo from '../Elements/ObjectCreatedInfo'

class DefFieldForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            baseFields: [],
            dataFields: [],
            defDossier: this.props.defDossier,
            defFieldLists: [],
            defDossierUsedBy: [],
            errors: null,
            infoItem: null,
            item: this.props.item
                ? {
                      id: this.props.item.id,
                      name: this.props.item.name,
                      type: this.props.item.type,
                      settings: this.props.item.settings
                          ? this.props.item.settings
                          : {},
                      ranks: [],
                  }
                : {
                      settings: {},
                  },
            submitting: false,
            duplicate: false,
            defFieldsSubmitting: false,
            defFieldsUpdated: [],
            defFieldsDone: 0,
            keypressCheckbox: false,
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onSettingsChange = this.onSettingsChange.bind(this)
        this.onRankChange = this.onRankChange.bind(this)
        this.onChangeSettings = this.onChangeSettings.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.submitBaseField = this.submitBaseField.bind(this)
        this.getUsedBaseFields = this.getUsedBaseFields.bind(this)
        this.checkUsed = this.checkUsed.bind(this)
        this.duplicateFieldsModal = this.duplicateFieldsModal.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.updateDefFields = this.updateDefFields.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    handleSuccess() {
        this.setState({
            submitting: false,
            item: {},
            errors: false,
        })

        this.props.onClose(true)
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: this.props.kedo.t('Something went wrong'),
                },
            })
        }
        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    handleCheck(event) {
        let item = this.state.item
        item[event.target.name] = event.target.checked
        this.setState({ item: item })
    }

    onSettingsChange(name, value) {
        let item = this.state.item
        if (!item.settings) {
            item.settings = { [name]: value }
        }
        item.settings[name] = value
        this.setState({ item: item })
    }
    onRankChange(items) {
        let ranks = items.map((item) => {
            return {
                uuid: item.uuid,
                id: item.id,
                rank: item.rank,
            }
        })
        this.setState({ items_ranks: ranks })
    }
    onChangeSettings(event) {
        let item = this.state.item
        item.settings[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submitBaseField(duplicateItem = null) {
        const kedo = this.props.kedo

        if (!this.state.item.name || !this.state.item.type) {
            let errors = {}
            if (!this.state.item.name || this.state.item.name.length <= 0) {
                errors.name = kedo.t('This is a required field')
            }
            if (!this.state.item.type || this.state.item.type.length <= 0) {
                errors.type = kedo.t('This is a required field')
            }

            this.setState({ errors: errors })
            return
        }

        if (duplicateItem) {
            this.setState({
                submitting: true,
                errors: false,
            })
        } else {
            this.setState({
                submitting: duplicateItem ? false : true,
                errors: false,
            })
        }

        let item = {
            name: duplicateItem?.name || this.state.item.name,
            settings: duplicateItem?.settings || this.state.item.settings,
        }

        if (this.props.item.id && !this.state.duplicate) {
            kedo.api()
                .patch(
                    kedo.api().getDefFieldEndpoint() + '/' + this.props.item.id,
                    item
                )
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
            if (this.state.items_ranks) {
                this.state.items_ranks.forEach((item) => {
                    kedo.api().patch(
                        kedo.api().getDefFieldListEndpoint() + '/' + item.id,
                        {
                            rank: item.rank,
                        }
                    )
                })
            }
        } else {
            item.environment = kedo.env().getEnvironment().id
            item.type = this.state.item.type || duplicateItem?.type
            item.uuid = kedo.utils().uuidv4()

            return kedo
                .api()
                .post(kedo.api().getDefFieldEndpoint(), item)
                .then((successResponse) => {
                    this.handleSuccess()
                    if (duplicateItem) {
                        return successResponse.data
                    }
                })
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    getBaseFieldInfo() {
        const kedo = this.props.kedo

        kedo.api()
            .get(kedo.api().getDefFieldEndpoint(this.props.item.id))
            .then((response) => {
                this.setState({ infoItem: response.data })
            })
    }

    getUsedBaseFields() {
        const kedo = this.props.kedo
        let defDossiers = kedo.env().getCurrentEnvironmentDefDossiers()
        let defDossierDefFields = []

        const requests = defDossiers.map((item) => {
            return kedo
                .api()
                .get(kedo.api().getDefDossierDefFieldEndpoint(), {
                    params: {
                        defDossier: item.id,
                    },
                })
                .then((successResponse) =>
                    defDossierDefFields.push(...successResponse.data.results)
                )
        })

        Promise.all(requests).then(() => {
            let found = defDossierDefFields.filter(
                (item) => item?.def_field?.id == this.props.item.id
            )
            this.setState({ defDossierUsedBy: found })
        })
    }

    componentDidMount() {
        if (this.props?.item?.id) {
            this.getUsedBaseFields()
            this.getBaseFieldInfo()
            document.addEventListener('keyup', (e) => {
                if (e.key === 'Shift') {
                    this.setState({ keypressCheckbox: false })
                }
            })
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Shift') {
                    this.setState({ keypressCheckbox: true })
                }
            })
        }
    }

    checkUsed() {
        if (this.props.checkUsed && this.props.checkUsed())
            return this.props.checkUsed()

        if (this.state.defDossierUsedBy?.length > 0) return true

        return false
    }

    async updateDefFields() {
        const kedo = this.props.kedo

        const name = `${this.props?.item?.name.split('#')[0].trim()} #${
            this.props?.item?.name.includes('#')
                ? parseInt(this.props?.item?.name.split('#')[1]) + 1
                : 1
        }`
        const baseField = await this.submitBaseField({
            name: name,
            type: this.props?.item?.type,
            settings: this.props?.item?.settings,
        })

        if (
            this.props?.item?.type === 'list' &&
            this.props?.item?.def_field_lists.length > 0
        ) {
            const requests = this.props.item.def_field_lists.map((item) => {
                delete item.def_field_id
                delete item.id
                delete item.uuid

                return kedo.api().post(kedo.api().getDefFieldListEndpoint(), {
                    ...item,
                    defField: baseField.id,
                    environment: kedo.env().getEnvironmentId(),
                })
            })

            Promise.all(requests)
        }

        if (baseField !== null) {
            if (this.state.defFieldsUpdated?.length > 0) {
                this.setState({ defFieldsSubmitting: true })

                const requests = this.state.defFieldsUpdated.map((item) => {
                    delete item.def_field

                    return kedo
                        .api()
                        .put(
                            kedo.api().getDefDossierDefFieldEndpoint(item.id),
                            {
                                ...item,
                                defField: baseField.id,
                            }
                        )
                        .then((successResponse) =>
                            this.setState({
                                defFieldsDone: this.state.defFieldsDone + 1,
                            })
                        )
                })

                Promise.all(requests).then((result) => {
                    this.setState(
                        { defFieldsSubmitting: false, defFieldsSuccess: true },
                        () => setTimeout(() => this.handleSuccess(), 600)
                    )
                })
            } else {
                this.setState(
                    { defFieldsSubmitting: false, defFieldsSuccess: true },
                    () => setTimeout(() => this.handleSuccess(), 600)
                )
            }
        }
    }

    handleChecked = (e, item) => {
        if (e.type === 'mouseover' && !this.state.keypressCheckbox) return null

        let defFieldsUpdated = this.state.defFieldsUpdated

        defFieldsUpdated = defFieldsUpdated.filter(
            (field) => field.id !== item.id
        )

        if (e.type === 'mouseover') {
            e.target.checked = !e.target.checked
        }

        if (e.target.checked) {
            defFieldsUpdated.push(item)
        }

        this.setState({ defFieldsUpdated: defFieldsUpdated })
    }

    duplicateFieldsModal() {
        return (
            <Modal
                show={true}
                backdrop="static"
                onHide={() =>
                    this.setState({ duplicate: !this.state.duplicate })
                }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.kedo.t('Duplicate')}{' '}
                        {this.props.kedo.t('Base field').toLowerCase()}:{' '}
                        {this.props.item.id
                            ? this.state.item.name
                            : this.props.kedo.t('Unknown')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.defFieldsSuccess ? (
                        <Alert variant={'success'}>
                            {this.props.kedo.t('Item has been saved')}
                        </Alert>
                    ) : null}
                    {this.state.defDossierUsedBy?.length > 0 ? (
                        <>
                            <p>
                                {this.props.kedo.t('duplicate_transfer_fields')}
                            </p>
                            <Form.Group
                                className="unselectable"
                                style={{
                                    overflowY: 'scroll',
                                    maxHeight: '150px',
                                }}
                            >
                                {[...this.state.defDossierUsedBy]
                                    .sort((a, b) => {
                                        return a?.def_dossier_id ===
                                            this.props.defDossier
                                            ? -1
                                            : b?.def_dossier_id ===
                                              this.props.defDossier
                                            ? 1
                                            : a?.def_dossier_id >
                                              b?.def_dossier_id
                                            ? 1
                                            : -1
                                    })
                                    .map((item, index) => {
                                        let defDossier = this.props.kedo
                                            .env()
                                            .getDefDossierById(
                                                item?.def_dossier_id
                                            )
                                        return (
                                            <Form.Check
                                                key={index.toString()}
                                                id={index.toString()}
                                                onChange={(e) =>
                                                    this.handleChecked(e, item)
                                                }
                                                type="checkbox"
                                                onMouseOver={(e) =>
                                                    this.handleChecked(e, item)
                                                }
                                                label={
                                                    <>
                                                        {item?.name}{' '}
                                                        {this.props
                                                            .defDossier ===
                                                        item?.def_dossier_id ? (
                                                            <>
                                                                (
                                                                <b>
                                                                    {this.props.kedo.t(
                                                                        'Current'
                                                                    )}
                                                                </b>
                                                                )
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}{' '}
                                                        <OverlayTrigger
                                                            key={index.toString()}
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip
                                                                    id={`tooltip-${index}`}
                                                                >
                                                                    {this.props.kedo
                                                                        .env()
                                                                        .translateItem(
                                                                            defDossier,
                                                                            'defdossier'
                                                                        )}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faInfoCircle
                                                                }
                                                            />
                                                        </OverlayTrigger>
                                                    </>
                                                }
                                            />
                                        )
                                    })}
                            </Form.Group>

                            {this.state.defFieldsSubmitting ? (
                                <Form.Group>
                                    <ProgressBar
                                        min={0}
                                        max={
                                            this.state.defFieldsUpdated?.length
                                        }
                                        animated
                                        now={this.state.defFieldsDone}
                                    />
                                </Form.Group>
                            ) : null}
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.updateDefFields()}
                        variant="primary"
                    >
                        {this.state.defFieldsSubmitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Continue')}
                    </Button>
                    <Button
                        onClick={() =>
                            this.setState({ duplicate: !this.state.duplicate })
                        }
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <>
                {this.state.duplicate ? this.duplicateFieldsModal() : null}

                <Modal
                    size={'xl'}
                    show={true}
                    onHide={() => this.props.onClose()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {kedo.t(this.props.item.id ? 'Edit' : 'Add')}{' '}
                            {kedo.t('Base field').toLowerCase()}:{' '}
                            {this.props.item.id
                                ? this.state.item.name
                                : this.props.kedo.t('Unknown')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'pillsModalBody'}>
                        <Tab.Container defaultActiveKey="general">
                            <Row>
                                <Col sm={3} className={'pillsSidebar'}>
                                    <Nav
                                        variant="pills"
                                        className="flex-column"
                                    >
                                        <Nav.Item>
                                            <Nav.Link eventKey="general">
                                                {kedo.t('Generic')}
                                                {this.state.errors != null &&
                                                this.state.errors &&
                                                (this.state.errors.name ||
                                                    this.state.errors.type) ? (
                                                    <FontAwesomeIcon
                                                        className={`validationIcon validationFailed`}
                                                        icon={
                                                            faExclamationCircle
                                                        }
                                                    />
                                                ) : null}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="misc">
                                                {kedo.t('Misc')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="info">
                                                {kedo.t('Info')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9} className={'pillsContent'}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="general">
                                            {this.checkUsed() ? (
                                                <Alert variant={'warning'}>
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faExclamationCircle
                                                        }
                                                    />
                                                    &nbsp;{' '}
                                                    {this.props.kedo.t(
                                                        'used_field_alert'
                                                    )}
                                                </Alert>
                                            ) : null}
                                            <h3>{kedo.t('Generic')}</h3>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Name')}
                                                </Form.Label>
                                                <Form.Control
                                                    isInvalid={
                                                        this.state.errors !==
                                                            null &&
                                                        this.state.errors.name
                                                    }
                                                    value={this.state.item.name}
                                                    name={'name'}
                                                    onChange={this.handleChange}
                                                />
                                                {this.state.errors !== null &&
                                                this.state.errors.name ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.state.errors.name}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>
                                                    {kedo.t('Type')}
                                                </Form.Label>
                                                <Form.Control
                                                    isInvalid={
                                                        this.state.errors !==
                                                            null &&
                                                        this.state.errors.type
                                                    }
                                                    disabled={this.checkUsed()}
                                                    value={this.state.item.type}
                                                    as="select"
                                                    name={'type'}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value={''}>
                                                        {kedo.t(
                                                            'Choose an option'
                                                        )}
                                                    </option>
                                                    <optgroup
                                                        label={kedo.t('Text')}
                                                    >
                                                        <option value={'text'}>
                                                            {kedo.t('Text')}
                                                        </option>
                                                        <option
                                                            value={'textarea'}
                                                        >
                                                            {kedo.t(
                                                                'Text area'
                                                            )}
                                                        </option>
                                                        <option
                                                            value={'amount'}
                                                        >
                                                            {kedo.t('Amount')}
                                                        </option>
                                                        <option value={'url'}>
                                                            URL
                                                        </option>
                                                        <option value={'email'}>
                                                            {kedo.t('E-mail')}
                                                        </option>
                                                        <option
                                                            value={
                                                                'autoIncrement'
                                                            }
                                                        >
                                                            {kedo.t(
                                                                'Auto increment'
                                                            )}
                                                        </option>
                                                        <option value={'calc'}>
                                                            {kedo.t('Formula')}
                                                        </option>
                                                    </optgroup>
                                                    <optgroup
                                                        label={kedo.t('Other')}
                                                    >
                                                        <option
                                                            value={'imageCode'}
                                                        >
                                                            {kedo.t(
                                                                'Image code'
                                                            )}
                                                        </option>
                                                        <option value={'date'}>
                                                            {kedo.t('Date')}
                                                        </option>
                                                        <option value={'time'}>
                                                            {kedo.t('Time')}
                                                        </option>
                                                        <option value={'file'}>
                                                            {kedo.t('Files')}
                                                        </option>
                                                        <option
                                                            value={
                                                                'geolocation'
                                                            }
                                                        >
                                                            {kedo.t(
                                                                'Geolocation'
                                                            )}
                                                        </option>
                                                        <option value={'list'}>
                                                            {kedo.t('List')}
                                                        </option>
                                                        <option value={'user'}>
                                                            {kedo.t('Users')}
                                                        </option>
                                                        <option
                                                            value={
                                                                'customActionButton'
                                                            }
                                                        >
                                                            {kedo.t(
                                                                'Action button'
                                                            )}
                                                        </option>
                                                    </optgroup>
                                                </Form.Control>
                                                {this.state.errors !== null &&
                                                this.state.errors.type ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {this.state.errors.type}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="misc">
                                            <Form.Group>
                                                <DefFieldSettings
                                                    onSettingsChange={
                                                        this.onSettingsChange
                                                    }
                                                    onRankChange={
                                                        this.onRankChange
                                                    }
                                                    kedo={this.props.kedo}
                                                    item={this.state.item}
                                                />
                                            </Form.Group>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'info'}>
                                            <h3>{kedo.t('Info')}</h3>
                                            {this.state.infoItem ? (
                                                <ObjectCreatedInfo
                                                    kedo={kedo}
                                                    item={this.state.infoItem}
                                                />
                                            ) : null}
                                            {this.props?.item?.id ? (
                                                <>
                                                    <hr />
                                                    <Form.Label>
                                                        {kedo.t(
                                                            'Additional information'
                                                        )}
                                                    </Form.Label>
                                                    <div>
                                                        {kedo.t(
                                                            'usage_basefield'
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            overflowY: 'scroll',
                                                            maxHeight: '250px',
                                                        }}
                                                    >
                                                        <table
                                                            className={
                                                                'table table-striped'
                                                            }
                                                        >
                                                            <tbody>
                                                                <tr
                                                                    style={{
                                                                        position:
                                                                            'sticky',
                                                                        top: 0,
                                                                        width: '100%',
                                                                        backgroundColor:
                                                                            'rgba(243, 244, 247, 1)',
                                                                    }}
                                                                >
                                                                    <th>
                                                                        {kedo.t(
                                                                            'Entity'
                                                                        )}
                                                                    </th>
                                                                    <th>
                                                                        {kedo.t(
                                                                            'Data field'
                                                                        )}
                                                                    </th>
                                                                </tr>
                                                                {this.state
                                                                    .defDossierUsedBy
                                                                    ?.length > 0
                                                                    ? [
                                                                          ...this
                                                                              .state
                                                                              .defDossierUsedBy,
                                                                      ]
                                                                          .sort(
                                                                              (
                                                                                  a,
                                                                                  b
                                                                              ) => {
                                                                                  return a?.def_dossier_id ===
                                                                                      this
                                                                                          .props
                                                                                          .defDossier
                                                                                      ? -1
                                                                                      : b?.def_dossier_id ===
                                                                                        this
                                                                                            .props
                                                                                            .defDossier
                                                                                      ? 1
                                                                                      : a?.def_dossier_id >
                                                                                        b?.def_dossier_id
                                                                                      ? 1
                                                                                      : -1
                                                                              }
                                                                          )
                                                                          .map(
                                                                              (
                                                                                  item,
                                                                                  index
                                                                              ) => {
                                                                                  let defDossier =
                                                                                      kedo
                                                                                          .env()
                                                                                          .getDefDossierById(
                                                                                              item?.def_dossier_id
                                                                                          )
                                                                                  let html = `${kedo
                                                                                      .env()
                                                                                      .translateItem(
                                                                                          defDossier,
                                                                                          'defdossier'
                                                                                      )} ${
                                                                                      this
                                                                                          .props
                                                                                          .defDossier ===
                                                                                      item?.def_dossier_id
                                                                                          ? `(<b>${kedo.t(
                                                                                                'Current'
                                                                                            )}</b>)`
                                                                                          : ''
                                                                                  }`
                                                                                  return (
                                                                                      <tr
                                                                                          key={index.toString()}
                                                                                      >
                                                                                          <td
                                                                                              dangerouslySetInnerHTML={{
                                                                                                  __html: html,
                                                                                              }}
                                                                                          />
                                                                                          <td>
                                                                                              {
                                                                                                  item?.name
                                                                                              }
                                                                                          </td>
                                                                                      </tr>
                                                                                  )
                                                                              }
                                                                          )
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            ) : null}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.item.id ? (
                            <div className={'mr-auto'}>
                                <Button
                                    disabled={this.checkUsed()}
                                    onClick={() =>
                                        this.props.onDelete(this.props.item)
                                    }
                                    variant="danger"
                                    title={
                                        this.checkUsed()
                                            ? this.props.kedo.t(
                                                  'You cannot delete a placed field'
                                              )
                                            : this.props.kedo.t('Delete')
                                    }
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                    &nbsp; {this.props.kedo.t('Delete')}
                                </Button>
                                <Button
                                    className="ml-1"
                                    onClick={() =>
                                        this.setState({
                                            duplicate: !this.state.duplicate,
                                        })
                                    }
                                    variant="info"
                                >
                                    <FontAwesomeIcon icon={faClone} />
                                    &nbsp; {this.props.kedo.t('Duplicate')}
                                </Button>
                            </div>
                        ) : null}
                        <Button
                            onClick={this.submitBaseField}
                            variant="primary"
                        >
                            {this.state.submitting ? (
                                <LoadingDefault size={'sm'} as={'span'} />
                            ) : (
                                <FontAwesomeIcon icon={faSave} />
                            )}
                            &nbsp; {kedo.t('Save')}
                        </Button>
                        <Button
                            onClick={() => this.props.onClose()}
                            variant="secondary"
                        >
                            {kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default DefFieldForm
