import React from 'react'
import { ResponsiveFunnel } from '@nivo/funnel'
import BlockToolbar from './BlockToolbar'

export default class BlockFunnel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            loading: false,
        }
        this.parRef = React.createRef()
        this.renderData = this.renderData.bind(this)
    }

    getParentRef() {
        return this.parRef
    }

    fetchData() {
        this.setState({ loading: true })

        let data = [
            {
                id: 'step_qualification',
                value: 720,
                label: 'Qualification',
            },
            {
                id: 'step_meeting',
                value: 314,
                label: 'Meeting',
            },
            {
                id: 'step_proposal',
                value: 247,
                label: 'Proposal',
            },
            {
                id: 'step_closing',
                value: 206,
                label: 'Closing',
            },
        ]

        this.setState({
            loading: false,
            data: data,
        })
    }

    componentDidMount() {
        this.fetchData()
    }

    renderData() {
        if (!this.state.data) {
            return ''
        }

        const containerStyle = {
            minWidth: '300px',
            minHeight: '300px',
            height: '400px',
            //position: 'absolute'
        }

        return (
            <div style={containerStyle}>
                <ResponsiveFunnel
                    data={this.state.data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    colors={{ scheme: 'nivo' }}
                    labelColor="#ffffff"
                    borderWidth={5}
                    borderOpacity={0.25}
                    beforeSeparatorLength={100}
                    beforeSeparatorOffset={20}
                    afterSeparatorLength={100}
                    afterSeparatorOffset={20}
                    currentPartSizeExtension={10}
                    currentBorderWidth={40}
                    motionConfig="gentle"
                />
            </div>
        )
    }

    render() {
        let cssClassName = !this.props.dashboard ? 'kedo-block' : ''
        if (this.state.hidePrint) {
            cssClassName = cssClassName + ' d-print-none'
        } else {
            cssClassName = cssClassName + ' d-print-block'
        }

        return (
            <div
                className={cssClassName}
                style={{
                    height: this.props.container === true ? '300px' : '100%',
                    marginBottom: '30px',
                    minHeight: '300px',
                    minWidth: '300px',
                }}
                ref={this.parRef}
            >
                <div className="block-header">
                    <BlockToolbar
                        setFilterAndFetchData={this.setFilterAndFetchData}
                        onClose={this.props.onClose}
                        onSuccess={this.props.onSuccess}
                        block={this.props.block}
                        kedo={this.props.kedo}
                        fetchData={() => this.fetchData()}
                    />
                    {this.props.getBlockHeader
                        ? this.props.getBlockHeader(
                              this.props.block,
                              this.props.dossier
                          )
                        : null}
                </div>
                {this.renderData(this.state.data)}
            </div>
        )
    }
}
