import {
    Table,
    Button,
    ButtonGroup,
    Row,
    Col,
    ProgressBar,
    Alert,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight'

import moment from 'moment'
import React from 'react'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            day: 0,
            month: moment().toDate(),
            events: [],
            locations: [],
            monthPicker: false,
        }

        this.openDatePicker = this.openDatePicker.bind(this)
    }

    incrementDay() {
        this.setState(
            {
                day: this.state.day + 1,
            },
            () => this.updateDays()
        )
    }

    decrementDay() {
        this.setState(
            {
                day: this.state.day - 1,
            },
            () => this.updateDays()
        )
    }

    updateDays() {
        this.setState({
            today: moment(this.state.month).add(this.state.day, 'days'),
            tomorrow: moment(this.state.month).add(this.state.day + 1, 'days'),
            day_after_tomorrow: moment(this.state.month).add(
                this.state.day + 2,
                'days'
            ),
        })
    }

    fetchData() {
        if (!this.props.data) {
            return
        }

        return this.props.data.dossiers.map((dossier) => {
            let options = this.props.templateOptions
                ? this.props.templateOptions
                : null

            let event = this.props.data.contents.filter(
                (content) => content.id === dossier.id
            )
            let name =
                options && options.name
                    ? event.find((field) => field.content.id === options.name)
                    : null
            let location =
                options && options.location
                    ? event.find(
                          (field) => field.content.id === options.location
                      )
                    : null
            let start =
                options && options.start
                    ? event.find((field) => field.content.id === options.start)
                    : null
            let end =
                options && options.end
                    ? event.find((field) => field.content.id === options.end)
                    : null

            let events = this.state.events

            if (
                options &&
                options.name !== null &&
                options.location !== null &&
                options.start !== null &&
                options.end !== null
            ) {
                events.push({
                    id: dossier.id,
                    name: name.content.content,
                    start: start.content.content,
                    end: end.content.content,
                    location: location.content.content[0].summary,
                })

                let locations = this.state.locations

                if (
                    locations.find(
                        (loc) => loc.id === location.content.content[0].id
                    ) === undefined
                ) {
                    locations.push({
                        id: location.content.content[0].id,
                        name: location.content.content[0].summary,
                    })
                }

                this.setState({ locations: locations })
            }
        })
    }

    componentDidMount() {
        this.updateDays()
        this.fetchData()
    }

    checkDate(date, isSame) {
        var iscurrentDate = moment(date).isSame(isSame, 'day')
        if (iscurrentDate) {
            return true
        }
    }

    renderEvents(location) {
        let events = this.state.events.filter(
            (event) => event.location === location.name
        )

        let days = [
            this.state.today,
            this.state.tomorrow,
            this.state.day_after_tomorrow,
        ]

        return (
            <>
                {days.map((dayItem) => (
                    <td width="33%" key={`${dayItem}-${location.id}`}>
                        {events.map((item) => {
                            if (
                                moment(item.start).format('DD/MM/YYYY') ===
                                moment(dayItem).format('DD/MM/YYYY')
                            ) {
                                return (
                                    <Link
                                        key={`day-item-${item.id}`}
                                        to={`/contentdossier/${item.id}`}
                                    >
                                        <ProgressBar
                                            variant="success"
                                            now={100}
                                            label={item.name}
                                        />
                                    </Link>
                                )
                            }
                            if (
                                moment(item.end).format('DD/MM/YYYY') ===
                                moment(dayItem).format('DD/MM/YYYY')
                            ) {
                                return (
                                    <Link
                                        key={`day-item-${item.id}`}
                                        to={`/contentdossier/${item.id}`}
                                    >
                                        <ProgressBar
                                            variant="danger"
                                            className={'end'}
                                            now={100}
                                            label={item.name}
                                        />
                                    </Link>
                                )
                            }
                        })}
                    </td>
                ))}
            </>
        )
    }

    openDatePicker() {
        this.setState({
            monthPicker: !this.state.monthPicker,
        })
    }

    render() {
        let options = this.props.templateOptions
            ? this.props.templateOptions
            : null

        if (
            options === null ||
            options.name === null ||
            options.location === null ||
            options.start === null ||
            options.end === null
        ) {
            return (
                <Alert variant={'warning'}>
                    {this.props.kedo.t(
                        'Event module not properly setup. Please guide your contract admin.'
                    )}
                </Alert>
            )
        }

        if (this.state.loading) {
            return (
                <center>
                    <LoadingDefault />
                </center>
            )
        }

        return (
            <div id="eventcalendar">
                {this.state.monthPicker ? (
                    <div className={'custom-month'}>
                        {/* <DatePicker
            selected={moment(this.state.today).toDate()}
            onChange={(e) => {
              this.setState({
                month: e,
                day: 0
              }, () => {
                this.updateDays();
              });

              this.openDatePicker();
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onClickOutside={this.openDatePicker}
            open={this.state.monthPicker}
            customInput={<></>}
          /> */}
                    </div>
                ) : null}
                <Table striped bordered>
                    <thead>
                        <th>
                            <Row>
                                <Col
                                    className={'d-flex justify-content-center'}
                                >
                                    {moment(this.state.today)
                                        .startOf('day')
                                        .format('MMMM yyyy')}
                                </Col>
                                <Col>
                                    <ButtonGroup>
                                        <Button
                                            variant={'light'}
                                            onClick={() => this.decrementDay()}
                                            className={'rounded-circle'}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronLeft}
                                            />
                                        </Button>
                                        {/* <Button variant={'light'} onClick={() => this.openDatePicker()} className={'rounded-circle'}><FontAwesomeIcon icon={faCalendarAlt} /></Button> */}
                                        <Button
                                            variant={'light'}
                                            onClick={() => this.incrementDay()}
                                            className={'rounded-circle'}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </th>
                        <th width="33%">
                            <Row>
                                <Col
                                    className={
                                        'd-flex justify-content-center font-weight-normal'
                                    }
                                    md={12}
                                >
                                    {moment(this.state.today)
                                        .startOf('day')
                                        .format('ddd')}
                                </Col>
                                <Col
                                    className={'d-flex justify-content-center'}
                                >
                                    {moment(this.state.today)
                                        .startOf('day')
                                        .format('DD')}
                                </Col>
                            </Row>
                        </th>
                        <th width="33%">
                            <Row>
                                <Col
                                    className={
                                        'd-flex justify-content-center font-weight-normal'
                                    }
                                    md={12}
                                >
                                    {moment(this.state.tomorrow)
                                        .startOf('day')
                                        .format('ddd')}
                                </Col>
                                <Col
                                    className={'d-flex justify-content-center'}
                                >
                                    {moment(this.state.tomorrow)
                                        .startOf('day')
                                        .format('DD')}
                                </Col>
                            </Row>
                        </th>
                        <th width="33%">
                            <Row>
                                <Col
                                    className={
                                        'd-flex justify-content-center font-weight-normal'
                                    }
                                    md={12}
                                >
                                    {moment(this.state.day_after_tomorrow)
                                        .startOf('day')
                                        .format('ddd')}
                                </Col>
                                <Col
                                    className={'d-flex justify-content-center'}
                                >
                                    {moment(this.state.day_after_tomorrow)
                                        .startOf('day')
                                        .format('DD')}
                                </Col>
                            </Row>
                        </th>
                    </thead>
                    <tbody>
                        {this.state.locations.map((location) => {
                            return (
                                <tr key={location.id}>
                                    <td>
                                        <Link
                                            to={`/contentdossier/${location.id}`}
                                        >
                                            {location.name}
                                        </Link>
                                    </td>
                                    {this.renderEvents(location)}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
}
