import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import SelectList from '../../Elements/SelectList'

class DisplayItemBlockSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            block:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.block
                    ? this.props.item.settings.block
                    : null,
            block_data: null,
            block_link:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.block_link
                    ? this.props.item.settings.block_link
                    : null,
            blocks: [],
        }
    }

    fetchData() {
        const api = this.props.kedo.api()

        api.get(api.getMyPageBlockEndpoint(), {
            params: {
                defDossier: this.props.item.def_dossier_id,
                environment: this.props.kedo.env().getEnvironment().id,
            },
        }).then((response) => this.setState({ blocks: response.data.results }))
    }

    fetchBlock(id) {
        if (!id) {
            return
        }

        const api = this.props.kedo.api()
        api.get(api.getMyPageBlockEndpoint(id)).then((response) =>
            this.setState({ block_data: response.data })
        )
    }

    componentDidMount() {
        this.fetchData()
        this.fetchBlock(this.state.block)
    }

    render() {
        const kedo = this.props.kedo

        let defDossierId = this.state.block_data
            ? this.state.block_data.def_dossier_id
            : null

        return (
            <div>
                <Form.Group>
                    <Form.Label>{kedo.t('Block')}</Form.Label>
                    <Form.Control
                        name={'block'}
                        onChange={(e) => {
                            this.setState({ block: e.target.value })
                            this.fetchBlock(e.target.value)
                            this.props.onChange('block', e.target.value)
                        }}
                        value={this.state.block}
                        as={'select'}
                    >
                        <option value={''}>{kedo.t('Choose an option')}</option>
                        {this.state.blocks.map((block) => (
                            <option key={block.id} value={block.id}>
                                {kedo.env().translateItem(block, 'mypageblock')}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{kedo.t('Link')}</Form.Label>
                    {defDossierId ? (
                        <SelectList
                            currentValue={this.state.block_link}
                            disableInit={true}
                            searchName={'search'}
                            onClear={() => {
                                this.setState({ block_link: null })
                                this.props.onChange('block_link', null)
                            }}
                            kedo={kedo}
                            urlParams={{
                                environment: kedo.env().getEnvironment().id,
                                defDossier: defDossierId,
                            }}
                            onDisplayName={(item) => {
                                return item.id + ': ' + item.name
                            }}
                            url={kedo.api().getDefDossierLinkEndpoint()}
                            onSelect={(item) => {
                                this.setState({ block_link: item.id })
                                this.props.onChange('block_link', item.id)
                            }}
                        />
                    ) : null}
                </Form.Group>
            </div>
        )
    }
}

export default DisplayItemBlockSettings
