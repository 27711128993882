import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import Loading from '../../Elements/Loading/LoadingContent'
import styled from 'styled-components'
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import DOMPurify from 'dompurify'
import DossierModalEditButton from '../../Elements/DossierModalEditButton'
import DisplayItem from '../../DisplayItems/DisplayItem'
import Pagination from '../../Elements/Pagination'

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

class DefDossierTimeline extends Component {
    constructor(props) {
        super(props)
        let sortOptions = []
        if (this.props.ddiSort) {
            for (let i = 0; i < this.props.ddiSort.length; i++) {
                sortOptions.push({
                    order: this.props.ddiSort[i].sort,
                    item: {
                        def_dossier_def_field: {
                            id: parseInt(this.props.ddiSort[i].dddfId),
                        },
                    },
                })
            }
        }

        this.state = {
            displayItem: null,
            search: null,
            pager: null,
            page: 0,
            sort: sortOptions,
            showFilter: false,
            searchItems: [],
            hiddenColumns: [],
            loading: false,
            defDossier: null,
            items: [],
            customItems: [],
            refs: [],
        }

        this.onChangePage = this.onChangePage.bind(this)
    }

    fetchDefDossier() {
        let api = this.props.kedo.api()
        let params = {
            params: {
                defDossier: this.props.defDossierId,
                limit: 1,
            },
        }

        this.setState({ loading: true })
        api.getCached(
            api.getDefDossierEndpoint(this.props.defDossierId),
            params
        ).then((response) => {
            this.setState(
                {
                    defDossier: response.data,
                    timeline_field: response.data.settings
                        ? response.data.settings.timeline_field
                        : null,
                    timeline_desc_field: response.data.settings
                        ? response.data.settings.timeline_desc_field
                        : null,
                    timeline_custom_view: response.data.settings
                        ? response.data.settings.timeline_custom_view
                        : null,
                    loading: false,
                },
                this.fetchContent
            )
        })
    }

    getContentByView(content, displayItems, viewName) {
        //filter the display items
        let returnContent = {}

        let filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem, index) => {
                content[index]
                    ? (returnContent[displayItem.id] =
                          content[index].content.content)
                    : null
            })
        }

        return returnContent
    }

    onChangePage(number) {
        this.setState({ page: number }, this.fetchContent)
    }

    fetchContent() {
        let api = this.props.kedo.api()
        let dateId = this.state.timeline_field

        let params = {
            params: {
                defDossier: this.props.defDossierId,
                page: this.state.page,
            },
        }

        if (this.state.sort && this.state.sort.length > 0) {
            params.params.sort = []
            params.params.sortOrder = []
            this.state.sort.map((filterItem) => {
                params.params.sort.push(
                    filterItem.item.def_dossier_def_field
                        ? filterItem.item.def_dossier_def_field.id
                        : filterItem.item.settings.dossier_data
                )
                params.params.sortOrder.push(filterItem.order)
            })
        }

        if (this.state.timeline_custom_view) {
            params.params.view = this.state.timeline_custom_view
        }

        if (this.props.embedded) {
            params.params.embedded = this.props.embedded
            params.params.linkId = this.props.linkId
        }

        this.setState({ loading: true })

        api.get(api.getContentDossierEndpoint(), params).then((response) => {
            let items = []

            response.data.results.dossiers.map((dossier) => {
                let contents = response.data.results.contents.filter(
                    (item) => item.id === dossier.id
                )
                let dateValue = contents.find(
                    (item) =>
                        item.content.id === parseInt(this.state.timeline_field)
                )
                let descValue = contents.find(
                    (item) =>
                        item.content.id ===
                        parseInt(this.state.timeline_desc_field)
                )

                items.push({
                    key: dossier.id,
                    dossier: dossier,
                    content: this.state.timeline_custom_view
                        ? this.getContentByView(
                              response.data.results.contents.filter(
                                  (contentItem) => contentItem.id === dossier.id
                              ),
                              response.data.results.items,
                              this.state.timeline_custom_view
                          )
                        : null,
                    date:
                        dateValue && dateValue.content
                            ? this.props.kedo
                                  .utils()
                                  .dateFormat(dateValue.content.content)
                            : '',
                    descr:
                        descValue && descValue.content
                            ? descValue.content.content
                            : '',
                })
            })

            this.setState({
                loading: false,
                pager: response.data.pager,
                items: items,
                customItems: this.state.timeline_custom_view
                    ? response.data.results.items.filter(
                          (displayItem) =>
                              displayItem.view ===
                                  this.state.timeline_custom_view &&
                              displayItem.id !==
                                  parseInt(this.state.timeline_field) &&
                              displayItem.id !==
                                  parseInt(this.state.timeline_desc_field)
                      )
                    : null,
            })
        })
    }
    componentDidMount() {
        this.fetchDefDossier()
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        return (
            <Row>
                <Col>
                    {this.state.edit ? (
                        <DossierModalEditButton
                            onSuccess={() => this.setState({ edit: null })}
                            onClose={() => this.setState({ edit: null })}
                            key={this.state.edit}
                            edit={true}
                            dossierId={this.state.edit}
                            kedo={this.props.kedo}
                        />
                    ) : null}
                    <VerticalTimeline>
                        {this.state.items.map((item, index) => {
                            return (
                                <VerticalTimelineElement
                                    key={index}
                                    className="vertical-timeline-element--work"
                                    onTimelineElementClick={() =>
                                        this.setState({ edit: item.dossier.id })
                                    }
                                    contentStyle={{
                                        cursor: 'pointer',
                                        background: '#F2F9FA',
                                        color: '#000',
                                    }}
                                    contentArrowStyle={{
                                        borderRight: '7px solid  #8793AB',
                                    }}
                                    date={item.date}
                                    icon={<FontAwesomeIcon icon={faList} />}
                                >
                                    <h5 className="vertical-timeline-element-title">
                                        {item.dossier.summary}
                                    </h5>
                                    {item.descr ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    item.descr
                                                ),
                                            }}
                                        />
                                    ) : null}
                                    {this.state.customItems
                                        ? this.state.customItems.map(
                                              (listItem) => (
                                                  <>
                                                      <DisplayItem
                                                          kedo={this.props.kedo}
                                                          changeValue={() => {
                                                              //TODO
                                                          }}
                                                          content={
                                                              item.content
                                                                  ? item
                                                                        .content[
                                                                        listItem
                                                                            .id
                                                                    ]
                                                                  : null
                                                          }
                                                          dossier={item.dossier}
                                                          errors={null}
                                                          item={listItem}
                                                          key={listItem.id}
                                                          mode={item.view}
                                                      />{' '}
                                                      <br />
                                                  </>
                                              )
                                          )
                                        : null}
                                </VerticalTimelineElement>
                            )
                        })}
                    </VerticalTimeline>
                    {this.state.pager ? (
                        <Pagination
                            pager={this.state.pager}
                            onChangePage={this.onChangePage}
                        />
                    ) : null}
                </Col>
            </Row>
        )
    }
}

export default DefDossierTimeline
