import React from 'react'
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap'

import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DefaultSearchbar extends React.Component {
    /*** How to use:
        <DefaultSearchbar kedo={this.props.kedo} handleChange={this.handleChange} handleSearch={this.handleSearch} searchName={this.state.searchName} resetButton={this.resetButton}/>
     ***/

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <div className="input-group searchField">
                        <InputGroup className="mb-3">
                            <FormControl
                                className="form-control"
                                name={'searchName'}
                                autocomplete={'off'}
                                onChange={this.props.handleChange}
                                onKeyPress={this.props.handleSearch}
                                placeholder={
                                    this.props.kedo.t('Search') + '...'
                                }
                                type="text"
                                value={this.props.searchName}
                            />
                            <InputGroup.Append>
                                <span className="input-group-text">
                                    <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        onClick={this.props.handleSearch}
                                    />
                                </span>
                                {this.props.searchName &&
                                this.props.searchName.length > 0
                                    ? this.props.resetButton()
                                    : null}
                                {this.props.appendButton
                                    ? this.props.appendButton
                                    : null}
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default DefaultSearchbar
