import React from 'react'
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle'
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronLeft'

import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import DefaultGrid from '../../Grid/DefaultGrid'
import LoadingPage from '../../Elements/Loading/LoadingPage'
import { toast } from 'react-toastify'
import ShowMultiAdd from './NewDossier/ShowMultiAdd.tsx'
import Sidebar from '../../Elements/Sidebar/Sidebar.tsx'
import DetailContentDossier from '../../../feature/ContentDossier/Detail/ContentDossier.tsx'

class NewContentDossier extends React.Component {
    constructor(props) {
        super(props)
        this.dpMain = React.createRef()
        this.state = {
            changed: {},
            embedded: false,
            showExisting: false,
            ignoreWarning: false,
            content: this.props.content ? this.props.content : {},
            edit: true,
            errors: [],
            denied_error: false,
            id: this.props.defDossierId
                ? this.props.defDossierId
                : this.props.match.params.id,
            items: [],
            loading: false,
            redirect: false,
            toastOptions: {
                position: 'bottom-center',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },
            showModal: false,
            existingUser: null,
        }

        this.onChangeValue = this.onChangeValue.bind(this)
        this.getAllValues = this.getAllValues.bind(this)
        this.isValid = this.isValid.bind(this)
        this.submitDossier = this.submitDossier.bind(this)
        this.renderExistingDossier = this.renderExistingDossier.bind(this)
    }

    fetchDisplayItems() {
        const kedo = this.props.kedo
        let params = {
            params: {
                view: ['edit', 'show/edit'],
                defDossier: this.state.id,
                displayPosition: ['main_content'],
                sort: ['rank', 'col'],
                sortOrder: ['ASC', 'ASC'],
            },
        }

        this.setState({ loading: true })
        kedo.api()
            .getCached(kedo.api().getDefDossierEndpoint() + '/' + this.state.id)
            .then((ddResponse) => {
                kedo.api()
                    .getCached(kedo.api().getDisplayItemEndpoint(), params)
                    .then((response) => {
                        this.setState({
                            defDossier: ddResponse.data,
                            loading: false,
                            items: response.data.results,
                            pager: response.data.pager,
                        })
                    })
            })
    }

    findAddress(displayItem, changedDdiId) {
        const kedo = this.props.kedo

        let addressSettings =
            this.state.defDossier.settings.address_lookup_settings
        let values = this.dpMain.current.getValues()

        let streetDdiId = parseInt(addressSettings.street_ddi)
        let zipcodeDdiId = parseInt(addressSettings.zipcode_ddi)
        let housenrDdiId = parseInt(addressSettings.housenr_ddi)
        let housenrSufDdiId = parseInt(addressSettings.housenrsuf_ddi)
        let municipalityDdiId = parseInt(addressSettings.municipality_ddi)
        let longDdiId = parseInt(addressSettings.long_ddi)
        let latDdiId = parseInt(addressSettings.lat_ddi)

        //Don't do a lookup if the address is already known.
        if (values[streetDdiId] && values[streetDdiId].length > 0) {
            //return;
        }

        //Need more values to be able to
        if (
            (!values[zipcodeDdiId] || values[zipcodeDdiId].length <= 0) &&
            (!values[housenrDdiId] || values[housenrDdiId].length <= 0)
        ) {
            return
        }

        let params = {
            apikey: '843e29ec811a047e6aa877e521248a43',
            postcode: values[zipcodeDdiId],
            housenumber: values[housenrDdiId],
            suffix: values[housenrSufDdiId],
            districts: false,
            neighbourhoods: false,
        }

        kedo.api()
            .getFull(
                'https://bag.kedo.nu/byPostCode',
                {
                    disableSecurity: true,
                    params: params,
                },
                false
            )
            .then((lookupResult) => {
                if (
                    lookupResult.data.street &&
                    this.dpMain.current['ddiRef' + addressSettings.street_ddi]
                ) {
                    this.dpMain.current[
                        'ddiRef' + addressSettings.street_ddi
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.street
                    )
                }
                if (
                    lookupResult.data.city &&
                    this.dpMain.current['ddiRef' + addressSettings.city_ddi]
                ) {
                    this.dpMain.current[
                        'ddiRef' + addressSettings.city_ddi
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.city
                    )
                }
                if (
                    latDdiId &&
                    lookupResult.data.latitude &&
                    this.dpMain.current['ddiRef' + latDdiId]
                ) {
                    this.dpMain.current[
                        'ddiRef' + latDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.latitude
                    )
                    this.dpMain.current[
                        'ddiRef' + latDdiId
                    ].current.ddiRef.current.setVal(
                        'latitude',
                        lookupResult.data.latitude
                    )
                }
                if (
                    longDdiId &&
                    lookupResult.data.longitude &&
                    this.dpMain.current['ddiRef' + longDdiId]
                ) {
                    this.dpMain.current[
                        'ddiRef' + longDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.longitude
                    )
                    this.dpMain.current[
                        'ddiRef' + longDdiId
                    ].current.ddiRef.current.setVal(
                        'longitude',
                        lookupResult.data.longitude
                    )
                }
                if (
                    municipalityDdiId &&
                    lookupResult.data.council_name &&
                    this.dpMain.current['ddiRef' + municipalityDdiId]
                ) {
                    this.dpMain.current[
                        'ddiRef' + municipalityDdiId
                    ].current.ddiRef.current.setVal(
                        'value',
                        lookupResult.data.council_name
                    )
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {})
    }

    onChangeValue(itemId, value, item) {
        //BAG: Lookup addresses
        if (
            this.state.defDossier &&
            this.state.defDossier.settings &&
            this.state.defDossier.settings.address_lookup_settings &&
            this.state.defDossier.settings.address_lookup_settings
                .address_lookup === true
        ) {
            if (
                parseInt(
                    this.state.defDossier.settings.address_lookup_settings
                        .zipcode_ddi
                ) === itemId
            ) {
                this.findAddress(item, itemId)
            } else if (
                parseInt(
                    this.state.defDossier.settings.address_lookup_settings
                        .housenr_ddi
                ) === itemId
            ) {
                this.findAddress(item, itemId)
            }
        }

        //TODO KeDo: Check conditions here.
        //TODO KeDo: Check other modules here and maybe change data based on
    }

    getAllValues() {
        let values = this.dpMain.current.getValues()

        if (this.props.embedded && this.props.embedded !== true) {
            values['embedded'] = this.props.embedded
            values['linkId'] = this.props.linkId
        }

        if (this.state.ignoreWarning === true) {
            values['ignore_existing'] = true
        }

        values.defDossier = this.state.id

        return values
    }

    isValid(values) {
        let mandatory = {}
        this.state.items
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => {
                if (
                    this.isObligatory(item, values) &&
                    (!values[item.id] || values[item.id].length <= 0)
                ) {
                    mandatory[item.id] = 'This is a required field'
                }
            })

        if (!!Object.keys(mandatory).length > 0) {
            this.setState({
                errors: mandatory,
                submitting: false,
            })

            const errorId = this.checkErrors(mandatory)
            this.dpMain.current.doFocus(errorId)

            return false
        }

        return true
    }

    submitDossier(addContinue) {
        const kedo = this.props.kedo
        this.setState({ submitting: true })

        let values = this.getAllValues()

        if (!this.isValid(values)) {
            return
        }

        kedo.api()
            .post(kedo.api().getContentDossierEndpoint(), values)
            .then((response) => {
                if (addContinue) {
                    this.setState({
                        edit: false,
                        submitting: false,
                        errors: [],
                        content: {},
                        changed: {},
                        saved: true,
                    })
                    setTimeout(() => this.setState({ saved: false }), 5000)
                    setTimeout(() => this.fetchDisplayItems(), 500)
                    setTimeout(
                        () => this.props.onContinueSuccess(response.data.id),
                        500
                    )
                } else {
                    this.setState({
                        redirect:
                            this.props.location === 'Block'
                                ? false
                                : response.data.id,
                        edit: false,
                        submitting: false,
                        errors: [],
                    })
                }

                toast.success(
                    this.props.kedo.t('dossier_errors.success_create', {
                        defdossier: kedo
                            .env()
                            .translateItem(this.state.defDossier, 'defdossier'),
                    }),
                    this.state.toastOptions
                )

                if (
                    this.props.location === 'Block' ||
                    (this.props.location === 'EmbeddedLinkField' &&
                        !addContinue)
                ) {
                    this.props.onSuccess(response.data.id)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    this.setState({
                        errors: error.response.data.errors,
                        submitting: false,
                    })
                    if (
                        error.response.data.existing &&
                        error.response.data.existing.length > 0
                    ) {
                        this.setState({
                            showExisting: error.response.data.existing,
                            existingConstraintType:
                                error.response.data.constraint_type,
                        })
                    }
                } else if (error.response && error.response.status === 403) {
                    this.setState({
                        denied_error: this.props.kedo.t(
                            'not_allowed_to_create_dossier'
                        ),
                        submitting: false,
                    })

                    toast.error(
                        this.props.kedo.t('dossier_errors.permission_create'),
                        this.state.toastOptions
                    )
                    window.scrollTo(0, 0)
                } else {
                    toast.error(
                        this.props.kedo.t('dossier_errors.error_create'),
                        this.state.toastOptions
                    )
                    this.setState({
                        errors: error.response.data.errors,
                        submitting: false,
                    })

                    const errorId = this.checkErrors()
                    this.dpMain.current.doFocus(errorId)
                }
            })
    }

    preSubmitDossier() {
        if (this.props.multiAdd) {
            this.setState({
                showMultiAddModal: this.isValid(this.getAllValues()),
            })
            return
        }

        this.submitDossier(false)
    }

    renderButtons() {
        let addContinue =
            this.state.defDossier &&
            this.state.defDossier.settings &&
            this.state.defDossier.settings.add_continue === true
                ? true
                : false
        return (
            <div className={'float-right'}>
                <Button
                    disabled={this.state.submitting ? true : false}
                    type="submit"
                    onClick={() => this.preSubmitDossier()}
                    title={this.props.kedo.t('Create')}
                    variant="primary"
                >
                    {this.state.submitting ? (
                        <LoadingDefault size={'sm'} as={'span'} />
                    ) : (
                        <FontAwesomeIcon icon={faSave} />
                    )}
                    &nbsp; {this.props.kedo.t('Add')}
                </Button>{' '}
                &nbsp;
                {addContinue ? (
                    <Button
                        disabled={this.state.submitting ? true : false}
                        type="submit"
                        onClick={() => this.submitDossier(true)}
                        title={this.props.kedo.t('Create')}
                        variant="primary"
                    >
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Add and continue')}
                    </Button>
                ) : null}
                {this.props.location === 'Block' ||
                this.props.location === 'EmbeddedLinkField' ? (
                    <Button
                        variant="secondary"
                        title={this.props.kedo.t('Cancel')}
                        onClick={() => this.props.onClose()}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        &nbsp; {this.props.kedo.t('Cancel')}
                    </Button>
                ) : (
                    <Button
                        variant="secondary"
                        title={this.props.kedo.t('Cancel')}
                        onClick={() => this.props.history.goBack()}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        &nbsp; {this.props.kedo.t('Cancel')}
                    </Button>
                )}
            </div>
        )
    }

    isObligatory(item, values) {
        if (
            item.def_dossier_def_field &&
            item.def_dossier_def_field.obligatory &&
            item.def_dossier_def_field.obligatory === true
        ) {
            return true
        }

        if (
            item.def_dossier_link &&
            item.def_dossier_link.obligatory === true
        ) {
            return true
        }

        if (
            this.state.defDossier &&
            this.state.defDossier.settings &&
            this.state.defDossier.settings.conditions
        ) {
            let condResult = false
            this.state.defDossier.settings.conditions.map((condition) => {
                if (this.dpMain.current.conditionMatches(condition, values)) {
                    if (
                        condition.actions
                            .filter(
                                (action) => action.action === 'require_fields'
                            )
                            .filter((action) =>
                                action.values.find(
                                    (actVal) => actVal === item.id
                                )
                            ).length > 0
                    ) {
                        condResult = true
                    }
                }
            })

            return condResult
        }

        return false
    }
    renderSuccessAlert() {
        return (
            <Alert key={'success'} variant={'success'}>
                <FontAwesomeIcon icon={faCheckCircle} />
                &nbsp;{' '}
                {this.props.kedo.t('Dossier has been saved successfully')}
            </Alert>
        )
    }

    checkErrors(errors) {
        let values = []
        let items = this.state.items.filter(
            (item) => item.view === 'edit' || item.view === 'show/edit'
        )

        items.map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: errors
                    ? errors[filterItem.id]
                    : this.state.errors[filterItem.id],
            })
        })

        let focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            this.state.items.length !== nextState.items ||
            this.state.submitting !== nextState.submitting
        ) {
            return true
        }

        if (this.state.errors.length !== nextState.errors.length) {
            return true
        }
    }

    componentDidMount() {
        this.fetchDisplayItems()
    }

    renderExistingDossier() {
        const kedo = this.props.kedo
        return (
            <Modal
                centered
                show={true}
                onHide={() => {
                    this.setState({ showModal: false })
                }}
                size={'lq'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.existingUser.summary}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetailContentDossier
                        props={{
                            existingUser: true,
                            modalized: true,
                            dossierId: this.state.existingUser.id,
                            disableBreadcrumb: true,
                        }}
                        kedo={this.props.kedo}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {this.state.existingConstraintType === 'warning' ? (
                        <Button
                            onClick={() => {
                                this.setState({ ignoreWarning: true })
                                setTimeout(
                                    () =>
                                        this.props.onSuccess(
                                            this.state.existingUser.id
                                        ),
                                    50
                                )
                            }}
                        >
                            {kedo.t('Use this')}
                        </Button>
                    ) : null}
                    <Button
                        onClick={() => this.setState({ showModal: false })}
                        variant={'secondary'}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderExisting() {
        const kedo = this.props.kedo

        if (this.state.showModal !== false) {
            return this.renderExistingDossier()
        }

        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                    onHide={() => this.setState({ showExisting: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {kedo.t('Already existing items')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{kedo.t('existing_items_description')}</p>
                        <ul className={'list-group'}>
                            {this.state.showExisting.map((existingItem) => (
                                <li
                                    key={existingItem.id}
                                    className={
                                        'list-group-item list-group-item-action'
                                    }
                                    onClick={() => {
                                        this.setState({
                                            showModal: true,
                                            existingUser: existingItem,
                                        })
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {existingItem.summary
                                        ? existingItem.summary
                                        : existingItem.id}
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.existingConstraintType === 'warning' ? (
                            <Button
                                onClick={() => {
                                    this.setState({ ignoreWarning: true })
                                    setTimeout(() => this.submitDossier(), 50)
                                }}
                            >
                                {kedo.t('Ignore warning and save')}
                            </Button>
                        ) : null}
                        <Button
                            onClick={() =>
                                this.setState({ showExisting: false })
                            }
                            variant={'secondary'}
                        >
                            {kedo.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderMultiAdd() {
        //TODO: Add check
        return (
            <ShowMultiAdd
                kedo={this.props.kedo}
                displayItems={this.state.items}
                handleGetValues={this.getAllValues}
                handleSuccess={this.props.onSuccess}
                handleClose={() => this.setState({ showMultiAddModal: false })}
                dossierData={this.dpMain.current.getValues()}
                defDossier={this.state.defDossier}
            />
        )
    }

    render() {
        const kedo = this.props.kedo
        if (this.state.redirect !== false) {
            if (this.props.embedded) {
                // this.props.onSuccess(this.state.redirect);
                return ''
            }
            return <Redirect to={`/contentdossier/${this.state.redirect}`} />
        }

        if (
            !this.state.defDossier ||
            this.state.loading ||
            (this.props.match && this.state.id !== this.props.match.params.id)
        ) {
            return <LoadingPage />
        }

        return (
            <Row>
                {this.props.leftSideBar && window.innerWidth > 576 ? (
                    <Col style={{ borderRight: '1px solid #dee2e6' }} xs={4}>
                        <Sidebar
                            kedo={kedo}
                            defDossier={this.state.defDossier}
                            embeddedDefDossierId={
                                this.props.embeddedDefDossierId
                            }
                            dossierId={this.props.embedded}
                            leftSideBar={this.props.leftSideBar}
                        />
                    </Col>
                ) : null}
                <Col
                    xs={
                        this.props.leftSideBar && window.innerWidth > 576
                            ? 8
                            : 12
                    }
                >
                    {this.state.showMultiAddModal
                        ? this.renderMultiAdd()
                        : null}
                    {this.state.showExisting !== false
                        ? this.renderExisting()
                        : null}
                    {this.state.denied_error !== false ? (
                        <Alert variant={'warning'}>
                            {this.state.denied_error}
                        </Alert>
                    ) : null}
                    {this.state.saved === true ? (
                        <Alert variant={'success'}>
                            {kedo.t('Dossier has been saved successfully')}
                        </Alert>
                    ) : null}
                    <div
                        className={
                            this.props.location === 'EmbeddedLinkField'
                                ? ''
                                : 'mainContainer'
                        }
                    >
                        {this.state.showSaveSuccess === true
                            ? this.renderSuccessAlert()
                            : null}
                        {this.props.location === 'EmbeddedLinkField' ? null : (
                            <Row>
                                <Col xs={8}>
                                    <h1>
                                        {this.props.multiAdd ? (
                                            <small>{kedo.t('Multi add')}</small>
                                        ) : null}
                                        {kedo.t('New')}{' '}
                                        {kedo
                                            .env()
                                            .translateItem(
                                                this.state.defDossier,
                                                'defdossier'
                                            )}
                                    </h1>
                                </Col>
                                {this.state.items.length ? (
                                    <Col xs={4}>{this.renderButtons()}</Col>
                                ) : null}
                            </Row>
                        )}
                        <div>
                            {this.props.multiAdd ? (
                                <Alert variant={'info'}>
                                    {kedo.t('multi_add_dossier_header')}
                                </Alert>
                            ) : null}
                            <DefaultGrid
                                changeValue={this.onChangeValue}
                                conditions={
                                    this.state.defDossier &&
                                    this.state.defDossier.settings &&
                                    this.state.defDossier.settings.conditions
                                        ? this.state.defDossier.settings
                                              .conditions
                                        : []
                                }
                                content={this.state.content}
                                embedded={this.props.embedded}
                                linkId={this.props.linkId}
                                errors={this.state.errors}
                                hiddenfields={
                                    this.state.defDossier &&
                                    this.state.defDossier.settings &&
                                    this.state.defDossier.settings.hiddenfields
                                        ? this.state.defDossier.settings
                                              .hiddenfields
                                        : []
                                }
                                items={this.state.items}
                                kedo={this.props.kedo}
                                mode={'edit'}
                                ref={this.dpMain}
                            />
                            <Row>
                                <Col xs={12}>{this.renderButtons()}</Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                {this.props.defDossierId ? null : (
                    <Col xs={12}>
                        <Button
                            className={'goBackButton'}
                            variant={'secondary'}
                            onClick={() => this.props.history.goBack()}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                            &nbsp; {this.props.kedo.t('Back to overview')}
                        </Button>
                    </Col>
                )}
            </Row>
        )
    }
}

export default NewContentDossier
