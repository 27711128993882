import React from 'react'
import {Form} from "react-bootstrap";

class AutoIncrementField extends React.Component {
    render() {
        if (this.props.mode === 'search' || this.props.mode === 'advanced-search') {
            return <Form.Control onKeyPress={this.props.onKeyPress ? (event) => this.props.onKeyPress(this.props.item.id, event): null} value={this.props.value} onChange={(event) =>  this.props.changeValue(this.props.item.id, event.target.value)} type="text"/>
        }

        return this.props.value ? this.props.value : '';
    }
}

export default AutoIncrementField