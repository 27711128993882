import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import SelectList from '../../../Elements/SelectList'

class BlockFormTemplateSelect extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    renderTemplateOptions() {
        if (!this.props.template) {
            return ''
        }
        const kedo = this.props.kedo
        let fieldNames = []
        switch (this.props.template) {
            case 'cardPerson':
                fieldNames = ['title', 'picture', 'description']
                break
            case 'cardCarousel':
                fieldNames = ['title', 'picture', 'subtitle', 'description']
                break
            case 'cardTimeline':
                fieldNames = [
                    'date',
                    'title',
                    'picture',
                    'subtitle',
                    'description',
                ]
                break
            case 'calendar':
                fieldNames = ['name', 'start', 'end', 'location']
                break
        }

        return (
            <div>
                {fieldNames.map((fieldName) => (
                    <Form.Group key={fieldName}>
                        <Form.Label>
                            {kedo.t(`template_${fieldName}`)}
                        </Form.Label>
                        <SelectList
                            currentValue={
                                this.props.templateOptions &&
                                this.props.templateOptions[fieldName]
                                    ? this.props.templateOptions[fieldName]
                                    : null
                            }
                            onClear={() =>
                                this.props.handleTemplateOptionsChange(
                                    fieldName,
                                    null
                                )
                            }
                            searchName={'search'}
                            disableInit={true}
                            kedo={kedo}
                            urlParams={{
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.props.defDossier,
                                view: this.props.view_name,
                            }}
                            onDisplayName={(item) =>
                                kedo
                                    .env()
                                    .translateItem(item, 'displayitem', true)
                            }
                            url={kedo.api().getDisplayItemEndpoint()}
                            onSelect={(item) =>
                                this.props.handleTemplateOptionsChange(
                                    fieldName,
                                    item.id
                                )
                            }
                        />
                    </Form.Group>
                ))}
            </div>
        )
    }

    renderTemplates() {
        let options = ['default']
        if (this.props.layout === 'cards') {
            options = [
                'cardDefault',
                'cardCarousel',
                'cardPerson',
                'cardListVariant',
                'cardTimeline',
            ]
        }

        return (
            <Form.Group>
                <Form.Label>{this.props.kedo.t('Template')}</Form.Label>
                <Form.Control
                    value={this.props.template}
                    as="select"
                    name={'template'}
                    onChange={this.props.handleNormalChange}
                >
                    <option value={''}>
                        {this.props.kedo.t('Choose an option')}
                    </option>
                    {options.map((template) => (
                        <option key={template} value={template}>
                            {this.props.kedo.t(`block_template_${template}`)}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        )
    }

    handleChange(event) {
        if (event.target.value === 'calendar') {
            this.props.handleChangeVar('template', 'calendar')
        } else {
            this.props.handleChangeVar('template', null)
        }

        this.props.handleNormalChange(event)
    }

    render() {
        const kedo = this.props.kedo
        return (
            <div>
                <h3>{kedo.t('Template')}</h3>
                <Form.Group>
                    <Form.Label>{kedo.t('Layout')}</Form.Label>
                    <Form.Control
                        value={this.props.layout}
                        as="select"
                        name={'layout'}
                        onChange={this.handleChange}
                    >
                        <option value={''}>{kedo.t('Choose an option')}</option>
                        <option value={'table'}>{kedo.t('Table')}</option>
                        <option value={'cards'}>{kedo.t('Cards')}</option>
                        <option value={'calendar'}>{kedo.t('Calendar')}</option>
                    </Form.Control>
                </Form.Group>
                {this.props.layout !== 'calendar'
                    ? this.renderTemplates()
                    : null}
                {this.renderTemplateOptions()}
            </div>
        )
    }
}

export default BlockFormTemplateSelect
