import React from 'react'
import { Button, ButtonGroup, Modal, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class ContentDossierArchive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
        }

        this.archive = this.archive.bind(this)
        this.dearchive = this.dearchive.bind(this)
    }

    archive() {
        let url =
            this.props.kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id +
            '/archive'
        this.setState({ archiving: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) => {
                let dossier = this.props.dossier
                dossier.archived = true
                this.setState({
                    dossier: dossier,
                    archiving: false,
                    showModal: false,
                })
            })
    }

    dearchive() {
        let url =
            this.props.kedo.api().getContentDossierEndpoint() +
            '/' +
            this.props.dossier.id +
            '/dearchive'
        this.setState({ archiving: true })
        this.props.kedo
            .api()
            .get(url)
            .then((response) => {
                let dossier = this.props.dossier
                dossier.archived = false
                this.setState({ dossier: dossier, archiving: false })
            })
    }

    render() {
        return (
            <>
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.kedo.t('Archive')}{' '}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.props.kedo.t('confirm_archive')}</p>

                        <Row>
                            <Col md={12} className={'modal-footer'}>
                                <hr />
                                <ButtonGroup className={'float-right'}>
                                    <Button
                                        onClick={this.archive}
                                        variant={'primary'}
                                    >
                                        {this.state.archiving === true ? (
                                            <LoadingDefault
                                                as={'span'}
                                                size={'sm'}
                                            />
                                        ) : (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faFolder}
                                                />{' '}
                                                &nbsp;
                                                {this.props.kedo.t('Archive')}
                                            </>
                                        )}
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        onClick={() =>
                                            this.setState({ showModal: false })
                                        }
                                        variant={'secondary'}
                                    >
                                        {this.props.kedo.t('Cancel')}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {this.props.dossier.archived === true ? (
                    <Button
                        onClick={this.dearchive}
                        variant="outline-primary"
                        title="De-archiveren"
                        className={'active'}
                    >
                        {this.state.archiving === true ? (
                            <LoadingDefault as={'span'} size={'sm'} />
                        ) : (
                            <FontAwesomeIcon icon={faFolder} />
                        )}
                    </Button>
                ) : (
                    <Button
                        size={this.props.size}
                        onClick={() => this.setState({ showModal: true })}
                        variant="outline-primary"
                        title="Archiveren"
                    >
                        <FontAwesomeIcon icon={faFolder} />
                    </Button>
                )}
            </>
        )
    }
}

export default ContentDossierArchive
