import React, { Component } from 'react'
import { Alert, Button, Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import SelectList from '../Elements/SelectList'

class DisplayCustomViewForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            viewName: this.props.item ? this.props.item.viewName : null,
            defDossier: this.props.item
                ? this.props.item.def_dossier_id
                : this.props.defDossierId,
        }

        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    submit() {
        this.setState({
            submitting: true,
            errors: false,
        })

        let api = this.props.kedo.api()

        let item = {
            viewName: this.state.viewName,
            environment: this.props.kedo.env().getEnvironment().id,
            defDossier: this.state.defDossier,
        }

        if (this.props.item && this.props.item.id) {
            api.patch(
                api.getDisplayCustomViewEndpoint(this.props.item.id),
                item
            )
                .then((successResponse) => this.props.onSuccess())
                .catch((error) =>
                    this.setState({
                        submitting: false,
                        error: api.getErrorFromApiCall(error.response),
                    })
                )
        } else {
            api.post(api.getDisplayCustomViewEndpoint(), item)
                .then((successResponse) => this.props.onSuccess())
                .catch((error) =>
                    this.setState({
                        submitting: false,
                        error: api.getErrorFromApiCall(error.response),
                    })
                )
        }
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal size={'lg'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.item && this.props.item.id
                            ? this.props.kedo.t('Edit')
                            : this.props.kedo.t('Add')}{' '}
                        {this.props.kedo.t('Display custom view').toLowerCase()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    {this.state.error && this.state.error.generic ? (
                        <Alert variant={'danger'}>
                            {this.state.error.generic}
                        </Alert>
                    ) : null}
                    <Tab.Container defaultActiveKey="generic">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="generic">
                                            {kedo.t('Generic')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="generic">
                                        <h3>{kedo.t('Generic')}</h3>
                                        <Form.Group controlId="name">
                                            <Form.Label>
                                                {this.props.kedo.t('Name')}
                                            </Form.Label>
                                            <Form.Control
                                                onChange={this.handleChange}
                                                name={'viewName'}
                                                type={'text'}
                                                value={this.state.viewName}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="entity">
                                            <Form.Label>
                                                {this.props.kedo.t('Entity')}
                                            </Form.Label>
                                            <SelectList
                                                currentValue={
                                                    this.state.defDossier
                                                }
                                                disableInit={true}
                                                searchName={'search'}
                                                kedo={kedo}
                                                urlParams={{
                                                    environment: this.props.kedo
                                                        .env()
                                                        .getEnvironment().id,
                                                }}
                                                onDisplayName={(item) => {
                                                    return this.props.kedo
                                                        .env()
                                                        .translateItem(
                                                            item,
                                                            'defdossier'
                                                        )
                                                }}
                                                url={kedo
                                                    .api()
                                                    .getDefDossierEndpoint()}
                                                onSelect={(item) => {
                                                    this.setState({
                                                        defDossier: item.id,
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.submit} variant="primary">
                        {this.state.submitting === true ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DisplayCustomViewForm
