import React, { Component } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle'
import logo from '../../../images/xlab-logo.jpg'
import Loading from '../../Elements/Loading/LoadingData'

class EnvironmentDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            EditEnvironmentForm: false,
            description: null,
            details: [],
            detailsLoaded: false,
            errorFields: {},
            errors: false,
            file: '',
            imagePreviewUrl: '',
            name: null,
            saving: false,
        }

        this.handleClose = this.handleClose.bind(this)
    }

    fetchDetails() {
        this.setState({ loading: true })
        this.props.kedo
            .api()
            .get(
                this.props.kedo.api().getEnvironmentEndpoint() +
                    '/' +
                    this.props.item.id
            )
            .then((response) => {
                this.setState({
                    details: response.data,
                    loading: false,
                })
            })
    }

    handleClose(openEnvironment) {
        this.setState({ EditEnvironmentForm: false })
        if (openEnvironment) {
            this.props.environment()
        }
    }

    getLogo(item) {
        if (!item.logo || !item.logo.base64) {
            return <img className={'avatar'} src={logo} alt={'Logo'} />
        }

        return (
            <img
                className={'avatar'}
                src={`data:image/jpeg;base64,${item.logo.base64}`}
                alt={'Logo ' + item.name}
            />
        )
    }

    environmentDetails() {
        const kedo = this.props.kedo

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.handleClose}
                show={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.details.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.loading ? (
                        <Loading />
                    ) : (
                        <Row>
                            <Col sm={4}>{this.getLogo(this.state.details)}</Col>
                            <Col sm={8}>
                                <Table striped responsive>
                                    <tbody>
                                        <tr>
                                            <th>{kedo.t('Name')}</th>
                                            <td>
                                                {this.state.details.name
                                                    ? this.state.details.name
                                                    : kedo.t('Unknown')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{kedo.t('Description')}</th>
                                            <td>
                                                {this.state.details.description
                                                    ? this.state.details
                                                          .description
                                                    : kedo.t('Unknown')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{kedo.t('Created by')}</th>
                                            <td>
                                                {this.state.details
                                                    .created_by &&
                                                this.state.details.created_by
                                                    .username
                                                    ? this.state.details
                                                          .created_by.username
                                                    : kedo.t('Unknown')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{kedo.t('Created at')}</th>
                                            <td>
                                                {kedo
                                                    .utils()
                                                    .dateFormat(
                                                        this.state.details
                                                            .created_at
                                                    )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                {kedo.t('Last modified on')}
                                            </th>
                                            <td>
                                                {this.state.details.modified_at
                                                    ? kedo
                                                          .utils()
                                                          .dateFormat(
                                                              this.state.details
                                                                  .modified_at
                                                          )
                                                    : kedo.t('Unknown')}{' '}
                                                (
                                                {this.state.details
                                                    .modified_by &&
                                                this.state.details.modified_by
                                                    .username
                                                    ? this.state.details
                                                          .modified_by.username
                                                    : kedo.t('Unknown')}
                                                )
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.handleClose(true)}
                        variant="primary"
                    >
                        {kedo.t('Open environment')}
                    </Button>
                    <Button
                        onClick={() => this.handleClose(false)}
                        variant="secondary"
                        title={'Cancel'}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <>
                {this.state.EditEnvironmentForm
                    ? this.environmentDetails()
                    : null}
                <Button
                    disabled={!this.props.item.active}
                    className={'float-right'}
                    onClick={() => {
                        this.fetchDetails()
                        this.setState({ EditEnvironmentForm: true })
                    }}
                    variant="secondary"
                >
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
            </>
        )
    }
}

export default EnvironmentDetails
