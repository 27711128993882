import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DefFieldImageCodeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageType: this.props.item && this.props.item.settings && this.props.item.settings.imageType ? this.props.item.settings.imageType : 'code128',
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if(event.target.getAttribute('type') === 'checkbox') {
            this.props.onChange(event.target.name, event.target.checked);
            this.setState({[event.target.name]: event.target.checked});
            return;
        }
        this.props.onChange(event.target.name, event.target.value);
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return  <div>
                    <h3>{this.props.kedo.t('Settings')}</h3>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Type')}</Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={this.onChange}
                            name={'imageType'}
                            value={this.state.imageType}>
                            <option value={'qrcode'}>{this.props.kedo.t('qrcode')}</option>
                            <option value={'code128'}>{this.props.kedo.t('code128')}</option>
                            <option value={'code39'}>{this.props.kedo.t('code39')}</option>
                        </Form.Control>
                    </Form.Group>
                </div>
    }
}

export default DefFieldImageCodeSettings