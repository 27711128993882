import React from 'react'
import { CardDeck, Col, Row } from 'react-bootstrap'

export default class BlockCardPerson extends React.Component {
    constructor(props) {
        super(props)
    }

    handleContent(event, dossier) {
        if (event.target.nodeName !== 'TD' && event.target.nodeName !== 'DIV') {
            event.preventDefault()
            return false
        }

        window.location.replace(`/contentdossier/${dossier.id}`)
    }

    getImageContent(imageField, dossierData) {
        if (!dossierData) {
            return ''
        }

        let baseUrl = this.props.kedo.api().getBaseUrl()
        let singleDossierData = false
        singleDossierData = dossierData.find(
            (data) => data.content.id === imageField.id
        )
        if (singleDossierData && singleDossierData.content) {
            singleDossierData = singleDossierData.content.content
        }

        if (!singleDossierData || singleDossierData.length <= 0) {
            return ''
        }

        return (
            <img src={`${baseUrl}/file/${singleDossierData[0].id}/download`} />
        )
    }

    renderContentCard(dossierData, dossier, listItems) {
        const col = this.props.block.col

        if (
            !this.props.block ||
            !this.props.block.settings ||
            !this.props.block.settings.templateOptions
        ) {
            return ''
        }

        let pictureId = this.props.block.settings.templateOptions.picture
        let pictureField = listItems.find(
            (listItem) => listItem.id === pictureId
        )

        let titleId = this.props.block.settings.templateOptions.title
        let titleField = listItems.find((listItem) => listItem.id === titleId)

        let descId = this.props.block.settings.templateOptions.description
        let descField = listItems.find((listItem) => listItem.id === descId)

        return (
            <Col
                xs={12}
                md={col && col >= 6 && col <= 12 ? col : 12}
                lg={col && col > 0 && col <= 12 ? col : 12}
            >
                <div className="card mt-3">
                    <div className="box">
                        {pictureField ? (
                            <div className="img">
                                {this.getImageContent(
                                    pictureField,
                                    dossierData
                                )}
                            </div>
                        ) : null}
                        {titleField ? (
                            <h2>
                                {this.props.getValue(
                                    titleField,
                                    dossierData,
                                    dossier
                                )}
                            </h2>
                        ) : null}
                        {descField
                            ? this.props.getValue(
                                  descField,
                                  dossierData,
                                  dossier
                              )
                            : null}
                    </div>
                </div>
            </Col>
        )
    }

    render() {
        const col = this.props.block.col

        if (
            !this.props.block ||
            !this.props.block.settings ||
            !this.props.block.settings.templateOptions
        ) {
            return ''
        }

        let pictureId = this.props.block.settings.templateOptions.picture
        let pictureField = this.props.listItems.find(
            (listItem) => listItem.id === pictureId
        )

        let titleId = this.props.block.settings.templateOptions.title
        let titleField = this.props.listItems.find(
            (listItem) => listItem.id === titleId
        )

        let descId = this.props.block.settings.templateOptions.description
        let descField = this.props.listItems.find(
            (listItem) => listItem.id === descId
        )

        const mdCol = col && col >= 6 && col <= 12 ? col : 12
        const lgCol = col && col > 0 && col <= 12 ? col : 12

        return (
            <Row className={''}>
                <CardDeck>
                    {this.props.data.dossiers.map((dossier) => {
                        let dossierData = this.props.data.contents.filter(
                            (dataItem) => dataItem.id === dossier.id
                        )

                        return (
                            <Col key={dossier.id} xs={12} md={mdCol} lg={lgCol}>
                                <div className="card mt-3">
                                    <div className="box">
                                        {pictureField ? (
                                            <div className="img">
                                                {this.getImageContent(
                                                    pictureField,
                                                    dossierData
                                                )}
                                            </div>
                                        ) : null}
                                        <h2>
                                            <a
                                                href={`/contentdossier/${dossier.id}`}
                                            >
                                                {titleField
                                                    ? this.props.getValue(
                                                          titleField,
                                                          dossierData,
                                                          dossier
                                                      )
                                                    : dossier.summary}
                                            </a>
                                        </h2>
                                        {descField
                                            ? this.props.getValue(
                                                  descField,
                                                  dossierData,
                                                  dossier
                                              )
                                            : null}
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </CardDeck>
            </Row>
        )
    }
}
