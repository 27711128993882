import * as React from 'react'
import { useState, useEffect } from 'react'
import {
    Table,
    Container,
    Form,
    Modal,
    Button,
    Alert,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/pro-duotone-svg-icons/faGear'
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons/faCircleInfo'

import { TabSelect } from '../utils/tabs'
import { processItems } from '../utils/automaticSelect'

interface ViewDataFieldSelect {
    props?: any
    dossiers: number[]
    option: string
    onFinish: boolean
    closeModal: () => void
}

type Dossier = {
    id?: number
    content?: any[]
    link_id?: number
    use_from?: number[]
}

type DataType = {
    environment: number
    dossiers?: {
        action: string
        ids: number[]
    }
    mergeDossier?: Dossier[]
}

const FieldSelect: React.FC<ViewDataFieldSelect> = ({
    props,
    dossiers,
    option,
    onFinish,
    closeModal,
}) => {
    const [items, setItems] = useState<
        {
            type: string
            name: string
            id: number
            content: any
        }[][]
    >([])
    const [links, setLinks] = useState<
        Record<number, { name: string; link_id: number; content: any }[]>
    >({})
    const [showAlert, setShowAlert] = useState(false)
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
    const [selectedCheckLink, setSelectedCheckLink] = useState([])
    const [dossierData, setDossierData] = useState([])
    const [linkData, setLinkData] = useState([])
    const [environmentId, setEnvironmentId] = useState<number>()
    const [modalLink, setModalLink] = useState<boolean>(false)
    const [modalConfi, setModalConfi] = useState<boolean>(false)
    const [selectedLink, setSelectedLink] = useState<string>('default')
    const [content, setContent] = useState([])
    const [newDossier, setNewDossier] = useState<number>(0)
    const [metadata, setMetadata] = useState([])

    const setMetaDataFromResponse = (dossierResponses) => {
        const metaDatas = []

        dossierResponses.map((dossierResponse) => {
            metaDatas.push({
                dossier_id: dossierResponse?.data?.dossier?.id,
                created_at: props.kedo
                    .utils()
                    .dateFormat(dossierResponse?.data?.dossier?.created_at),
                modified_at: props.kedo
                    .utils()
                    .dateFormat(dossierResponse?.data?.dossier?.modified_at),
                created_by: dossierResponse?.data?.dossier?.created_by,
                modified_by: dossierResponse?.data?.dossier?.modified_by,
                summary: dossierResponse?.data?.dossier?.summary || dossiers[0],
            })
        })

        setMetadata(metaDatas)
    }

    useEffect(() => {
        const setLoadingContent = async () => {
            try {
                const api = props.kedo.api()

                const responses = await Promise.all(
                    dossiers.map((valor) => {
                        const url = api.getContentDossierEndpoint(valor)
                        return api.get(url)
                    })
                )

                const combinedItems: {
                    name: string
                    id: number
                    content: any
                    type: string
                }[][] = []

                const combinedLinks: Record<
                    number,
                    {
                        name: string
                        link_id: number
                        content: any
                    }[]
                > = {}
                setContent(Object.keys(responses[0].data.content))
                setEnvironmentId(responses[0]?.data?.dossier?.environment_id)

                setMetaDataFromResponse(responses)

                responses.forEach((response) => {
                    const dossierItems = []
                    const dossierLinks = []
                    response.data.items.forEach((item: any) => {
                        const contentId = item.id
                        const newContent = response.data.content[contentId]
                        if (item.def_dossier_def_field?.name) {
                            dossierItems.push({
                                name: item.def_dossier_def_field?.name || '',
                                id: item?.id || '',
                                content: newContent || '',
                                type: item.def_dossier_def_field?.def_field
                                    ?.type,
                            })
                        } else if (item.def_dossier_link?.name) {
                            dossierLinks.push({
                                name: item.def_dossier_link?.name || '',
                                link_id: item?.id || '',
                                content: newContent || '',
                                multiple:
                                    item.def_dossier_link?.settings?.multiple ||
                                    false,
                            })
                        }
                    })
                    const dossierId = response.data.dossier.id

                    if (!combinedItems[dossierId]) {
                        combinedItems[dossierId] = []
                    }
                    combinedItems[dossierId].push(...dossierItems)

                    if (!combinedLinks[dossierId]) {
                        combinedLinks[dossierId] = []
                    }
                    combinedLinks[dossierId].push(...dossierLinks)
                })

                const defDossierId = responses[0]?.data?.dossier?.def_dossier_id

                const {
                    combinedItems: newCombinedItems,
                    combinedLinks: newCombinedLinks,
                    contentValue: newcontentValue,
                } = await TabSelect(props, defDossierId, dossiers)

                if (newcontentValue && newcontentValue.length > 0) {
                    setContent((prevContent) => [
                        ...prevContent,
                        ...newcontentValue,
                    ])
                    Object.entries(newCombinedItems).forEach(([id, items]) => {
                        if (!combinedItems[id]) {
                            combinedItems[id] = []
                        }
                        items.forEach((item) => {
                            const existingItem = combinedItems[id].find(
                                (existing: { id: number }) =>
                                    existing.id === item.id
                            )
                            if (!existingItem) {
                                combinedItems[id].push(item)
                            }
                        })
                    })

                    Object.entries(newCombinedLinks).forEach(([id, links]) => {
                        if (!combinedLinks[id]) {
                            combinedLinks[id] = []
                        }
                        links.forEach((link) => {
                            const existingLink = combinedLinks[id].find(
                                (existing: { id: number }) =>
                                    existing.id === link.link_id
                            )
                            if (existingLink) {
                                existingLink.content += link.content
                            } else {
                                combinedLinks[id].push(link)
                            }
                        })
                    })
                }

                setItems(combinedItems)
                setLinks(combinedLinks)
            } catch (error) {
                console.log(error)
            }
        }

        setLoadingContent()
    }, [props, dossiers])

    useEffect(() => {
        processItems(
            items,
            selectedCheckboxes,
            setDossierData,
            setSelectedCheckboxes
        )
    }, [items])

    useEffect(() => {
        const createNewDossier = async () => {
            try {
                const data: DataType = {
                    environment: environmentId,
                    dossiers: {
                        action: option,
                        ids: [dossiers[0], dossiers[1]],
                    },
                    mergeDossier: content
                        .map((key) => {
                            const numericKey = parseInt(key)
                            const matchingDossier = Object.values(
                                dossierData
                            ).find((entry) => entry.id === numericKey)

                            if (matchingDossier) {
                                if ('content' in matchingDossier) {
                                    if ('id' in matchingDossier) {
                                        return {
                                            id: numericKey,
                                            content: matchingDossier.content,
                                        }
                                    }
                                }

                                const ids = Object.values(matchingDossier)
                                    .filter(
                                        (entry) =>
                                            typeof entry === 'object' &&
                                            entry !== null &&
                                            'id' in entry
                                    )
                                    .map((entry: any) => entry.id)
                                    .filter((id) => id !== undefined)

                                if ('id' in matchingDossier) {
                                    return {
                                        id: numericKey,
                                        content: ids,
                                    }
                                }
                            }

                            const matchingLink = Object.values(
                                selectedCheckLink
                            ).find((entry) => {
                                return entry.link_id === numericKey
                            })

                            if (matchingLink) {
                                if (selectedLink === 'left') {
                                    return {
                                        link_id: numericKey,
                                        use_from: [dossiers[0]],
                                    }
                                } else if (selectedLink === 'right') {
                                    return {
                                        link_id: numericKey,
                                        use_from: [dossiers[1]],
                                    }
                                } else if (selectedLink === 'default') {
                                    return {
                                        link_id: numericKey,
                                        use_from: [dossiers[0], dossiers[1]],
                                    }
                                }
                            }

                            let inFirstArray = false
                            let inSecondArray = false

                            if (linkData[0]) {
                                inFirstArray = linkData[0].some(
                                    (item: { link_id: number }) =>
                                        item.link_id === numericKey
                                )
                            }

                            if (linkData[1]) {
                                inSecondArray = linkData[1].some(
                                    (item: { link_id: number }) =>
                                        item.link_id === numericKey
                                )
                            }

                            if (inFirstArray && inSecondArray) {
                                return {
                                    link_id: numericKey,
                                    use_from: [dossiers[0], dossiers[1]],
                                }
                            } else if (inFirstArray) {
                                return {
                                    link_id: numericKey,
                                    use_from: [dossiers[0]],
                                }
                            } else if (inSecondArray) {
                                return {
                                    link_id: numericKey,
                                    use_from: [dossiers[1]],
                                }
                            }
                        })
                        .filter((entry) => entry !== undefined),
                }
                const api = props.kedo.api()
                const url = api.getContentDossierEndpoint()

                const responseDossier = await api.post(url + '/merge', data)
                const idNewDossier = responseDossier.data.id

                setNewDossier(idNewDossier)

                setModalConfi(true)
            } catch (error) {
                console.log(error)
            }
        }

        if (onFinish) {
            createNewDossier()
        }
    }, [onFinish])

    useEffect(() => {
        let specificLinks = []

        if (selectedLink === 'left') {
            const firstKey = Object.keys(links)[0]
            specificLinks = links[firstKey]
        } else if (selectedLink === 'right') {
            const secondKey = Object.keys(links)[1]
            specificLinks = links[secondKey]
        } else if (selectedLink === 'default') {
            specificLinks = Object.values(links).flat()
        }

        setSelectedCheckLink(specificLinks)
    }, [selectedLink, links])

    const handleCheckboxChange = (
        rowIndex?: number,
        dossierIndex?: number,
        contentIndex?: number,
        contentItem?: { name: string; id: number; content: any },
        dossierId?: any
    ) => {
        setSelectedCheckboxes((prevSelected) => {
            const newSelected = { ...prevSelected }

            if (rowIndex !== undefined) {
                if (contentIndex !== undefined) {
                    if (!Array.isArray(newSelected[rowIndex])) {
                        newSelected[rowIndex] = []
                    }

                    newSelected[rowIndex][contentIndex] = dossierIndex
                } else {
                    newSelected[rowIndex] = dossierIndex
                }
            }

            return newSelected
        })

        setDossierData((prevSelected) => {
            const newSelected = { ...prevSelected }

            if (contentIndex !== undefined) {
                if (!newSelected[rowIndex]) {
                    newSelected[rowIndex] = []
                }

                newSelected[rowIndex][contentIndex] = contentItem
                if (newSelected[rowIndex].length >= 1) {
                    newSelected[rowIndex].id = dossierId.id
                }
            } else {
                newSelected[rowIndex] = contentItem
            }

            return newSelected
        })
    }

    const handleCheckboxLink = (
        rowIndex: number,
        dossierIndex: number,
        contentIndex: { name: string; link_id: number; content: any }
    ) => {
        setLinkData((prevLinkData) => {
            const updatedLinkData = { ...prevLinkData }

            if (!updatedLinkData[dossierIndex]) {
                updatedLinkData[dossierIndex] = []
            }

            const index = updatedLinkData[dossierIndex].findIndex(
                (item: { rowIndex: number; link_id: number }) =>
                    item.rowIndex === rowIndex &&
                    item.link_id === contentIndex.link_id
            )

            if (index !== -1) {
                updatedLinkData[dossierIndex].splice(index, 1)
            } else {
                updatedLinkData[dossierIndex].push({
                    ...contentIndex,
                    rowIndex,
                })
            }

            return updatedLinkData
        })

        setSelectedCheckLink((prevSelected) => {
            const newSelected = { ...prevSelected }

            if (!newSelected[rowIndex]) {
                newSelected[rowIndex] = []
            }

            if (newSelected[rowIndex].includes(dossierIndex)) {
                newSelected[rowIndex] = newSelected[rowIndex].filter(
                    (index: number) => index !== dossierIndex
                )
            } else {
                newSelected[rowIndex].push(dossierIndex)
            }

            return newSelected
        })
    }

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target

        setSelectedLink((prev) => (prev === value ? '' : value))
        setSelectedCheckLink([])
    }

    const confirmationModal = () => {
        if (selectedLink) {
            setModalLink(false)
            setShowAlert(false)
        } else {
            setShowAlert(true)
        }
    }

    const modalConfigurationLink = () => {
        const radioOptions = [
            {
                id: 'default',
                label: 'By default, copy all links of all selected files cumulatively. (Will have no value)',
                value: 'default',
            },
            {
                id: 'left',
                label: `Copy all value from the dossier ${metadata[0]?.summary}.`,
                value: 'left',
            },
            {
                id: 'right',
                label: `Copy all value from the dossier ${metadata[1]?.summary}.`,
                value: 'right',
            },
            {
                id: 'selectedlinks',
                label: 'Custom selection of linked elements.',
                value: 'selectedlinks',
            },
        ]

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalLink}
                onHide={() => setModalLink(false)}
            >
                <Modal.Header>
                    <Modal.Title>
                        {props.kedo.t('Configure link settings')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {radioOptions.map((option) => (
                        <Form.Check
                            key={option.id}
                            type="radio"
                            id={option.id}
                            label={
                                <>
                                    {props.kedo.t(option.label)}{' '}
                                    {(['left', 'right'].includes(option.id) && (
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip
                                                    id={`tooltip-dossier-${option.id}`}
                                                >
                                                    {'Id dossier: '}
                                                    {option.id === 'left'
                                                        ? dossiers[0]
                                                        : dossiers[1]}
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={faCircleInfo}
                                            />
                                        </OverlayTrigger>
                                    )) ||
                                        null}
                                </>
                            }
                            value={option.value}
                            checked={selectedLink === option.value}
                            onChange={handleLinkChange}
                        />
                    ))}
                    {showAlert && (
                        <Alert variant="danger" className="mt-2">
                            {props.kedo.t('You must select an option')}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => confirmationModal()}
                    >
                        {props.kedo.t('Set')}
                    </Button>
                    <Button
                        className="ml-4"
                        variant="secondary"
                        onClick={() => setModalLink(false)}
                    >
                        {props.kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const modalResponse = () => {
        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalConfi}
                    onHide={() => setModalConfi(false)}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {props.kedo.t('Confirmation')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {props.kedo.t(
                                'The dossier has been successfully created'
                            )}
                        </p>

                        <Link to={`/contentdossier/` + newDossier}>
                            {props.kedo.t('Click to go to the new dossier')}{' '}
                            <strong>{newDossier}</strong>
                        </Link>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setModalLink(false),
                                    setModalConfi(false),
                                    closeModal()
                            }}
                        >
                            {props.kedo.t('Success')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const linkclassification = () => {
        let specificLink = links

        if (selectedLink === 'left') {
            const firstKey = Object.keys(links)[0]
            specificLink = { [firstKey]: links[firstKey] }
        } else if (selectedLink === 'right') {
            const secondKey = Object.keys(links)[1]
            specificLink = { [secondKey]: links[secondKey] }
        }

        return (
            <tbody>
                {selectedLink === 'left' || selectedLink === 'right'
                    ? Object.values(links)[0].map((_, rowIndex) => (
                          <tr key={rowIndex}>
                              <td>
                                  <strong>
                                      {Object.values(links)[0][
                                          rowIndex
                                      ]?.name.replace(/_/g, ' ') || ''}
                                  </strong>
                              </td>
                              {Object.values(specificLink).map(
                                  (dossierLinks, dossierIndex) => (
                                      <td key={dossierIndex}>
                                          {Array.isArray(
                                              dossierLinks[rowIndex]?.content
                                          ) && (
                                              <p>
                                                  {` has ${dossierLinks[rowIndex]?.content.length} items`}
                                              </p>
                                          )}
                                      </td>
                                  )
                              )}
                          </tr>
                      ))
                    : Object.values(links)[0].map((_, rowIndex) => (
                          <tr key={rowIndex}>
                              <td>
                                  <strong>
                                      {Object.values(links)[0][
                                          rowIndex
                                      ]?.name.replace(/_/g, ' ') || ''}
                                  </strong>
                              </td>
                              {Object.values(specificLink).map(
                                  (dossierLinks, dossierIndex) => (
                                      <td key={dossierIndex}>
                                          {Array.isArray(
                                              dossierLinks[rowIndex]?.content
                                          ) && (
                                              <Form.Check
                                                  type="checkbox"
                                                  id={`link-${rowIndex}-${dossierIndex}`}
                                                  label={` has ${dossierLinks[rowIndex]?.content.length} items`}
                                                  checked={
                                                      selectedCheckLink[
                                                          rowIndex
                                                      ] &&
                                                      selectedCheckLink[
                                                          rowIndex
                                                      ].includes(dossierIndex)
                                                  }
                                                  onChange={() =>
                                                      handleCheckboxLink(
                                                          rowIndex,
                                                          dossierIndex,
                                                          dossierLinks[rowIndex]
                                                      )
                                                  }
                                              />
                                          )}
                                      </td>
                                  )
                              )}
                          </tr>
                      ))}
            </tbody>
        )
    }

    const selectedLabel = () => {
        switch (selectedLink) {
            case 'default':
                return 'By default, copy all links of all selected files cumulatively.'
            case 'left':
                return `Copy all value from the dossier ${metadata[0]?.summary}.`
            case 'right':
                return `Copy all value from the dossier ${metadata[1]?.summary}.`
            case 'selectedlinks':
                return 'Custom selection of linked elements.'
            default:
                return 'By default, copy all links of all selected files cumulatively.'
        }
    }

    const renderShow = () => {
        return (
            <>
                <Container>
                    <Table size="sm" striped hover responsive>
                        <thead>
                            <tr>
                                <th>{props.kedo.t('Field')}</th>
                                {metadata.map((item, index) => (
                                    <th key={index}>
                                        Dossier {item.summary}&nbsp;(
                                        {dossiers[index]})&nbsp;
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={
                                                <Tooltip
                                                    id={`tooltip-dossier-${item.summary}`}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <strong>
                                                            Dossier id:
                                                        </strong>{' '}
                                                        {item?.dossier_id}{' '}
                                                        <br />
                                                        <strong>
                                                            Created at:
                                                        </strong>{' '}
                                                        {item?.created_at}{' '}
                                                        <br />
                                                        <strong>
                                                            Modified at:
                                                        </strong>{' '}
                                                        {item?.modified_at}{' '}
                                                        <br />
                                                        <strong>
                                                            Created by:
                                                        </strong>{' '}
                                                        {
                                                            item?.created_by
                                                                ?.username
                                                        }{' '}
                                                        <br />
                                                        <strong>
                                                            Modified by:
                                                        </strong>{' '}
                                                        {
                                                            item?.modified_by
                                                                ?.username
                                                        }
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={faCircleInfo}
                                            />
                                        </OverlayTrigger>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(items).length > 0 &&
                                Object.values(items)[0].map((_, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>
                                            <strong>
                                                {Object.values(items)[0][
                                                    rowIndex
                                                ]?.name.replace(/_/g, ' ') ||
                                                    ''}
                                            </strong>
                                        </td>

                                        {Object.values(items).map(
                                            (dossierItems, dossierIndex) => (
                                                <td key={dossierIndex}>
                                                    {Array.isArray(
                                                        dossierItems[rowIndex]
                                                            ?.content
                                                    ) ? (
                                                        dossierItems[
                                                            rowIndex
                                                        ].content.map(
                                                            (
                                                                contentItem,
                                                                contentIndex
                                                            ) => (
                                                                <Form.Check
                                                                    key={
                                                                        contentIndex
                                                                    }
                                                                    type="checkbox"
                                                                    id={`checkbox-${rowIndex}-${dossierIndex}-${contentIndex}`}
                                                                    label={
                                                                        contentItem?.text ||
                                                                        contentItem?.filename ||
                                                                        contentItem
                                                                            ?.translations?.[0]
                                                                            ?.text ||
                                                                        ''
                                                                    }
                                                                    checked={
                                                                        selectedCheckboxes[
                                                                            rowIndex
                                                                        ] &&
                                                                        selectedCheckboxes[
                                                                            rowIndex
                                                                        ][
                                                                            contentIndex
                                                                        ] ===
                                                                            dossierIndex
                                                                    }
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            rowIndex,
                                                                            dossierIndex,
                                                                            contentIndex,
                                                                            contentItem,
                                                                            dossierItems[
                                                                                rowIndex
                                                                            ]
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )
                                                    ) : (
                                                        <Form.Check
                                                            type="radio"
                                                            id={`checkbox-${rowIndex}-${dossierIndex}`}
                                                            disabled={
                                                                !dossierItems[
                                                                    rowIndex
                                                                ]?.content ||
                                                                dossierItems[
                                                                    rowIndex
                                                                ].content === ''
                                                            }
                                                            label={
                                                                dossierItems[
                                                                    rowIndex
                                                                ]?.content ||
                                                                (dossierItems[
                                                                    rowIndex
                                                                ]?.type ===
                                                                'calc'
                                                                    ? props.kedo.t(
                                                                          'This is a formula field and will be recalculated.'
                                                                      )
                                                                    : props.kedo.t(
                                                                          'There is no value.'
                                                                      ))
                                                            }
                                                            checked={
                                                                selectedCheckboxes[
                                                                    rowIndex
                                                                ] ===
                                                                dossierIndex
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxChange(
                                                                    rowIndex,
                                                                    dossierIndex,
                                                                    undefined,
                                                                    dossierItems[
                                                                        rowIndex
                                                                    ],
                                                                    dossierItems[
                                                                        rowIndex
                                                                    ].id
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>

                    <section className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <p>
                                <Button
                                    onClick={() => {
                                        setModalLink(true)
                                    }}
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faGear} />{' '}
                                    {props.kedo.t('Configuration links')}
                                </Button>
                            </p>
                            <p>
                                <strong> {props.kedo.t('Selected')}: </strong>
                                {selectedLabel()}
                            </p>
                        </div>
                        {selectedLink === 'default' ? (
                            <p>
                                <strong>
                                    {props.kedo.t(
                                        'No action needed, everything will be copied cumulative.'
                                    )}
                                </strong>
                            </p>
                        ) : null}
                    </section>

                    {modalConfigurationLink()}
                    {modalLink == false &&
                    selectedLink &&
                    selectedLink !== 'default' ? (
                        <Table size="sm" striped hover responsive>
                            <thead>
                                <tr>
                                    <th>{props.kedo.t('Link')}</th>
                                    {selectedLink === 'left' && (
                                        <th>
                                            Summary {metadata[0]?.summary}
                                            &nbsp;({dossiers[0]})&nbsp;
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip
                                                        id={`tooltip-dossier-${metadata[0].summary}`}
                                                    >
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            <strong>
                                                                Dossier id:
                                                            </strong>{' '}
                                                            {
                                                                metadata[0]
                                                                    ?.dossier_id
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Created at:
                                                            </strong>{' '}
                                                            {
                                                                metadata[0]
                                                                    ?.created_at
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Modified at:
                                                            </strong>{' '}
                                                            {
                                                                metadata[0]
                                                                    ?.modified_at
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Created by:
                                                            </strong>{' '}
                                                            {
                                                                metadata[0]
                                                                    ?.created_by
                                                                    ?.username
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Modified by:
                                                            </strong>{' '}
                                                            {
                                                                metadata[0]
                                                                    ?.modified_by
                                                                    ?.username
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faCircleInfo}
                                                />
                                            </OverlayTrigger>
                                        </th>
                                    )}
                                    {selectedLink === 'right' && (
                                        <th>
                                            Summary {metadata[1]?.summary}
                                            &nbsp;({dossiers[1]})&nbsp;
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={
                                                    <Tooltip
                                                        id={`tooltip-dossier-${metadata[1].summary}`}
                                                    >
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            <strong>
                                                                Dossier id:
                                                            </strong>{' '}
                                                            {
                                                                metadata[1]
                                                                    ?.dossier_id
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Created at:
                                                            </strong>{' '}
                                                            {
                                                                metadata[1]
                                                                    ?.created_at
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Modified at:
                                                            </strong>{' '}
                                                            {
                                                                metadata[1]
                                                                    ?.modified_at
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Created by:
                                                            </strong>{' '}
                                                            {
                                                                metadata[1]
                                                                    ?.created_by
                                                                    ?.username
                                                            }{' '}
                                                            <br />
                                                            <strong>
                                                                Modified by:
                                                            </strong>{' '}
                                                            {
                                                                metadata[1]
                                                                    ?.modified_by
                                                                    ?.username
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faCircleInfo}
                                                />
                                            </OverlayTrigger>
                                        </th>
                                    )}
                                    {selectedLink === 'selectedlinks' &&
                                        metadata.map((item, index) => (
                                            <th key={index}>
                                                Dossier {item.summary}&nbsp;(
                                                {dossiers[index]})&nbsp;
                                                <OverlayTrigger
                                                    placement={'bottom'}
                                                    overlay={
                                                        <Tooltip
                                                            id={`tooltip-dossier-${item.summary}`}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                }}
                                                            >
                                                                <strong>
                                                                    Dossier id:
                                                                </strong>{' '}
                                                                {
                                                                    item?.dossier_id
                                                                }{' '}
                                                                <br />
                                                                <strong>
                                                                    Created at:
                                                                </strong>{' '}
                                                                {
                                                                    item?.created_at
                                                                }{' '}
                                                                <br />
                                                                <strong>
                                                                    Modified at:
                                                                </strong>{' '}
                                                                {
                                                                    item?.modified_at
                                                                }{' '}
                                                                <br />
                                                                <strong>
                                                                    Created by:
                                                                </strong>{' '}
                                                                {
                                                                    item
                                                                        ?.created_by
                                                                        ?.username
                                                                }{' '}
                                                                <br />
                                                                <strong>
                                                                    Modified by:
                                                                </strong>{' '}
                                                                {
                                                                    item
                                                                        ?.modified_by
                                                                        ?.username
                                                                }
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faCircleInfo}
                                                    />
                                                </OverlayTrigger>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            {linkclassification()}
                        </Table>
                    ) : null}

                    {modalConfi == true ? modalResponse() : null}
                </Container>
            </>
        )
    }

    return renderShow()
}

export default FieldSelect
