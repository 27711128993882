import React, {Component} from 'react'
import {Form} from "react-bootstrap";

class DefFieldAmountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currency: this.props.item && this.props.item.settings && this.props.item.settings.currency ? this.props.item.settings.currency : null,
            decimals: this.props.item && this.props.item.settings && this.props.item.settings.decimals ? this.props.item.settings.decimals : null,
            valueType: this.props.item && this.props.item.settings && this.props.item.settings.valueType ? this.props.item.settings.valueType : 'number',
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if(event.target.getAttribute('type') === 'checkbox') {
            this.props.onChange(event.target.name, event.target.checked);
            this.setState({[event.target.name]: event.target.checked});
            return;
        }
        this.props.onChange(event.target.name, event.target.value);
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return  <div>
                    <h3>{this.props.kedo.t('Settings')}</h3>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Type')}</Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={this.onChange}
                            name={'valueType'}
                            value={this.state.valueType}>
                            <option value={'currency'}>{this.props.kedo.t('Currency')}</option>
                            <option value={'number'}>{this.props.kedo.t('Number')}</option>
                            <option value={'percentage'}>{this.props.kedo.t('Percentage')}</option>
                        </Form.Control>
                    </Form.Group>
                    {this.state.valueType === 'currency' || this.state.valueType === 'number'  ? <Form.Group>
                        <Form.Label>{this.props.kedo.t('Decimals')}</Form.Label>
                        <Form.Control
                            value={this.state.decimals}
                            onChange={this.onChange}
                            name={'decimals'}
                            type={'number'}
                        />
                    </Form.Group> : null}
                    {this.state.valueType === 'currency' ? <Form.Group>
                        <Form.Label>{this.props.kedo.t('Currency')}</Form.Label>
                        <Form.Control
                            value={this.state.currency}
                            onChange={this.onChange}
                            name={'currency'}
                            as={'select'}
                        >
                            <option value={''}>{this.props.kedo.t('Choose an option')}</option>
                            <option value={'COP'}>{this.props.kedo.t('COP')} Colombian Peso</option>
                            <option value={'CRC'}>{this.props.kedo.t('CRC')} Costa Rican Colón</option>
                            <option value={'EUR'}>{this.props.kedo.t('EUR')} Euro</option>
                            <option value={'GBP'}>{this.props.kedo.t('GBP')} British Pound Sterling</option>
                            <option value={'ILS'}>{this.props.kedo.t('INR')} Israeli New Sheqel</option>
                            <option value={'INR'}>{this.props.kedo.t('INR')} Indian Rupee</option>
                            <option value={'JPY'}>{this.props.kedo.t('JPY')} Japanese Yen</option>
                            <option value={'KRW'}>{this.props.kedo.t('KRW')} South Korean Won</option>
                            <option value={'MAD'}>{this.props.kedo.t('MAD')} Moroccan dirham</option>
                            <option value={'NGN'}>{this.props.kedo.t('NGN')} Nigerian Naira</option>
                            <option value={'PYG'}>{this.props.kedo.t('PYG')} Paraguayan Guarani</option>
                            <option value={'PLN'}>{this.props.kedo.t('PLN')} Polish Zloty</option>
                            <option value={'THB'}>{this.props.kedo.t('THB')} Thai Baht</option>
                            <option value={'USD'}>{this.props.kedo.t('USD')} US Dollar</option>
                            <option value={'UAH'}>{this.props.kedo.t('UAH')} Ukrainian Hryvnia</option>
                            <option value={'VND'}>{this.props.kedo.t('VND')} Vietnamese Dong</option>
                        </Form.Control>
                    </Form.Group> : null}
                </div>
    }
}

export default DefFieldAmountSettings