import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine'
import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp'
import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown'
import { faCogs } from '@fortawesome/pro-duotone-svg-icons/faCogs'

class AdminMainMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            displayAdminSettings: false,
        }

        this.handleAdminSettings = this.handleAdminSettings.bind(this)
    }

    handleAdminSettings() {
        this.setState((state) => ({
            displayAdminSettings: !state.displayAdminSettings,
        }))
    }

    render() {
        const kedo = this.props.kedo

        return (
            <nav className="dropdown MainMenu">
                <NavLink
                    activeClassName="selectedLink"
                    className={'menu-item'}
                    key={'dashboard'}
                    exact
                    to={`/`}
                >
                    <li
                        className={'menuHeadItem dashboard'}
                        onClick={this.props.close}
                    >
                        <FontAwesomeIcon icon={faChartLine} /> &nbsp;{' '}
                        {kedo.t('Dashboard')}
                    </li>
                </NavLink>
                {kedo.isAdminOrEnvironmentAdmin() ? (
                    <div
                        onClick={this.handleAdminSettings}
                        className={'menuHeadItem admin'}
                    >
                        <FontAwesomeIcon icon={faCogs} />
                        &nbsp; {kedo.t('Admin settings')}
                        <FontAwesomeIcon
                            icon={
                                this.state.displayAdminSettings
                                    ? faChevronCircleUp
                                    : faChevronCircleDown
                            }
                            className={'dropArrow float-right'}
                        />
                    </div>
                ) : null}
                {this.state.displayAdminSettings ? (
                    <ul onClick={() => window.scrollTo(0, 0)}>
                        <NavLink
                            to={'/environment/edit'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Generic')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/datastructure'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Datastructures')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/erd'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('ERD')}
                            </li>
                        </NavLink>
                        {/*<NavLink to={'/layout'} className={"MainMenuLink"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>{kedo.t('Layout')}</li>*/}
                        {/*</NavLink>*/}
                        <NavLink
                            to={'/roles'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Roles')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/permissions'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Permissions')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/logs'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>{kedo.t('Logs')}</li>
                        </NavLink>
                        <NavLink
                            to={'/useractivity'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('User activity')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/files'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Files')}
                            </li>
                        </NavLink>
                        {/*<NavLink to={'/backups'} className={"MainMenuLink"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>{kedo.t('Backups')}</li>*/}
                        {/*</NavLink>*/}
                        <NavLink
                            to={'/user'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Users')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/languages'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Languages')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/blocks'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Blocks')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/modules'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Modules')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/events'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Events')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/wordkedocx'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Kedocx')}
                            </li>
                        </NavLink>
                        <NavLink
                            to={'/querybuilder'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Querybuilder')}
                            </li>
                        </NavLink>
                        {/*<NavLink to={'/reports'} className={"MainMenuLink"} activeClassName="selectedLink">*/}
                        {/*    <li onClick={this.props.close}>Reports</li>*/}
                        {/*</NavLink>*/}
                        <NavLink
                            to={'/webhooks'}
                            className={'MainMenuLink'}
                            activeClassName="selectedLink"
                        >
                            <li onClick={this.props.close}>
                                {kedo.t('Webhooks')}
                            </li>
                        </NavLink>
                    </ul>
                ) : null}
            </nav>
        )
    }
}

export default AdminMainMenu
